import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled, { CSSProperties } from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';

interface Props {
  images: Array<{ url: string }>;
  imageStyle?: CSSProperties;
}

function ImageList(props: Props) {
  const { images, imageStyle } = props;
  return (
    <Container>
      <SmallImagesContainer>
        {images.map((e, index) => {
          return (
            <SmallImageContainer style={imageStyle}>
              <PreviewImage
                src={images[index]?.url ?? images[index]}
                style={{ opacity: images[index] ? 1 : 0 }}
              />
              <RXIcon name="Plus" size={20} color={COLOR.Gray_300} />
              <Body1Text>เพิ่มรูป</Body1Text>
            </SmallImageContainer>
          );
        })}
      </SmallImagesContainer>
    </Container>
  );
}

export default ImageList;

const Container = styled.div``;

const PreviewImage = styled.img`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
`;

const SmallImagesContainer = styled.label`
  display: flex;
  flex-direction: row;
`;

const SmallImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 120px;
  margin: 16px 16px 0px 0px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.Gray_50};
  border: none;
  border-radius: 16px;

  :hover {
    cursor: pointer;
  }
`;

const Body1Text = styled(TEXT.body1)`
  color: ${COLOR.Gray_300};
  text-align: center;
`;
