import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RateCard } from '../../../types/api/getInfluencer';
import { PlatformType } from '../../../types/deal';
import ImageOverlay from '../../atoms/ImageOverlay';
import InfluencerCardBody from '../../atoms/Influencer/InfluencerCardBody';
import InfluencerCardHeader from '../../atoms/Influencer/InfluencerCardHeader';
import { CategoryType } from '../../organisms/CreateCampaign/Options';

interface ContainerProps {
  maxWidth: string;
  maxHeight: string;
  img: string;
}
interface Props {
  tier: string;
  platform: PlatformType;
  username: string;
  influencerRating: number;
  ratingCount: number;
  followers: number;
  influencerEngagement: number;
  profile_picture: string;
  cover_picture: string;
  id: string;
  accountId: string;
  attributes: string[];
  activeAttributes?: string[];
  category: string[];
  activeCategory?: CategoryType;
  rateCard: {
    instagram: Array<RateCard>;
    tiktok: Array<RateCard>;
    lemon: Array<RateCard>;
    facebook: Array<RateCard>;
  };
  lemonUrl?: string;
  prevPath: string;
  isSaved: boolean;
  onSavedClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  maxImageWidth?: string;
  maxImageHeight?: string;
}

const NewInfluencerCard = (props: Props) => {
  const {
    tier = 'nano',
    platform,
    username,
    ratingCount,
    influencerEngagement,
    influencerRating,
    followers,
    cover_picture,
    id,
    attributes = [],
    activeAttributes = [],
    lemonUrl,
    prevPath,
    isSaved,
    onSavedClick,
    rateCard,
    activeCategory,
    category,
    maxImageHeight = 'none',
    maxImageWidth = 'none'
  } = props;

  const link = `/favorite?influencerId=${id}&platform=${platform}`;
  const [currentImageUrl, setCurrentImageUrl] = useState(cover_picture);

  const handleImageLoad = () => {
    setCurrentImageUrl(cover_picture);
  };

  const handleImageError = () => {
    setCurrentImageUrl(
      require('../../../assets/images/Placeholder/Placeholder_influencerCard.png')
    );
  };

  useEffect(() => {
    const img = new Image();
    img.src = cover_picture;
    img.onload = handleImageLoad;
    img.onerror = handleImageError;
  }, [cover_picture]);

  return (
    <Container
      href={link}
      target="_blank"
      onClick={e => {
        //   e.preventDefault();
        //   setCurrentInfluencerName(username);
        //   navigation(link, { state: { prevPath } });
        window.open(link, '_blank');
      }}
      img={currentImageUrl}
      maxWidth={maxImageWidth}
      maxHeight={maxImageHeight}
      onError={e => console.log(e)}
    >
      <ImageOverlay
        zIndex={2}
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%,rgba(0, 0, 0, 0) 30%)"
        containerStyle={{ borderRadius: 12 }}
      />
      <InfluencerCardHeader
        isSaved={isSaved}
        onSavedClick={onSavedClick}
        username={username}
        platform={platform}
        influencerRating={influencerRating}
        ratingCount={ratingCount}
        tier={tier}
      />
      <InfluencerCardBody
        attributes={attributes}
        activeAttributes={activeAttributes}
        followers={followers}
        influencerEngagement={influencerEngagement}
        platform={platform}
        lemonUrl={lemonUrl}
        rateCard={rateCard}
        activeCategory={activeCategory}
        category={category}
      />
    </Container>
  );
};

export default NewInfluencerCard;

const Container = styled.a<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 273px;
  min-height: 424.5px;
  max-width: ${props => props.maxWidth};
  max-height: ${props => props.maxHeight};
  aspect-ratio: 0.64310954063;
  background-image: ${props => `url(${props.img})`};
  background-size: cover;
  border-radius: 12px;
  z-index: 2;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;
