import styled from 'styled-components';
import Facebook from '../../assets/images/registerFrom/Facebook.svg';
import Profile from '../../assets/images/registerFrom/Profile.svg';
import Twitter from '../../assets/images/registerFrom/Twitter.svg';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';
import Rradiobutton from './Rradiobutton';

interface ButtonProps {
  active: boolean;
}

interface Props {
  text: string;
  selected: string;
  onClick: () => void;
}

function SocialButton(props: Props) {
  const { text, selected, onClick } = props;

  function renderIcon() {
    switch (text) {
      case 'Facebook':
        return <Facebook width={24} height={24} />;
      case 'Instagram':
        return (
          <Image
            src={require('../../assets/images/registerFrom/Instagram.png')}
          />
        );
      case 'Tiktok':
        return (
          <Image
            src={require('../../assets/images/registerFrom/Tiktok.png')}
            style={{
              width: '27px',
              height: '27px',
              marginTop: '-1px'
            }}
          />
        );
      case 'Lemon8':
        return (
          <Image
            src={require('../../assets/images/registerFrom/Lemon8.png')}
            style={{
              width: '27px',
              height: '27px',
              marginTop: '-1px'
            }}
          />
        );
      case 'Youtube':
        return (
          <Image
            src={require('../../assets/images/registerFrom/Youtube.png')}
            style={{
              width: '27px',
              height: '27px',
              marginTop: '-1px'
            }}
          />
        );
      case 'Google':
        return (
          <Image
            src={require('../../assets/images/registerFrom/Google.png')}
            style={{
              width: '27px',
              height: '27px',
              marginTop: '-1px'
            }}
          />
        );
      case 'Twitter':
        return <Twitter width={24} height={24} style={{ borderRadius: 50 }} />;
      case 'คำแนะนำจากคนรู้จัก':
        return <Profile width={24} height={24} fill={COLOR.Blue_700} />;
      case 'อื่นๆ':
        return (
          <Image
            src={require('../../assets/images/registerFrom/Other.png')}
            style={{
              width: '27px',
              height: '27px',
              marginTop: '-1px'
            }}
          />
        );
    }
  }

  return (
    <ButtonContainer onClick={onClick} active={selected === text}>
      <LabelContainer>
        {renderIcon()}
        <ButtonText active={selected === text}>{text}</ButtonText>
      </LabelContainer>
      <Rradiobutton active={selected === text} />
    </ButtonContainer>
  );
}

export default SocialButton;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
`;

const ButtonContainer = styled.div<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 12px;
  gap: 10px;
  width: 335px;
  height: 50px;
  background: ${props => (props.active ? COLOR.Blue_50 : COLOR.WHITE)};
  border: ${props =>
    props.active ? '1px solid #1422ED' : '1px solid #eaeaea'};
  border-radius: 25px;
`;

const ButtonText = styled(TEXT.Bu2_Reg)<ButtonProps>`
  margin-left: 12px;
  font-weight: ${props => (props.active ? 700 : 400)};
  color: ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;
