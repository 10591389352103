import React, { InputHTMLAttributes } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import {
  FontAktivGroteskRegular,
  FontAktivGroteskThaiRegular
} from '../../fonts/font';
import { TEXT } from '../../fonts/text';

export type InputStateProps = 'normal' | 'error' | 'check';

interface LabelProps {
  haveIcon: boolean;
}

interface InputProps {
  state: InputStateProps;
}

interface Props {
  icon?: IconName;
  iconSize?: number;
  customIcon?: any;
  label?: string;
  containerStyle?: React.CSSProperties;
  password?: boolean;
  required?: boolean;
  state?: InputStateProps;
  inputProp?: InputHTMLAttributes<HTMLInputElement>;
  unit?: string;
}

function RSmallInput(props: Props) {
  const {
    icon,
    iconSize = 24,
    customIcon,
    label,
    containerStyle,
    required = false,
    state = 'normal',
    inputProp,
    unit
  } = props;

  return (
    <Container style={containerStyle}>
      <LabelContainer>
        {icon && <RXIcon name={icon} size={iconSize} />}
        {customIcon}
        <LabelText haveIcon={!!(icon || customIcon)}>{label}</LabelText>
        {required && <RequiredText>&nbsp;*</RequiredText>}
      </LabelContainer>
      <InputContainer>
        <Input type={'text'} state={state} {...inputProp} />
        <CurrencyText>{unit}</CurrencyText>
      </InputContainer>
    </Container>
  );
}

export default RSmallInput;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
`;

const LabelText = styled(TEXT.H7_Reg)<LabelProps>`
  margin-left: ${props => (props.haveIcon ? '8px' : '0')};
`;

const RequiredText = styled(TEXT.H7_Bold)`
  color: ${COLOR.PINK};
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

const Input = styled.input<InputProps>`
  font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.48px;
  display: flex;
  flex: 1;
  width: 160px;
  height: 48px;
  border: none;
  border-radius: 24px;
  padding: 15px 24px;
  padding: 15px 36px 15px 24px;
  background: ${COLOR.Gray_50};
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  resize: none;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLOR.Gray_300};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${COLOR.Gray_300};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${COLOR.Gray_300};
  }

  :not(:placeholder-shown) {
    background-color: ${COLOR.WHITE};
    border: 1px solid;
    border-color: ${props =>
      props.state === 'normal'
        ? COLOR.Gray_300
        : props.state === 'error'
          ? COLOR.PINK
          : COLOR.GREEN};
  }

  :focus {
    background-color: ${COLOR.WHITE};
    outline: none;
    border: 1px solid ${COLOR.BLACK};
    box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
  }
`;

const CurrencyText = styled(TEXT.T1_Reg)`
  position: absolute;
  color: ${COLOR.Gray_300};
  top: 13px;
  right: 22px;
`;
