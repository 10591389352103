import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { Deal } from '../../../../types/deal';
import PendingCampaignCardDetail from './PendingCampaignCardDetail';
import REmptyState from '../../../atoms/REmptyState';

interface Props {
  navigate: NavigateFunction;
  deals: Deal[];
  nextDeals: (() => void) | undefined;
  metadata: {
    count: number;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
  };
  initialize: () => void;
  drawerStatus: string;
  deleteCampaign: ({ dealId }: { dealId: string }) => Promise<void>;
  emptyStateHeader: string;
  emptyStateDescription: string[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  margin-bottom: 34px;
`;

const PendingCampaignList = (props: Props) => {
  const {
    navigate,
    deals,
    initialize,
    drawerStatus,
    deleteCampaign,
    emptyStateDescription,
    emptyStateHeader
  } = props;

  useEffect(() => {
    initialize();
  }, []);

  if (deals.length === 0)
    return (
      <EmptyContainer>
        <REmptyState
          graphic={
            <EmptyStateImage
              src={require('../../../../assets/images/state/NothingHere.png')}
            />
          }
          header={emptyStateHeader}
          descriptions={emptyStateDescription}
        />
      </EmptyContainer>
    );

  return (
    <Container>
      {deals?.map((deal, index) => {
        return (
          <PendingCampaignCardDetail
            navigate={navigate}
            key={deal.dealId + index}
            deal={deal}
            drawerStatus={drawerStatus}
            deleteCampaign={deleteCampaign}
          />
        );
      })}
    </Container>
  );
};

export default observer(PendingCampaignList);

const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;
