import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { TEXT } from '../../../../fonts/text';
import { toDecimalNumber, toMonthYear } from '../../../../utils';
import useWindowDimensions from '../../../../utils/hook/UseWindowDimensions';
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';

import {
  ArcElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  CoreScaleOptions,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Scale,
  Tick,
  Title,
  Tooltip
} from 'chart.js';
import { observer } from 'mobx-react';
import REmptyState from '../../../../components/atoms/REmptyState';
import {
  FontNotoSansBold,
  FontNotoSansRegular,
  FontPoppinsBold,
  FontPoppinsRegular
} from '../../../../fonts/font';
import { DealsChartData, PlatformType } from '../../../../types/deal';
import { getMonthRange, getWeekRange } from '../../function';
import PerformanceChartLegend from './PerformanceChartLegend';
import PerformanceChartSwitch from './PerformanceChartSwitch';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export type LegendType = 'engagement' | 'reach' | 'impression' | 'view';

export const circleColors: Record<LegendType, string> = {
  engagement: COLOR.Blue_700,
  reach: COLOR.Lemon,
  impression: COLOR.Green_M,
  view: COLOR.Green_M
};

export const circleStrokeColors: Record<LegendType, string> = {
  engagement: COLOR.Blue_50,
  reach: COLOR.Yellow_L,
  impression: COLOR.Green_L,
  view: COLOR.Green_L
};

interface GetColorStyleProps {
  color: string;
  strokeColor: string;
  index: number;
  hoveredIndex: number | null;
}

const getColorStyle = (props: GetColorStyleProps) => {
  const { color, strokeColor, index, hoveredIndex } = props;
  return {
    borderColor: color,
    backgroundColor: color,
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: strokeColor,
    borderWidth: 2
  };
};

export interface DataSetProps {
  insightData?: {
    labels: Date[];
    engagement: number[];
    reach: number[];
    impression: number[];
    view: number[];
  };
  hoveredIndex: number | null;
  platformActive: PlatformType;
  dateFormat: 'daily' | 'weekly' | 'monthly';
}

const getDataSet = (
  props: DataSetProps
): ChartData<'line', number[], string> => {
  const { insightData, hoveredIndex, dateFormat, platformActive } = props;
  if (!insightData) return { labels: [], datasets: [] };

  const { labels, engagement, reach, impression, view } = insightData;
  const dateLabel = labels.map(label => {
    if (dateFormat === 'monthly') return toMonthYear({ dueDate: label });
    return toMonthYear({ dueDate: label, haveDay: true, haveYear: false });
  });

  const defaultDataStyle = {
    pointHoverBorderWidth: 5,
    pointBorderWidth: 0,
    pointHitRadius: 50,
    tension: 0.1
  };

  const createDataset = (
    label: 'Engagement' | 'Reach' | 'Impression' | 'View',
    data: number[],
    index: number
  ) => {
    const colorLabel = label.toLowerCase() as
      | 'engagement'
      | 'reach'
      | 'impression';
    return {
      label,
      data,
      hidden: index === 3,
      ...getColorStyle({
        color: circleColors[colorLabel],
        strokeColor: circleStrokeColors[colorLabel],
        index,
        hoveredIndex
      }),
      ...defaultDataStyle
    };
  };

  const isTikTok = platformActive === 'TIKTOK';
  const impressionOrViewText = isTikTok ? 'View' : 'Impression';

  return {
    labels: dateLabel,
    datasets: [
      createDataset('Engagement', engagement, 0),
      createDataset('Reach', isTikTok ? [] : reach, 1),
      createDataset(impressionOrViewText, isTikTok ? view : impression, 2)
    ]
  };
};
interface Props {
  data: DealsChartData | undefined;
  dateFormat: 'daily' | 'weekly' | 'monthly';
  setDateFormat: React.Dispatch<
    React.SetStateAction<'daily' | 'weekly' | 'monthly'>
  >;
  platformActive: PlatformType;
}

const PerformanceChart = (props: Props) => {
  const { data, dateFormat, setDateFormat, platformActive } = props;

  const windowDimension = useWindowDimensions();
  const chartRef = useRef<
    ChartJSOrUndefined<'line', number[], string> | undefined
  >();

  const [legendActive, setLegendActive] = useState<
    Record<LegendType, { index: number; active: boolean }>
  >({
    engagement: { index: 0, active: true },
    reach: { index: 1, active: true },
    impression: { index: 2, active: true },
    view: { index: 2, active: true }
  });
  const [chartData, setChartData] =
    useState<ChartData<'line', number[], string>>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const shouldOffset = chartData?.labels?.length === 1;

  const yAxisSetting = (
    color: string
  ): {
    border: {
      display: boolean;
      dash: number[];
    };
    grid: {
      color: string;
      tickBorderDash: number[];
    };
    ticks: {
      padding: number;
      // color: string;
      maxTicksLimit: number;
      stepSize: number;
      callback: (
        this: Scale<CoreScaleOptions>,
        value: string | number,
        index: number,
        ticks: Tick[]
      ) => string;
    };
  } => {
    return {
      border: {
        display: false,
        dash: [20, 1]
      },
      grid: {
        color: COLOR.Gray_50,
        tickBorderDash: [20, 50]
      },
      ticks: {
        // color,
        padding: 4,
        maxTicksLimit: 5,
        stepSize: 1,
        callback: function (value, index, ticks) {
          return toDecimalNumber({
            number: value,
            toFixed: 0,
            round: false,
            isZeroDash: false
          });
        }
      }
    };
  };

  const options: ChartOptions<'line'> = {
    animation: {},
    hover: {
      mode: 'index', // Ensure it captures all datasets at the same index
      intersect: false
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        titleColor: COLOR.Black,
        bodyColor: COLOR.Gray_D,
        bodyFont: { family: `${FontPoppinsRegular}, ${FontNotoSansRegular}` },
        bodySpacing: 4,
        titleFont: { family: `${FontPoppinsBold}, ${FontNotoSansBold}` },
        boxPadding: 4,
        borderColor: 'rgba(255,255,255,0.8)',
        borderWidth: 1,
        padding: 16,
        mode: 'index',
        usePointStyle: true,
        intersect: false,
        callbacks: {
          label: tooltipItem => {
            return `${tooltipItem.dataset.label}: ${toDecimalNumber({
              number: tooltipItem.raw as number,
              isZeroDash: false,
              round: true
            })}`;
          },
          labelPointStyle: () => {
            return {
              pointStyle: 'circle', // Sets the marker shape as circle
              rotation: 0,
              borderWidth: 0 // Removes the border
            };
          },
          title: title => {
            if (dateFormat === 'daily')
              return `ภาพรวมทั้งหมด (${title[0].label})`;
            if (dateFormat === 'weekly')
              return `ภาพรวมทั้งหมด (${getWeekRange({
                dateInput: title[0].label
              })})`;
            return `ภาพรวมทั้งหมด (${getMonthRange({
              dateInput: title[0].label
            })})`;
          }
        }
      }
    },
    elements: {
      point: {
        radius: 1,
        hoverRadius: 8
      }
    },
    scales: {
      x: {
        border: {
          display: true
        },
        grid: {
          tickBorderDash: [20, 50]
        },
        offset: shouldOffset
      },
      y: {
        ...yAxisSetting(circleColors.engagement)
      }
    }
  };

  useEffect(() => {
    setChartData(
      getDataSet({
        insightData: data?.[dateFormat],
        hoveredIndex,
        dateFormat,
        platformActive
      })
    );
  }, [!!data?.monthly, !!data?.weekly, hoveredIndex, platformActive]);

  const emptyStyle = {
    width: '100%',
    alignSelf: 'center',
    marginTop: 24,
    marginBottom: 24
  };

  if (!data)
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../../assets/images/EmptyData.png')}
          />
        }
        header="กำลังรวบรวมข้อมูล"
        containerStyle={emptyStyle}
      />
    );

  if (
    data.daily.labels.length === 0 &&
    data.monthly.labels.length === 0 &&
    data.weekly.labels.length === 0
  )
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={emptyStyle}
      />
    );

  return (
    <Container>
      <SpaceBetweenRowContainer style={{ width: '100%' }}>
        <HeaderContainer>
          <BoldH7Text>ภาพรวมทั้งหมด</BoldH7Text>
          <VerticalLine />
          <PerformanceChartLegend
            type="engagement"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            ref={chartRef}
            platformActive={platformActive}
          />
          <PerformanceChartLegend
            type="reach"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            ref={chartRef}
            platformActive={platformActive}
          />
          <PerformanceChartLegend
            type="impression"
            legendActive={legendActive}
            setLegendActive={setLegendActive}
            ref={chartRef}
            platformActive={platformActive}
          />
        </HeaderContainer>
        <PerformanceChartSwitch
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          data={data}
          setChartData={setChartData}
          hoveredIndex={hoveredIndex}
          platformActive={platformActive}
          getDataSet={getDataSet}
        />
      </SpaceBetweenRowContainer>
      <Chart
        ref={chartRef}
        type="line"
        data={
          chartData ?? {
            labels: [],
            datasets: []
          }
        }
        width={(windowDimension.width - 240) * 0.96}
        height={338}
        options={options}
        plugins={[
          {
            id: 'mouseOut',
            beforeEvent: (chart, args) => {
              const event = args.event;
              if (event.type === 'mouseout') {
                setHoveredIndex(null);
              }
            }
          }
        ]}
      />
    </Container>
  );
};

export default observer(PerformanceChart);

const Container = styled(FlexStartColumnContainer)`
  gap: 36px;
  width: 100%;
  position: relative;
`;

const HeaderContainer = styled(RowContainer)`
  gap: 8px;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 20px;
  border-right: 1px solid ${COLOR.Gray_300};
  margin: 0px 8px 0px 8px;
`;

const BoldH7Text = styled(TEXT.H7_Bold)``;
