import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import BackButton from '../../atoms/Layout/BackButton';
import MenuDetail from '../../atoms/Layout/MenuDetail';

interface Props {
  navigation: NavigateFunction;
}

const DiscoverWorkDrawer = (props: Props) => {
  const { navigation } = props;
  return (
    <>
      <PlaceholderContainer />
      <HeaderContainer>
        <LogoContainer
          src={require('../../../assets/images/AnotherDotLogoBlack.png')}
        />
        <BackButton navigation={navigation} />
      </HeaderContainer>
    </>
  );
};

export default DiscoverWorkDrawer;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 240px;
  padding: 20px 16px;
  height: 100%;
  z-index: 9999;
  border-right: 0.6px solid ${COLOR.Gray_200};
  background-color: ${COLOR.White};
`;

const PlaceholderContainer = styled.div`
  width: 240px;
  flex-shrink: 0;
`;

const LogoContainer = styled.img`
  margin-top: 8px;
  width: 120px;
  height: 23px;
  margin-right: 20px;
  align-self: center;
`;
const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  margin-top: 20px;
  margin-left: 12px;
  color: ${COLOR.Gray_M};
`;
