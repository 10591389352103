import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from '../components/atoms/ScrollToTop';
import CompanyCard from '../components/organisms/Survey/CompanyCard';
import { useEffect } from 'react';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  min-width: 1280px;
  margin-bottom: 80px;
`;

function Survey() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state !== 'fromLogin') {
      navigate('/login');
    }
  }, []);

  return (
    <PageContainer>
      <ScrollToTop />
      <ContentContainer>
        <CompanyCard />
      </ContentContainer>
    </PageContainer>
  );
}

export default observer(Survey);
