import React, { useEffect, useState } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';
import { ANDCOLOR } from '../../fonts/andColor';
import { ANDTEXT } from '../../fonts/andText';

interface ButtonProps {
  active?: boolean;
  backgroundColor?: string;
  textColor?: string;
}

interface Props {
  title: string;
  icon?: IconName;
  iconLocation?: 'righticon' | 'lefticon';
  iconSize?: number;
  onPress: () => void;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  active?: boolean;
  backgroundColor?: string;
  hoverColor?: string;
  textColor?: string;
}

function ANDButton(props: Props) {
  const {
    title,
    icon,
    iconLocation = 'textonly',
    iconSize = 28,
    containerStyle,
    textStyle,
    onPress,
    active = true,
    backgroundColor = ANDCOLOR.BLUE,
    textColor = COLOR.WHITE
  } = props;
  const [buttonActive, setButtonActive] = useState<boolean>(active);

  useEffect(() => {
    setButtonActive(active);
  }, [active]);

  const TextComponent = () => {
    return (
      <ButtonTextContainer
        active={active}
        style={textStyle}
        textColor={textColor}
      >
        {title}
      </ButtonTextContainer>
    );
  };

  const IconComponent = () => {
    if (!icon) {
      return <></>;
    }
    return (
      <RXIcon
        name={icon}
        size={iconSize}
        color={active ? textColor : COLOR.Gray_D}
      />
    );
  };

  const ButtonContent = () => {
    switch (iconLocation) {
      case 'lefticon':
        return (
          <ButtonIconContainer>
            <IconComponent />
            <TextComponent />
          </ButtonIconContainer>
        );
      default:
        return (
          <ButtonIconContainer>
            <TextComponent />
            <IconComponent />
          </ButtonIconContainer>
        );
    }
  };

  return (
    <ButtonContainer
      active={active}
      onClick={onPress}
      disabled={!buttonActive}
      style={containerStyle}
      backgroundColor={backgroundColor}
    >
      <ButtonContent />
    </ButtonContainer>
  );
}

export default ANDButton;

const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  height: 32px;
  min-width: 75px;
  white-space: nowrap;
  padding: 13px 12px;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.active ? props.backgroundColor : COLOR.Gray_200};
  border: ${props =>
    props.backgroundColor === ANDCOLOR.WHITE
      ? `1px solid ${ANDCOLOR.BLUE}`
      : 'none'};
  border-radius: 50px;
  cursor: ${props => (props.active ? 'pointer' : '')};
  transition: 500ms;

  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
`;

const ButtonIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const ButtonTextContainer = styled(ANDTEXT.body2BOLD)<ButtonProps>`
  color: ${props => (props.active ? props.textColor : COLOR.Gray_D)};
`;
