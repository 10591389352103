import { useState } from 'react';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { AuthSignUpProp } from '../../../types/auth';
import { PrivacyPolicyLink, TermOfUseLink } from '../../../utils/Link';
import RButton from '../../atoms/RButton';
import RCheckbox from '../../atoms/RCheckbox';
import RInput, { InputStateProps } from '../../atoms/RInput';

interface Props {
  changePage: () => void;
  authSignUp: AuthSignUpProp;
  setAuthSignUp: ({
    value,
    property
  }: {
    value: string;
    property:
      | 'teamEmail'
      | 'password'
      | 'rePassword'
      | 'fullName'
      | 'position'
      | 'workingEmail'
      | 'phoneNumber';
  }) => void;
  register: () => Promise<string[]>;
  authSignUpError: string[];
  validateTeamEmail: boolean;
  validatePassword: boolean;
  validateRePassword: boolean;
}

function RegisterCardDetail(props: Props) {
  const {
    changePage,
    setAuthSignUp,
    authSignUp,
    register,
    authSignUpError,
    validateTeamEmail,
    validatePassword,
    validateRePassword
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<InputStateProps>('normal');
  const [rePasswordError, setRePasswordError] =
    useState<InputStateProps>('normal');
  const [agreed, setAgreed] = useState<boolean>(false);
  const [marketingAgree, setMarketingAgree] = useState<boolean>(false);

  const RegisterPress = async () => {
    try {
      setLoading(true);
      await register();
      setLoading(false);
      if (authSignUpError.length === 0) changePage();
    } catch (error) {
      console.log(error);
    }
  };

  const emailError = authSignUpError.includes('Team email is already taken.');
  const isActive =
    !loading &&
    validateTeamEmail &&
    validatePassword &&
    validateRePassword &&
    passwordError !== 'error' &&
    rePasswordError !== 'error' &&
    agreed;

  const isIncludeSpecialCharacter = (char: string) => {
    return (
      char.includes('.') ||
      char.includes('^') ||
      char.includes('_') ||
      char.includes('-') ||
      char.includes('/') ||
      char.includes('=') ||
      char.includes('+')
    );
  };

  return (
    <Container>
      <HeaderText>สร้างบัญชีสำหรับแบรนด์</HeaderText>
      <RInput
        icon="Email"
        label="อีเมลสำหรับเข้าใช้งาน"
        type="text"
        containerStyle={{ marginTop: 9 }}
        state={emailError ? 'error' : 'normal'}
        inputProp={{
          onChange: e => {
            setAuthSignUp({ value: e.target.value, property: 'teamEmail' });
          },
          placeholder: 'youremail@email.com'
        }}
        message={
          emailError
            ? 'มีอีเมลนี้อยู่ในระบบแล้ว โปรดเข้าสู่ระบบหรือใช้อีเมลอื่น'
            : ' '
        }
      />
      <RInput
        icon="Lock"
        label="รหัสผ่าน"
        type="password"
        state={passwordError}
        inputProp={{
          onChange: e => {
            setAuthSignUp({ value: e.target.value, property: 'password' });
          },
          onBlur: e => {
            const strongRegex = new RegExp(
              /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
            );
            const strongRegexEachChar = new RegExp(/^[a-zA-Z0-9!@#$%^&*]$/);
            if (isIncludeSpecialCharacter(authSignUp.password)) {
              const testString = authSignUp.password
                .split('')
                .map(
                  char =>
                    (char =
                      strongRegexEachChar.test(char) ||
                      isIncludeSpecialCharacter(char)
                        ? 'accept'
                        : 'reject')
                )
                .filter(x => x === 'reject');
              if (testString.length > 0) setPasswordError('error');
              else setPasswordError('check');
            } else if (
              !strongRegex.test(authSignUp.password) ||
              authSignUp.password.length < 8
            )
              setPasswordError('error');
            else setPasswordError('check');
          },
          placeholder: 'Create your password'
        }}
        password={true}
        messageArray={
          passwordError === 'error'
            ? [
                'รหัสผ่านต้องประกอบไปด้วยอักษรภาษาอังกฤษ, ตัวเลข, และอักขระพิเศษ',
                'ความยาวอย่างน้อย 8 ตัวขระขึ้นไป โดยไม่มีการเว้นวรรค'
              ]
            : ['']
        }
      />
      <RInput
        icon="LockCheck"
        label="ยืนยันรหัสผ่าน"
        type="password"
        containerStyle={{ marginTop: 9 }}
        state={rePasswordError}
        inputProp={{
          onChange: e => {
            setAuthSignUp({ value: e.target.value, property: 'rePassword' });
          },
          onBlur: e => {
            if (authSignUp.rePassword !== authSignUp.password)
              setRePasswordError('error');
            else setRePasswordError('check');
          },
          placeholder: 'Confirm your password'
        }}
        password={true}
        message={
          rePasswordError === 'error'
            ? 'รหัสผ่านไม่ตรงกัน โปรดใส่รหัสผ่านให้ถูกต้อง'
            : ' '
        }
      />
      <CardButtonContainer>
        <CheckboxContainer haveMargin={true}>
          <RCheckbox
            active={agreed && marketingAgree}
            onClick={() => {
              // TODO : Change logic for efficiency
              if (agreed && marketingAgree) {
                setAgreed(false);
                setMarketingAgree(false);
                authSignUp.marketingAgreed = false;
              } else if (agreed) {
                setMarketingAgree(true);
                authSignUp.marketingAgreed = true;
              } else if (marketingAgree) {
                setAgreed(true);
              } else {
                setAgreed(true);
                setMarketingAgree(true);
                authSignUp.marketingAgreed = true;
              }
            }}
            containerStyle={{ width: '20px', height: '20px' }}
          />
          <CheckboxText>ยอบรับทั้งหมด</CheckboxText>
        </CheckboxContainer>
        <CheckboxContainer haveMargin={true}>
          <RCheckbox
            active={agreed}
            onClick={() => setAgreed(!agreed)}
            containerStyle={{ width: '20px', height: '20px' }}
          />
          <CheckboxText>
            ฉันได้อ่านและยอมรับ{' '}
            <LinkText href={TermOfUseLink} target="_blank">
              ข้อกำหนดในการใช้งาน
            </LinkText>{' '}
            และ{' '}
            <LinkText href={PrivacyPolicyLink} target="_blank">
              นโยบายความเป็นส่วนตัว
            </LinkText>
            เรียบร้อยแล้ว
          </CheckboxText>
        </CheckboxContainer>
        <CheckboxContainer>
          <RCheckbox
            active={marketingAgree}
            onClick={() => {
              setMarketingAgree(!marketingAgree);
              authSignUp.marketingAgreed = !marketingAgree;
            }}
            containerStyle={{ width: '20px', height: '20px' }}
          />
          <CheckboxText>
            ฉันยินยอมให้บริษัททำการเก็บรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคล
            <br />
            ของฉันเพื่อการดำเนินการทางการตลาดและการประชาสัมพันธ์
          </CheckboxText>
        </CheckboxContainer>
        <ButtonContainer>
          <RButton
            title="สร้างบัญชี"
            onPress={RegisterPress}
            active={isActive}
          />
        </ButtonContainer>
      </CardButtonContainer>
    </Container>
  );
}

export default RegisterCardDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled(TEXT.H4_Bold)``;

const CardButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const CheckboxContainer = styled.label<{ haveMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-top: ${props => (props.haveMargin ? '4px' : '0px')};
`;

const CheckboxText = styled(TEXT.B4_Reg)`
  margin-left: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  align-self: flex-end;
`;

const LinkText = styled.a`
  color: ${COLOR.Blue_700};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
  :active {
    color: ${COLOR.Blue_800};
  }
`;
