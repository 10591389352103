import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { PulseLoader } from 'react-spinners';
import { dotStream } from 'ldrs';
import { ANDCOLOR } from '../../fonts/andColor';
import Lottie from 'react-lottie';

interface Props {
  containerStyle?: CSSProperties;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px 0px;
  align-items: center;
`;

function RLoading(props: Props) {
  const { containerStyle } = props;
  dotStream.register();
  return (
    <Container style={containerStyle}>
      <Lottie
        options={{
          autoplay: true,
          animationData: require('../../assets/images/state/LoadingState.json')
        }}
        speed={1.1}
        height={64}
        style={{ marginTop: 24 }}
      />
    </Container>
  );
}

export default RLoading;
