import styled from 'styled-components';
import { ANDCOLOR } from '../../fonts/andColor';
import { COLOR } from '../../fonts/color';

interface Props {
  isActive: boolean;
  onChange: () => void;
}

interface ActiveProps {
  isActive: boolean;
}

function ANDSwitch(props: Props) {
  const { isActive, onChange } = props;
  return (
    <Switch onClick={onChange} isActive={isActive}>
      <Thumb isActive={isActive} />
    </Switch>
  );
}

export default ANDSwitch;

const Switch = styled.div<ActiveProps>`
  position: relative;
  width: 64px;
  height: 29px;
  border-radius: 64px;
  background-color: ${props =>
    props.isActive ? ANDCOLOR.BLUE500 : COLOR.Gray_D};
  padding: 4px;
`;

const Thumb = styled.div<ActiveProps>`
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  left: ${props => (props.isActive ? 'auto' : '4px')};
  right: ${props => (props.isActive ? '4px' : 'auto')};
  background-color: ${ANDCOLOR.WHITE};
`;
