import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { toDecimalNumber } from '../../../utils';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../../utils/styling/general';

interface TierDetailProps {
  title: string;
  value: number;
  rate: string;
}

const TierSummaryDetail = (props: TierDetailProps) => {
  const { title, value, rate } = props;
  return (
    <TierDetailContainer>
      <FourGapRowContainer>
        <BoldH6Text>
          {toDecimalNumber({ number: value, isZeroDash: false })}
        </BoldH6Text>
        <GrayDH9Text>{`(${rate} ฿)`}</GrayDH9Text>
      </FourGapRowContainer>
      <GrayDH9Text>{title}</GrayDH9Text>
    </TierDetailContainer>
  );
};

export default TierSummaryDetail;

const TierDetailContainer = styled(FlexStartColumnContainer)`
  width: 33%;
`;

const FourGapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const GrayDH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.Gray_D};
`;
