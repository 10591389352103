import styled from 'styled-components';
import { scopeOfWorkType } from '../../../class/ScopeOfWork';
import PostIcon from '../../../components/atoms/PostIcon';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { IsTierType, postTypeName, toCapitalize } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';

interface PostTypeComponentProps {
  type?: string;
  onClick: () => void;
  total: number;
  active: boolean;
}

const PostTypeComponent = (props: PostTypeComponentProps) => {
  const { type, onClick, total, active } = props;
  const isTier = !!type && IsTierType(type);
  let postName: string = '';

  if (!!type) {
    if (isTier) {
      postName = toCapitalize(type);
    } else {
      postName = postTypeName[type as scopeOfWorkType];
    }
  } else {
    postName = 'ทั้งหมด';
  }

  const totalNumber = postName === 'ทั้งหมด' ? '' : ` (${total})`;

  return (
    <PostTypeContainer onClick={onClick}>
      {!isTier && <PostIcon type={type as scopeOfWorkType} active={active} />}
      <Bu3Text active={active}>{postName + totalNumber}</Bu3Text>
      <ActiveBar active={active} />
    </PostTypeContainer>
  );
};

interface Props {
  tabs: Record<string, number>;
  activeTab?: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  dealPostType: string[];
}

const InfluencerPerformanceTab = (props: Props) => {
  const { tabs, activeTab, setActiveTab, dealPostType } = props;
  const components: JSX.Element[] = [];
  components.push(
    <PostTypeComponent
      type={undefined}
      total={0}
      onClick={() => setActiveTab(undefined)}
      active={activeTab === undefined}
    />
  );
  for (const key in tabs) {
    if (Object.prototype.hasOwnProperty.call(tabs, key)) {
      const scopeOfWorkKey = key as string;
      const element = tabs[scopeOfWorkKey];
      if (
        scopeOfWorkKey !== 'IG_tv' &&
        dealPostType.find(post => post === scopeOfWorkKey)
      )
        components.push(
          <PostTypeComponent
            type={scopeOfWorkKey}
            total={element}
            onClick={() => setActiveTab(scopeOfWorkKey)}
            active={activeTab === scopeOfWorkKey}
          />
        );
    }
  }
  return <TabContainer>{components}</TabContainer>;
};

export default InfluencerPerformanceTab;

const PostTypeContainer = styled(RowContainer)`
  position: relative;
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;
const TabContainer = styled(RowContainer)`
  width: 100%;
  gap: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLOR.Gray_300};
`;

const ActiveBar = styled.div<ActiveProps>`
  position: absolute;
  bottom: -18px;
  background-color: ${props => (props.active ? COLOR.Blue_700 : 'none')};
  height: 3px;
  width: 100%;
  border-radius: 50px;
`;
const Bu3Text = styled(TEXT.Bu3_Reg)<ActiveProps>`
  white-space: nowrap;
  font-weight: ${props => (props.active ? 700 : 400)};
  color: ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
`;
