import { LinearProgress } from '@mui/material';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { TEXT } from '../../../../fonts/text';
import { Deal } from '../../../../types/deal';
import { limitText, toDateDue } from '../../../../utils';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import MoreOptions, { OptionProps } from '../../../atoms/MoreOptions';

interface Props {
  deal: Deal;
  deleteCampaign: ({ dealId }: { dealId: string }) => Promise<void>;
}

function PendingCampaignInfo(props: Props) {
  const { deal, deleteCampaign } = props;
  const { dealId } = deal;
  const [active, setActive] = useState<boolean>(false);

  const options: Array<OptionProps> = [
    {
      title: 'ลบ',
      color: COLOR.Warning,
      icon: 'Trash',
      iconColor: COLOR.Warning,
      action: () => {
        setActive(false);
        deleteCampaign({ dealId });
      }
    }
  ];

  return (
    <RowContainer style={{ alignItems: 'flex-start' }}>
      <Container>
        <SpaceBetweenRowContainer
          style={{
            height: '44px',
            overflowWrap: 'break-word'
          }}
        >
          <BoldH7Text>{limitText(deal.name, 100)}</BoldH7Text>
        </SpaceBetweenRowContainer>
        <ProgressBarContainer>
          <ProgressBar value={100} variant="determinate" />
          <SpaceBetweenRowContainer style={{ justifyContent: 'space-between' }}>
            <BlueB3Text>Completed</BlueB3Text>
            <BlueB3Text>จะเผยแพร่วันที่ {toDateDue(deal.startDate)}</BlueB3Text>
          </SpaceBetweenRowContainer>
        </ProgressBarContainer>
      </Container>
      <IconContainer
        onClick={e => {
          setActive(!active);
          e.stopPropagation();
        }}
      >
        <RXIcon name="MoreVertical" color={COLOR.Gray_300} />
        {active && (
          <div style={{ position: 'absolute', left: '444px' }}>
            <MoreOptions options={options} />
          </div>
        )}
      </IconContainer>
    </RowContainer>
  );
}

export default PendingCampaignInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
  width: 484px;
  padding: 8px 0px 15px 0px;
`;

const BoldH7Text = styled(TEXT.H7_Bold)`
  white-space: wrap;
  width: 484px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const BlueB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Blue_700};
`;

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 12,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLOR.Gray_200,
    borderRadius: 12
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 12,
    backgroundColor: COLOR.Blue_700
  }
}));

const IconContainer = styled.div`
  margin-top: 7px;

  :hover {
    cursor: pointer;
  }
`;
