import { action, makeAutoObservable, observable } from 'mobx';
import { IInfluencerWorks } from '../types/api/getInfluWork';
import { Deal, DealsChartData, IDealsHistory, StatusType } from '../types/deal';
import { Work } from '../types/work.d';
import {
  isSameDay,
  isSameMonth,
  isSameWeek,
  objectToQuerystring
} from '../utils';
import { logAnalyticsEvent } from '../utils/Firebase';
import { DELETE, GET, PATCH, POST, getErrorMessage } from '../utils/Networking';
import { IInfluencerReview } from './../types/api/getInfluencer';
import { initateDeal } from './../types/deal.d';
import { getDeal, getInfluWorks, listDeals } from './logic/deals';

export type DealsData = {
  data: Deal[];
  metadata: {
    count: number;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
  };
  next: () => void;
};
export class DealStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  loading = {
    pending: false,
    ongoing: false,
    done: false,
    deal: false,
    reviewing: false,
    influWorks: false,
    review: false,
    campaignList: false,
    dealHistory: false
  };

  @observable
  deals: {
    pending: DealsData;
    ongoing: DealsData;
    done: DealsData;
  } = {
    pending: {
      data: [],
      metadata: {
        count: 0,
        limit: 10,
        offset: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      },
      next: () => {}
    },
    ongoing: {
      data: [],
      metadata: {
        count: 0,
        limit: 10,
        offset: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      },
      next: () => {}
    },
    done: {
      data: [],
      metadata: {
        count: 0,
        limit: 10,
        offset: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      },
      next: () => {}
    }
  };

  @observable
  error = {
    pending: null,
    ongoing: null,
    done: null,
    deal: null,
    reviewing: null,
    influWorks: null
  };

  @observable
  deal: Deal = initateDeal;

  @observable
  campaignList: {
    ongoing: Deal[];
    pending: Deal[];
    done: Deal[];
  } = { ongoing: [], pending: [], done: [] };

  @observable
  influWorks?: IInfluencerWorks = null || undefined;

  @observable
  campaignWorks: Work[] = [];

  @observable
  dealReviews: {
    reviews: IInfluencerReview[];
    reviewOverall: {
      starCount: number[];
      avgRating: number;
      totalReview: number;
      tagCount: number[];
    };
  } = {
    reviews: [],
    reviewOverall: {
      starCount: [],
      avgRating: 0,
      totalReview: 0,
      tagCount: []
    }
  };

  //TODO: Remove from store and declare in dealPerformancePage
  @observable
  dealHistory: DealsChartData = {
    daily: {
      labels: [],
      engagement: [],
      reach: [],
      impression: [],
      view: []
    },
    weekly: {
      labels: [],
      engagement: [],
      reach: [],
      impression: [],
      view: []
    },
    monthly: {
      labels: [],
      engagement: [],
      reach: [],
      impression: [],
      view: []
    }
  };

  @action
  reset = () => {
    this.deals = {
      pending: {
        data: [],
        metadata: {
          count: 0,
          limit: 10,
          offset: 0,
          sortBy: 'createdAt',
          sortOrder: 'desc'
        },
        next: () => {}
      },
      ongoing: {
        data: [],
        metadata: {
          count: 0,
          limit: 10,
          offset: 0,
          sortBy: 'createdAt',
          sortOrder: 'desc'
        },
        next: () => {}
      },
      done: {
        data: [],
        metadata: {
          count: 0,
          limit: 10,
          offset: 0,
          sortBy: 'createdAt',
          sortOrder: 'desc'
        },
        next: () => {}
      }
    };
  };

  @action
  listDeals = async ({
    limit = 10,
    offset = 0,
    sortBy = 'createdAt',
    sortOrder = 'desc',
    status
  }: {
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: string;
    status:
      | 'pending'
      | 'ongoing'
      | 'done'
      | 'deal'
      | 'reviewing'
      | 'influWorks';
  }) => {
    if (this.loading[status]) {
      return;
    }
    try {
      this.error[status] = null;
      this.loading[status] = true;
      const result = await listDeals({
        limit,
        offset,
        sortBy,
        sortOrder,
        status
      });
      if (status === 'pending' || status === 'ongoing' || status === 'done') {
        const prevData = this.deals[status].data || [];
        // mean next page
        if (offset >= limit) {
          this.deals[status] = {
            ...result,
            data: [...prevData, ...result.data],
            next: () => {}
          };
        } else {
          this.deals[status] = {
            ...result,
            data: [...result.data],
            next: () => {}
          };
        }
        const newLimit = limit + offset;
        this.deals[status].next = () => {
          this.listDeals({
            limit,
            offset: newLimit,
            sortBy,
            sortOrder,
            status
          });
        };
      }
      return result;
    } catch (error) {
      this.error[status] = getErrorMessage(error);
    } finally {
      this.loading[status] = false;
    }
  };

  @action
  dealInfluSizeIncrement = ({
    dealId,
    status,
    key,
    isIncrease
  }: {
    dealId: string;
    status: 'pending' | 'ongoing' | 'done';
    key: 'pendingSize' | 'ongoingSize' | 'checkSize' | 'doneSize';
    isIncrease: boolean;
  }) => {
    const deal = this.deals[status].data.findIndex(
      deal => deal.dealId === dealId
    );
    if (deal !== -1) {
      if (isIncrease) {
        this.deals[status].data[deal][key] += 1;
      } else {
        this.deals[status].data[deal][key] -= 1;
      }
    }
  };

  @action
  dealNotification = ({
    dealId,
    status,
    key
  }: {
    dealId: string;
    status: 'pending' | 'ongoing' | 'done';
    key: 'notiPending' | 'notiOngoing' | 'notiCheck' | 'notiDone';
  }) => {
    const deal = this.deals[status].data.findIndex(
      deal => deal.dealId === dealId
    );
    if (deal !== -1) {
      this.deals[status].data[deal][key] = 1;
    }
  };

  @action
  getDeal = async ({
    dealId,
    isChatUpdate
  }: {
    dealId: string;
    isChatUpdate?: boolean;
  }) => {
    try {
      if (isChatUpdate) {
        this.loading.deal = false;
      } else {
        this.deal = initateDeal;
        this.loading.deal = true;
      }
      this.error.deal = null;
      const deal = await getDeal({ dealId });
      this.deal = deal;
      return deal;
    } catch (error) {
      this.error.deal = getErrorMessage(error);
    } finally {
      this.loading.deal = false;
    }
  };

  @action
  getWorks = async ({ dealId }: { dealId?: string }) => {
    try {
      this.loading.influWorks = true;
      if (this.deal !== undefined) {
        const queryId = dealId ?? this.deal.dealId;
        this.campaignWorks = [];
        if (!queryId) return;
        const campaignWorks = await GET(
          `/deals/work?dealId=${queryId}&status=done`
        );
        this.campaignWorks = campaignWorks;
        return campaignWorks;
      }
    } catch (error) {
      this.error.influWorks = getErrorMessage(error);
    } finally {
      this.loading.influWorks = false;
    }
  };

  @action
  getInfluWorks = async ({ dealContactId }: { dealContactId: string }) => {
    try {
      this.influWorks = undefined;
      this.loading.influWorks = true;
      this.error.influWorks = null;
      const influWorks = await getInfluWorks({ dealContactId });

      this.influWorks = influWorks;
    } catch (error) {
      this.error.influWorks = getErrorMessage(error);
    } finally {
      this.loading.influWorks = false;
    }
  };

  @action
  approve = async ({
    dealContactId,
    influencerName
  }: {
    dealContactId: string;
    influencerName: string;
  }) => {
    await POST('/deals/approve', { dealContactId });
    logAnalyticsEvent('approve_influencer_to_campaign', {
      dealContactId: dealContactId,
      influencerName: influencerName
    });
    this.removePending({ dealContactId });
    this.addOngoing({ dealContactId });
  };

  @action
  reject = async ({ dealContactId }: { dealContactId: string }) => {
    await POST('/deals/reject', { dealContactId });
    this.removePending({ dealContactId });
    this.addReject({ dealContactId });
  };

  @action
  counter = async ({
    dealContactId,
    negotiatingData
  }: {
    dealContactId: string;
    negotiatingData: string;
  }) => {
    await POST('/deals/counter', {
      dealContactId,
      negotiatingData
    });
    this.removePending({ dealContactId });
    this.addCounterPending({ dealContactId });
  };

  @action
  cancelInflu = async ({
    dealContactId,
    reason,
    status
  }: {
    dealContactId: string;
    reason: string;
    status: StatusType;
  }) => {
    logAnalyticsEvent('cancel_influencer_from_campaign', {
      dealContactId: dealContactId
    });
    if (status === 'ongoing') {
      this.removeOngoing({ dealContactId });
    } else if (status === 'check') {
      this.removeCheck({ dealContactId });
    } else {
      return;
    }
    this.addReject({ dealContactId });
    await POST('/deals/reject', { dealContactId, rejectedReason: reason });
  };

  removePending = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.pendingContacts = this.deal.pendingContacts.filter(
        contact => contact !== dealContactId
      );
    }
  };

  addOngoing = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.ongoingContacts.push(dealContactId);
    }
  };

  removeOngoing = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.ongoingContacts = this.deal.ongoingContacts.filter(
        contact => contact !== dealContactId
      );
    }
  };

  addReject = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.rejectedContacts.push(dealContactId);
    }
  };

  addCounterPending = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.counterPendingContacts.push(dealContactId);
    }
  };

  addDone = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.doneContacts.push(dealContactId);
    }
  };

  addCheck = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.checkContacts.push(dealContactId);
    }
  };

  removeCheck = ({ dealContactId }: { dealContactId: string }) => {
    if (this.deal !== undefined) {
      this.deal.checkContacts = this.deal.checkContacts.filter(
        contact => contact !== dealContactId
      );
    }
  };

  @action
  getDealInfluReview = async ({
    dealContactId,
    influencerName,
    rate,
    reviewTag,
    comment
  }: {
    dealContactId: string;
    influencerName: string;
    rate: string;
    reviewTag: number[];
    comment: string;
  }) => {
    if (this.loading.reviewing) {
      return;
    }
    try {
      this.loading.reviewing = true;
      await POST('/deals/work/review', {
        dealContactId,
        rate,
        commend: comment,
        reviewTag
      });
      logAnalyticsEvent('finish_work', {
        dealContactId: dealContactId,
        influencerName: influencerName
      });
      this.removeCheck({ dealContactId });
      this.addDone({ dealContactId });
    } catch (error) {
      this.error.reviewing = getErrorMessage(error);
    } finally {
      this.loading.reviewing = false;
    }
  };

  @action
  getCampaignList = async () => {
    try {
      this.loading.campaignList = true;
      this.campaignList = await GET(`/brands/deals/campaignlist`);
      return (
        this.campaignList.ongoing[this.campaignList.ongoing.length - 1] ||
        this.campaignList.done[this.campaignList.done.length - 1]
      );
    } catch (error) {
      this.error.reviewing = getErrorMessage(error);
      this.loading.campaignList = false;
    } finally {
      this.loading.campaignList = false;
    }
  };

  @action
  changeDeliverStatus = async ({
    dealContactId,
    status = 'DELIVERED'
  }: {
    dealContactId: string;
    status: 'DELIVERED' | 'TODELIVER';
  }) => {
    await PATCH(`/dealContact/${dealContactId}`, {
      status
    });
  };

  @action
  editWork = async ({
    navigation,
    dealContactId,
    reason
  }: {
    navigation: any;
    dealContactId: string;
    reason: string;
  }) => {
    await PATCH(`/brands/deals/edit/${dealContactId}`, {
      message: reason
    });
    this.removeOngoing({ dealContactId });
    this.addCheck({ dealContactId });
    navigation.navigate('Deal', { screen: 'CheckContact' });
  };

  @action
  deleteCampaign = async ({ dealId }: { dealId: string }) => {
    try {
      this.loading.pending = true;
      await DELETE(`/brands/deals/deleteUnreleaseDeal/${dealId}`);
      this.loading.pending = false;
      this.listDeals({
        limit: 10,
        offset: 0,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        status: 'pending'
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action
  getDealReviews = async ({
    dealId,
    limit,
    offset,
    sortOrder,
    filter
  }: {
    dealId: string;
    limit: number;
    offset: number;
    sortOrder: string;
    filter: number;
  }) => {
    if (!dealId) return;
    const queryString = {
      dealId,
      limit,
      offset,
      sortOrder,
      filter
    };
    try {
      this.loading.review = true;
      const result = await GET(
        `/deals/reviews${objectToQuerystring(queryString)}`
      );
      this.dealReviews.reviews = result.reviews[0].data;
      this.dealReviews.reviewOverall = result.reviewOverall;
      this.loading.review = false;
    } catch (error) {
      console.log(error);
      this.loading.review = false;
    }
  };

  pushDealChartData = ({
    type,
    data,
    date
  }: {
    type: 'daily' | 'weekly' | 'monthly';
    data: IDealsHistory;
    date: Date;
  }) => {
    this.dealHistory[type].labels.unshift(date);
    this.dealHistory[type].engagement.unshift(data.engagement);
    this.dealHistory[type].impression.unshift(data.impressionCount ?? 0);
    this.dealHistory[type].reach.unshift(data.reachCount ?? 0);
    this.dealHistory[type].view.unshift(data.viewCount ?? 0);
  };

  clearDealHistory = () => {
    this.dealHistory.daily = {
      labels: [],
      engagement: [],
      reach: [],
      impression: [],
      view: []
    };
    this.dealHistory.weekly = {
      labels: [],
      engagement: [],
      reach: [],
      impression: [],
      view: []
    };
    this.dealHistory.monthly = {
      labels: [],
      engagement: [],
      reach: [],
      impression: [],
      view: []
    };
  };

  @action
  getDealHistory = async ({ dealId }: { dealId: string }) => {
    try {
      if (!dealId) return;
      this.loading.dealHistory = true;
      this.clearDealHistory();
      const { platforms } = this.deal;
      const queryString = {
        dealId,
        platform: platforms?.[0] ?? 'Instagram'
      };
      const result: IDealsHistory[] = await GET(
        `/deals/dealHistory${objectToQuerystring(queryString)}`
      );

      const dataLength = result.length - 1;

      for (let i = dataLength; i >= 0; i--) {
        const element = result[i];
        const currentDate = new Date(element.timestamp);
        const previousDate =
          i < dataLength ? new Date(result[i + 1].timestamp) : null;

        if (!previousDate || !isSameDay(previousDate, currentDate)) {
          this.pushDealChartData({
            type: 'daily',
            data: element,
            date: currentDate
          });
        }
        if (!previousDate || !isSameWeek(previousDate, currentDate)) {
          const startOfWeek = new Date(element.timestamp);
          startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
          this.pushDealChartData({
            type: 'weekly',
            data: element,
            date: startOfWeek
          });
        }
        if (!previousDate || !isSameMonth(previousDate, currentDate)) {
          this.pushDealChartData({
            type: 'monthly',
            data: element,
            date: currentDate
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.loading.dealHistory = false;
    } finally {
      this.loading.dealHistory = false;
    }
  };
}

export const dealStore = new DealStore();
