import React, { Dispatch, SetStateAction, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { useResetPasswordStore } from '../../../../stores/initialize/Context';
import ForgotPasswordCard from '../../../organisms/ForgotPassword/ForgotPasswordCard';
import ForgotPasswordCode from '../../../organisms/ForgotPassword/ForgotPasswordCode';
import ForgotPasswordNew from '../../../organisms/ForgotPassword/ForgotPasswordNew';
import { observer } from 'mobx-react';

interface Props {
  setPage: Dispatch<SetStateAction<number>>;
}

function ForgetPasswordComponent(props: Props) {
  const { setPage } = props;

  const {
    email,
    setEmail,
    sendForgetPasswordEmail,
    emailError,
    isEmailSendActive,
    code,
    checkIsValidCode,
    resetPassword,
    isRepasswordSendActive
  } = useResetPasswordStore();
  const [forgotPage, setForgotPage] = useState<number>(1);

  const renderComponent = () => {
    switch (forgotPage) {
      case 1:
        return (
          <ForgotPasswordCard
            changePage={() => setForgotPage(2)}
            email={email}
            setEmail={setEmail}
            sendForgetPasswordEmail={sendForgetPasswordEmail}
            emailError={emailError}
            isEmailSendActive={isEmailSendActive}
          />
        );
      case 2:
        return (
          <ForgotPasswordCode
            changePage={() => setForgotPage(3)}
            changeBack={() => {
              email.value = '';
              setForgotPage(1);
            }}
            email={email.value}
            code={code}
            checkIsValidCode={checkIsValidCode}
          />
        );
      case 3:
        return (
          <ForgotPasswordNew
            code={code}
            resetPassword={resetPassword}
            isRepasswordSendActive={isRepasswordSendActive}
            onDone={() => setPage(1)}
          />
        );
    }
  };

  return <Container>{renderComponent()}</Container>;
}

export default observer(ForgetPasswordComponent);

const Container = styled.div``;
