import { useState } from 'react';
import styled from 'styled-components';
import { RXIcon } from 'rn-rx-icons';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { uploadImageAsync, uploadFileAsync } from '../../../utils/Networking';
import { COLOR } from '../../../fonts/color';
import { User } from 'react-web-gifted-chat';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Space = styled.div`
  margin-top: 50px;
`;

const ImageContainer = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.WHITE};
  transition: all 0.2s;
  border: none;
  border-radius: 48px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const ChatImageInput = styled.input`
  display: none;
`;

interface Props {
  onSendImage: (dealContactId: string, images: File, user: User) => void;
  onSendVideo: (dealContactId: string, images: File, user: User) => void;
  dealContactId: string;
  user: User;
}

function ChatImageSelector(props: Props) {
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const { onSendImage, onSendVideo, dealContactId, user } = props;

  const uploadImageToDatabase = async (
    uri: string,
    size: { width: number; height: number }
  ) => {
    const onProgress = ({
      total,
      loaded
    }: {
      total: number;
      loaded: number;
    }) => {
      const percent = Math.ceil((loaded / total) * 100);
      setProgress(percent);
    };
    const uploadedImages = await uploadImageAsync(uri, size, onProgress);
  };

  const uploadFileToDatabase = async (
    uri: File,
    size: { width: number; height: number }
  ) => {
    const onProgress = ({
      total,
      loaded
    }: {
      total: number;
      loaded: number;
    }) => {
      const percent = Math.ceil((loaded / total) * 100);
      setProgress(percent);
    };
    const uploadedImages = await uploadFileAsync(uri, size, onProgress);
  };
  const onChange = async (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    const updateImage = await uploadImageToDatabase(imageList[0].data_url, {
      width: 250,
      height: 250
    });
    setImages(imageList as never[]);
  };

  const onChangeInput = async (image: File) => {
    // data for submit
    if (image && image.type.match('image')) {
      onSendImage(dealContactId, image, user);
    }
    if (image && image.type.match('video')) {
      onSendVideo(dealContactId, image, user);
    }
  };

  return (
    <Container>
      <ImageContainer>
        <ChatImageInput
          type="file"
          name="myImage"
          onChange={event => {
            if (event.target.files) {
              onChangeInput(event.target.files[0]);
            }
          }}
        />
        <RXIcon name="Image" color={COLOR.Gray_300} size={24} />
      </ImageContainer>
    </Container>
  );
}

export default ChatImageSelector;
