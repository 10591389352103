import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ColumnContainer, RowContainer } from '../../../utils/styling/general';

interface Props {
  bodySize: {
    weight: string;
    height: string;
    bust: string;
    waist: string;
    hip: string;
    footwear: string;
  };
}

export const BodySize = (props: Props) => {
  const { bodySize } = props;
  const { weight, height, hip, waist, footwear, bust } = bodySize;
  return (
    <Container>
      <BoldH8Text>สัดส่วนของอินฟลูเอนเซอร์</BoldH8Text>
      <SizeContainer>
        <SizeDetailContainer>
          <BoldB3Text>{weight}</BoldB3Text>
          <B4Text>{`น้ำหนัก (กก.)`}</B4Text>
        </SizeDetailContainer>
        <SizeDetailContainer>
          <BoldB3Text>{height}</BoldB3Text>
          <B4Text>{`ส่วนสูง (ซม.)`}</B4Text>
        </SizeDetailContainer>
        <SizeDetailContainer>
          <BoldB3Text>
            {bust}/{waist}/{hip}
          </BoldB3Text>
          <B4Text>{`อก/เอว/สะโพก (นิ้ว)`}</B4Text>
        </SizeDetailContainer>
        <SizeDetailContainer>
          <BoldB3Text>{footwear}</BoldB3Text>
          <B4Text>{`รองเท้า (EU)`}</B4Text>
        </SizeDetailContainer>
      </SizeContainer>
    </Container>
  );
};

const Container = styled(ColumnContainer)`
  display: flex;
  padding: 16px 0px 0px 66px;
  gap: 8px;
  width: 700px;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const B4Text = styled(TEXT.B4_Reg)``;

const BoldH8Text = styled(TEXT.H8_Bold)``;

const BoldB3Text = styled(TEXT.B3_Bold)``;

const SizeContainer = styled(RowContainer)`
  gap: 16px;
`;

const SizeDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
  background: ${COLOR.Gray_50};
  border-radius: 16px;
`;
