import { observer } from 'mobx-react-lite';
import { SetStateAction, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { Account } from '../../../types/account';
import { PLACEHOLDER_PROFILE } from '../../../utils';
import { FAQLink, PrivacyPolicyLink, TermOfUseLink } from '../../../utils/Link';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../../utils/styling/general';
import LoginModal from '../Setting/LoginModal';
import BrandLogoWithStatus from '../../../reusables/BrandLogoWithStatus';

interface ItemComponentProps {
  onClick: () => void;
  icon: JSX.Element;
  title: string;
  textColor?: string;
}

const ItemComponent = (props: ItemComponentProps) => {
  const { onClick, title, icon, textColor } = props;
  return (
    <ItemContainer onClick={onClick}>
      {icon}
      <Bu3Text style={{ color: textColor }}>{title}</Bu3Text>
    </ItemContainer>
  );
};

interface StatusComponentProps {
  isVerified: boolean;
}

const StatusComponent = (props: StatusComponentProps) => {
  const { isVerified } = props;
  if (isVerified) {
    return <RXIcon name="Check" color={COLOR.White} size={12} />;
  }
  return (
    <ClockContainer>
      <RXIcon name="ClockFill" color={COLOR.ORANGE} size={12} />
    </ClockContainer>
  );
};

interface NormalMenuProps {
  img: string;
  isVerified: boolean;
  name: string;
  email: string;
  navigate: NavigateFunction;
  deactivate: () => void;
  setComponentStatus: React.Dispatch<
    React.SetStateAction<'switch' | 'logout' | 'normal'>
  >;
  accounts: Account[];
  logout: () => Promise<void>;
}

const NormalMenuComponent = (props: NormalMenuProps) => {
  const {
    name,
    email,
    navigate,
    deactivate,
    setComponentStatus,
    accounts,
    logout
  } = props;
  return (
    <>
      <ProfileContainer>
        <BrandLogoWithStatus />
        <FlexStartColumnContainer>
          <Bu3Text>{name}</Bu3Text>
          <GrayDB5Text>{email}</GrayDB5Text>
        </FlexStartColumnContainer>
      </ProfileContainer>
      <TextContainer>
        <GrayMBu3Text>Account</GrayMBu3Text>
      </TextContainer>
      <ItemComponent
        title="บัญชีของฉัน"
        icon={<RXIcon name="Profile" size={20} />}
        onClick={() => {
          navigate('/setting?page=profile');
          deactivate();
        }}
      />
      <ItemComponent
        onClick={() => {
          setComponentStatus('switch');
        }}
        icon={<RXIcon name="SwitchAccount" size={20} />}
        title={'เปลี่ยนบัญชี'}
      />
      <TextContainer>
        <GrayMBu3Text>AnotherDot</GrayMBu3Text>
      </TextContainer>
      <ItemComponent
        onClick={() => {
          window.open('https://anotherdot.co', '_blank', 'noreferrer');
          deactivate();
        }}
        title="เกี่ยวกับ AnotherDot"
        icon={
          <ImageContainer
            src={require('../../../assets/images/AnotherDotSmall.png')}
          />
        }
      />
      <ItemComponent
        onClick={() => {
          window.open(FAQLink, '_blank', 'noreferrer');
          deactivate();
        }}
        icon={<RXIcon name="FAQ" size={20} />}
        title="คำถามที่พบบ่อย"
      />
      <ItemComponent
        onClick={() => {
          navigate('/setting?page=contacts');
          deactivate();
        }}
        icon={<RXIcon name="Phone" size={20} />}
        title="ติดต่อเรา"
      />
      <Dashline />
      <LogoutContainer
        onClick={() => {
          if (accounts.length === 1) {
            logout();
            navigate('/login');
            return;
          }
          setComponentStatus('logout');
        }}
      >
        <WarningBu3Text>ออกจากระบบ</WarningBu3Text>
      </LogoutContainer>
    </>
  );
};

interface SelectSwitchAccountProps {
  newAccount: Account;
  name: string;
  navigate: NavigateFunction;
  setAuthLoading: (status: boolean) => void;
  switchAccount: (authToken: string) => Promise<void>;
  deactivate: () => void;
  setSwitchActive: React.Dispatch<SetStateAction<number>>;
}

const selectSwitchAccount = async (props: SelectSwitchAccountProps) => {
  const {
    newAccount,
    name,
    navigate,
    setAuthLoading,
    switchAccount,
    deactivate
  } = props;
  if (newAccount.name === name) return;
  setAuthLoading(true);
  await switchAccount(newAccount.authToken);
  deactivate();
  navigate('/');
  setAuthLoading(false);
};

interface OnSelectLogoutAccountProps {
  account: Account;
  currentBrandId: string;
  setLogoutAccount: React.Dispatch<SetStateAction<Account[]>>;
  logoutAccount: Account[];
}

const onSelectLogoutAccount = (props: OnSelectLogoutAccountProps) => {
  const { account, currentBrandId, setLogoutAccount, logoutAccount } = props;
  if (account.brandId === currentBrandId) return;
  if (logoutAccount.includes(account)) {
    setLogoutAccount(logoutAccount.filter(logAcc => logAcc !== account));
  } else {
    setLogoutAccount([...logoutAccount, account]);
  }
};

interface SwitchMenuProps {
  removeAccounts: (brandId?: string | undefined) => void;
  componentStatus: 'switch' | 'logout' | 'normal';
  setComponentStatus: React.Dispatch<
    SetStateAction<'switch' | 'logout' | 'normal'>
  >;
  navigate: NavigateFunction;
  name: string;
  account: Account;
  accounts: Account[];
  logoutAccounts: (toRemoveAccounts: Account[]) => Promise<boolean>;
  logoutAccount: Account[];
  setSwitchActive: React.Dispatch<SetStateAction<number>>;
  currentBrandId: string;
  setAuthLoading: (status: boolean) => void;
  switchAccount: (authToken: string) => Promise<void>;
  deactivate: () => void;
  setLogoutAccount: React.Dispatch<SetStateAction<Account[]>>;
  logout: () => Promise<void>;
}

const SwitchAccount = (props: SwitchMenuProps) => {
  const {
    componentStatus,
    setComponentStatus,
    logoutAccounts,
    navigate,
    accounts,
    logoutAccount,
    account,
    setSwitchActive,
    currentBrandId,
    name,
    setAuthLoading,
    switchAccount,
    deactivate,
    setLogoutAccount,
    logout
  } = props;

  const renderButton = () => {
    if (componentStatus === 'logout') {
      return (
        <ItemComponent
          onClick={async () => {
            const isEmpty = await logoutAccounts([...logoutAccount, account]);
            if (isEmpty) {
              navigate('/login');
            }
          }}
          icon={<RXIcon name="Logout" color={COLOR.Warning} size={20} />}
          textColor={COLOR.Warning}
          title={'Log out'}
        />
      );
    }
    return (
      <ItemComponent
        onClick={() => {
          setSwitchActive(1);
        }}
        icon={<RXIcon name="Plus" size={20} />}
        title="เพิ่มบัญชี"
      />
    );
  };

  if (componentStatus !== 'normal') {
    return (
      <>
        <BackButtonContainer onClick={() => setComponentStatus('normal')}>
          <RXIcon name="ArrowShortLeft" size={16} color={COLOR.Gray_M} />
          <Bu3Text>ย้อนกลับ</Bu3Text>
        </BackButtonContainer>
        <Dashline />
        {accounts.map(account => {
          if (componentStatus === 'switch') {
            return (
              <ContentContainer
                key={account.brandId}
                onClick={() =>
                  selectSwitchAccount({
                    newAccount: account,
                    name,
                    navigate,
                    setAuthLoading,
                    switchAccount,
                    deactivate,
                    setSwitchActive
                  })
                }
              >
                <ProfileContainer>
                  <BrandLogoWithStatus />
                  <FlexStartColumnContainer>
                    <Bu3Text>{account.name}</Bu3Text>
                    <GrayDB5Text>{account.email || 'test'}</GrayDB5Text>
                  </FlexStartColumnContainer>
                </ProfileContainer>
                <ActiveAccountContainer active={account.name === name}>
                  <RXIcon name="Check" color={COLOR.WHITE} size={16} />
                </ActiveAccountContainer>
              </ContentContainer>
            );
          }
          return (
            <ContentContainer
              key={account.brandId}
              onClick={() =>
                onSelectLogoutAccount({
                  account,
                  currentBrandId,
                  setLogoutAccount,
                  logoutAccount
                })
              }
            >
              <ProfileContainer>
                <Image src={account.img || PLACEHOLDER_PROFILE} />
                <CheckContainer active={account.isVerified}>
                  <StatusComponent isVerified={account.isVerified} />
                </CheckContainer>
                <FlexStartColumnContainer>
                  <Bu3Text>{account.name}</Bu3Text>
                  <GrayDB5Text>{account.email || 'test'}</GrayDB5Text>
                </FlexStartColumnContainer>
              </ProfileContainer>
              <SelectedContainer
                default={account.brandId === currentBrandId}
                active={
                  logoutAccount.includes(account) ||
                  account.brandId === currentBrandId
                }
              >
                <RXIcon name="Check" color={COLOR.WHITE} size={16} />
              </SelectedContainer>
            </ContentContainer>
          );
        })}
        <Dashline style={{ marginTop: 0 }} />
        {renderButton()}
        <ItemComponent
          onClick={async () => {
            await logout();
            navigate('/login');
          }}
          icon={<RXIcon name="Logout" color={COLOR.Warning} size={20} />}
          title="นำบัญชีออกทั้งหมด"
          textColor={COLOR.Warning}
        />
      </>
    );
  }
  return <></>;
};

interface MenuComponentProps {
  removeAccounts: (brandId?: string | undefined) => void;
  componentStatus: 'switch' | 'logout' | 'normal';
  setComponentStatus: React.Dispatch<
    SetStateAction<'switch' | 'logout' | 'normal'>
  >;
  navigate: NavigateFunction;
  name: string;
  account: Account;
  accounts: Account[];
  logoutAccounts: (toRemoveAccounts: Account[]) => Promise<boolean>;
  logoutAccount: Account[];
  setSwitchActive: React.Dispatch<SetStateAction<number>>;
  currentBrandId: string;
  setAuthLoading: (status: boolean) => void;
  switchAccount: (authToken: string) => Promise<void>;
  deactivate: () => void;
  setLogoutAccount: React.Dispatch<SetStateAction<Account[]>>;
  img: string;
  isVerified: boolean;
  email: string;
  logout: () => Promise<void>;
}

const MenuComponent = (props: MenuComponentProps) => {
  const { componentStatus } = props;
  if (componentStatus === 'normal') return <NormalMenuComponent {...props} />;
  return <SwitchAccount {...props} />;
};

interface Props {
  account: Account;
  accounts: Account[];
  switchAccount: (authToken: string) => Promise<void>;
  active: boolean;
  deactivate: () => void;
  navigate: NavigateFunction;
  logout: () => Promise<void>;
  logoutAccounts: (toRemoveAccounts: Account[]) => Promise<boolean>;
  setAuthLoading: (status: boolean) => void;
  offsetRight?: number;
  removeAccounts: (brandId?: string | undefined) => void;
}

interface ContainerProps {
  right: number;
}

const HeaderDropdown = (props: Props) => {
  const { account, active, deactivate, offsetRight = -100 } = props;
  const {
    name = '',
    brandId: currentBrandId,
    img,
    email,
    isVerified
  } = account;

  const [logoutAccount, setLogoutAccount] = useState<Account[]>([]);
  const [componentStatus, setComponentStatus] = useState<
    'normal' | 'switch' | 'logout'
  >('normal');
  const [switchActive, setSwitchActive] = useState<number>(0);

  if (!active) {
    return <></>;
  }

  return (
    <Container right={offsetRight}>
      <LoginModal modalVisible={switchActive} setPage={setSwitchActive} />
      <MenuComponent
        componentStatus={componentStatus}
        logoutAccount={logoutAccount}
        setSwitchActive={setSwitchActive}
        currentBrandId={currentBrandId}
        setLogoutAccount={setLogoutAccount}
        img={img}
        isVerified={isVerified}
        name={name}
        email={email}
        setComponentStatus={setComponentStatus}
        {...props}
      />
      <FlexStartColumnContainer>
        <GapRowContainer>
          <Gray400B4Text
            onClick={() => {
              deactivate();
              window.open(PrivacyPolicyLink, '_blank');
            }}
          >
            นโยบายส่วนตัว
          </Gray400B4Text>
          <VerticalDashline />
          <Gray400B4Text
            onClick={() => {
              deactivate();
              window.open(TermOfUseLink, '_blank');
            }}
          >
            ข้อกำหนดและเงื่อนไข
          </Gray400B4Text>
        </GapRowContainer>
        <GrayDB5Text style={{ marginLeft: 12 }}>Version 1.0.0</GrayDB5Text>
      </FlexStartColumnContainer>
    </Container>
  );
};

export default observer(HeaderDropdown);

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  border-radius: 12px;
  border: 0.6px solid ${COLOR.Gray_300};
  background: ${COLOR.White};
  padding: 20px 16px;
  right: ${props => props.right}px;
  width: 240px;
  border-radius: 16px;
  gap: 4px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 20px;
  border-radius: 12px;
  align-items: center;
  background: ${COLOR.WHITE};

  :hover {
    cursor: pointer;
    background: ${COLOR.OFFWHITE};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 220px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  overflow: hidden;
  background-color: ${COLOR.WHITE};
  z-index: 9999;

  :hover {
    cursor: pointer;
  }
`;

const GapRowContainer = styled(RowContainer)`
  margin: 4px 12px;
  gap: 8px;
  width: 100%;
`;

const ProfileContainer = styled(RowContainer)`
  gap: 8px;
  margin-bottom: 12px;
  position: relative;
`;

const ImageContainer = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50px;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: ${COLOR.Gray_M};
  object-fit: cover;
  flex-shrink: 0;
`;

const ActiveAccountContainer = styled.div<ActiveProps>`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.Blue_700};
  border-radius: 50px;
  opacity: ${props => (props.active ? 1 : 0)};
  flex-shrink: 0;
`;

const SelectedContainer = styled.div<{ default: boolean; active: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.active ? COLOR.Blue_700 : COLOR.White)};
  border: 1px solid ${COLOR.Gray_200};
  border: ${props =>
    props.active ? `1px solid ${COLOR.White}` : `1px solid ${COLOR.Gray_200}`};
  border-radius: 50px;
  opacity: ${props => (props.default ? 0.5 : 1)};
`;

const CheckContainer = styled.div<{ active: boolean }>`
  position: absolute;
  top: 24px;
  left: 16px;
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border: 2.5px solid white;
  border-radius: 50%;
  background: ${props => (props.active ? COLOR.Herbal : COLOR.ORANGE)};
  overflow: hidden;
  z-index: 99;
`;

const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
`;

const Dashline = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR.Gray_200};
  margin: 10px 0px;
`;

const VerticalDashline = styled.div`
  width: 0.4px;
  height: 15px;
  background-color: ${COLOR.Gray_200};
`;

const LogoutContainer = styled.div`
  margin: 4px 12px;
  :hover {
    cursor: pointer;
  }
`;

const BackButtonContainer = styled(RowContainer)`
  padding: 8px 12px;
  gap: 8px;
  border-radius: 12px;
  :hover {
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  margin: 4px 12px;
`;

const GrayDB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_D};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_M};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Bu3Text = styled(TEXT.Bu3_Reg)`
  white-space: nowrap;
`;

const WarningBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Warning};
  white-space: nowrap;
`;

const Gray400B4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_400};
  :hover {
    cursor: pointer;
  }
`;
