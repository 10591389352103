import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { RateCard } from '../../../types/api/getInfluencer';
import { toDecimalNumber } from '../../../utils';
import {
  ColumnContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import { CategoryType } from '../../organisms/CreateCampaign/Options';
import FavoriteInfluencerCardSocial from './FavoriteInfluencerCardBodySocial';
import InfluencerCardCategory from './InfluencerCardCategory';

interface GetRateCardRangeProps {
  rateCard: {
    instagram: Array<RateCard>;
    tiktok: Array<RateCard>;
    lemon: Array<RateCard>;
    facebook: Array<RateCard>;
  };
}

const getRateCardRange = (props: GetRateCardRangeProps) => {
  const { rateCard } = props;

  if (
    !rateCard ||
    (rateCard?.instagram?.length === 0 &&
      rateCard?.tiktok?.length === 0 &&
      rateCard?.facebook?.length === 0 &&
      rateCard?.lemon?.length === 0)
  )
    return 'ไม่มีข้อมูล Ratecard';

  let minRate = Infinity;
  let maxRate = -Infinity;

  Object.values(rateCard).forEach(platformRates => {
    platformRates.forEach(({ rate }) => {
      if (rate < minRate) {
        minRate = rate;
      }
      if (rate > maxRate) {
        maxRate = rate;
      }
    });
  });
  if (minRate === maxRate) return `${toDecimalNumber({ number: minRate })} ฿`;
  return `${toDecimalNumber({ number: minRate })} - ${toDecimalNumber({
    number: maxRate
  })} ฿`;
};

interface RateCardTextComponentProps {
  haveRateCard: boolean;
  rateCard: string;
}

const RateCardTextComponent = (props: RateCardTextComponentProps) => {
  const { rateCard, haveRateCard } = props;
  if (!haveRateCard) return <WhiteH9Text>{rateCard}</WhiteH9Text>;
  return <WhiteBoldH9Text>{rateCard}</WhiteBoldH9Text>;
};

interface BodyComponentProps {
  instagramFollowers: number;
  tiktokFollowers: number;
  facebookFollowers: number;
  category: string[];
  activeCategory?: CategoryType;
  rateCard: {
    instagram: Array<RateCard>;
    tiktok: Array<RateCard>;
    lemon: Array<RateCard>;
    facebook: Array<RateCard>;
  };
}

const FavoriteInfluencerCardBody = (props: BodyComponentProps) => {
  const { rateCard, category, activeCategory } = props;

  const rateCardRange = getRateCardRange({ rateCard });
  const haveRateCard = rateCardRange !== 'ไม่มีข้อมูล Ratecard';

  return (
    <BodyContainer>
      <FavoriteInfluencerCardSocial {...props} />
      <Dashline />
      <FullWidthColumnContainer>
        <FullWidthSpaceRowContainer>
          <Gray300B5Text>ราคาต่อโพสต์</Gray300B5Text>
          <RateCardTextComponent
            haveRateCard={haveRateCard}
            rateCard={rateCardRange}
          />
        </FullWidthSpaceRowContainer>
        {/* <InfluencerCardAttributes
            activeAttributes={activeAttributes}
            attributes={attributes}
          /> */}
        <InfluencerCardCategory
          activeCategory={activeCategory}
          category={category}
        />
      </FullWidthColumnContainer>
    </BodyContainer>
  );
};

export default FavoriteInfluencerCardBody;

const BodyContainer = styled(ColumnContainer)`
  margin: 0px 12px 12px 12px;
  padding: 16px;
  min-width: 249px;
  height: 152px;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(1, 1, 1, 0.5);
  backdrop-filter: blur(18px);
  z-index: 3;
  gap: 12px;
`;

const FullWidthColumnContainer = styled(ColumnContainer)`
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 4px;
`;

const FullWidthSpaceRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const Dashline = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 0.6px solid ${COLOR.Gray_M};
`;

const WhiteBoldH9Text = styled(TEXT.H9_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;

const Gray300B5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_300};
`;

// const WhiteH9Text = styled(TEXT.H9_Reg)`
//   color: ${COLOR.White};
// `;
