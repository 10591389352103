import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { TEXT } from '../../../../fonts/text';
import { SpaceBetweenRowContainer } from '../../../../utils/styling/general';
import AudienceAge from './AudienceAge';
import AudienceGender from './AudienceGender';
import AudienceLocation from './AudienceLocation';

const AudienceFilter = () => {
  const [active, setActive] = useState<boolean>(true);

  const ArrowComponent = () => {
    if (active) return <RXIcon name="ArrowDown" />;
    return <RXIcon name="ArrowUp" />;
  };
  const ContentComponent = () => {
    if (!active) return <></>;
    return (
      <>
        <AudienceGender />
        <AudienceAge />
        <AudienceLocation />
      </>
    );
  };

  return (
    <Container>
      <RowContainer onClick={() => setActive(!active)}>
        <BoldH8Text>รายละเอียด Audience</BoldH8Text>
        <ArrowComponent />
      </RowContainer>
      <ContentComponent />
    </Container>
  );
};

export default AudienceFilter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RowContainer = styled(SpaceBetweenRowContainer)`
  :hover {
    cursor: pointer;
  }
`;

const BoldH8Text = styled(TEXT.H8_Bold)``;
