import styled from 'styled-components';
import { PasswordCodeProp } from '../../../types/resetpassword';
import ForgotPasswordCodeDetail from '../../molecules/ForgotPassword/ForgotPasswordCodeDetail';

interface Props {
  changePage: () => void;
  changeBack: () => void;
  email: string;
  code: PasswordCodeProp;
  checkIsValidCode: ({ code }: { code: string }) => Promise<boolean>;
}

const RegisterCodeContainer = styled.div`
  width: 567px;
  height: 664px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  padding: 50px 88px;
`;

function ForgotPasswordCode(props: Props) {
  return (
    <RegisterCodeContainer>
      <ForgotPasswordCodeDetail {...props} />
    </RegisterCodeContainer>
  );
}

export default ForgotPasswordCode;
