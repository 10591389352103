import { observer } from 'mobx-react';
import React from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { IInfluencerReview } from '../../../types/api/getInfluencer';
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import InfluencerReviewCard from '../../atoms/Influencer/InfluencerReviewCard';
import REmptyState from '../../atoms/REmptyState';
import RLoading from '../../atoms/RLoading';
import { formatToFixedWithoutZero } from '../../../utils';

interface TagProps extends Props {
  index: number;
}

const ReviewTagComponent = (props: TagProps) => {
  const { index, reviewOverall } = props;
  const { tagCount } = reviewOverall || {
    avgRating: 0,
    totalReview: 0,
    tagCount: [0, 0, 0, 0, 0]
  };
  const reviewTags: { icon: IconName; text: string; value: number }[] = [
    {
      icon: 'TermsFill',
      text: 'ทำตามบรีฟ',
      value: tagCount[0]
    },
    {
      icon: 'Camera',
      text: 'ถ่ายรูปสวย',
      value: tagCount[1]
    },
    {
      icon: 'ChatFill',
      text: 'ตอบแชทไว',
      value: tagCount[2]
    },
    {
      icon: 'ClockFill',
      text: 'ส่งงานตรงเวลา',
      value: tagCount[3]
    },
    {
      icon: 'Attach',
      text: 'ความคิดสร้างสรรค์',
      value: tagCount[4]
    }
  ];

  return (
    <ReviewTagContainer>
      <BlueB2Text>
        {reviewTags[index].text}{' '}
        <BlueB3Text>({reviewTags[index].value})</BlueB3Text>
      </BlueB2Text>
    </ReviewTagContainer>
  );
};

interface StarProps extends Props {
  count: number;
}

const StarComponent = (props: StarProps) => {
  const { count, selectFilter, selectedFilter, starCount } = props;
  if (!starCount) {
    return <></>;
  }
  return (
    <StarsContainer
      onClick={() => selectFilter(count)}
      active={selectedFilter === count}
    >
      <FilterContainer>
        {new Array(count).fill('0').map((a, index) => {
          return (
            <RXIcon
              key={`star${index}`}
              name="StarFilled"
              color={COLOR.YELLOW}
              size={20}
            />
          );
        })}
      </FilterContainer>
      <B3Text>{`(${starCount[count - 1]})`}</B3Text>
    </StarsContainer>
  );
};

const FiltersComponent = (props: Props) => {
  const { starCount } = props;
  if (!starCount) {
    return <></>;
  }
  return (
    <FiltersContainer>
      <StarComponent count={5} {...props} />
      <StarComponent count={4} {...props} />
      <StarComponent count={3} {...props} />
      <StarComponent count={2} {...props} />
      <StarComponent count={1} {...props} />
    </FiltersContainer>
  );
};

const ReviewComponent = (props: Props) => {
  const { loading, reviews } = props;

  const loadingStyle = {
    marginTop: '52px',
    marginBottom: '240px',
    alignSelf: 'center'
  };

  if (loading) {
    return <RLoading containerStyle={loadingStyle} />;
  }
  if (reviews.length < 1) {
    return (
      <REmptyState
        containerStyle={{
          marginTop: '100px',
          marginBottom: '68px',
          alignSelf: 'center'
        }}
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/Campaign/Comment.png')}
          />
        }
        buttonWidth={131}
        header="ไม่พบความคิดเห็น"
        descriptions={[]}
      />
    );
  } else {
    return (
      <>
        {reviews?.map((reviewDetail, index) => {
          return (
            <InfluencerReviewCard
              reviewDetail={reviewDetail}
              isLast={index === reviews.length - 1}
            />
          );
        })}
      </>
    );
  }
};

interface RatingTextProps {
  value: number | string;
}

//TODO: Remove the string union type after merge fix/string-rating and check functional on mobile app
const getRatingText = (props: RatingTextProps) => {
  const { value } = props;
  if (typeof value === 'string') return value;
  return formatToFixedWithoutZero(value, 1);
};

interface Props {
  reviews: IInfluencerReview[];
  reviewOverall: {
    avgRating: number;
    totalReview: number;
    tagCount: number[];
  };
  starCount?: number[];
  selectedFilter: number;
  selectFilter: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  title?: string;
}

const Review = (props: Props) => {
  const { reviewOverall, title = 'Review' } = props;
  const { avgRating, totalReview } = reviewOverall || {
    avgRating: 0,
    totalReview: 0,
    tagCount: [0, 0, 0, 0, 0]
  };

  const avgRatingValue = getRatingText({ value: avgRating });

  return (
    <Container>
      <HeaderContainer>
        <GapRowContainer>
          <BoldH6Text>{`${title} ${avgRatingValue} / 5`}</BoldH6Text>
          <GrayDB1Text>{`(${totalReview} ความคิดเห็น)`}</GrayDB1Text>
        </GapRowContainer>
        {/* <GapRowContainer>
          <GrayBu1Text>ดูทั้งหมด</GrayBu1Text>
          <RXIcon name="ArrowRight" />
        </GapRowContainer> */}
      </HeaderContainer>
      <FiltersComponent {...props} />
      <ReviewTagsContainer>
        <ReviewTagComponent index={0} {...props} />
        <ReviewTagComponent index={1} {...props} />
        <ReviewTagComponent index={2} {...props} />
        <ReviewTagComponent index={3} {...props} />
        <ReviewTagComponent index={4} {...props} />
      </ReviewTagsContainer>
      <ReviewComponent {...props} />
    </Container>
  );
};

export default observer(Review);

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  margin-top: 36px;
`;

const HeaderContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const ReviewTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 16px;
`;

const ReviewTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLOR.Blue_50};
  border-radius: 16px;
`;

const FiltersContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const FilterContainer = styled.div`
  margin-top: 2px;
`;

const StarsContainer = styled.div<ActiveProps>`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 25px;
  border-color: ${props => (props.active ? COLOR.Black : COLOR.Gray_200)};

  :hover {
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px #0000000d;
    background-color: ${COLOR.Gray_50};
  }
`;

const EmptyStateImage = styled.img`
  width: 148px;
  height: 85px;
  margin-right: 10px;
`;
const BoldH6Text = styled(TEXT.H6_Bold)``;

const GrayDB1Text = styled(TEXT.B1_Reg)`
  color: ${COLOR.Gray_D};
`;

const GrayBu1Text = styled(TEXT.Bu1_Reg)`
  color: ${COLOR.Gray_D};
`;

const BlueBoldBu2Text = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const BlueB2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.Blue_700};
`;

const B3Text = styled(TEXT.B3_Reg)`
  margin-left: 4px;
`;

const BlueB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Blue_700};
`;
