import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  GiftedChat,
  IMessage,
  MessageImageProps,
  MessageProps,
  RenderMessageImageProps,
  User
} from 'react-web-gifted-chat';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { Account } from '../../../types/account';
import { IGetInfluencerResponse } from '../../../types/api/getInfluencer';
import { ChatBanners, ChatMessage } from '../../../types/chatMessage';
import { ColumnContainer } from '../../../utils/styling/general';
import ChatBanner from '../../atoms/Chat/ChatBanner';
import CustomBubble from '../../atoms/Chat/CustomBubble';
import CustomMessageImage from '../../atoms/Chat/CustomMessageImage';
import CustomMessageText from '../../atoms/Chat/CustomMessageText';
import CustomMessageVideo from '../../atoms/Chat/CustomMessageVideo';
import Keyboard from '../../atoms/Chat/Keyboard';
import SystemMessage from '../../atoms/Chat/SystemMessage';
import RModal from '../../atoms/RModal';
import { RXIcon } from 'rn-rx-icons';

interface Props {
  influencer: IGetInfluencerResponse | undefined;
  lastOnline: string;
  dealContactId: string;
  account: Account;
  popChatInfluencerToTop: (dealContactId: string) => void;
  send: (dealContactId: string, messages: ChatMessage[]) => void;
  messages: Record<string, ChatMessage[]>;
  subscribeToChat: (dealContactId: string) => void;
  canLoadEarlier: boolean;
  loading: boolean;
  loadEarlier: (dealContactId: string) => void;
  toggleShouldUpdate: () => void;
  shouldUpdate: boolean;
  sendImages: (
    dealContactId: string,
    images: File,
    user: User
  ) => Promise<void>;
  sendVideos: (dealContactId: string, video: File, user: User) => Promise<void>;
  getChatBannerDetail: ({
    dealContactId
  }: {
    dealContactId: string;
  }) => Promise<void>;
  banners: Record<string, ChatBanners>;
  bannerLoading: boolean;
  dealId: string;
  submissionDate: Date;
  readChatMessage: ({
    brandId,
    dealContactId
  }: {
    brandId: string;
    dealContactId: string;
  }) => Promise<void>;
}

interface ContainerProps {
  composerHeight: number;
}

type ModifiedChat = Readonly<MessageProps<IMessage>> &
  Readonly<{
    children?: React.ReactNode;
  }> & { forwarding: boolean; forwardMsg: Array<ChatMessage> };

type ModifiedImageChat = Readonly<MessageImageProps<IMessage>> &
  Readonly<{
    children?: React.ReactNode;
  }> & { forwarding: boolean; forwardMsg: Array<ChatMessage> };

function InfluencerChat(props: Props) {
  const {
    influencer = {},
    lastOnline,
    dealContactId,
    account,
    popChatInfluencerToTop,
    send,
    messages,
    subscribeToChat,
    canLoadEarlier,
    loading,
    loadEarlier,
    toggleShouldUpdate,
    shouldUpdate,
    sendImages,
    sendVideos,
    getChatBannerDetail,
    banners,
    bannerLoading,
    dealId,
    submissionDate,
    readChatMessage
  } = props;

  const user: User = {
    id: account.brandId,
    avatar: account.img,
    name: account.name
  };

  const [showImage, setShowImage] = useState<string>('');
  const [composerHeight, setComposerHeight] = useState<number>(138);

  useEffect(() => {
    subscribeToChat(dealContactId);
    getChatBannerDetail({ dealContactId });
    readChatMessage({ brandId: account.brandId, dealContactId });
  }, []);

  useEffect(() => {
    if (shouldUpdate) {
      toggleShouldUpdate();
    }
  }, [messages[dealContactId]?.length, shouldUpdate]);

  //TODO: can't view .MOV video sent from Iphone.

  return (
    <Container>
      <ChatContainer>
        {!bannerLoading && (
          <ChatBanner
            dealContactId={dealContactId}
            banners={banners}
            dealId={dealId}
            submissionDate={submissionDate}
          />
        )}
        <GiftedChat
          user={user}
          // @ts-ignore
          messages={messages[dealContactId]}
          onSend={mes => {
            send(dealContactId, mes);
            popChatInfluencerToTop(dealContactId);
          }}
          // @ts-ignore
          onSendImage={sendImages}
          onSendVideo={sendVideos}
          loadEarlier={canLoadEarlier}
          isLoadingEarlier={loading}
          dealContactId={dealContactId}
          onLoadEarlier={() => loadEarlier(dealContactId)}
          placeholder="Type a Message ..."
          shouldUpdateMessage={(
            props: ModifiedChat,
            nextProps: ModifiedChat
          ) => {
            return (
              props.forwarding !== nextProps.forwarding ||
              props.forwardMsg.length !== nextProps.forwardMsg.length
            );
          }}
          scrollToBottom
          wrapInSafeArea={false}
          renderMessageText={(props: any) => <CustomMessageText {...props} />}
          renderComposer={(props: any) => (
            <div
              style={{
                width: '100%',
                boxShadow: `0px 4px 10px rgba(0, 0, 0, 0.05)`,
                backgroundColor: `${COLOR.WHITE}`,
                marginTop: -1,
                borderBottomLeftRadius: '16px',
                borderBottomRightRadius: '16px'
                // zIndex: 99999,
              }}
            >
              <Keyboard
                {...props}
                setComposerHeight={setComposerHeight}
                composerHeight={composerHeight}
              />
            </div>
          )}
          renderMessageVideo={(props: any) => <CustomMessageVideo {...props} />}
          renderBubble={(props: any) => <CustomBubble {...props} />}
          renderAvatar={(props: any) => (
            <div style={{ marginLeft: '35px' }}></div>
          )}
          renderSend={(props: any) => <></>}
          renderSystemMessage={(props: any) => <SystemMessage {...props} />}
          renderMessageImage={(props: RenderMessageImageProps<IMessage>) => (
            <CustomMessageImage {...props} setShowImage={setShowImage} />
          )}
        />
      </ChatContainer>
      <RModal active={showImage !== ''} setActive={() => setShowImage('')}>
        <ImageContainer>
          <Image src={showImage} />
          <CloseButton onClick={() => setShowImage('')}>
            <RXIcon name="Close" color={COLOR.White} size={36} />
          </CloseButton>
        </ImageContainer>
      </RModal>
    </Container>
  );
}

export default observer(InfluencerChat);

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const ChatContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const ImageContainer = styled(ColumnContainer)`
  width: 100%;
  position: relative;
`;

const Image = styled.img`
  max-width: 70%;
  height: auto;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  :hover {
    cursor: pointer;
  }
`;
