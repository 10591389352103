import React, { useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { Deal } from '../../../../types/deal';
import { ColumnContainer } from '../../../../utils/styling/general';
import RLoading from '../../../atoms/RLoading';
import DiscoverCampaignCardDetail from '../../../molecules/Discover/Campaign/DiscoverCampaignCardDetail';
import DiscoverCampaignCardImage from '../../../molecules/Discover/Campaign/DiscoverCampaignCardImage';

interface Props {
  deals: Deal[];
  loading: boolean;
  next: () => void;
  metadata: {
    count: number;
    limit: number;
    offset: number;
    seed: number;
  };
  navigate: NavigateFunction;
}
function DiscoverCampaignCard(props: Props) {
  const { deals, loading, next, metadata, navigate } = props;

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (
      scrollTop + clientHeight >= scrollHeight - 674 &&
      !loading &&
      metadata?.count > deals.length
    ) {
      next();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [loading]);

  return (
    <Container>
      {deals &&
        deals.map(deal => {
          return (
            <CampaignContainer
              onClick={() => {
                navigate(`/discover?page=campaign&dealId=${deal.dealId}`);
              }}
            >
              <DiscoverCampaignCardImage
                brandImg={deal.brand?.img || ''}
                brandName={
                  deal.brand?.companyName || deal.brand?.name || 'Pickle Brand'
                }
                img={deal.coverImg || ''}
                // tags="High Reach"
                tags={deal?.tags && deal?.tags[0]}
              />
              <DiscoverCampaignCardDetail
                name={deal.name}
                value={deal.value}
                cash={deal.cash}
                criteria={{
                  minFollower: deal.criteria?.minFollower || 0,
                  tiktokMinFollower: deal.criteria?.tiktokMinFollower || 0
                }}
                tags={deal?.tags && deal?.tags[0]}
                reach={deal.analysis?.reachs || 0}
                like={deal.analysis?.likes || 0}
                comment={deal.analysis?.comments || 0}
                userImage={deal.pastInfluencers || []}
                totalRegister={deal.total || 10}
              />
            </CampaignContainer>
          );
        })}
      {loading && <RLoading />}
    </Container>
  );
}

export default DiscoverCampaignCard;

const Container = styled(ColumnContainer)`
  gap: 16px;
  margin-bottom: 80px;
`;

const CampaignContainer = styled.div`
  display: flex;
  width: 885px;
  height: 386px;
  padding: 32px;
  gap: 32px;
  border-radius: 24px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;
