import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import RLoading from '../../../components/atoms/RLoading';
import ScrollToTop from '../../../components/atoms/ScrollToTop';
import DiscoverCampaignDetail from '../../../components/organisms/Discover/Campaign/DiscoverCampaignDetail';
import DiscoverCampaignHeader from '../../../components/organisms/Discover/Campaign/DiscoverCampaignHeader';
import { dealKeys } from '../../../types/createDeal';
import { DiscoverPage } from '../../DiscoverPage';

interface Props {
  dealDetail: dealKeys;
  navigate: NavigateFunction;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  loading: boolean;
}

function DiscoverCampaignDetailsPage(props: Props) {
  const { dealDetail, navigate, setPage, loading } = props;

  const { name, category, value, cash, criteria, photos } = dealDetail;

  const { minFollower, tiktokMinFollower } = criteria;

  const width = window.innerWidth;
  return loading ? (
    <LoadingContainer>
      <RLoading />
    </LoadingContainer>
  ) : (
    <Container>
      <ScrollToTop />
      <DiscoverCampaignHeader
        width={width}
        name={name}
        category={category}
        value={value}
        cash={cash}
        minFollower={minFollower}
        tiktokMinFollower={tiktokMinFollower}
        coverImg={typeof photos[0] === 'string' ? photos[0] : ''}
        navigate={navigate}
        setPage={setPage}
      />
      <DiscoverCampaignDetail dealDetail={dealDetail} />
    </Container>
  );
}

export default DiscoverCampaignDetailsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 522px;
  margin-top: 100px;
`;
