import { ChatStore } from '../chatStore';
import { createContext, useContext } from 'react';
import { AccountStore } from '../accountStore';
import { FeatureAccessStore } from '../featureAccessStore';
import { AuthStore } from '../authStore';
import { FeedStore } from '../feedStore';
import { ResetPasswordStore } from '../resetPasswordStore';
import { DealStore } from '../dealStore';
import { CreateDealStore } from '../createDealStore';
import { InfluencerStore } from '../influencerStore';
import { EditDealStore } from '../editDealStore';
import { NotificationStore } from '../notificationStore';
import { WebSocketStore } from '../webSocketStore';
import { OfferStore } from '../offerStore';
import { NetworkStore } from '../networkStore';

export const AuthStoreContext = createContext<AuthStore>(null!);
export const useAuthStore = () => useContext(AuthStoreContext);

export const AccountStoreContext = createContext<AccountStore>(null!);
export const useAccountStore = () => useContext(AccountStoreContext);

export const FeatureAccessContext = createContext<FeatureAccessStore>(null!);
export const useFeatureAccessStore = () => useContext(FeatureAccessContext);

export const FeedStoreContext = createContext<FeedStore>(null!);
export const useFeedStore = () => useContext(FeedStoreContext);

export const ResetPasswordContext = createContext<ResetPasswordStore>(null!);
export const useResetPasswordStore = () => useContext(ResetPasswordContext);

export const DealStoreContext = createContext<DealStore>(null!);
export const useDealStore = () => useContext(DealStoreContext);

export const CreateDealStoreContext = createContext<CreateDealStore>(null!);
export const useCreateDealStore = () => useContext(CreateDealStoreContext);

export const ChatStoreContext = createContext<ChatStore>(null!);
export const useChatStore = () => useContext(ChatStoreContext);

export const InfluencerStoreContext = createContext<InfluencerStore>(null!);
export const useInfluencerStore = () => useContext(InfluencerStoreContext);

export const EditDealStoreContext = createContext<EditDealStore>(null!);
export const useEditDealStore = () => useContext(EditDealStoreContext);

export const NotificationStoreContext = createContext<NotificationStore>(null!);
export const useNotificationStore = () => useContext(NotificationStoreContext);

export const WebSocketStoreContext = createContext<WebSocketStore>(null!);
export const useWebSocketStore = () => useContext(WebSocketStoreContext);

export const OfferStoreContext = createContext<OfferStore>(null!);
export const useOfferStore = () => useContext(OfferStoreContext);

export const NetworkStoreContext = createContext<NetworkStore>(null!);
export const useNetworkStore = () => useContext(NetworkStoreContext);
