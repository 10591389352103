import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { TEXT } from '../../../../fonts/text';
import { SpaceBetweenRowContainer } from '../../../../utils/styling/general';
import FilterAge from './FilterAge';
import FilterGender from './FilterGender';
import FilterTier from './FilterTier';
import FilterLocation from './FilterLocation';

interface ComponentProps {
  active: boolean;
}
const ArrowComponent = (props: ComponentProps) => {
  const { active } = props;
  if (active) return <RXIcon name="ArrowDown" />;
  return <RXIcon name="ArrowUp" />;
};

const ContentComponent = (props: ComponentProps) => {
  const { active } = props;
  if (!active) return <></>;
  return (
    <>
      <FilterGender />
      <FilterTier />
      <FilterLocation />
      <FilterAge />
    </>
  );
};

const InfluencerFilter = () => {
  const [active, setActive] = useState<boolean>(true);

  return (
    <Container>
      <RowContainer onClick={() => setActive(!active)}>
        <BoldH8Text>รายละเอียด Influencer</BoldH8Text>
        <ArrowComponent active={active} />
      </RowContainer>
      <ContentComponent active={active} />
    </Container>
  );
};

export default InfluencerFilter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: -20px;
`;

const RowContainer = styled(SpaceBetweenRowContainer)`
  :hover {
    cursor: pointer;
  }
`;

const BoldH8Text = styled(TEXT.H8_Bold)``;
