import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LoginLogo from '../../components/organisms/Login/LoginLogo';
import RegisterCard from '../../components/organisms/Register/RegisterCard';
import RegisterCodeSent from '../../components/organisms/Register/VerifiedCard';
import { useAuthStore } from '../../stores/initialize/Context';

function RegisterPage() {
  const {
    authSignUp,
    setAuthSignUp,
    register,
    authSignUpError,
    validateTeamEmail,
    validatePassword,
    validateRePassword,
    loadIsLoggedIn
  } = useAuthStore();
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (loadIsLoggedIn()) {
      navigate('/');
    }
  }, []);

  const renderComponent = () => {
    switch (page) {
      case 1:
        return (
          <RegisterCard
            changePage={() => setPage(2)}
            authSignUp={authSignUp}
            setAuthSignUp={setAuthSignUp}
            register={register}
            authSignUpError={authSignUpError}
            validateTeamEmail={validateTeamEmail}
            validatePassword={validatePassword}
            validateRePassword={validateRePassword}
          />
        );
      case 2:
        return (
          <RegisterCodeSent
            onDone={() => navigate('/')}
            email={authSignUp.teamEmail}
          />
        );
    }
  };

  return (
    <Container>
      <ContentContainer>
        <LogoContainer>
          <LoginLogo />
        </LogoContainer>
        <RegisterContainer>{renderComponent()}</RegisterContainer>
      </ContentContainer>
    </Container>
  );
}

export default observer(RegisterPage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1280px;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 80px;
`;

const RegisterContainer = styled.div`
  margin-top: 174px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 280px;
  margin-right: 120px;
`;
