import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TEXT } from '../../../fonts/text';
import ScrollToTop from '../../atoms/ScrollToTop';
import Tab from '../../molecules/NewCreateCampaign/Tab';
import { NavigateFunction } from 'react-router-dom';
import { pageIndex } from '../../../pages/NewCreateCampaign/NewCreateCampaignPage';

interface Props {
  navigate: NavigateFunction;
  page: pageIndex;
  setPage: React.Dispatch<React.SetStateAction<pageIndex>>;
  indexSuccess: number[];
}

const tabContent: { header: string; detail: string }[] = [
  {
    header: 'ข้อมูลเชิงลึก',
    detail: 'สรุปข้อมูลคาดการณ์ Engagement'
  },
  {
    header: 'ขอบเขตของงาน',
    detail: 'ระบุความต้องการ'
  },
  {
    header: 'รายละเอียดแคมเปญ',
    detail: 'เพิ่มข้อมูลการโปรโมต'
  },
  {
    header: 'ตรวจสอบข้อมูล',
    detail: 'เช็คข้อมูลก่อนเริ่มแคมเปญ'
  }
];

function TabNavigator(props: Props) {
  const { navigate, page, setPage, indexSuccess } = props;

  const determineStatus = (index: number) => {
    if (page === index) return 'current';
    if (indexSuccess.includes(index - 1)) return 'complete';
    return 'ongoing';
  };
  const TabsComponent = () => {
    return (
      <>
        {tabContent.map((tab, index) => (
          <Tab
            key={tab.header}
            header={tab.header}
            detail={tab.detail}
            index={index + 1}
            navigate={navigate}
            status={determineStatus(index + 1)}
            onClick={() => {
              setPage((index + 1) as pageIndex);
              navigate(`/createcampaign?page=${index + 1}`);
            }}
          />
        ))}
      </>
    );
  };

  return (
    <Container>
      <ScrollToTop />
      <TabsComponent />
    </Container>
  );
}

export default observer(TabNavigator);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  padding-left: 36px;
  width: 100%;
`;
