import styled from 'styled-components';
import { Work } from '../../../types/work';
import { RowContainer } from '../../../utils/styling/general';
import Post from '../../../components/atoms/Post';
import REmptyState from '../../../components/atoms/REmptyState';
import { PlatformType } from '../../../types/deal';

interface Props {
  campaignWorks: Work[];
}

const PostListComponent = (props: Props) => {
  const { campaignWorks } = props;
  const components: JSX.Element[] = [];

  const getPostDetail = (platform: PlatformType, work: Work) => {
    switch (platform) {
      case 'INSTAGRAM':
        return { caption: work?.caption ?? '', permalink: work?.permalink };
      case 'TIKTOK':
        return { caption: work?.title ?? '', permalink: work?.share_url };
      case 'FACEBOOK':
        return { caption: work?.message ?? '', permalink: work?.permalink_url };
      default:
        return { caption: '', permalink: '' };
    }
  };

  campaignWorks.map(work => {
    const platform = work.platform.toUpperCase() as PlatformType;
    const { caption, permalink } = getPostDetail(platform, work);
    components.push(
      <Post
        key={work.workId}
        work={{
          image: work.thumbnail_url ?? work.media_url,
          username: work.username,
          caption,
          permalink,
          like: work.like_count,
          share: work.share_count ?? 0,
          comment: work.comments_count ?? 0,
          createdAt: work.createdAt,
          view: work.view_count ?? 0,
          engagement: work.engagement ?? 0,
          impression: work.impressions,
          reach: work.reach,
          saved: work.saved ?? 0,
          profile_picture_url: work.profile_picture_url
        }}
        platformActive={platform}
      />
    );
  });
  return <Container>{components}</Container>;
};

const PostList = (props: Props) => {
  const { campaignWorks } = props;

  if (campaignWorks.length === 0) {
    const emptyStyle = {
      width: '66%',
      alignSelf: 'center',
      marginTop: 24,
      marginBottom: 24
    };
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={emptyStyle}
      />
    );
  }

  return <PostListComponent {...props} />;
};

export default PostList;

const Container = styled(RowContainer)`
  gap: 12px;
  flex-wrap: wrap;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;
