import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import ANDButton from '../../../atoms/ANDButton';
import Dropdown from '../../../atoms/Dropdown/Dropdown';
import { interestCategory } from '../../../organisms/CreateCampaign/Options';

interface Props {
  category: string;
  setCategory: (category: string) => void;
}

function Category(props: Props) {
  const { category, setCategory } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>หมวดหมู่ สินค้า/บริการ</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <Dropdown
          defaultValue={category}
          placeholder="เลือกหมวดหมู่สินค้า/บริการ"
          activeText={!!category}
          options={interestCategory.map(category => category.value)}
          onChange={(option: string) => {
            setCategory(option);
          }}
          containerStyle={{ width: 568 }}
        />
      </ContentContainer>
    </Container>
  );
}

export default Category;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;
