import React from 'react';
import { useLocation } from 'react-router-dom';
import { scopeOfWorkType } from '../class/ScopeOfWork';
import { RateCard } from '../types/api/getInfluencer';
import { PlatformType } from '../types/deal';

export const HTTP_PATTERN = /^(https?:\/\/)/;

export function log(input: any) {
  console.log(input);
}

export function objectToQuerystring(
  obj: Record<string, string | number | boolean | undefined>
) {
  const qs = new URLSearchParams(removeEmpty(obj) as Record<string, string>);
  return `?${qs.toString()}`;
}

export function removeEmpty(obj: Record<string, any>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const toMonthYear = ({
  dueDate,
  haveDay,
  haveYear = true,
  dateLanguage = 'eng'
}: {
  dueDate: string | Date;
  haveDay?: boolean;
  haveYear?: boolean;
  dateLanguage?: 'th' | 'eng';
}) => {
  if (!dueDate) {
    return '';
  }

  var month = [
    { eng: 'Jan', th: 'ม.ค.' },
    { eng: 'Feb', th: 'ก.พ.' },
    { eng: 'Mar', th: 'มี.ค.' },
    { eng: 'Apr', th: 'เม.ย.' },
    { eng: 'May', th: 'พ.ค.' },
    { eng: 'Jun', th: 'มิ.ย.' },
    { eng: 'Jul', th: 'ก.ค.' },
    { eng: 'Aug', th: 'ส.ค.' },
    { eng: 'Sep', th: 'ก.ย.' },
    { eng: 'Oct', th: 'ต.ค.' },
    { eng: 'Nov', th: 'พ.ย.' },
    { eng: 'Dec', th: 'ธ.ค.' }
  ];

  const date = new Date(dueDate);
  const dayDate = date.getDate();
  const monthFromDate = date.getMonth();

  if (!monthFromDate) return '';

  const monthDate = month[monthFromDate][dateLanguage];
  const yearDate =
    dateLanguage === 'th' ? date.getFullYear() + 543 : date.getFullYear();

  if (haveDay && haveYear) {
    return `${dayDate} ${monthDate} ${yearDate}`;
  } else if (haveDay) {
    return `${dayDate} ${monthDate}`;
  } else if (haveYear) {
    return `${monthDate}, ${yearDate}`;
  } else {
    return `${monthDate}`;
  }
};

export const getDaysArray = function (start: Date, end: Date) {
  if (start > end) return [];
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const toDecimalNumber = ({
  number,
  toFixed = 1,
  round = false,
  isZeroDash = true
}: {
  number: number | string;
  toFixed?: number;
  round?: boolean;
  isZeroDash?: boolean;
}) => {
  const num = typeof number === 'string' ? parseInt(number) : number;
  if (!num) {
    if (isZeroDash) return '-';
    return `${num}`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(toFixed)}M`;
  }
  if (num >= 10000) {
    return `${(num / 1000).toFixed(toFixed)}K`;
  }
  if (num >= 1000) {
    return numberWithCommas(num);
  }
  if (round && num < 1000) return `${Math.round(num)}`;
  return `${num}`;
};

export const minusHours = (ISOString: any, numberofhour: any) => {
  var d = new Date(ISOString);
  d.setHours(d.getHours() - numberofhour);
  return d.toISOString();
};

export const minusMinutes = (ISOString: any, numberofminute: any) => {
  var d = new Date(ISOString);
  d.setMinutes(d.getMinutes() - numberofminute);
  return d.toISOString();
};

export const plusDays = (ISOString: any, numberofday: any) => {
  var d = new Date(ISOString);
  d.setDate(d.getDate() + numberofday);
  return d.toISOString();
};

export const numberWithCommas = (x: string | number) => {
  if (!x) {
    return '0';
  }
  let s = x;
  if (typeof x === 'number') {
    if (x % 1 !== 0) s = x.toFixed(0);
  }
  return s?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const toISODate = (date: number) => {
  const newDate: Date = new Date(date);
  return newDate.toISOString();
};

export const toDateDue = (dueDate: string | Date, withComma = false) => {
  if (!dueDate) {
    return '';
  }
  var month = [];
  month[0] = 'Jan';
  month[1] = 'Feb';
  month[2] = 'Mar';
  month[3] = 'Apr';
  month[4] = 'May';
  month[5] = 'Jun';
  month[6] = 'Jul';
  month[7] = 'Aug';
  month[8] = 'Sep';
  month[9] = 'Oct';
  month[10] = 'Nov';
  month[11] = 'Dec';
  let date;
  if (typeof dueDate === typeof '') {
    const dateString = dueDate as string;
    date = new Date(dateString.split('+')?.[0]);
  } else {
    date = new Date(dueDate);
  }
  return `${('0' + date.getDate()).slice(-2)}${withComma ? ',' : ''} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;
};

export function clearArray<T>(array: T[]) {
  while (array.length) {
    array.pop();
  }
}

export function ValidateEmail(mail: string) {
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(mail)) {
    return true;
  }
  return false;
}

export const PLACEHOLDER_IMAGE =
  'https://storage.googleapis.com/rabbitx/1612256798664Pickle-Default-01.png';
export const PLACEHOLDER_INFLUENCER = require('../assets/images/Placeholder/Placeholder_profile.png');
export const PLACEHOLDER_PICKLE = require('../assets/images/Pickle.png');
export const PLACEHOLDER_BRAND = require('../assets/images/Placeholder/Pickle For Brand.png');

export const PLACEHOLDER_PROFILE =
  'https://firebasestorage.googleapis.com/v0/b/rabbitx.appspot.com/o/influencerProfiles%2FMicrosoftTeams-image.png?alt=media&token=a807108d-7e37-4bd7-a754-d7b564a1321c';

export const PLACEHOLDER_ANOTHERDOT = require('../assets/images/Placeholder/Placeholder_anotherdot.png');
export const PLACEHOLDER_REELS = require('../assets/images/Placeholder/PlaceholderReel.png');

export const TODAYSTRING = new Date().toISOString();

export const genderFieldToText = (genderField: string) => {
  if (!genderField) return 'ไม่ระบุเพศ';
  if (genderField === 'W,M,L' || genderField === 'both') return 'ทุกเพศ';
  else if (genderField === 'male') return 'ชาย';
  else if (genderField === 'female') return 'หญิง';
  else {
    const selectedGenders = genderField.split(',').map(gen => {
      switch (gen) {
        case 'W': {
          return 'เพศหญิง';
        }
        case 'M': {
          return 'เพศชาย';
        }
        case 'L': {
          return 'LGBTQ+';
        }
      }
    });
    return selectedGenders.join(', ');
  }
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const groupBy = <T>(arr: T[], key: string): Record<string, T[]> | {} => {
  return arr.reduce((rv, x) => {
    // @ts-ignore
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

type DateFormatType = 'd' | 'w' | 'm' | 'y' | 'hrs' | 'min' | 'sec';

const dateFormatData: Record<
  DateFormatType,
  { short: string; full: string; thai: string }
> = {
  d: { short: 'd', full: 'days', thai: 'วัน' },
  m: { short: 'm', full: 'months', thai: 'เดือน' },
  w: { short: 'w', full: 'weeks', thai: 'สัปดาห์' },
  y: { short: 'y', full: 'years', thai: 'ปี' },
  hrs: { short: 'h', full: 'hours', thai: 'ชั่วโมง' },
  min: { short: 'm', full: 'minutes', thai: 'นาที' },
  sec: { short: 's', full: 'seconds', thai: 'วินาที' }
};

const dateFormatter = (
  dateFormat: DateFormatType,
  dateType: 'thai' | 'short' | 'full'
) => {
  return dateFormatData[dateFormat][dateType];
};

//TODO: fix props in this function
export const difTime = (
  comparedTime: string | Date,
  isPass = true,
  fullword = false,
  isDayUnit = false,
  isThai = false
) => {
  if (!comparedTime) return '';
  const compared = new Date(comparedTime);
  const now = new Date();
  const Difference_In_Time = isPass
    ? (now.getTime() - compared.getTime()) / 1000
    : (compared.getTime() - now.getTime()) / 1000; // second

  const type = isThai ? 'thai' : fullword ? 'full' : 'short';

  if (Difference_In_Time < 0) {
    return `0 ${dateFormatter('d', type)}`;
  }
  if (isDayUnit) {
    return `${Math.round(Difference_In_Time / 86400)} ${dateFormatter(
      'd',
      type
    )}`;
  }
  if (Difference_In_Time > 31536000) {
    return `${Math.round(Difference_In_Time / 31536000)} ${dateFormatter(
      'y',
      type
    )}`;
  }
  if (Difference_In_Time > 2629746) {
    return `${Math.round(Difference_In_Time / 2629746)} ${dateFormatter(
      'm',
      type
    )}`;
  }
  if (Difference_In_Time > 604800) {
    return `${Math.round(Difference_In_Time / 604800)} ${dateFormatter(
      'w',
      type
    )}`;
  }
  if (Difference_In_Time > 86400) {
    return `${Math.round(Difference_In_Time / 86400)} ${dateFormatter(
      'd',
      type
    )}`;
  }
  if (Difference_In_Time > 3600) {
    return `${Math.round(Difference_In_Time / 3600)} ${dateFormatter(
      'hrs',
      type
    )}`;
  }
  if (Difference_In_Time > 60) {
    return `${Math.round(Difference_In_Time / 60)} ${dateFormatter(
      'min',
      type
    )}`;
  }
  return `${Math.round(Difference_In_Time)} ${dateFormatter('sec', type)}`;
};

export const limitText = (text: string, limit: number) => {
  if (text.length < limit) {
    return text;
  } else {
    return text.slice(0, limit) + '...';
  }
};

export const reviewTagBinaryConverter = (index: number) => {
  switch (index) {
    case 0:
      return 'ทำตามบรีฟ';
    case 1:
      return 'ถ่ายรูปสวย';
    case 2:
      return 'ตอบแชทไว';
    case 3:
      return 'ส่งงานตรงเวลา';
    case 4:
      return 'ความคิดสร้างสรรค์';
    default:
      return '';
  }
};

export const toCapitalize = (text: string) => {
  const lowerText = text.toLowerCase();
  const capitalLetter = text[0].toUpperCase();
  return capitalLetter + lowerText.slice(1);
};

export const monthNames = [
  { full: 'มกราคม', short: 'ม.ค.' },
  { full: 'กุมภาพันธ์', short: 'ก.พ.' },
  { full: 'มีนาคม', short: 'มี.ค.' },
  { full: 'เมษายน', short: 'เม.ษ.' },
  { full: 'พฤษภาคม', short: 'พ.ค.' },
  { full: 'มิถุนายน', short: 'มิ.ย.' },
  { full: 'กรกฎาคม', short: 'ก.ค.' },
  { full: 'สิงหาคม.', short: 'ส.ค.' },
  { full: 'กันยายน', short: 'ก.ย.' },
  { full: 'ตุลาคม', short: 'ต.ค.' },
  { full: 'พฤศจิกายน', short: 'พ.ย.' },
  { full: 'ธันวาคม', short: 'ธ.ค.' }
];

export const toThaiDate = (d: string | Date, short: boolean = false) => {
  if (!d) return '';
  var date = new Date(d);
  const day = date.getDate();
  const month = short
    ? monthNames[date.getMonth()].short
    : monthNames[date.getMonth()].full;
  const year = date.getFullYear() + (short ? -1957 : 543);
  return day + ' ' + month + ' ' + year;
};

export const renderCapatalizedSocialPlatform = (platformName: PlatformType) => {
  switch (platformName) {
    case 'LEMON':
      return 'Lemon8';
    case 'TIKTOK':
      return 'TikTok';
    default:
      return toCapitalize(platformName);
  }
};

export const getTierByFollower = (followers: number) => {
  if (followers > 300000) return 'macro';
  if (followers > 50000) return 'micro';
  if (followers > 10000) return 'mid';
  return 'nano';
};

export const isHaveEveryElement = (arr1: any[], arr2: any[]): boolean => {
  return arr1.every(element => arr2.includes(element));
};

export const hasKey = <T extends object>(
  obj: T,
  key: keyof any
): key is keyof T => {
  return key in obj;
};

export const postTypeName: Record<scopeOfWorkType, string> = {
  IG_individual_post: 'Single Post',
  IG_album_post: 'Multi Post',
  IG_story: 'Story',
  IG_tv: '',
  IG_reels: 'Reels',
  TK_video: 'Video',
  LM_article: 'Article',
  FB_individual_post: 'Single Post',
  FB_album_post: 'Multi Post',
  FB_reels: 'Reels'
};

export const calculatePercentage = (value1: number, value2: number) => {
  const percent = value1 / value2;
  return ((isNaN(percent) ? 0 : percent) * 100).toFixed(1);
};

export const calculateAverageData = (value1: number, value2: number) => {
  const average = value1 / value2;
  return (isNaN(average) || average === Infinity ? 0 : average).toFixed(1);
};

export const rateCardTypeText = (card: RateCard) => {
  if (!card?.detail) return;

  const [type, detail] = card.detail.split('/');
  switch (type) {
    case 'a':
      return 'Lemon8 Article';
    case 'fi':
    case 'i':
      return 'Single Post';
    case 'fm':
    case 'm':
      return 'Multi Post';
    case 'fr':
    case 'r':
      return 'Reels';
    case 's':
      return 'Story';
    case 'v':
    case 'z':
      return detail;
  }
};

export const hexToRgba = (hex: string, opacity: number): string | null => {
  hex = hex.replace('#', '');
  if (!/^(?:[0-9a-fA-F]{3}){1,2}$/.test(hex)) {
    return null;
  }
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  return date1.toDateString() === date2.toDateString();
};

export const isSameWeek = (date1: Date, date2: Date): boolean => {
  const startOfWeek1 = new Date(date1);
  const startOfWeek2 = new Date(date2);

  startOfWeek1.setDate(startOfWeek1.getDate() - startOfWeek1.getDay());
  startOfWeek2.setDate(startOfWeek2.getDate() - startOfWeek2.getDay());

  return startOfWeek1.toDateString() === startOfWeek2.toDateString();
};

export const isSameMonth = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
};

export const IsTierType = (value: string) => {
  return (
    value === 'nano' ||
    value === 'micro' ||
    value === 'mid' ||
    value === 'macro'
  );
};

export const roundUpNearest = (value: number) => {
  if (value === 0) return value;
  // exceptional case. Function can't use to calculate the 0-9 number
  if (value < 5) return 5;
  if (value < 10) return 10;
  let strValue = value.toString();
  let length = strValue.length;
  let significantPlace = Math.pow(10, length - 1);
  let midPoint = 1.5 * significantPlace;
  if (value < midPoint) {
    return (
      Math.ceil(value / (0.5 * significantPlace)) * (0.5 * significantPlace)
    );
  }
  let roundedValue = Math.ceil(value / significantPlace) * significantPlace;
  return roundedValue;
};

export const formatToFixedWithoutZero = (
  value: number,
  toFixed: number
): number => {
  const roundNumber = Math.round(value);
  if (value % 1 !== 0) return parseFloat(value.toFixed(toFixed));
  return roundNumber;
};
