import React from 'react';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { TEXT } from '../../../../fonts/text';
import { COLOR } from '../../../../fonts/color';
import {
  FlexEndColumnContainer,
  FlexStartColumnContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import ANDSilder from '../../../../components/atoms/ANDSlider';
import { useInfluencerListFilterContext } from '../../InfluencerListContext';

interface FunctionProps {
  e: React.ChangeEvent<HTMLInputElement>;
  index: number;
}

const FilterEngagement = () => {
  const { filters, updateFilter, maxEngagement } =
    useInfluencerListFilterContext();

  const onChangeHandler = (props: FunctionProps) => {
    const { e, index } = props;
    const newValue = parseInt(e.currentTarget.value);
    const newEngagementRange = [...filters.engagementRange];
    newEngagementRange[index] = newValue;

    if (newEngagementRange[0] <= newEngagementRange[1]) {
      updateFilter('engagementRange', newEngagementRange);
    }
  };
  return (
    <Container>
      <GrayMB3Text>Avg.Engagement</GrayMB3Text>
      <SpaceBetweenRowContainer style={{ alignItems: 'center' }}>
        <FlexStartColumnContainer style={{ gap: 4 }}>
          <NumberContainer>
            <Input
              placeholder={'0'}
              type="number"
              value={filters.engagementRange[0]}
              onChange={e =>
                onChangeHandler({
                  e,
                  index: 0
                })
              }
            />
          </NumberContainer>
          <GrayDB5Text>Min</GrayDB5Text>
        </FlexStartColumnContainer>
        <GrayDBu3Text>-</GrayDBu3Text>
        <FlexEndColumnContainer style={{ gap: 4 }}>
          <NumberContainer>
            <Input
              placeholder={'100K'}
              type="number"
              value={filters.engagementRange[1]}
              onChange={e =>
                onChangeHandler({
                  e,
                  index: 0
                })
              }
            />
          </NumberContainer>
          <GrayDB5Text>Max</GrayDB5Text>
        </FlexEndColumnContainer>
      </SpaceBetweenRowContainer>
      <ANDSilder
        range={filters.engagementRange}
        min={0}
        max={maxEngagement}
        setRange={range => {
          let tempRange = [...filters.engagementRange];
          tempRange = range;
          updateFilter('engagementRange', tempRange);
        }}
      />
    </Container>
  );
};

export default FilterEngagement;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const NumberContainer = styled.div`
  display: flex;
  width: 108px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.4px solid ${ANDCOLOR.MEDIUMGRAY};
`;

const Input = styled.input`
  border: none;
  width: 100%;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

const GrayDB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_D};
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
  margin-bottom: 12px;
`;

const GrayDBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_D};
  margin-bottom: 16px;
`;
