import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useAccountStore,
  useInfluencerStore
} from '../../stores/initialize/Context';
import {
  IFacebookInfluencer,
  IGetInfluencerResponse,
  IInstagramInfluencer,
  ITiktokInfluencer
} from '../../types/api/getInfluencer';
import { PlatformType } from '../../types/deal';
import { PLACEHOLDER_ANOTHERDOT, useQuery } from '../../utils';
import InfluencerInsight from '../../components/molecules/Influencer/InfluencerInsight';
import MockedInfluencerInsight from '../../components/molecules/Influencer/MockedInfluencerInsight';
import PostList from '../../components/molecules/Influencer/PostList';
import Review from '../../components/molecules/Influencer/Review';
import SimilarInfluencer from '../../components/molecules/Influencer/SimilarInfluencer';
import InfluencerBlueCard from '../../components/molecules/Influencer/InfluencerBlueCard';
import { Feature, featureAccessStore } from '../../stores/featureAccessStore';
import { BlurredContainer } from '../../utils/styling/general';
import MockedPostList from '../../components/molecules/Influencer/MockedPostList';
import PlatformTab from '../../components/atoms/Influencer/PlatformTab';
import FreeTrialBanner from '../FreeTrialBanner';
import { InfluencerInsightTitle } from './InfluencerInsightTitle';
import { getAgeGenderData, getStatisticData } from './getInfluencerInsightData';
import InfluencerInviteFooter from './InfluencerInviteFooter';

interface DeterminerProps {
  instagram: IInstagramInfluencer;
  tiktok: ITiktokInfluencer;
  facebook: IFacebookInfluencer;
  platform: PlatformType;
}

const influencerIdDeterminer = (props: DeterminerProps) => {
  const { platform, instagram, tiktok, facebook } = props;
  try {
    switch (platform) {
      case 'INSTAGRAM':
        return instagram.id;
      case 'TIKTOK':
        return tiktok.id;
      case 'FACEBOOK':
        return facebook.id;
      default:
        return '';
    }
  } catch (error) {
    return '';
  }
};

interface onSavedProps {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  accountId: string;
  isSaved: boolean;
  addSavedInflu: (accountId: string) => void;
  removeSavedInflu: (accountId: string) => void;
  triggerSaveInfluencer: ({
    accountId,
    isSaved
  }: {
    accountId: string;
    isSaved: boolean;
  }) => Promise<void>;
}

const onSavedHandler = (props: onSavedProps) => {
  const {
    e,
    accountId,
    isSaved,
    addSavedInflu,
    removeSavedInflu,
    triggerSaveInfluencer
  } = props;
  e.preventDefault();
  e.stopPropagation();
  if (isSaved) {
    removeSavedInflu(accountId);
  } else {
    addSavedInflu(accountId);
  }
  triggerSaveInfluencer({ accountId, isSaved });
};

interface InviteFooterProps {
  setAcceptModalActive?: React.Dispatch<React.SetStateAction<string>>;
}

const AcceptInfluFooterComponent = (props: InviteFooterProps) => {
  const { setAcceptModalActive } = props;
  if (!!setAcceptModalActive)
    return (
      <InfluencerInviteFooter setAcceptModalActive={setAcceptModalActive} />
    );
  return <></>;
};

interface ContainerProps {
  haveFooter: boolean;
}

interface Props {
  influencer: IGetInfluencerResponse;
  setActive?: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptModalActive?: React.Dispatch<React.SetStateAction<string>>;
}

const InfluencerPortfolio = (props: Props) => {
  const { influencer, setActive, setAcceptModalActive } = props;

  const { addSavedInflu, removeSavedInflu, brandSaved } = useAccountStore();

  const {
    getInfluencersReviews,
    reviews,
    loading,
    getInfluencerPosts,
    getInfluencerGraphData,
    socialData,
    getInfluencerSimilarList,
    triggerSaveInfluencer
  } = useInfluencerStore();
  const { starCount } = reviews;
  const query = useQuery();

  const platform = query.get('platform')
    ? (query.get('platform') as PlatformType)
    : 'INSTAGRAM';

  const [selectedFilter, selectFilter] = useState<number>(0);
  const [platformActive, setPlatformActive] = useState<PlatformType>(platform);
  const [dateFormat, setDateFormat] = useState<'weekly' | 'monthly'>('weekly');

  const isShowFeature = featureAccessStore.canAccess(Feature.INSIGHT_PORTFOLIO);
  const isSaved = brandSaved.includes(influencer.account.id);
  const statisticData = getStatisticData({
    platformActive: platformActive === 'LEMON' ? 'FACEBOOK' : platformActive,
    instagram: influencer.instagram,
    tiktok: influencer.tiktok,
    facebook: influencer.facebook,
    dateFormat
  });
  const ageGenderData = getAgeGenderData({
    instagram: influencer.instagram,
    tiktok: influencer.tiktok,
    facebook: influencer.facebook,
    platformActive
  });

  useEffect(() => {
    getInfluencersReviews({
      accountId: influencer.account.id,
      filter: selectedFilter,
      sortOrder: 'desc',
      limit: 10,
      offset: 0
    });
  }, [influencer.account.id, selectedFilter]);

  useEffect(() => {
    const id = influencerIdDeterminer({
      instagram: influencer.instagram,
      tiktok: influencer.tiktok,
      facebook: influencer.facebook,
      platform: platformActive
    });
    getInfluencerPosts(id, platformActive);
    getInfluencerGraphData(id, platformActive);
    getInfluencerSimilarList(id, platformActive);
  }, [platformActive]);

  return (
    <>
      <Container haveFooter={!!setAcceptModalActive}>
        {!isShowFeature ? <FreeTrialBanner /> : null}
        <InfluencerBlueCard
          username={influencer.account.name}
          avgRating={influencer.account.review.avgRating}
          totalReview={influencer.account.review.totalReview}
          province={influencer.account.province}
          lastOnline={influencer.lastOnline}
          gender={influencer.account.gender}
          interests={influencer.account.interests || []}
          profile_img={
            influencer.account.profileImages[0] ||
            influencer.account.img ||
            PLACEHOLDER_ANOTHERDOT
          }
          attributes={influencer.attributes}
          instagram={influencer.instagram}
          tiktok={influencer.tiktok}
          facebook={influencer.facebook}
          rateCard={influencer.account.rateCard}
          lemonUrl={influencer.account.lemonUrl}
          setActive={setActive}
          isSaved={isSaved}
          onSaved={e =>
            onSavedHandler({
              accountId: influencer.account.id,
              addSavedInflu,
              removeSavedInflu,
              triggerSaveInfluencer,
              isSaved,
              e
            })
          }
        />
        <ContentContainer>
          <InfluencerInsightTitle />
          <PlatformTab
            platformActive={platformActive}
            setPlatformActive={setPlatformActive}
            haveIG={!!influencer.instagram}
            haveTK={!!influencer.tiktok}
            haveFB={!!influencer.facebook}
          />
          {isShowFeature ? (
            <>
              <InfluencerInsight
                instagram={influencer.instagram}
                tiktok={influencer.tiktok}
                facebook={influencer.facebook}
                platformActive={platformActive}
                setPlatformActive={setPlatformActive}
                dateFormat={dateFormat}
                setDateFormat={setDateFormat}
                statistic={statisticData}
                socialData={socialData}
                loading={loading}
                ageGenderData={ageGenderData}
              />
              <PostList
                platformActive={platformActive}
                postList={socialData[platformActive].posts}
              />
            </>
          ) : (
            <>
              <BlurredContainer>
                <MockedInfluencerInsight />
                <MockedPostList />
              </BlurredContainer>
            </>
          )}

          <Review
            reviewOverall={influencer.account.review}
            reviews={reviews.data.slice(0, 2)}
            starCount={starCount}
            selectFilter={selectFilter}
            selectedFilter={selectedFilter}
            loading={loading.reviews}
          />
          <SimilarInfluencer
            influencerList={socialData[platformActive].similarList}
            brandSaved={brandSaved}
            addSavedInflu={addSavedInflu}
            removeSavedInflu={removeSavedInflu}
            triggerSaveInfluencer={triggerSaveInfluencer}
            loading={loading.similarList}
          />
        </ContentContainer>
      </Container>
      <AcceptInfluFooterComponent setAcceptModalActive={setAcceptModalActive} />
    </>
  );
};

export default observer(InfluencerPortfolio);

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 16px;
  padding-bottom: ${props => (props.haveFooter ? 80 : 0)}px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px;
`;
