import axios, { AxiosError, AxiosRequestConfig } from 'axios';

const env = process.env.REACT_APP_ENVIRONMENT;
const API_URL =
  env === 'staging'
    ? process.env.REACT_APP_STAGING_API_URL
    : env === 'production'
      ? process.env.REACT_APP_PRODUCTION_API_URL
      : process.env.REACT_APP_LOCAL_API_URL;

const getAuthToken = () => {
  const authToken = localStorage.getItem('authToken');
  return authToken;
};

export const GET = async (
  url: string,
  onCatch?: (error: AxiosError<any>) => void,
  authToken?: string
) => {
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + (authToken || (await getAuthToken()))
      }
    };

    const result = await axios.get(`${API_URL}${url}`, config);
    return result.data;
  } catch (error: any) {
    if (onCatch) onCatch(error);
    throw error;
  }
};

export const _GET = async <T>(url: string, authToken?: string) => {
  try {
    let config = {
      headers: {
        Authorization:
          'Bearer ' + (authToken || localStorage.getItem('authToken'))
      }
    };
    const result = await axios.get<T>(`${API_URL}${url}`, config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const POST = async (
  url: string,
  body: {},
  cfg: AxiosRequestConfig['headers'] | null = null,
  progressCallBack: (progressEvent: any) => void = () => {},
  onError = (error: AxiosError<any>): void => {
    throw new Error(error?.response?.data?.message);
  },
  cancelToken: AbortSignal | null = null,
  authToken?: string
) => {
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + (await getAuthToken()),
        ...cfg
      },
      // signal: cancelToken,
      // // cancelToken,
      // headers: {
      //   Authorization: 'Bearer ' + (await getAuthToken()),
      //   ...cfg,
      // },
      onUploadProgress: progressCallBack,
      signal: cancelToken
    } as AxiosRequestConfig;

    const result = await axios.post(`${API_URL}${url}`, body, config);
    return result.data;
  } catch (error: any) {
    if (onError) onError(error);
    else throw new Error(error?.response?.data?.message);
  }
};

export const PATCH = async (
  url: string,
  body: {},
  cfg: AxiosRequestConfig['headers'] | null = null,
  progressCallBack: (progressEvent: any) => void = () => {},
  onError = (error: AxiosError<any>): void => {
    throw new Error(error?.response?.data.message);
  },
  authToken?: string
) => {
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + (authToken || getAuthToken()),
        ...cfg
      },
      onUploadProgress: progressCallBack
    } as AxiosRequestConfig;

    const result = await axios.patch(`${API_URL}${url}`, body, config);
    return result.data;
  } catch (error: any) {
    onError(error);
  }
};

export const uploadImageAsync = async (
  uri: string,
  size?: { width: number; height: number },
  onProgress?: (progress: any) => void,
  abortController?: AbortController
) => {
  const sizeString = size ? `?width=${size.width}&height=${size.height}` : '';
  if (uri.startsWith('https')) {
    return { imageUrl: uri };
  }
  let uriParts = uri.split('.');
  let fileType = uriParts[uriParts.length - 1];

  let formData = new FormData();
  uri = JSON.stringify(uri);
  formData.append(
    'photo',
    JSON.stringify({
      uri,
      name: `photo.${fileType}`,
      type: `image/${fileType}`
    })
  );

  const controller = abortController || new AbortController();

  const config: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };

  return await POST(`/upload${sizeString}`, formData, config, progress => {
    if (onProgress) onProgress(progress);
  });
};

export const uploadVideoAsync = async (
  uri: File,
  onProgress?: (progress: any) => void,
  abortController?: AbortController
) => {
  let formData = new FormData();
  formData.append('video', uri);

  const controller = abortController || new AbortController();

  const config: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };

  return await POST(
    '/upload/video',
    formData,
    config,
    (progress: any) => {
      if (onProgress) onProgress({ ...progress });
    },
    undefined,
    controller.signal
  );
};

export const uploadVideoURLAsync = async (
  uri: string,
  onProgress?: (progress: any) => void,
  abortController?: AbortController
) => {
  let uriParts = uri.split('.');
  let fileType = uriParts[uriParts.length - 1];

  let formData = new FormData();
  formData.append(
    'video',
    JSON.stringify({
      uri,
      name: `video.${fileType}`,
      type: `video/${fileType}`
    })
  );

  const controller = abortController || new AbortController();

  const config: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };

  return await POST(
    '/upload/video',
    formData,
    config,
    (progress: any) => {
      if (onProgress) onProgress({ ...progress });
    },
    undefined,
    controller.signal
  );
};

export const uploadFileAsync = async (
  uri: File,
  size?: { width: number; height: number },
  onProgress?: (progress: any) => void,
  abortController?: AbortController
) => {
  const sizeString = size ? `?width=${size.width}&height=${size.height}` : '';

  let formData = new FormData();
  formData.append('photo', uri);

  const controller = abortController || new AbortController();

  const config: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };

  return await POST(`/upload${sizeString}`, formData, config, progress => {
    if (onProgress) onProgress(progress);
  });
};

export const uploadDocumentAsync = async (
  uri: File,
  name: string,
  onProgress?: (progress: any) => void
) => {
  let formData = new FormData();
  formData.append('file', uri);
  formData.append('name', name);

  const config: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  };

  return await POST(`/upload/file`, formData, config, progress => {
    if (onProgress) onProgress(progress);
  });
};

export const DELETE = async (
  url: string,
  cfg?: AxiosRequestConfig['headers'] | null,
  progressCallBack: (progressEvent: any) => void = () => {},
  onError = (error: AxiosError<any>): void => {
    throw new Error(error.response?.data.message);
  }
) => {
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + (await getAuthToken()),
        ...cfg
      },
      onUploadProgress: progressCallBack
    } as AxiosRequestConfig;
    const result = await axios.delete(`${API_URL}${url}`, config);
    return result.data;
  } catch (error: any) {
    onError(error);
  }
};

export const getErrorMessage = (error: AxiosError | any) => {
  if (error?.response?.data?.message) return error?.response?.data?.message;
  return error?.response?.data;
};

const config: AxiosRequestConfig['headers'] = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data'
};
