import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import MobileFooter from './components/atoms/MobileFooter';
import MaintenanceModal from './components/molecules/Network/MaintenanceModal';
import OfflineModal from './components/molecules/Network/OfflineModal';
import MainLayout from './components/organisms/Layout/MainLayout';
import CampaignManagement from './pages/CampaignManagement/CampaignManagement';
import InfluencerManagement from './pages/CampaignManagement/InfluencerManagement';
import CompanyInfo from './pages/CompanyInformation';
import DirectoryPage from './pages/DirectoryPage';
import DiscoverPage from './pages/DiscoverPage';
import FavoritePage from './pages/FavoritePage';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPassword';
import LoginPage from './pages/Login';
import MainCampaign from './pages/MainCampaign';
import NewCreateCampaignPage from './pages/NewCreateCampaign/NewCreateCampaignPage';
import PerformancePage from './pages/PerformancePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import RegisterPage from './pages/Register/Register';
import Setting from './pages/Settings/Setting';
import {
  useAccountStore,
  useDealStore,
  useNetworkStore
} from './stores/initialize/Context';
import { getDevice } from './utils/Device';
import { featureAccessStore } from './stores/featureAccessStore';

function Navigator() {
  const { account, getMyAccount } = useAccountStore();
  const { isMaintenance, isOffline } = useNetworkStore();
  const { listDeals } = useDealStore();

  const isAndroid = getDevice() === 'android';

  const renderModal = () => {
    if (isOffline) {
      return <OfflineModal active={isOffline} />;
    }
    if (isMaintenance) {
      return <MaintenanceModal active={isMaintenance} />;
    }
  };

  useEffect(() => {
    if (!account.brandId) {
      getMyAccount();
    }
    // get deals to update deal status count at campaign drawer
    listDeals({
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      status: 'ongoing'
    });
    listDeals({
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      status: 'pending'
    });
    listDeals({
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      status: 'done'
    });
  }, []);

  return (
    <BrowserRouter>
      <LocationWatcher />
      <AppContainer isMobile={getDevice() !== 'pc'}>
        {renderModal()}
        <MainLayout>
          <Routes>
            {/* main pickle web */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="/createprofile" element={<CompanyInfo />} />
            <Route path="/campaign" element={<MainCampaign />} />
            <Route path="/createcampaign" element={<NewCreateCampaignPage />} />
            <Route path="/deal" element={<CampaignManagement />} />
            <Route path="/influencer" element={<InfluencerManagement />} />
            <Route path="/performance" element={<PerformancePage />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/discover" element={<DiscoverPage />} />
            <Route path="/favorite" element={<FavoritePage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/directory" element={<DirectoryPage />} />
            <Route path="*" element={<MainCampaign />} />
          </Routes>
        </MainLayout>
      </AppContainer>
      {getDevice() !== 'pc' && <MobileFooter isAndroid={isAndroid} />}
    </BrowserRouter>
  );
}

function LocationWatcher() {
  const location = useLocation();

  useEffect(() => {
    // TODO: we can optimize triggering get featureAccess by specific page
    featureAccessStore.getFeatureAccess();
  }, [location]);

  return null;
}

export default observer(Navigator);

const AppContainer = styled.div<{ isMobile: boolean }>`
  overflow: ${props => (props.isMobile ? 'scroll' : 'visible')};
`;
