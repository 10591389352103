import { useEffect, useState } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { scopeOfWorkType } from '../../../class/ScopeOfWork';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { DealPerformanceTableData, PlatformType } from '../../../types/deal';
import { Work } from '../../../types/work';
import {
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import InfluencerPerformanceData from './InfluencerPerformanceData';
import InfluencerPerformanceTab from './InfluencerPerformanceTab';
import { tierType } from '../../../types/createDeal';
import ExplanationTooltip from './ExplanationTooltip';

interface TogglerComponentProps {
  active: boolean;
  icon: IconName;
  title: string;
  onClick: () => void;
}

const TogglerComponent = (props: TogglerComponentProps) => {
  const { icon, active, title, onClick } = props;
  return (
    <TogglerContainer active={active} onClick={onClick}>
      <RXIcon
        name={icon}
        color={active ? COLOR.Black : COLOR.Gray_400}
        size={20}
      />
      <Bu2Text active={active}>{title}</Bu2Text>
    </TogglerContainer>
  );
};

interface Props {
  campaignWorks: Work[];
  dealPostType: scopeOfWorkType[];
  dealTierType: tierType[];
  tableData: DealPerformanceTableData[];
  tierTypes: Record<tierType, number>;
  postTypes: Record<scopeOfWorkType, number>;
  platform: PlatformType;
}

const InfluencerPerformance = (props: Props) => {
  const {
    campaignWorks,
    dealPostType,
    dealTierType,
    tableData,
    tierTypes,
    postTypes,
    platform
  } = props;

  const [displayState, setDisplayState] = useState<'grid' | 'table'>('grid');
  const [acitvePostType, setActivePostType] = useState<string>();

  const tabTypes = displayState === 'grid' ? postTypes : tierTypes;
  const dealTabTypes = displayState === 'grid' ? dealPostType : dealTierType;

  const filteredCampaignWorks = campaignWorks.filter(
    work => work.postType === acitvePostType || !acitvePostType
  );

  const filteredTableData = [...tableData].filter(
    table => table.tier === acitvePostType || !acitvePostType
  );

  useEffect(() => {
    setActivePostType(undefined);
  }, [displayState]);

  return (
    <Container>
      <FullWidthSpaceRow>
        <BoldH6Text>Influencer Performance</BoldH6Text>
        <RowContainer style={{ gap: 16 }}>
          <ToggleContainer>
            <TogglerComponent
              title="Post"
              icon="Grid"
              active={displayState === 'grid'}
              onClick={() => setDisplayState('grid')}
            />
            <TogglerComponent
              title="Influencer"
              icon="List"
              active={displayState === 'table'}
              onClick={() => {
                setDisplayState('table');
              }}
            />
          </ToggleContainer>
          <ExplanationTooltip />
        </RowContainer>
      </FullWidthSpaceRow>
      <InfluencerPerformanceTab
        tabs={tabTypes}
        activeTab={acitvePostType}
        setActiveTab={setActivePostType}
        dealPostType={dealTabTypes}
      />
      <InfluencerPerformanceData
        displayState={displayState}
        campaignWorks={filteredCampaignWorks}
        tableData={filteredTableData}
        platform={platform}
      />
    </Container>
  );
};

export default InfluencerPerformance;

const Container = styled(FlexStartColumnContainer)`
  margin-top: 24px;
  width: 100%;
  gap: 24px;
`;

const FullWidthSpaceRow = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const ToggleContainer = styled.div`
  display: flex;
  padding: 6px;
  align-items: flex-start;
  border-radius: 8px;
  border: 0.6px solid ${COLOR.Gray_300};
  :hover {
    cursor: pointer;
  }
`;

const TogglerContainer = styled(RowContainer)<ActiveProps>`
  padding: 4px 12px;
  gap: 4px;
  height: 32px;
  border-radius: 4px;
  background-color: ${props => (props.active ? COLOR.Gray_100 : COLOR.White)};
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const Bu2Text = styled(TEXT.Bu2_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Black : COLOR.Gray_400)};
`;
