import { TextareaAutosize } from '@mui/material';
import { styled as mstyled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import {
  FontAktivGroteskRegular,
  FontAktivGroteskThaiRegular
} from '../../../fonts/font';
import { TEXT } from '../../../fonts/text';
import ChatImageSelector from './ChatImageSelector';

function Keyboard(props: any) {
  const { composerHeight, setComposerHeight } = props;
  const HotkeyArray = ['สวัสดีค่ะ', 'ขอบคุณค่ะ', 'ส่งของแล้วค่ะ'];
  const Ref = useRef<null | HTMLDivElement>(null);
  const [sendActive, setSendActive] = useState<boolean>(false);
  const onContentSizeChange = (e: any) => {
    const { contentSize } = e.nativeEvent;

    // Support earlier versions of React Native on Android.
    if (!contentSize) return;

    if (
      !contentSize ||
      contentSize.width !== contentSize.width ||
      contentSize.height !== contentSize.height
    ) {
      props.onInputSizeChanged(contentSize);
    }
  };

  const onChangeText = (text: string) => {
    if (text.trim() !== '') {
      setSendActive(true);
    } else {
      setSendActive(false);
    }
    props.onTextChanged(text);
  };

  const { text, onSend } = props;

  const onPaste = (e: any) => {};

  const onKeyDown = (e: any) => {
    if (!e.shiftKey && e.key === 'Enter') {
      if (text.trim() !== '') {
        onSend({ text: text.trim() }, true);
      }
      e.preventDefault();
    }
    if (Ref.current) {
      if (e.shiftKey && e.key === 'Enter') {
        if (composerHeight >= 300) {
          setComposerHeight(Ref.current.clientHeight);
        } else {
          setComposerHeight(Ref.current.clientHeight + 20);
        }
      } else if (composerHeight !== Ref.current.clientHeight) {
        setComposerHeight(Ref.current.clientHeight);
      }
    }
    return false;
  };

  return (
    <Container ref={Ref}>
      <ColumnContainer>
        <RowContainer>
          {HotkeyArray.map(text => (
            <HotkeyText onClick={() => onSend({ text }, true)}>
              {text}
            </HotkeyText>
          ))}
          {/* <HotkeyText>+</HotkeyText> */}
        </RowContainer>
        <RowContainer style={{ paddingTop: '24px' }}>
          <ChatImageSelector
            onSendImage={props.onSendImage}
            onSendVideo={props.onSendVideo}
            dealContactId={props.dealContactId}
            user={props.user}
          />
          <TextAreaStyle
            testID={props.placeholder}
            placeholder={props.placeholder}
            placeholderTextColor={COLOR.Gray_300}
            multiline={true}
            maxRows={10}
            onChange={event => onChangeText(event.target.value)}
            value={props.text}
            enablesReturnKeyAutomatically
            keyboardAppearance={props.keyboardAppearance}
            {...props.textInputProps}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
          />
          <Button
            onClick={() => {
              setSendActive(false);
              onSend({ text: text.trim() }, true);
            }}
            disabled={!sendActive}
          >
            <FiSend
              color={sendActive ? COLOR.Blue_700 : COLOR.Gray_300}
              size={24}
            />
          </Button>
        </RowContainer>
      </ColumnContainer>
    </Container>
  );
}

export default Keyboard;

const Container = styled.div`
  width: 100%;
  bottom: 0;
  padding: 0px 20px 20px 20px;
  margin-top: -50px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextAreaStyle = mstyled(TextareaAutosize)`
  background-color: ${COLOR.Gray_50};
  padding: 15px 24px;
  
  font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
  font-size: 16px;
  width: 568px;
  height: 48px;
  border-width: 0px;
  max-height: 240px;
  overflow-y: auto;
  border-radius: 24px;
  resize: none;
  ::placeholder {
    color: ${COLOR.Gray_300}
  }

  &:focus {
    border: 1px solid;
    outline: none;
  }
  
  &::-webkit-scrollbar {
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_300};
    border-radius: 18px;
    border: 8px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.Gray_M};
    border-radius: 18px;
    border: 8px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 8px;
    margin-bottom: 8px;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 50%;
  margin-left: 8px;
  width: 48px;
  height: 48px;
  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const HotkeyText = styled(TEXT.body2)`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  gap: 10px;
  background: ${COLOR.Gray_200};
  color: ${COLOR.Gray_M};
  border-radius: 25px;
  margin: 8px;
`;
