import styled from 'styled-components';
import { TEXT } from '../../../fonts/text';
import RButton from '../../atoms/RButton';

interface Props {
  onDone: () => void;
  email: string;
}

function RegisterCodeSentDetail(props: Props) {
  const { onDone, email } = props;
  return (
    <Container>
      <LargeText>โปรดยืนยันบัญชี</LargeText>
      <SmallText>
        โปรดยืนยันบัญชีของคุณที่ <Bold>{email}</Bold> ในกล่อง Inbox หรือเช็คที่
        Junk Mail โดยอีเมลจะใช้เวลา ในการส่งประมาณ 3-5 นาที
      </SmallText>
      <ButtonContainer>
        {/* TODO : Re Hover */}
        <RButton title="ยืนยันแล้ว" onPress={onDone} active={true} />
      </ButtonContainer>
    </Container>
  );
}

export default RegisterCodeSentDetail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LargeText = styled(TEXT.H4_Bold)``;

const SmallText = styled(TEXT.B2_Reg)`
  margin-top: 15px;
`;

const Bold = styled(TEXT.B2_Bold)``;

const ButtonContainer = styled.div`
  margin-top: 35px;
  align-self: flex-end;
`;
