import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { FontFCIconicRegular } from '../../../fonts/font';
import { TEXT } from '../../../fonts/text';
import { ChatMessage } from '../../../types/chatMessage';
import CustomMarkdown from './CustomMarkdown';

const Caption1Text = styled(TEXT.caption1)`
  color: ${COLOR.Gray_M};
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
`;

const SystemMessage = ({
  currentMessage,
  nextMessage
}: {
  currentMessage: ChatMessage;
  nextMessage: ChatMessage;
}) => {
  const { text, mdText } = currentMessage;
  const isLastMessage = nextMessage
    ? Object.keys(nextMessage).length === 0
    : false;

  return (
    <div
      style={{
        display: 'flex',
        // height: "40px",
        marginTop: 5,
        marginBottom: isLastMessage ? '50px' : '10px',
        padding: '2px 10px 2px 10px',
        backgroundColor: COLOR.WHITE,
        borderRadius: 18,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
        alignSelf: 'center',
        alignItems: 'center'
        // justifyContent: "center",
      }}
    >
      {/* <Caption1Text> */}
      <CustomMarkdown
        message={mdText || text || ''}
        userType={'system'}
        // components={components}
      />
      {/* </Caption1Text> */}
    </div>
  );
};

export default SystemMessage;
