import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';

function TermOfUse() {
  return (
    <Container>
      <HeaderContainer>
        <RXIcon name="Terms" size={32} />
        <BoldH5Text style={{ marginLeft: '8px' }}>
          ข้อกำหนดในการใช้งาน
        </BoldH5Text>
      </HeaderContainer>
      <TextContainer>
        <B3Text>
          Pickle (“แพลตฟอร์ม”) เป็นแพลตฟอร์มที่พัฒนาโดยบริษัท แรบบิท ดิจิทัล
          กรุ๊ป จำกัด (“บริษัท”) เพื่อใช้เป็นสื่อกลางในการให้บริการแก่
          Influencer (“Influencer”) และ Marketer (“Marketer”)
          โดยมีจุดมุ่งหมายเพื่อตอบโจทย์ความต้องการของ Influencer และ Marketer
          (รวมเรียกว่า “ผู้ใช้บริการ”)
          เพื่อให้ทั้งสองฝ่ายสามารถทำงานร่วมกันได้อย่างมีประสิทธิภาพภายใต้การใช้แพลตฟอร์มเดียวกัน
          <br />
          <br />
          กรุณาอ่านรายละเอียดในเอกสารฉบับนี้โดยละเอียดและครบถ้วน
          เพื่อรักษาไว้ซึ่งสิทธิประโยชน์ของผู้ใช้บริการเอง
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>1. คำนิยาม</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          <li>
            “บริษัท” หมายถึง บริษัท แรบบิท ดิจิทัล กรุ๊ป จำกัด จัดตั้งอยู่ที่
            1706/34 ถนนพระรามที่ 4 แขวงรองเมือง เขตปทุมวัน กรุงเทพฯ 10330
          </li>
          <li>
            “แพลตฟอร์ม” หมายถึง
            ระบบหรือช่องทางการให้บริการใดๆของทางบริษัทที่ใช้ในการเชื่อมต่อกับผู้ใช้บริการ
            ทั้งทางออนไลน์และออฟไลน์
            เพื่อให้ผู้ใช้บริการสามารถเข้าถึงการให้บริการของทางบริษัทได้ ทั้งนี้
            อาจรวมไปถึง เนื้อหา หรือบริการอื่นใดๆ
            ที่นำเสนอผ่านแพลตฟอร์มของบริษัท
            ซึ่งในที่นี้แพลตฟอร์มของบริษัทจะใช้ชื่อว่า Pickle
          </li>
          <li>
            “Marketer” หมายถึง บุคคลธรรมดา หรือนิติบุคคล
            ซึ่งเป็นเจ้าของสินค้าหรือบริการ ที่ต้องการขอรับบริการจากแพลตฟอร์ม
            เพื่อส่งเสริมหรือประชาสัมพันธ์สินค้าหรือบริการของตนเอง
            ซึ่งเป็นผู้ที่ได้ลงทะเบียนเข้าใช้บริการแพลตฟอร์มตามเงื่อนไขที่ทางบริษัทได้กําหนดไว้แล้ว
          </li>
          <li>
            “Contact person” หมายถึง บุคคลที่ Marketer
            มอบหมายให้เป็นผู้ดูแลหรือเป็นเจ้าของ Account ของ Marketer
            บนแพลตฟอร์ม ซึ่งจะมีได้แค่บุคคลเดียว
            และบุคคลดังกล่าวนั้นจะต้องเป็นบุคคลที่บริษัทหรือ Influencer
            สามารถติดต่อได้ หากมีข้อสงสัยใดๆเกี่ยวกับสินค้าหรือบริการของ
            Marketer
          </li>
          <li>
            “ผู้ใช้บริการ” หมายถึง “Influencer” และ “Marketer” บนแพลตฟอร์ม
            ที่ได้ลงทะเบียนและยอมรับในข้อกำหนดต่างๆที่ทางบริษัทได้ระบุไว้
          </li>
          <li>
            “Account” หมายถึง บัญชีของ Marketer แต่ละรายบนแพลตฟอร์ม
            เพื่อเข้าถึงการใช้บริการบนแพลตฟอร์มอย่างเต็มรูปแบบ
          </li>
          <li>
            “Username” หมายถึง ชื่อที่ Marketer เป็นผู้กำหนดขึ้นเอง
            เพื่อใช้ในการลงทะเบียนหรือล็อคอินเข้าสู่ Account
            บนแพลตฟอร์มเมื่อต้องการขอรับบริการจากทางแพลตฟอร์มของบริษัท ทั้งนี้
            Marketer จะต้องรักษา Username ดังกล่าวให้เป็นความลับ
            เพื่อความปลอดภัยในการใช้งาน
          </li>
          <li>
            “Password” หมายถึง ตัวอักษรหรือตัวเลขที่ทาง Marketer
            เป็นผู้กำหนดขึ้นเอง (โดยทางบริษัทจะเป็นผู้กำหนดจำนวนอักษรหรือตัวเลข)
            เพื่อใช้เป็นรหัสในการเข้าสู่ Account บนแพลตฟอร์ม ทั้งนี้
            เพื่อใช้ป้องกันการเข้าถึงข้อมูลของ Marketer จากบุคคลภายนอก และ
            Marketer จะต้องรักษา Password ดังกล่าวให้เป็นความลับ
            เพื่อความปลอดภัยในการใช้งาน
          </li>
          <li>
            “นโยบายความเป็นส่วนตัว” หมายถึงเอกสารที่ออกโดยบริษัท
            เพื่อใช้ในการปกป้องและคุ้มครองความเป็นส่วนตัวของผู้ใช้บริการ
            รวมถึงข้อมูลส่วนบุคคลของผู้ใช้บริการอีกด้วย ทั้งนี้
            ผู้ใช้บริการควรศึกษาและทำความเข้าใจข้อมูลดังกล่าวก่อนการเริ่มใช้งานบนแพลตฟอร์ม
          </li>
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>2. การยอมรับข้อตกลงการเข้าใช้งาน</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          ผู้ใช้บริการควรศึกษาและทำความเข้าใจข้อกำหนดในการใช้งานฉบับนี้ และ
          <br />
          นโยบายความเป็นส่วนตัว
          รวมถึงเอกสารใดๆที่ออกโดยบริษัทเพื่อใช้ประกอบในการอ้างอิงโดยละเอียด
          ก่อนการลงทะเบียนเข้าใช้งาน
          ทันทีที่ผู้ใช้บริการกดตกลงและยอมรับข้อกำหนดและเงื่อนไขการใช้บริการแพลตฟอร์มดังกล่าว
          ทางบริษัทจะถือว่าผู้ใช้บริการได้ยืนยัน
          และตกลงที่จะผูกพันตามเงื่อนไขดังกล่าว
          และอนุญาตให้บริษัทสามารถเข้าถึงข้อมูลความเป็นส่วนตัวของผู้ใช้บริการได้
          ตามนโยบายความเป็นส่วนตัวของทางบริษัท
          <br />
          <br />
          นอกจากนี้ การยอมรับข้อตกลงการเข้าใช้งานยังหมายรวมถึง
          การที่ผู้ใช้บริการยินยอมให้บริษัทสามารถเปลี่ยนแปลง
          หรือแก้ไขเนื้อหาใดๆได้ ภายใต้ขอบเขตของกฎหมาย
          โดยทางบริษัทจะแจ้งให้ทางผู้ใช้บริการทราบและยอมรับล่วงหน้าทุกครั้งก่อนการดำเนินการ
          ทั้งนี้ ทันทีที่ทางบริษัทประกาศเอกสารฉบับดังกล่าวลงบนแพลตฟอร์มแล้ว
          เอกสารนั้นจะสามารถมีผลบังคับใช้ได้ทันที 
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>3. การลงทะเบียน</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          3.1 กรณีผู้ใช้บริการเป็น Influencer
          <br />
          <br />
          ในการเริ่มใช้บริการแพลตฟอร์ม Influencer
          จำเป็นต้องเชื่อมต่อผ่านช่องทางบัญชีสื่อสังคมออนไลน์ (Social Media
          Account) ที่เป็นของ Influencer รายนั้นๆเอง
          และกรอกข้อมูลเพิ่มเติมเพื่อยืนยันตัวตนตามที่ทางแพลตฟอร์มร้องขอ
          โดยข้อมูลที่นำเข้าสู่แพลตฟอร์มจะต้องเป็นข้อมูลที่ถูกต้องและเป็นปัจจุบัน
          หากทางบริษัทตรวจพบว่า
          ข้อมูลที่ได้ลงทะเบียนไว้เป็นเท็จหรือไม่เป็นปัจจุบัน
          ทางบริษัทขอสงวนสิทธิ์ในการระงับการให้บริการแก่ Influencer
          ภายในระยะเวลาอันสมควร โดยไม่จําเป็นต้องแจ้งให้ Influencer ทราบล่วงหน้า
          นอกจากนี้ หลังจากที่ Influencer
          ได้กรอกข้อมูลส่วนบุคคลใดๆไว้กับทางแพลตฟอร์มแล้ว
          ข้อมูลดังกล่าวอาจถูกนําไปใช้ตามวัตถุประสงค์ของทางบริษัท
          ตามที่ได้ชี้แจงไว้ในนโยบายความเป็นส่วนตัวของบริษัท
          <br />
          <br />
          3.2 กรณีผู้ใช้บริการเป็น Marketer
          <br />
          <br />
          ในการเริ่มใช้บริการแพลตฟอร์ม Marketer จำเป็นจะต้องมี Account
          บนแพลตฟอร์ม โดย Marketer
          จะต้องกรอกข้อมูลเกี่ยวกับที่อยู่และสินค้าหรือบริการของตนเอง
          รวมถึงรายละเอียดของ Contact person (ซึ่งสามารถเปลี่ยนแปลงได้
          หากมีการเปลี่ยนผู้รับผิดชอบหรือผู้ดูแล Account )
          ตามที่ทางแพลตฟอร์มร้องขอ พร้อมทั้งสร้าง Username และ Password
          สำหรับการลงทะเบียนเข้าสู่แพลตฟอร์ม
          โดยข้อมูลที่นำเข้าสู่แพลตฟอร์มจะต้องเป็นข้อมูลที่ถูกต้องและเป็นปัจจุบัน
          ซึ่ง Username และ Password ดังกล่าว สามารถใช้ร่วมกันภายในองค์กรของ
          Marketer ได้ แต่รายละเอียดของ Contact person ที่ผูกไว้กับทางแพลตฟอร์ม
          จะมีได้แค่บุคคลเดียว ทั้งนี้ Marketer
          จะเป็นผู้รับผิดชอบในข้อมูลที่นำเข้าแพลตฟอร์มทั้งหมดด้วยตนเอง
          <br />
          <br />
          3.3 ความปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้บริการ
          <br />
          <br />
          ทางบริษัทจะไม่เปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลใดๆของผู้ใช้บริการให้แก่บุคคลภายนอกได้รับรู้
          เว้นแต่ในกรณีดังต่อไปนี้
          <br />
          <br />
          <li>
            เพื่อใช้ในการโฆษณาหรือประชาสัมพันธ์เกี่ยวกับบริษัทและ/หรือ
            ผู้ใช้บริการ เพื่อให้เป็นที่รู้จักต่อสาธารณะชนหรือบุคคลภายนอก 
          </li>
          <li>
            บริษัทในเครือหรือคู่ค้าทางธุรกิจที่ทางบริษัทได้ทำข้อตกลงร่วมมือกันไว้
            ในการพัฒนาและปรับปรุงคุณภาพของแพลตฟอร์ม
            เพื่อให้ผู้ใช้บริการสามารถเข้าถึงการใช้งานที่มีประสิทธิภาพสูงสุด
          </li>
          <li>
            เมื่อบริษัทเห็นว่ามีความจำเป็นในการคุ้มครองสิทธิ
            และความปลอดภัยของผู้ใช้บริการและของทางบริษัท 
          </li>
          <li>
            เพื่อปฏิบัติตามกฏหมาย คำสั่งจากองค์กรของรัฐ
            หรือกระบวนการทางกฏหมายรวมถึงเพื่อดำเนินการตามการร้องขอของเจ้าหน้าที่ตามกฏหมายหรือทางราชการ
          </li>
          <br />
          เพื่อให้เป็นไปตามข้อกำหนดในการใช้งาน และ
          นโยบายความเป็นส่วนตัวของบริษัท 
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>4. การจัดการเนื้อหาของผู้ใช้บริการ</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          <li>
            ผู้ใช้บริการรับรองว่า
            ผู้ใช้บริการมีอิสระในการสร้างสรรค์ผลงานของตนเอง 
            และจะเป็นผู้รับผิดชอบต่อเนื้อหาของตนทั้งหมดให้มีความถูกต้อง เหมาะสม
            และเป็นปัจจุบันอยู่เสมอ
            รวมถึงจะต้องรับผิดตามกฏหมายหากข้อมูลหรือเนื้อหาของผู้ใช้บริการนั้นกระทบสิทธิของบุคคลภายนอก
          </li>
          <li>
            เมื่อผู้ใช้บริการได้นำเนื้อหาหรือข้อมูลใดๆเข้าสู่แพลตฟอร์มของทางบริษัทแล้ว
            จะถือว่าไม่ใช่ข้อมูลอันเป็นความลับและถือว่าผู้ใช้บริการอนุญาตให้ทางบริษัทสามารถเข้าถึงข้อมูลดังกล่าวได้เพื่อใช้ในการทำการตลาดของทางบริษัทเอง
            ทั้งนี้หากทางผู้ใช้บริการไม่ประสงค์ให้เผยแพร่ข้อมูลส่วนบุคคลใดๆ
            สามารถแจ้งให้ทางบริษัทลบหรือยุติการเผยแพร่ข้อมูลนั้นๆได้ทุกเมื่อ
          </li>
          <li>
            หลังจาก Influencer และ Marketer ตกลงร่วมงานกันแล้ว
            ทางบริษัทจะถือว่าเป็นเรื่องของ Influencer และ Marketer
            ที่ต้องไปตกลงกันเอง
            โดยทั้งสองฝ่ายจะต้องประสานงานกันและแจ้งให้อีกฝ่ายหนึ่งทราบถึงการเปลี่ยนแปลงใดๆ
            ที่มีต่อเนื้อหาหรือข้อมูลบนแพลตฟอร์ม
            ทั้งนี้ทางบริษัทจะไม่รับผิดชอบต่อปัญหาใดๆอันเกิดจากความขัดแย้ง
            หรือผลประโยชน์ ระหว่าง Influencer และ Marketer
            ซึ่งอาจนำไปสู่การเรียกร้องหรือการดำเนินคดีทางกฎหมาย{' '}
          </li>
          <li>
            นอกจากนี้ ผู้ใช้บริการจะต้องใช้ความระมัดระวังที่จะไม่นําไวรัส เวิร์ม
            ลอจิกบอมบ์หรือเครื่องมืออื่นๆเข้าสู่แพลตฟอร์มอันจะทำให้เกิดความเสียหายหรือเป็นอันตรายต่อแพลตฟอร์มหรือผู้ใช้งานรายอื่นๆได้
            นอกจากนี้ผู้ใช้บริการจะต้องรับผิดชอบหากเกิดความเสียหายดังกล่าวขึ้น
          </li>
          <br />
          บริษัทขออนุญาติส่งคำเตือนให้กับผู้ใช้บริการที่ไม่ปฏิบัติตามข้อกำหนดในการจัดการเนื้อหาของผู้ใช้บริการ
          ยกเลิกสิทธิ์พิเศษ หรือจำกัดการเข้าบัญชีผู้ใช้ของผู้ใช้บริการ
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>5. ข้อห้ามในการใช้งาน</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          เนื้อหาของผู้ใช้บริการบนแพลตฟอร์ม
          จะต้องไม่มีเนื้อหาอันเกี่ยวข้องกับกรณีดังต่อไปนี้
          <br />
          <br />
          <li>
            มีลักษณะลามก อนาจาร มีความรุนแรง รวมถึงหลอกลวง โจมตีใส่ร้าย ปลุกปั่น
            คุกคามหรือมีเนื้อหาละเมิดสิทธิของบุคคลอื่นหรือยุยงให้เกิดความเกลียดชัง
            (Hate Speech) 
          </li>
          <li>
            มีลักษณะเป็นการส่งเสริมความแตกแยกทางเชื้อชาติ เพศ ศาสนา สัญชาติ
            ความพิการ หรืออายุ 
          </li>
          <li>
            มีลักษณะเป็นการลอกเลียนแบบ หรือนำเนื้อหาของบุคคลอื่นมาใช้
            โดยไม่ได้กล่าวอ้างถึงหรือให้เครดิตแก่บุคคลดังกล่าว
          </li>
          <li>มีลักษณะเป็นการโฆษณาเพื่อแสวงหาผลกำไรให้แก่ตนเอง</li>
          <li>
            มีลักษณะเป็นการละเมิดต่อบทบัญญัติของกฎหมายหรือฝ่าฝืนศีลธรรม
            อันดีของประชาชน
          </li>
          <li>
            มีลักษณะเป็นการขัดต่อข้อกำหนดการใช้งานนี้
            และนโยบายความเป็นส่วนตัวของบริษัท
          </li>
          <br />
          บริษัทขออนุญาติส่งคำเตือนให้กับผู้ใช้บริการที่ไม่ปฏิบัติตามข้อห้ามในการใช้งาน
          ยกเลิกสิทธิ์พิเศษ หรือจำกัดการเข้าบัญชีผู้ใช้ของผู้ใช้บริการ
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>6. เกณฑ์การประเมินผู้ใช้บริการ</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          ุ6.1 สำหรับ Influencer
          <br />
          <br />
          ทางบริษัท เป็นผู้กําหนดกฎเกณฑ์และวิธีการประเมิน Influencer โดยที่
          Marketer จะเป็นผู้ประเมินตัว Influencer เอง
          โดยการคิดคํานวณจากเนื้อหาและความถูกต้องของงาน
          ตามที่ได้มีการตกลงกันไว้ก่อนหน้านี้
          และคะแนนดังกล่าวจะส่งผลต่อการงานหรือแบรนด์ที่ Influencer
          จะได้รับในครั้งถัดไป และยังใช้ในการกําหนดเงื่อนไขค่าตอบแทนของ
          Influencer อีกด้วย ทั้งนี้
          ทางบริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงแก้ไขปรับปรุงหลักเกณฑ์และวิธีการให้คะแนนดังกล่าวแต่เพียงผู้เดียว
          โดยไม่ต้องบอกกล่าวล่วงหน้า
          <br />
          <br />
          6.2 สำหรับ Marketer
          <br />
          <br />
          ทุกครั้งหลังจากที่ Influencer ได้ส่งผลงานให้กับทางบริษัทและทาง
          Marketer เรียบร้อยแล้ว Marketer จะต้องประเมิน Influencer
          ตามเนื้อหาและข้อตกลงที่ได้ให้ไว้ต่อกัน โดยอิงตามเกณฑ์และวิธีการประเมิน
          Influencer ที่ทางบริษัทได้กำหนดเอาไว้
          ซึ่งผลการประเมินดังกล่าวจะมีผลต่องานหรือแบรนด์ที่ Influencer
          จะได้รับในครั้งถัดไป และยังใช้ในการกําหนดเงื่อนไขค่าตอบแทนของ
          Influencer อีกด้วย ทั้งนี้
          ทางบริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงแก้ไขปรับปรุงหลักเกณฑ์และวิธีการให้คะแนนดังกล่าวแต่เพียงผู้เดียว
          โดยไม่ต้องบอกกล่าวล่วงหน้า
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>7. ผลตอบแทนของ Influencer</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
                 บริษัทอาจตอบแทน Influencer
          ที่ได้สร้างสรรค์ผลงานตามข้อตกลงที่ให้ไว้กับทางบริษัทและทาง Marketer
          เป็นเงินสด เงินสนับสนุน หรือผลิตภัณฑ์ใดๆ ตามเกณฑ์การประเมินหรือตามที่
          Marketer กำหนดไว้ ที่ทางบริษัทได้กำหนดไว้  ทั้งนี้ หาก Influencer
          ทำผิดข้อตกลงการใช้งานหรือนโยบายใดๆของทางบริษัท
          บริษัทสามารถยกเลิกหรือเปลี่ยนแปลงการให้ผลตอบแทนได้
          ตามดุลยพินิจของทางบริษัท 
          <br />
          <br />
          ทางบริษัทจะจ่ายค่าตอบแทนตามที่ได้ตกลงไว้กับ Influencer
          โดยบริษัทจะดําเนินการหักภาษีใดๆไว้ตามที่กฎหมายกำหนด
          และออกเอกสารที่เกี่ยวข้องตามวิธีการที่ทางบริษัทเห็นสมควร ทั้งนี้
          Influencer จะต้องเป็นผู้รับผิดชอบต่อค่าธรรมเนียมของสถาบันการเงิน
          (ถ้ามี) หรือค่าใช้จ่ายใดๆทางกฎหมายทั้งหมด
          <br />
          <br />
          ทั้งนี้ หากผลตอบแทนของ Influencer เป็นผลตอบแทนที่มาจาก Marketer โดยตรง
          ซึ่งไม่ได้มีความเกี่ยวพันใดๆกับทางบริษัท
          ทางบริษัทจะไม่รับผิดชอบต่อผลตอบแทนดังกล่าวหากเกิดการเรียกร้องใดๆทางกฎหมาย
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>8. สิทธิในทรัพย์สินทางปัญญา </BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          บริษัทเป็นผู้มีสิทธิในทรัพย์สินทางปัญญาแต่เพียงผู้เดียว อันได้แก่
          ลิขสิทธิ์ในเนื้อหา ข้อมูล ซอฟต์แวร์ โปรแกรมคอมพิวเตอร์
          ความลับทางการค้า
          และหมายความรวมถึงสิทธิในทรัพย์สินทางปัญญาอื่นที่อาจเกิดขึ้นในอนาคต 
          <br />
          <br />
          ข้อกำหนดการใช้งานนี้อนุญาตให้
          ผู้ใช้บริการใช้แพลตฟอร์มของทางบริษัทเพื่อขอรับบริการและติดต่องานเท่านั้น
          โดยผู้ใช้บริการจะต้องไม่ทําซํ้า เผยแพร่ แจกจ่าย จัดเก็บ
          คัดลอกข้อมูลที่เป็นทรัพย์สินทางปัญญาใดๆของทางบริษัทโดยไม่ได้รับการยินยอมจากทางบริษัทเสียก่อน
          เว้นแต่จะได้รับอนุญาตเป็นลายลักษณ์อักษรจากบริษัท ทั้งนี้
          ผู้ใช้บริการสามารถเก็บสําเนาของข้อมูลที่เป็นทรัพย์สินทางปัญญาไว้บนหน่วยความจําชั่วคราว
          (Ram) และเท่าที่จําเป็นต่อการ ดําเนินการของผู้ใช้บริการเท่านั้น 
          <br />
          <br />
          นอกจากนี้ ผู้ใช้บริการจะต้องไม่นําข้อมูล ภาพ หรือทรัพย์สินทางปัญญาใดๆ
          ของผู้ใช้บริการแพลตฟอร์มท่านอื่น รวมถึงบุคคลภายนอกไปใช้
          โดยที่ไม่ได้อ้างถึงแหล่งที่มาหรือให้เครดิตแก่ผู้สร้างสรรค์ผลงาน
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>9. ความรับผิดชอบของผู้ใช้บริการ</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          9.1 ความรับผิดชอบของ Influencer
          <br />
          <br />
          <li>
            ข้อมูลหรือเนื้อหาใดๆ ที่ Influencer นำเข้าสู่แพลตฟอร์มนั้น
            Influencer
            เข้าใจและยืนยันถึงความเป็นเจ้าของในเนื้อหาและข้อมูลของตนเอง
            และจะเป็นผู้รับผิดชอบต่อการเรียกร้องใดๆในเนื้อหาของ Influencer
            แต่เพียงผู้เดียว ไม่เกี่ยวข้องกับทางบริษัท ทั้งทางกฎหมายแพ่งและอาญา 
          </li>
          <li>
            หาก Influencer
            ฝ่าฝืนข้อกำหนดการใช้งานหรือกฎหมายใดๆที่เกี่ยวข้องระหว่างการใช้บริการแพลตฟอร์ม
            ส่งผลให้บริษัทได้รับความเสียหาย
            หรือถูกเรียกเก็บค่าใช้จ่ายจากบุคคลภายนอกทั้งทางตรงหรือทางอ้อม
            Influencer
            ยินดีจะต้องชดใช้ค่าเสียหายให้แก่บริษัททันทีที่ได้รับการเรียกร้องจากทางบริษัท
            และบริษัทอาจทําการตรวจสอบและพิจารณายกเลิกหรือระงับการให้บริการแก่
            Influencer ดังกล่าว โดยไม่ต้องแจ้งให้ทราบล่วงหน้า 
          </li>
          <li>
            ในกรณีที่ Influencer ตกลงและยืนยันการรับงานกับทาง Marketer แล้ว
            แต่ต้องการยกเลิกการให้บริการงานในส่วนนั้น Influencer
            สามารถกระทำได้จนถึงก่อนวันที่ Influencer ได้รับสินค้าหรือบริการจาก
            Marketer โดยจะไม่เสียค่าใช้จ่ายใดๆ
            แต่การกระทำดังกล่าวจะต้องอยู่ภายใต้ขอบเขตตามที่บริษัทเห็นสมควร
            หมายถึง Influencer จะต้องแจ้งให้ทางบริษัท และ Marketer
            ทราบถึงเหตุผลในการปฏิเสธงานดังกล่าวก่อนทุกครั้ง 
          </li>
          <li>
            ในกรณีที่ Influencer ส่งงานล้าช้าเกินกว่าระยะเวลาที่ได้ตกลงไว้
            Influencer จะต้องชดใช้ให้กับ Marketer
            เป็นจำนวนเงินตามที่ได้ตกลงกันไว้บนแพลตฟอร์ม ซึ่ง Marketer
            จะเป็นผู้เรียกเก็บจากทาง Influencer เองทั้งหมด
          </li>
          <li>
            ทั้งนี้ หากบริษัทได้รับความเสียหายหรือข้อเรียกร้องใดๆ จากบุคคลภายนอก
            ซึ่งอาจส่งผลกระทบให้การดําเนินงานส่วนใดส่วนหนึ่งหรือทั้งแพลตฟอร์ม
            ต้องหยุดชะงักลงหรือไม่สามารถให้บริการต่อไปได้ Influencer
            จะกระทําทุกวิธีทางเพื่อป้องกันไม่ให้บริษัทได้รับผลกระทบหรือความเสียหายใดๆ
            และจะเป็นผู้รับผิดชอบในบรรดาความเสียหายที่เกิดขึ้นทั้งหมด
          </li>
          <br />
          9.2 ความรับผิดชอบของ Marketer
          <br />
          <br />
          <li>
            ข้อมูลหรือเนื้อหาใดๆที่ Marketer นำเข้าสู่แพลตฟอร์มนั้น Marketer
            เข้าใจและยืนยันถึงความเป็นเจ้าของในเนื้อหาและข้อมูลของตนเอง
            และจะเป็นผู้รับผิดชอบต่อการเรียกร้องใดๆในเนื้อหาของ Marketer เอง
            ทั้งทางกฎหมายแพ่งและอาญา 
          </li>
          <li>
            หาก Marketer
            ฝ่าฝืนข้อกำหนดการใช้งานหรือกฎหมายใดๆที่เกี่ยวข้องระหว่างการใช้บริการแพลตฟอร์ม
            ส่งผลให้บริษัทได้รับความเสียหาย
            หรือถูกเรียกเก็บค่าใช้จ่ายจากบุคคลภายนอกทั้งทางตรงหรือทางอ้อม
            Marketer
            ยินดีจะต้องชดใช้ค่าเสียหายให้แก่บริษัททันทีที่ได้รับการเรียกร้องจากทางบริษัท
            และบริษัทอาจทําการตรวจสอบและพิจารณายกเลิกหรือระงับการให้บริการแก่
            Marketer ดังกล่าว โดยไม่ต้องแจ้งให้ทราบล่วงหน้า 
          </li>
          <li>
            ในกรณีที่ Marketer ตกลงและยืนยันในการรับทำงานร่วมกับ Influencer แล้ว
            แต่ต้องการปฏิเสธหรือยกเลิกการร่วมงานกับ Influencer คนดังกล่าว
            Marketer สามารถกระทำได้จนถึงก่อนวันที่ Influencer
            ได้รับสินค้าหรือบริการจาก Marketer โดยจะไม่เสียค่าใช้จ่ายใดๆ
            แต่การกระทำดังกล่าวจะต้องอยู่ภายใต้ขอบเขตตามที่บริษัทเห็นสมควร
            หมายถึง Marketer จะต้องแจ้งให้ทางบริษัท และ Influencer
            ทราบถึงเหตุผลในการปฏิเสธงานดังกล่าวก่อนทุกครั้ง 
          </li>
          <li>
            ในกรณีที่ Influencer ส่งงานล้าช้าเกินกว่าระยะเวลาที่ได้ตกลงไว้
            Influencer จะต้องชดใช้ให้กับ Marketer
            เป็นจำนวนเงินตามที่ได้ตกลงไว้บนแพลตฟอร์ม ซึ่ง Marketer
            จะต้องเป็นผู้เรียกเก็บจากทาง Influencer ด้วยตนเองทั้งหมด
          </li>
          <li>
            ทั้งนี้ หากบริษัทได้รับความเสียหายหรือข้อเรียกร้องใดๆ จากบุคคลภายนอก
            ซึ่งอาจส่งผลกระทบให้การดําเนินงานส่วนใดส่วนหนึ่งหรือทั้งแพลตฟอร์ม
            ต้องหยุดชะงักลงหรือไม่สามารถให้บริการต่อไปได้ Marketer
            จะกระทําทุกวิธีทางเพื่อป้องกันไม่ให้บริษัทได้รับผลกระทบหรือความเสียหายใดๆ
            และจะเป็นผู้รับผิดชอบในบรรดาความเสียหายที่เกิดขึ้นทั้งหมด 
          </li>
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>10. การจำกัดความรับผิดของบริษัท</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          <li>
            บริษัทจะไม่รับผิดชอบต่อค่าเสียหายใดๆ
            อันเกิดจากเครือข่ายสังคมออนไลน์อื่นๆที่ใช้ในการเชื่อมต่อกับทางแพลตฟอร์มหรือเว็บไซต์ของบริษัท
          </li>
          <li>
            บริษัทไม่มีหน้าที่ตรวจสอบและแก้ไขข้อมูลหรือเนื้อหาใดๆที่ผู้ใช้บริการนําเข้าสู่แพลตฟอร์ม
            ผู้ใช้บริการจะต้องเป็นผู้รับผิดชอบต่อความเหมาะสม ความถูกต้อง
            และความเป็นปัจจุบันของเนื้อหาทั้งหมดเอง
            รวมไปถึงการที่ทางบริษัทนำเนื้อหาของผู้ใช้บริการไปโปรโมทและเผยแพร่ต่อบุคคลภายนอกอีกด้วย 
          </li>
          <li>
            บริษัทจะไม่รับผิดชอบต่อการกระทำหรือข้อตกลงใดๆระหว่างผู้ใช้บริการ
            หรือ ระหว่างผู้ใช้บริการกับบุคคลอื่น
            รวมไปถึงในกรณีที่ผลกำไรไม่ได้เป็นไปตามที่ได้ตกลงกันไว้ระหว่าง
            Influencer และ Marketer 
          </li>
          <li>
            บริษัทจะไม่รับผิดชอบต่อการสูญเสียที่เกิดขึ้นจากการ
            ซ่อมแซม/เปลี่ยนการติดตั้งการทดสอบระบบของแพลตฟอร์ม
          </li>
          <li>
            บริษัทจะไม่รับผิดชอบต่อค่าเสียหาย อันเกิดจากภัยธรรมชาติ สงคราม
            หรือสถานการณ์ที่ไม่สามารถควบคุมได้
            หรือในกรณีที่เกิดความขัดข้องทางระบบคอมพิวเตอร์และระบบไฟฟ้า
            รวมถึงการที่ผู้ประกอบการโทรคมนาคมหยุดทำงานชั่วคราว หรือ
            ไม่ได้ให้บริการตามปกติ
          </li>
          <li>
            ผู้ใช้บริการยอมรับและรับทราบว่าทางบริษัทไม่สามารถรับประกันความปลอดภัยของข้อมูล
            หรือเนื้อหาใดที่ถูกนําเข้าสู่แพลตฟอร์มโดยผู้ใช้บริการท่านอื่น
            ดังนั้น
            ผู้ใช้บริการจำเป็นต้องมีการป้องกันไวรัสหรือการโจมตีข้อมูลในลักษณะใดๆด้วยตนเอง
            บริษัทจะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายใดๆ
            ที่เกิดขึ้นจากไวรัสหรือการโจมตีทางเทคโนโลยี
          </li>
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>11. การชดเชยค่าเสียหาย</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer>
        <B3Text>
          <li>
            บริษัทจะไม่ขอรับผิดชอบในการให้บริการใดๆ
            ซึ่งไม่ได้เป็นการละเมิดต่อตัวผู้ใช้บริการหรือข้อมูลส่วนบุคคลของผู้ใช้บริการ 
          </li>
          <li>
            ในกรณีที่ผู้ใช้บริการได้ละเมิดต่อข้อตกลงและนโยบายใดๆอันเกี่ยวข้อง
            ผู้ใช้บริการยืนยันจะชดใช้ค่าสินไหมทดแทนในบรรดาความเสียหายอันเกิดจากการเรียกร้องโดยบุคคลภายนอกต่อทางบริษัท
            และตกลงจะต่อสู้ และป้องกันไม่ให้บริษัท กรรมการ ผู้บริการ และลูกจ้าง
            รวมไปถึงบริษัทในเครือ หรือ คู่ค้าของทางบริษัท
            ต้องเสื่อมเสียหรือถูกฟ้องร้องดําเนินคดีจากการที่
            และหากบุคคลดังได้กล่าวได้รับผลกระทบ
            ผู้ใช้บริการต้องเป็นผู้รับผิดชอบความเสียหายและค่าใช้จ่ายที่เกิดขึ้นเองทั้งหมด
          </li>
          <li>
            อย่างไรก็ดี ผู้ใช้บริการมีสิทธิในการเรียกร้องความเสียหาย
            และจำเป็นต้องให้เหตุผลในการเรียกร้องค่าเสียหายพร้อมหลักฐานมาเป็นลายลักษณ์อักษร
            ให้แก่ทางบริษัทภายในระยะเวลา 3
            เดือนนับแต่ต้นเหตุแห่งการกระทำนั้นได้เกิดขึ้น
            มิฉะนั้นแล้วให้ถือว่าผู้ใช้บริการ
            ไม่ประสงค์จะใช้สิทธิเรียกร้องอีกต่อไป ทั้งนี้
            บริษัทขอสงวนสิทธิ์ในการเป็นเจ้าของดุลยพินิจแต่เพียงผู้เดียว
          </li>
        </B3Text>
      </TextContainer>
      <HeaderTextContainer>
        <BoldH7Text>12. กฏหมายที่บังคับใช้</BoldH7Text>
      </HeaderTextContainer>
      <TextContainer style={{ paddingBottom: '32px' }}>
        <B3Text>
          ข้อกำหนดการใช้งานฉบับนี้ รวมถึงนโยบายและเอกสารใดๆที่ออกโดยบริษัท
          จะอยู่ภายใต้ข้อบังคับ ทางกฎหมาย และขอบเขตอำนาจของศาลในประเทศไทย
        </B3Text>
      </TextContainer>
    </Container>
  );
}

export default TermOfUse;

const Container = styled.div`
  margin-bottom: 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 24px 56px 13px 56px;
  margin-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const HeaderTextContainer = styled.div`
  padding: 32px 40px 0px 56px;
`;

const TextContainer = styled.div`
  padding: 8px 40px 0px 80px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const B3Text = styled(TEXT.B3_Reg)``;
