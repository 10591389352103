import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ANDTEXT } from '../../../../fonts/andText';
import { ANDCOLOR } from '../../../../fonts/andColor';
import {
  ColumnContainer,
  PureContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import { RXIcon } from 'rn-rx-icons';
import { pageIndex } from '../../../../pages/NewCreateCampaign/NewCreateCampaignPage';
import { ImageProp } from '../../../organisms/CreateCampaign/ImageSelector';
import {
  adsDuration,
  pinnedOptions
} from '../../../organisms/CreateCampaign/Options';
import ImageList from '../../ImageList';
import { toThaiDate } from '../../../../utils';

interface Props {
  setPage?: (index: pageIndex) => void;
  photos: ImageProp[];
  name: string;
  detail: string;
  category: string;
  isSecret: boolean;
  ads: {
    startDate: string | null;
    endDate: string | null;
    value: adsDuration;
  };
  startDate: string | null;
  endDate: string | null;
  postingDate: string | null;
  submissionDate: string | null;
  haveEdit?: boolean;
}

function DealCardDetail(props: Props) {
  const {
    setPage,
    photos,
    name,
    detail,
    category,
    isSecret,
    ads,
    startDate,
    endDate,
    postingDate,
    submissionDate,
    haveEdit = true
  } = props;

  const [active, setActive] = useState<boolean>(true);

  const publicText = isSecret ? 'ปิด' : 'เปิด';

  const CalendarComponent = ({
    start,
    end
  }: {
    start: string | null;
    end: string | null;
  }) => {
    if (start && end) {
      return (
        <IconTextContainer>
          <RXIcon name="Calendar" color={ANDCOLOR.BLUE} />
          <Body2Text style={{ marginLeft: 4 }}>
            {toThaiDate(start) + ' - ' + toThaiDate(end)}
          </Body2Text>
        </IconTextContainer>
      );
    }
    return <></>;
  };

  const AdsTextComponent = () => {
    const detail =
      pinnedOptions.find(option => option.value === ads.value)?.detail || 'ปิด';
    const type =
      pinnedOptions.find(option => option.value === ads.value)?.text || '';
    return <Body2Text>{type + ' (' + detail + ')'}</Body2Text>;
  };
  const ContentComponent = () => {
    if (active)
      return (
        <ContentContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>รูปภาพ ({photos.length})</BoldHeader7Text>
            <DetailContainer>
              <ImageList images={photos} imageStyle={{ marginTop: 0 }} />
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>ชื่อแคมเปญ</BoldHeader7Text>
            <DetailContainer>
              <Body2Text>{name}</Body2Text>
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>รายละเอียด</BoldHeader7Text>
            <DetailContainer>
              <Body2Text>{detail}</Body2Text>
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>หมวดหมู่สินค้า/บริการ</BoldHeader7Text>
            <DetailContainer>{'・' + category}</DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>แคมเปญสาธารณะ</BoldHeader7Text>
            <DetailContainer>
              <Body2Text>{publicText}</Body2Text>
            </DetailContainer>
          </FullWidthRowContainer>
          {/* <FullWidthRowContainer>
            <BoldHeader7Text>ปักหมุดแคมเปญ</BoldHeader7Text>
            <DetailContainer>
              <AdsTextComponent />
            </DetailContainer>
          </FullWidthRowContainer> */}
          <BoldHeader7Text>กำหนดเวลา</BoldHeader7Text>
          <FullWidthRowContainer>
            <BoldHeader7Text style={{ marginLeft: 4 }}>
              1. กำหนดรับสมัคร
            </BoldHeader7Text>
            <DetailContainer>
              <CalendarComponent start={startDate} end={endDate} />
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text style={{ marginLeft: 4 }}>
              2. กำหนดโพสต์งาน
            </BoldHeader7Text>
            <DetailContainer>
              <CalendarComponent start={postingDate} end={submissionDate} />
            </DetailContainer>
          </FullWidthRowContainer>
        </ContentContainer>
      );
    return <></>;
  };

  const EditComponent = () => {
    if (haveEdit)
      return (
        <EditContainer
          onClick={() => {
            if (!!setPage) setPage(3);
          }}
        >
          <RXIcon name="Edit" size={16} color={ANDCOLOR.BLUE} />
          <ButtonSmallText>แก้ไข</ButtonSmallText>
        </EditContainer>
      );
    return <></>;
  };
  return (
    <Container>
      <TitleContainer>
        <BoldHeader6Text>ขั้นตอนที่ 3 : รายละเอียดแคมเปญ</BoldHeader6Text>
        <RowContainer>
          <EditComponent />
          <IconContainer onClick={() => setActive(!active)}>
            <RXIcon
              name={active ? 'ArrowUp' : 'ArrowDown'}
              color={ANDCOLOR.BLUE}
            />
          </IconContainer>
        </RowContainer>
      </TitleContainer>
      <ContentComponent />
    </Container>
  );
}

export default DealCardDetail;

const Container = styled.div`
  width: 100%;
  margin-top: 72px;
`;

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 16px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  align-items: flex-start;
  width: 100%;
`;

const DetailContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: 65%;
  gap: 4px;
`;

const TitleContainer = styled(SpaceBetweenRowContainer)`
  border-bottom: 1px solid ${ANDCOLOR.BLUE};
  padding-bottom: 16px;
  margin-bottom: 24px;
`;

const EditContainer = styled(RowContainer)`
  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  margin-left: 16px;
  :hover {
    cursor: pointer;
  }
`;

const IconTextContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BoldHeader6Text = styled(ANDTEXT.header6BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  min-width: 300px;
`;

const Body2Text = styled(ANDTEXT.body2)`
  max-width: 448px;
  word-wrap: break-word;
  white-space: normal;
`;

const ButtonSmallText = styled(ANDTEXT.buttonSmall)`
  margin-left: 4px;
  color: ${ANDCOLOR.BLUE};
`;
