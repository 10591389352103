import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';
import RButton from './RButton';
interface Props {
  isAndroid: boolean;
}

const MobileFooter = (props: Props) => {
  const { isAndroid } = props;

  return (
    <Container>
      <BoldH6Text>เรามีแอปพลิเคชั่นด้วยนะ!</BoldH6Text>
      <Body2Text>
        เพื่อประสบการณ์ใช้งานที่ดีที่สุด เราแนะนำให้ใช้งาน
        <br />
        ผ่านแอปพลิเคชั่น Pickle for brand
      </Body2Text>
      <RButton
        title="เปิดแอปฯ"
        onPress={() => {
          isAndroid
            ? window.open(
                'https://play.google.com/store/apps/details?id=com.rabbitx.picklebrand&hl=en&gl=US'
              )
            : window.open(
                'https://apps.apple.com/th/app/pickle-for-brand/id1532888600'
              );
        }}
        containerStyle={{ marginTop: 8, alignSelf: 'flex-end' }}
      />
    </Container>
  );
};

export default MobileFooter;

const Container = styled.div`
  position: fixed;

  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  height: 188px;
  background-color: ${COLOR.BLACK};
  gap: 8px;
  z-index: 999999;
`;

const BoldH6Text = styled(TEXT.header6BOLD)`
  color: ${COLOR.WHITE};
`;

const Body2Text = styled(TEXT.body2)`
  color: ${COLOR.WHITE};
`;
