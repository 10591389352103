import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { CategoryType } from '../../organisms/CreateCampaign/Options';
import RDraggableWithArrowsComponent from '../RDraggableWithArrowsComponent';
import { ActiveProps } from '../../../types';

interface Props {
  category: string[];
  activeCategory: CategoryType | undefined;
}

const InfluencerCardCategory = (props: Props) => {
  const { category, activeCategory } = props;
  const components: JSX.Element[] = [];
  if (!category || category?.length === 0) return <></>;
  category.map(cat => {
    const active = activeCategory === cat;
    components.push(
      <CategoryCardContainer
        key={`${active ? '1' : '2'}${cat}`}
        active={active}
      >
        <B5Text active={active}>{cat}</B5Text>
      </CategoryCardContainer>
    );
  });
  components.sort((a, b) => {
    if (!!a.key && !!b.key)
      return a.key.toString().localeCompare(b.key.toString());
    return 1;
  });
  return (
    <CategoryContainer>
      <RDraggableWithArrowsComponent
        leftArrowComponent={
          <RXIcon name="ArrowShortLeft" color={COLOR.WHITE} size={24} />
        }
        rightArrowComponent={
          <RXIcon name="ArrowShortRight" color={COLOR.WHITE} size={24} />
        }
      >
        <CategoryDataContainer>{components}</CategoryDataContainer>
      </RDraggableWithArrowsComponent>
    </CategoryContainer>
  );
};

export default InfluencerCardCategory;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const CategoryDataContainer = styled.div`
  display: flex;
  gap: 4px;
  align-self: flex-start;
  overflow-x: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const CategoryCardContainer = styled.div<ActiveProps>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.4px solid ${props => (props.active ? COLOR.Black : COLOR.Gray_300)};
  background: ${props => (props.active ? COLOR.Gray_100 : 'none')};
`;

const B5Text = styled(TEXT.B5_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Black : COLOR.White)};
  white-space: nowrap;
`;
