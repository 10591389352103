import { observer } from 'mobx-react';
import styled from 'styled-components';
import { ANDCOLOR } from '../../fonts/andColor';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../utils/styling/general';
import { COLOR } from '../../fonts/color';
import ANDButton from '../../components/atoms/ANDButton';

interface Props {
  setAcceptModalActive: React.Dispatch<React.SetStateAction<string>>;
}

const InfluencerInviteFooter = (props: Props) => {
  const { setAcceptModalActive } = props;

  return (
    <Container>
      <ANDButton
        title="ปฏิเสธ"
        textColor={COLOR.Warning}
        icon={'Close'}
        iconLocation="lefticon"
        onPress={() => setAcceptModalActive('deny')}
        containerStyle={{
          padding: '20px 24px',
          backgroundColor: COLOR.White,
          border: `1px solid ${COLOR.Warning}`
        }}
      />
      <ANDButton
        title="ตอบรับ"
        textColor={COLOR.White}
        icon="Check"
        iconLocation="lefticon"
        onPress={() => setAcceptModalActive('accept')}
        containerStyle={{
          padding: '20px 24px',
          backgroundColor: COLOR.Blue_700
        }}
      />
    </Container>
  );
};

export default observer(InfluencerInviteFooter);

const Container = styled(RowContainer)`
  display: flex;
  position: fixed;
  bottom: 0;
  width: calc(100% - 240px);
  padding: 12px 0px;
  align-items: center;
  justify-content: center;
  z-index: 999;
  gap: 24px;
  background: ${ANDCOLOR.WHITE};
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
`;

const GapRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8;
`;
