import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import HomeButton from '../../atoms/Layout/HomeButton';
import MenuDetail from '../../atoms/Layout/MenuDetail';
import { TEXT } from '../../../fonts/text';
import { COLOR } from '../../../fonts/color';
import { useQuery } from '../../../utils';
import { useDealStore } from '../../../stores/initialize/Context';
import { observer } from 'mobx-react';
import RBrandLogo from '../../atoms/RBrandLogo';

interface Props {
  navigation: NavigateFunction;
  pathname: string;
}

const CampaignDrawer = (props: Props) => {
  const { navigation, pathname } = props;

  const query = useQuery();
  const queryStatus = query.get('status') as string;
  const queryDealId = query.get('dealId') as string;
  const { deal } = useDealStore();
  const { dealId } = deal;
  const drawerDealId = dealId || queryDealId;

  const influencerStatusChildren: {
    text: string;
    onClick: () => void;
    active: boolean;
    haveTotal?: number;
  }[] = [
    {
      text: 'คำขอใหม่',
      active:
        pathname.includes('/deal') &&
        (queryStatus === 'pending' || !queryStatus),
      onClick: () => navigation(`/deal?dealId=${drawerDealId}&status=pending`),
      haveTotal: deal.pendingContacts.length
    },
    {
      text: 'กำลังดำเนินการ',
      active: queryStatus === 'ongoing',
      onClick: () => navigation(`/deal?dealId=${drawerDealId}&status=ongoing`),
      haveTotal: deal.ongoingContacts.length
    },
    {
      text: 'ตรวจงาน',
      active: queryStatus === 'check',
      onClick: () => navigation(`/deal?dealId=${drawerDealId}&status=check`),
      haveTotal: deal.checkContacts.length
    },
    {
      text: 'เสร็จสิ้น',
      active: queryStatus === 'done',
      onClick: () => navigation(`/deal?dealId=${drawerDealId}&status=done`),
      haveTotal: deal.doneContacts.length
    }
  ];

  return (
    <>
      <PlaceholderContainer />
      <HeaderContainer>
        <RBrandLogo navigation={navigation} />
        <HomeButton navigation={navigation} />
        <MenuDetail
          icon="FileFill"
          text="รายละเอียดแคมเปญ"
          onClick={() =>
            navigation(`deal?dealId=${drawerDealId}&status=detail`)
          }
          active={queryStatus === 'detail'}
        />
        <MenuDetail
          icon="FileFill"
          text="จัดการแคมเปญ"
          onClick={() => {}}
          active={pathname === '/' || pathname.includes('/campaign')}
          children={influencerStatusChildren}
        />
        <MenuDetail
          icon="Engagement"
          text="Performance"
          onClick={() => navigation(`/performance?dealId=${dealId}`)}
          active={pathname.includes('/performance')}
        />
        <MenuDetail
          icon="AI"
          text="แนะนำ Influencer"
          onClick={() =>
            navigation(`deal?dealId=${drawerDealId}&status=recommend`)
          }
          active={queryStatus === 'recommend'}
        />
      </HeaderContainer>
    </>
  );
};

export default observer(CampaignDrawer);

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 240px;
  padding: 20px 16px;
  height: 100%;
  z-index: 9999;
  border-right: 0.6px solid ${COLOR.Gray_200};
  background-color: ${COLOR.White};
`;

const PlaceholderContainer = styled.div`
  width: 240px;
  flex-shrink: 0;
`;
