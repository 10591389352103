import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';
import { useDealStore } from '../../stores/initialize/Context';
import { ActiveProps } from '../../types';
import { Deal, PlatformType } from '../../types/deal';
import { PLACEHOLDER_ANOTHERDOT, useQuery } from '../../utils';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../utils/styling/general';
import BackButton from '../../components/atoms/Layout/BackButton';
import RBrandLogo from '../../components/atoms/RBrandLogo';
import ProfileWithPlatfrom from '../../reusables/ProfileWithPlatform';

interface InitializeProps {
  getCampaignList: () => Promise<Deal | undefined>;
  queryDeal: string;
  setSelectedDeal: React.Dispatch<React.SetStateAction<string>>;
}

const initialize = async (props: InitializeProps) => {
  const { getCampaignList, queryDeal, setSelectedDeal } = props;
  const firstDeal = await getCampaignList();
  if (queryDeal === '' || queryDeal === null) {
    const { dealId } = firstDeal || {};
    setSelectedDeal(dealId || '');
  } else {
    setSelectedDeal(queryDeal);
  }
};

interface StatusProps {
  status: 'ongoing' | 'done';
}

const StatusTagComponent = (props: StatusProps) => {
  const { status } = props;
  if (status === 'done')
    return (
      <DoneContainer>
        <GreenMB4Text>จบแคมเปญ</GreenMB4Text>
      </DoneContainer>
    );
  else
    return (
      <OngoingContainer>
        <LemonB4Text>กำลังดำเนินการ</LemonB4Text>
      </OngoingContainer>
    );
};

interface CampaignComponentProps {
  name: string;
  img: string;
  status: 'ongoing' | 'done';
  selectedDeal: string;
  dealId: string;
  dealPlatform: PlatformType;
  setSelectedDeal: React.Dispatch<React.SetStateAction<string>>;
}

const CampaignComponent = (props: CampaignComponentProps) => {
  const {
    name,
    img,
    status,
    selectedDeal,
    dealId,
    dealPlatform,
    setSelectedDeal
  } = props;
  const navigation = useNavigate();
  const active = selectedDeal === dealId;
  return (
    <CampaignContainer
      active={active}
      onClick={() => {
        setSelectedDeal(dealId);
        navigation(`/performance?dealId=${dealId}`);
      }}
    >
      <ProfileWithPlatfrom profileImage={img} platform={dealPlatform} />
      <GapColumnContainer>
        <Bu3Text>{name}</Bu3Text>
        <StatusTagComponent status={status} />
      </GapColumnContainer>
    </CampaignContainer>
  );
};

interface CampaignListComponentProps {
  campaignList: {
    ongoing: Deal[];
    pending: Deal[];
    done: Deal[];
  };
  selectedDeal: string;
  setSelectedDeal: React.Dispatch<React.SetStateAction<string>>;
}

const CampaignListComponent = (props: CampaignListComponentProps) => {
  const { campaignList, selectedDeal, setSelectedDeal } = props;
  const components: JSX.Element[] = [];
  campaignList.ongoing.slice().map(ongoingCampaign => {
    const { name, photos = [], dealId, platforms } = ongoingCampaign;
    const dealPlatform = platforms[0].toUpperCase() as PlatformType;
    components.push(
      <CampaignComponent
        key={dealId}
        name={name}
        img={photos[0] || PLACEHOLDER_ANOTHERDOT}
        status="ongoing"
        selectedDeal={selectedDeal}
        dealId={dealId}
        setSelectedDeal={setSelectedDeal}
        dealPlatform={dealPlatform}
      />
    );
  });
  campaignList.done.slice().map(doneCampaign => {
    const { name, photos = [], dealId, platforms } = doneCampaign;
    const dealPlatform = platforms[0].toUpperCase() as PlatformType;
    components.push(
      <CampaignComponent
        key={dealId}
        name={name}
        img={photos[0] || PLACEHOLDER_ANOTHERDOT}
        status="done"
        selectedDeal={selectedDeal}
        dealId={dealId}
        setSelectedDeal={setSelectedDeal}
        dealPlatform={dealPlatform}
      />
    );
  });
  return (
    <GapScrollColumnContainer style={{ gap: 4 }}>
      {components}
    </GapScrollColumnContainer>
  );
};

interface Props {
  navigation: NavigateFunction;
}

const PerformanceDrawer = (props: Props) => {
  const { navigation } = props;

  const { getCampaignList, campaignList } = useDealStore();

  const [selectedDeal, setSelectedDeal] = useState<string>('');

  const query = useQuery();
  const queryDeal = query.get('dealId') as string;

  const campaignLength =
    campaignList.ongoing.length +
    campaignList.done.length +
    campaignList.pending.length;

  useEffect(() => {
    initialize({ getCampaignList, setSelectedDeal, queryDeal });
  }, []);

  return (
    <>
      <PlaceholderContainer />
      <Container>
        <RBrandLogo navigation={navigation} />
        <BackButton navigation={navigation} />
        <GrayMBu3Text>Performance {`(${campaignLength})`}</GrayMBu3Text>
        <CampaignListComponent
          campaignList={campaignList}
          selectedDeal={selectedDeal}
          setSelectedDeal={setSelectedDeal}
        />
      </Container>
    </>
  );
};

export default observer(PerformanceDrawer);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 240px;
  padding: 20px 16px;
  height: 100%;
  z-index: 9999;
  border-right: 0.6px solid ${COLOR.Gray_200};
  background-color: ${COLOR.White};
`;

const PlaceholderContainer = styled.div`
  width: 240px;
  flex-shrink: 0;
`;

const CampaignContainer = styled(RowContainer)<ActiveProps>`
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  flex-shrink: 0;
  background-color: ${props => (props.active ? COLOR.Gray_50 : COLOR.White)};
  overflow: hidden;

  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const GapColumnContainer = styled(FlexStartColumnContainer)`
  gap: 8px;
`;

const GapScrollColumnContainer = styled(FlexStartColumnContainer)`
  gap: 8px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    position: absolute;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_200};
    border-radius: 18px;
    border: solid 4px transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: solid 4px transparent;
    background: ${COLOR.Gray_300};
    background-clip: padding-box;
  }
`;

const DoneContainer = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 4px;
  border: 0.6px solid ${COLOR.Herbal};
  background: ${COLOR.Green_L};
`;

const OngoingContainer = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 0.6px solid ${COLOR.Yellow_M};
  background: ${COLOR.Yellow_L};
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  flex-shrink: 0;
`;

const Bu3Text = styled(TEXT.Bu3_Reg)`
  width: 128px;
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GreenMB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Green_M};
`;

const LemonB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Lemon};
`;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  margin: 20px 0px 8px 12px;
  color: ${COLOR.Gray_M};
`;
