import React, { InputHTMLAttributes } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { FontNotoSansRegular } from '../../fonts/font';
import { TEXT } from '../../fonts/text';

export type InputStateProps = 'normal' | 'error' | 'check';

interface StateProps {
  state?: InputStateProps;
}

interface LabelProps {
  haveIcon: boolean;
}

interface InputProps {
  state: InputStateProps;
}

interface Props {
  icon?: IconName;
  iconSize?: number;
  customIcon?: any;
  label?: string;
  containerStyle?: React.CSSProperties;
  required?: boolean;
  state?: InputStateProps;
  inputProp?: InputHTMLAttributes<HTMLTextAreaElement>;
  noBold?: boolean;
  unit?: string;
}

function RTextarea(props: Props) {
  const {
    icon,
    iconSize = 24,
    customIcon,
    label,
    containerStyle,
    required = false,
    state = 'normal',
    inputProp,
    noBold = false,
    unit
  } = props;

  const UnitText = () => {
    if (!!unit && !!inputProp?.defaultValue)
      return <CurrencyText>฿</CurrencyText>;
    return <></>;
  };
  return (
    <Container style={containerStyle}>
      <LabelContainer>
        {icon && <RXIcon name={icon} size={iconSize} />}
        {customIcon}
        {noBold ? (
          <LabelText haveIcon={!!(icon || customIcon)}>{'label'}</LabelText>
        ) : (
          <BoldLabelText haveIcon={!!(icon || customIcon)}>
            {label}
          </BoldLabelText>
        )}
        {required && <RequiredText>&nbsp;*</RequiredText>}
      </LabelContainer>
      <InputContainer>
        <Input state={state} {...inputProp} />
        <UnitText />
      </InputContainer>
    </Container>
  );
}

export default RTextarea;

const Container = styled.div`
  margin-bottom: 24px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 10px;
`;

const LabelText = styled(TEXT.H7_Reg)<LabelProps>`
  margin-left: ${props => (props.haveIcon ? '8px' : '0')};
`;

const BoldLabelText = styled(TEXT.H7_Bold)<LabelProps>`
  margin-left: ${props => (props.haveIcon ? '8px' : '0')};
`;

const RequiredText = styled(TEXT.H7_Bold)`
  color: ${COLOR.Warning};
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

const Input = styled.textarea<InputProps>`
  font-family: ${FontNotoSansRegular};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex: 1;
  height: 48px;
  border: none;
  border-radius: 24px;
  padding: 15px 24px;
  background: ${COLOR.Gray_50};
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 9px;
  border: none;
  outline: none;
  overflow: hidden;
  resize: none;
  overflow-y: scroll;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLOR.Gray_300};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${COLOR.Gray_300};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${COLOR.Gray_300};
  }

  :not(:placeholder-shown) {
    background-color: ${COLOR.WHITE};
    border: 1px solid;
    border-color: ${props =>
      props.state === 'normal'
        ? COLOR.Gray_300
        : props.state === 'error'
          ? COLOR.PINK
          : COLOR.GREEN};
  }

  :focus {
    background-color: ${COLOR.WHITE};
    outline: none;
    border: 1px solid ${COLOR.BLACK};
    box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_300};
    border-radius: 18px;
    border: solid 4px transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: solid 4px transparent;
    background: ${COLOR.Gray_M};
    background-clip: padding-box;
  }
`;

const CurrencyText = styled(TEXT.T1_Reg)`
  position: absolute;
  color: ${COLOR.Gray_300};
  top: 13px;
  right: 22px;
`;
