import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { FontNotoSansRegular } from '../../../fonts/font';
import { TEXT } from '../../../fonts/text';
import { useAuthStore } from '../../../stores/initialize/Context';
import RButton from '../../atoms/RButton';
import SocialButton from '../../atoms/SocialButton';

const options = [
  'Facebook',
  'Instagram',
  'Tiktok',
  'Twitter',
  'Lemon8',
  'Youtube',
  'Google',
  'คำแนะนำจากคนรู้จัก',
  'อื่นๆ'
];

function SurveyCardDetail() {
  const [selected, setSelected] = useState<string>('');
  const [other, setOther] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { sendRegisterFrom } = useAuthStore();
  const navigate = useNavigate();

  const onSend = async () => {
    setLoading(true);
    if (selected === 'อื่นๆ') {
      await sendRegisterFrom(other || 'อื่นๆ');
    } else {
      await sendRegisterFrom(selected);
    }
    setLoading(false);
    navigate('/');
  };

  const isSendActive =
    !loading && !!selected && (selected !== 'อื่นๆ' || !!other);

  return (
    <Container>
      <LargeText>คุณรู้จักเราช่องทางไหน</LargeText>
      {options.map((option, index) => {
        return (
          <BoxContainer key={index + option}>
            <SocialButton
              text={option}
              selected={selected}
              onClick={() => setSelected(option)}
            />
          </BoxContainer>
        );
      })}
      {selected === 'อื่นๆ' && (
        <OtherContainer>
          {/* <OtherText>โปรดระบุรายละเอียด</OtherText> */}
          <OtherInput
            rows={4}
            maxLength={70}
            onChange={e => setOther(e.target.value)}
            placeholder="โปรดระบุรายละเอียด"
          />
        </OtherContainer>
      )}
      <ButtonPadding>
        <RButton
          title="ยืนยัน"
          onPress={onSend}
          active={isSendActive}
          textStyle={{
            display: 'flex',
            flex: 1,
            width: '288px',
            padding: 0
          }}
        />
      </ButtonPadding>
    </Container>
  );
}

export default SurveyCardDetail;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LargeText = styled(TEXT.H5_Bold)`
  margin-bottom: 27px;
`;

const BoxContainer = styled.label`
  margin-top: 10px;
`;

const OtherContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OtherInput = styled.textarea`
  font-family: ${FontNotoSansRegular};
  font-size: 16px;
  line-height: 24px; /* 150% */
  padding: 20px 20px;
  margin-top: 12px;
  height: 110px;
  background-color: ${COLOR.Blue_50};
  border: none;
  border-radius: 16px;
  align-items: flex-start;
  outline: none;
  resize: none;
`;

const ButtonPadding = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;
