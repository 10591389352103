import shortId from 'shortid';
import { groupBy, log } from '../utils';

export type scopeOfWorkType =
  | 'IG_individual_post'
  | 'IG_album_post'
  | 'IG_story'
  | 'IG_tv'
  | 'IG_reels'
  | 'TK_video'
  | 'LM_article'
  | 'FB_individual_post'
  | 'FB_album_post'
  | 'FB_reels';

export interface PostMedia {
  duration?: number;
  id: string;
  image: number;
  video: number;
  article: number;
  postType: scopeOfWorkType;
}

export type PostMedias = Array<PostMedia>;

export type mediaPlatforms = 'instagram' | 'tiktok';

export interface PostTypes {
  IG_individual_post: number;
  IG_album_post: number;
  IG_album_images: number;
  IG_tv: number;
  IG_story: number;
  IG_reels: number;
  TK_video: number;
  LM_article: number;
  FB_individual_post: number;
  FB_album_post: number;
  FB_album_images: number;
  FB_reels: number;
}

export class ScopeOfWork {
  private works: PostMedias;

  constructor(post: PostMedias | PostTypes) {
    if (Array.isArray(post)) {
      this.works = post as PostMedias;
    } else {
      this.works = this.constructPostMedias(post as PostTypes);
    }
  }

  private sumPostMediaTypes = (): Record<
    scopeOfWorkType,
    Record<'image' | 'video' | 'article', number>
  > => {
    const sumTypes: Record<
      scopeOfWorkType,
      Record<'image' | 'video' | 'article', number>
    > = {
      IG_album_post: { image: 0, video: 0, article: 0 },
      IG_individual_post: { image: 0, video: 0, article: 0 },
      IG_reels: { image: 0, video: 0, article: 0 },
      IG_story: { image: 0, video: 0, article: 0 },
      IG_tv: { image: 0, video: 0, article: 0 },
      TK_video: { image: 0, video: 0, article: 0 },
      LM_article: { image: 0, video: 0, article: 0 },
      FB_album_post: { image: 0, video: 0, article: 0 },
      FB_individual_post: { image: 0, video: 0, article: 0 },
      FB_reels: { image: 0, video: 0, article: 0 }
    };

    this.works.forEach(work => {
      const { postType, image, video } = work;
      sumTypes[postType].image += image;
      sumTypes[postType].video += video;
    });

    return sumTypes;
  };

  private convertSumToPostMedia = (): PostMedias => {
    const sumPosts = this.sumPostMediaTypes();
    const keys = Object.keys(sumPosts) as scopeOfWorkType[];
    const medias: PostMedias = [];
    keys.forEach(key => {
      const value = sumPosts[key];
      const k = key as scopeOfWorkType;

      if (k === 'IG_album_post' || k === 'FB_album_post') {
        return;
      }
      const { image, video } = value;
      if (image > 0) {
        medias.push(this.constructPostMedia(image, 0, 0, k));
      }
      if (video > 0) {
        medias.push(this.constructPostMedia(0, video, 0, k));
      }
    });

    this.works
      .filter(
        w => w.postType === 'IG_album_post' || w.postType === 'FB_album_post'
      )
      .forEach(media => {
        medias.push(media);
      });

    return medias;
  };

  private constructPostMedia = (
    image: number,
    video: number,
    article: number,
    postType: scopeOfWorkType
  ): PostMedia => ({
    id: shortId.generate(),
    image,
    video,
    article,
    postType
  });

  private constructPostMedias = (post: PostTypes): PostMedias => {
    const medias: PostMedias = [];
    if (post?.IG_individual_post > 0) {
      const individuals_medias: PostMedias = new Array(post.IG_individual_post)
        .fill(0)
        .map(() => this.constructPostMedia(1, 0, 0, 'IG_individual_post'));
      medias.push(...individuals_medias);
    }
    if (post?.IG_album_post > 0) {
      const album_medias: PostMedias = new Array(post.IG_album_post)
        .fill(0)
        .map(() =>
          this.constructPostMedia(
            parseInt(`${post.IG_album_images}`, 10),
            0,
            0,
            'IG_album_post'
          )
        );
      medias.push(...album_medias);
    }
    if (post?.IG_story > 0) {
      const story_medias: PostMedias = [
        this.constructPostMedia(
          parseInt(`${post.IG_story}`, 10),
          0,
          0,
          'IG_story'
        )
      ];
      medias.push(...story_medias);
    }
    if (post?.IG_tv > 0) {
      const tv_medias: PostMedias = new Array(post.IG_tv)
        .fill(0)
        .map(() => this.constructPostMedia(0, 1, 0, 'IG_tv'));
      medias.push(...tv_medias);
    }
    if (post?.IG_reels > 0) {
      const reel_medias: PostMedias = new Array(post.IG_reels)
        .fill(0)
        .map(() => this.constructPostMedia(0, 1, 0, 'IG_reels'));
      medias.push(...reel_medias);
    }

    if (post?.FB_individual_post > 0) {
      const individuals_medias: PostMedias = new Array(post.FB_individual_post)
        .fill(0)
        .map(() => this.constructPostMedia(1, 0, 0, 'FB_individual_post'));
      medias.push(...individuals_medias);
    }
    if (post?.FB_album_post > 0) {
      const album_medias: PostMedias = new Array(post.FB_album_post)
        .fill(0)
        .map(() =>
          this.constructPostMedia(
            parseInt(`${post.FB_album_images}`, 10),
            0,
            0,
            'FB_album_post'
          )
        );
      medias.push(...album_medias);
    }
    if (post?.FB_reels > 0) {
      const reel_medias: PostMedias = new Array(post.FB_reels)
        .fill(0)
        .map(() => this.constructPostMedia(0, 1, 0, 'FB_reels'));
      medias.push(...reel_medias);
    }
    return medias;
  };

  public getMediaChannels = (): mediaPlatforms[] => {
    return Object.keys(this.getPlatformMedias()) as mediaPlatforms[];
  };

  public getPlatformMedia = (mediaPlatform: mediaPlatforms): PostMedias => {
    return this.getPlatformMedias()[mediaPlatform];
  };

  public getPlatformMedias = (): Record<mediaPlatforms, PostMedias> => {
    return {
      tiktok: this.works.filter(work => work.postType.includes('TIKTOK')),
      instagram: this.works.filter(work => work.postType.includes('IG'))
    };
  };

  public isEmpty = (): boolean => {
    return this.works.length === 0;
  };

  public toArray = (): PostMedias => {
    return this.works;
  };

  public toMediaArray = (): PostMedias => {
    return this.convertSumToPostMedia();
  };

  public toString = () => {
    return this.works
      .map(work => {
        const wImage = `${work.image > 0 ? `${work.image} รูป` : ''}`;

        const wVideo = `${work.video > 0 ? `${work.video} วิดีโอ` : ''}`;
        const requirement = [wImage, wVideo]
          .filter(w => w.length > 0)
          .join(',');

        switch (work.postType) {
          case 'IG_album_post':
            return `Album posts ${requirement}`;
          case 'IG_individual_post':
            return `Single posts ${requirement}`;
          case 'IG_reels':
            return `Reels ${requirement}`;
          case 'IG_story':
            return `Story posts ${requirement}`;
          case 'IG_tv':
            return `IG TV posts ${requirement}`;
          default:
            return '';
        }
      })
      .join('\n');
  };

  public toDisplayTiktok = (): { video: number; duration: number }[] => {
    const tiktokWorks = this.works.filter(w => w.postType === 'TK_video');

    const postMedias = groupBy(tiktokWorks, 'duration');

    const tiktokPostDuration = Object.keys(postMedias);

    const tiktokVideoList = tiktokPostDuration.map(duration => ({
      // @ts-ignore
      video: postMedias[duration].length,
      duration: +duration
    }));

    return tiktokVideoList;
  };

  public toDisplayLemon = (): PostMedia[] => {
    const lemonWorks = this.works.filter(w => w.postType === 'LM_article');

    return lemonWorks;
  };

  public toDisplayObject = () => {
    const postMedias = this.toMediaArray();
    const IG_individual_image_posts = postMedias.filter(postMedia => {
      return postMedia.postType === 'IG_individual_post' && postMedia.image > 0;
    })?.[0]?.image;

    const IG_individual_video_posts = postMedias.filter(postMedia => {
      return postMedia.postType === 'IG_individual_post' && postMedia.video > 0;
    })?.[0]?.video;

    const IG_album_posts = postMedias.filter(postMedia => {
      return postMedia.postType === 'IG_album_post';
    });
    const IG_story = postMedias.filter(postMedia => {
      return postMedia.postType === 'IG_story';
    });
    const IG_TV = postMedias.filter(postMedia => {
      return postMedia.postType === 'IG_tv';
    })?.[0]?.video;
    const IG_Reels = postMedias.filter(postMedia => {
      return postMedia.postType === 'IG_reels';
    })?.[0]?.video;

    return {
      IG_individual_image_posts,
      IG_individual_video_posts,
      IG_album_posts,
      IG_story,
      IG_TV,
      IG_Reels
    };
  };

  public toDisplayFacebook = () => {
    const postMedias = this.toMediaArray();
    const FB_individual_image_posts = postMedias.filter(postMedia => {
      return postMedia.postType === 'FB_individual_post' && postMedia.image > 0;
    })?.[0]?.image;

    const FB_individual_video_posts = postMedias.filter(postMedia => {
      return postMedia.postType === 'FB_individual_post' && postMedia.video > 0;
    })?.[0]?.video;

    const FB_album_posts = postMedias.filter(postMedia => {
      return postMedia.postType === 'FB_album_post';
    });
    const FB_Reels = postMedias.filter(postMedia => {
      return postMedia.postType === 'FB_reels';
    })?.[0]?.video;

    return {
      FB_individual_image_posts,
      FB_individual_video_posts,
      FB_album_posts,
      FB_Reels
    };
  };
}
