import { MakeOptional } from '@mui/x-charts/models/helpers';
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ITiktokInfluencer
} from '../../types/api/getInfluencer';
import { PlatformType } from '../../types/deal';
import { PieValueType } from '@mui/x-charts/models/seriesType';
import { COLOR } from '../../fonts/color';

interface GetDataProps {
  platformData: IInstagramInfluencer | ITiktokInfluencer | IFacebookInfluencer;
  dataDateFormat: 'weekData' | 'monthData';
}

const getData = (props: GetDataProps) => {
  const { platformData, dataDateFormat } = props;
  return {
    likeData: platformData?.[dataDateFormat]?.likeCount ?? 0,
    commentData: platformData?.[dataDateFormat]?.commentCount ?? 0,
    shareData: platformData?.[dataDateFormat]?.shareCount ?? 0,
    engagementData: platformData?.[dataDateFormat]?.engagement ?? 0,
    reachData: platformData?.[dataDateFormat]?.reachCount ?? 0,
    impressionsData: platformData?.[dataDateFormat]?.impressionCount ?? 0,
    saveData: platformData?.[dataDateFormat]?.saveCount ?? 0,
    viewData: platformData?.[dataDateFormat]?.viewCount ?? 0,
    postCount: platformData?.[dataDateFormat]?.postCount ?? 0
  };
};

interface PieDataProps {
  share: number;
  like: number;
  comment: number;
  platformActive: PlatformType;
}

const getPieData = (
  props: PieDataProps
): MakeOptional<PieValueType, 'id'>[] => {
  const { like, comment, share, platformActive } = props;
  if (like === 0 && share === 0 && comment === 0) {
    return [
      {
        value: 1,
        color: COLOR.Black,
        label: platformActive === 'INSTAGRAM' ? 'Saved' : 'Share'
      },
      {
        value: 1,
        color: COLOR.Blue_300,
        label: 'Like'
      },
      {
        value: 1,
        color: COLOR.Rubicund,
        label: 'Comment'
      }
    ];
  }
  return [
    {
      value: share,
      color: COLOR.Black,
      label: platformActive === 'INSTAGRAM' ? 'Saved' : 'Share'
    },
    {
      value: like,
      color: COLOR.Blue_300,
      label: 'Like'
    },
    {
      value: comment,
      color: COLOR.Rubicund,
      label: 'Comment'
    }
  ];
};

interface StatisticProps {
  platformActive: 'INSTAGRAM' | 'TIKTOK' | 'FACEBOOK';
  instagram: IInstagramInfluencer;
  tiktok: ITiktokInfluencer;
  facebook: IFacebookInfluencer;
  dateFormat: 'weekly' | 'monthly';
}

export const getStatisticData = (props: StatisticProps) => {
  const { platformActive, instagram, tiktok, facebook } = props;

  const dataDateFormat = 'monthData';

  const platformDataMap = {
    INSTAGRAM: instagram,
    TIKTOK: tiktok,
    FACEBOOK: facebook
  };

  const data = getData({
    platformData: platformDataMap[platformActive],
    dataDateFormat
  });

  const pieData = getPieData({
    like: data.likeData,
    comment: data.commentData,
    share: data.shareData,
    platformActive
  });

  return { ...data, pieData };
};

interface GetAgeGenderProps {
  instagram: IInstagramInfluencer;
  facebook: IFacebookInfluencer;
  tiktok: ITiktokInfluencer;
  platformActive: PlatformType;
}

export const getAgeGenderData = (props: GetAgeGenderProps) => {
  const { instagram, facebook, tiktok, platformActive } = props;
  switch (platformActive) {
    case 'INSTAGRAM':
      return {
        genders: instagram?.demographicGenders || [],
        ages: instagram?.demographicAges || [],
        ageGender: instagram?.demographicAgesGenders || []
      };
    case 'FACEBOOK':
      return {
        genders: facebook?.demographicGenders || [],
        ages: facebook?.demographicAges || [],
        ageGender: facebook?.demographicAgesGenders || []
      };
    default:
      return { genders: [], ages: [] };
  }
};
