import React, { useEffect, useState } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';

interface ButtonProps {
  active?: boolean;
  backgroundColor?: string;
  hoverColor?: string;
  textColor?: string;
}

interface Props {
  title: string;
  icon?: IconName;
  iconLocation?: 'righticon' | 'lefticon';
  onPress: () => void;
  containerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  active?: boolean;
  backgroundColor?: string;
  hoverColor?: string;
  textColor?: string;
}

const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  height: 48px;
  min-width: 136px;
  white-space: nowrap;
  padding: 13px 24px;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.active ? props.backgroundColor : COLOR.Gray_200};
  border: ${props =>
    props.backgroundColor === COLOR.WHITE
      ? `1px solid ${COLOR.Blue_700}`
      : 'none'};
  border-radius: 50px;
  cursor: ${props => (props.active ? 'pointer' : '')};
  transition: 500ms;

  :hover {
    background-color: ${props =>
      props.active ? props.hoverColor : COLOR.Gray_200};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
`;

const ButtonTextContainer = styled(TEXT.Bu1_Bold)<ButtonProps>`
  margin-top: 3px;
  justify-content: center;
  color: ${props => (props.active ? props.textColor : COLOR.Gray_M)};
`;

function RButton(props: Props) {
  const {
    title,
    icon,
    iconLocation = 'textonly',
    containerStyle,
    textStyle,
    onPress,
    active = true,
    backgroundColor = COLOR.Blue_700,
    hoverColor = COLOR.Blue_900,
    textColor = COLOR.WHITE
  } = props;
  const [buttonActive, setButtonActive] = useState<boolean>(active);

  useEffect(() => {
    setButtonActive(active);
  }, [active]);

  const renderText = () => {
    return (
      <ButtonTextContainer
        active={active}
        textColor={textColor}
        style={textStyle}
      >
        {title}
      </ButtonTextContainer>
    );
  };

  const renderIcon = () => {
    if (!icon) {
      return;
    }
    return (
      <RXIcon
        name={icon}
        size={28}
        color={active ? textColor : COLOR.Gray_200}
      />
    );
  };

  const renderContent = () => {
    switch (iconLocation) {
      case 'lefticon':
        return (
          <>
            {renderIcon()}
            {renderText()}
          </>
        );
      default:
        return (
          <>
            {renderText()}
            {renderIcon()}
          </>
        );
    }
  };

  return (
    <ButtonContainer
      active={active}
      onClick={onPress}
      disabled={!buttonActive}
      style={containerStyle}
      backgroundColor={backgroundColor}
      hoverColor={hoverColor}
    >
      {renderContent()}
    </ButtonContainer>
  );
}

export default RButton;
