import styled from 'styled-components';
import RTags from '../RTags';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { interestCategory } from '../../organisms/CreateCampaign/Options';

interface Props {
  interests: string[];
}

export const Category = (props: Props) => {
  const { interests } = props;
  return (
    <CategoryContainer>
      {interests.map(interest => {
        const interestObject = interestCategory.find(
          int => int.value === interest
        );
        return (
          <RTags
            key={interest}
            name={interestObject?.value || interest}
            icon={interestObject?.icon}
            onPress={() => {}}
            containerStyle={{
              marginRight: 8,
              marginBottom: 8
            }}
            textStyle={{
              color: COLOR.BLACK
            }}
          />
        );
      })}
    </CategoryContainer>
  );
};

const CategoryContainer = styled.div`
  display: flex;
  padding: 8px 0px 0px 0px;
  margin-left: 66px;
  margin-bottom: 4px;
  gap: 8px;
  width: 500px;
  flex-wrap: wrap;
`;
