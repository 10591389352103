import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ForgotPasswordCard from '../../components/organisms/ForgotPassword/ForgotPasswordCard';
import ForgotPasswordCode from '../../components/organisms/ForgotPassword/ForgotPasswordCode';
import ForgotPasswordNew from '../../components/organisms/ForgotPassword/ForgotPasswordNew';
import LoginLogo from '../../components/organisms/Login/LoginLogo';
import {
  useAuthStore,
  useResetPasswordStore
} from '../../stores/initialize/Context';

function ForgotPasswordPage() {
  const {
    email,
    setEmail,
    sendForgetPasswordEmail,
    emailError,
    isEmailSendActive,
    code,
    checkIsValidCode,
    resetPassword,
    isRepasswordSendActive
  } = useResetPasswordStore();
  const { loadIsLoggedIn } = useAuthStore();
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (loadIsLoggedIn()) {
      navigate('/');
    }
  }, []);

  const renderComponent = () => {
    switch (page) {
      case 1:
        return (
          <ForgotPasswordCard
            changePage={() => setPage(2)}
            email={email}
            setEmail={setEmail}
            sendForgetPasswordEmail={sendForgetPasswordEmail}
            emailError={emailError}
            isEmailSendActive={isEmailSendActive}
          />
        );
      case 2:
        return (
          <ForgotPasswordCode
            changePage={() => setPage(3)}
            changeBack={() => {
              email.value = '';
              setPage(1);
            }}
            email={email.value}
            code={code}
            checkIsValidCode={checkIsValidCode}
          />
        );
      case 3:
        return (
          <ForgotPasswordNew
            code={code}
            resetPassword={resetPassword}
            isRepasswordSendActive={isRepasswordSendActive}
            onDone={() => navigate('/')}
          />
        );
    }
  };

  return (
    <Container>
      <ContentContainer>
        <LogoContainer>
          <LoginLogo />
        </LogoContainer>
        <ForgetContainer>{renderComponent()}</ForgetContainer>
      </ContentContainer>
    </Container>
  );
}

export default observer(ForgotPasswordPage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1280px;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 80px;
`;

const ForgetContainer = styled.div`
  margin-top: 174px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 280px;
  margin-right: 120px;
`;
