import { RXIcon } from 'rn-rx-icons';
import { scopeOfWorkType } from '../../class/ScopeOfWork';
import { COLOR } from '../../fonts/color';
import { tierType } from '../../types/createDeal';

interface Props {
  type: scopeOfWorkType | tierType | undefined;
  active: boolean;
  size?: number;
  inactiveColor?: string;
}

const PostIcon = (props: Props) => {
  const { type, active, size = 24, inactiveColor = COLOR.Gray_M } = props;
  const iconColor = active ? COLOR.Blue_700 : inactiveColor;
  switch (type) {
    case 'IG_individual_post':
    case 'FB_individual_post':
      return <RXIcon name="Image" color={iconColor} size={size} />;
    case 'IG_story':
      return <RXIcon name="StoryCircle" color={iconColor} size={size} />;
    case 'LM_article':
      return <RXIcon name="Article" color={iconColor} size={size} />;
    case 'IG_album_post':
    case 'FB_album_post':
      return <RXIcon name="Multi" color={iconColor} size={size} />;
    case 'FB_reels':
    case 'IG_reels':
    case 'TK_video':
      return <RXIcon name="Reels" color={iconColor} size={size} />;
    default:
      return <></>;
  }
};

export default PostIcon;
