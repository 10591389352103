import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ITiktokInfluencer,
  RateCard
} from '../../../types/api/getInfluencer';
import { difTime, genderFieldToText } from '../../../utils';
import {
  ColumnContainer,
  FlexEndColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import ANDButton from '../../atoms/ANDButton';
import CardAttributes from '../../atoms/Influencer/CardAttributes';
import CardCategory from '../../atoms/Influencer/CardCategory';
import FacebookDetail from '../../atoms/Influencer/FacebookDetail';
import InstagramDetail from '../../atoms/Influencer/InstagramDetail';
import LemonAccount from '../../atoms/Influencer/LemonAccount';
import TiktokDetail from '../../atoms/Influencer/TiktokDetail';
import { CategoryType } from '../../organisms/CreateCampaign/Options';

interface SaveIconProps {
  isSaved: boolean;
  onSaved: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SaveIconComponent = (props: SaveIconProps) => {
  const { isSaved, onSaved } = props;
  const icon: IconName = isSaved ? 'SaveFill' : 'Save';
  return (
    <IconContainer onClick={onSaved}>
      <RXIcon name={icon} color={COLOR.White} />
    </IconContainer>
  );
};

interface Props {
  username: string;
  avgRating: number;
  totalReview: number;
  province: string;
  lastOnline: string;
  gender: string;
  interests: CategoryType[];
  profile_img: string;
  attributes: string[];
  instagram: IInstagramInfluencer;
  tiktok: ITiktokInfluencer;
  facebook: IFacebookInfluencer;
  lemonUrl: string;
  rateCard: {
    instagram: RateCard[];
    tiktok: RateCard[];
    lemon: RateCard[];
    facebook: RateCard[];
  };
  setActive?: React.Dispatch<React.SetStateAction<boolean>>;
  isSaved: boolean;
  onSaved: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const InfluencerBlueCard = (props: Props) => {
  const {
    username,
    avgRating,
    totalReview,
    province,
    lastOnline,
    gender,
    interests,
    profile_img,
    attributes,
    instagram,
    tiktok,
    facebook,
    rateCard = {
      instagram: [],
      tiktok: [],
      lemon: [],
      facebook: []
    },
    lemonUrl,
    setActive
  } = props;

  const {
    instagram: instagramRateCard = [],
    tiktok: tiktokRateCard = [],
    facebook: facebookRateCard = [],
    lemon: lemonRateCard = []
  } = rateCard;

  const genderProvinceText =
    genderFieldToText(gender) + ' | ' + (province || 'ไม่ระบุจังหวัด') + ' | ';
  const lastOnlineText = ` ออนไลน์ ${difTime(
    lastOnline,
    true,
    false,
    false,
    true
  )} ที่แล้ว `;

  return (
    <>
      <CardHeaderContainer>
        <CardImageContainer
          src={require('../../../assets/images/InfluencerCardBackground.jpg')}
        />
        <CardContentContainer>
          <ProfileImageContainer src={profile_img} />
          <CardDetailContainer>
            <DetailContainer>
              <GapColumnContainer>
                <GapRowContainer>
                  <WhiteBoldH5Text>{username}</WhiteBoldH5Text>
                  <RatingContainer>
                    <RXIcon name="StarFilled" size={16} color={COLOR.Straw} />
                    <WhiteBoldB5Text>{avgRating.toFixed(1)}</WhiteBoldB5Text>
                    <WhiteBoldB5Text>
                      {'(' + totalReview + ' แคมเปญ)'}
                    </WhiteBoldB5Text>
                  </RatingContainer>
                </GapRowContainer>
                <RowContainer>
                  <WhiteB3Text>{genderProvinceText}</WhiteB3Text>
                  <ClockIconContainer>
                    <RXIcon name="Clock" size={16} color={COLOR.White} />
                  </ClockIconContainer>
                  <WhiteB3Text>{lastOnlineText}</WhiteB3Text>
                </RowContainer>
              </GapColumnContainer>
              <GapRowContainer>
                <SaveIconComponent {...props} />
                {!!setActive && (
                  <ANDButton
                    title={'ส่งคำเชิญ'}
                    onPress={() => setActive(true)}
                    icon={'PaperSend'}
                    iconLocation="lefticon"
                    containerStyle={{ padding: '8px 20px', height: 40 }}
                  />
                )}
              </GapRowContainer>
            </DetailContainer>
            <DetailContainer>
              <GapRowContainer style={{ gap: 12 }}>
                <CardCategory interests={interests} />
                <CardAttributes attributes={attributes} />
              </GapRowContainer>
              <FlexEndColumnContainer style={{ gap: 4, alignSelf: 'flex-end' }}>
                <Gray200B4Text>Powered by</Gray200B4Text>
                <AnotherDotImg
                  src={require('../../../assets/images/AnotherDotLogoTextWhite.png')}
                />
              </FlexEndColumnContainer>
            </DetailContainer>
          </CardDetailContainer>
        </CardContentContainer>
        <BottomCardContainer>
          <InstagramDetail instagram={instagram} rateCard={instagramRateCard} />
          <TiktokDetail tiktok={tiktok} rateCard={tiktokRateCard} />
          <FacebookDetail facebook={facebook} rateCard={facebookRateCard} />
        </BottomCardContainer>
        {/* <AttributesTooltipComponent
          tooltipActive={tooltipActive}
          attributes={attributes}
        /> */}
      </CardHeaderContainer>
      <LemonAccount lemonUrl={lemonUrl} rateCard={lemonRateCard} />
    </>
  );
};

export default InfluencerBlueCard;

const CardHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 356px;
  border-radius: 16px;
  position: relative;
  z-index: 8;
  margin-bottom: 188px;
`;

const CardImageContainer = styled.img`
  position: absolute;
  width: 100%;
  height: 356px;
  border-radius: 16px;
  background: ${COLOR.Blue_950};
  z-index: 7;
`;

const CardContentContainer = styled(RowContainer)`
  align-items: flex-start;
  width: 100%;
  height: 256px;
  border-radius: 16px;
  z-index: 10;
  padding: 36px 20px;
  gap: 20px;
`;

const CardDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 200px;
`;

const DetailContainer = styled(SpaceBetweenRowContainer)`
  align-items: flex-start;
  width: 100%;
`;

const GapColumnContainer = styled(ColumnContainer)`
  gap: 8px;
  align-items: flex-start;
`;

const GapRowContainer = styled(RowContainer)`
  align-items: center;
  gap: 8px;
`;

const ProfileImageContainer = styled.img`
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: ${COLOR.Gray_D};
  object-fit: cover;
`;

const AnotherDotImg = styled.img`
  width: 92px;
  height: 44px;
`;

const BottomCardContainer = styled(RowContainer)`
  position: absolute;
  bottom: -180px;
  width: 100%;
  padding: 0px 20px 0px 20px;
  z-index: 9;
  gap: 14px;
`;

const RatingContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: rgba(246, 246, 246, 0.2);
  backdrop-filter: blur(2px);
`;

const IconContainer = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  :hover {
    cursor: pointer;
  }
`;

const ClockIconContainer = styled.div`
  padding: 0px 4px 0px 2px;
`;

const WhiteBoldH5Text = styled(TEXT.H5_Bold)`
  color: ${COLOR.White};
`;

const WhiteBoldB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.White};
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
  opacity: 0.8;
`;

const Gray200B4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.White};
`;
