import { observer } from 'mobx-react';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { FlexStartColumnContainer } from '../../../utils/styling/general';
import LifestyleList from './LifestyleList';

interface Props {
  activeList: string[];
  lifestyleList: {
    label: string;
    value: string;
  }[];
  fromDeal: boolean;
  setActiveList: (att: string[]) => void;
}

const LifestyleFilter = (props: Props) => {
  const { activeList, lifestyleList, setActiveList, fromDeal } = props;

  if (fromDeal || lifestyleList.length === 0) return <></>;
  return (
    <Container>
      {/* <GrayMH8Text>Lifestyle (มากสุด 5 ตัวเลือก)</GrayMH8Text>
      <LifestyleList
        activeList={activeList}
        lifestyleList={lifestyleList}
        setActiveList={setActiveList}
      /> */}
    </Container>
  );
};

export default observer(LifestyleFilter);

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 8px;
`;

const GrayMH8Text = styled(TEXT.H8_Reg)`
  color: ${COLOR.Gray_M};
`;
