import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled, { CSSProperties } from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import './Dropdown.css';

interface ContainerProp {
  active: boolean;
}

interface ItemsProps {
  value: string;
  onSelect: () => void;
}

interface DropdownProps {
  defaultValue?: string;
  placeholder: string;
  activeText?: boolean;
  options: Array<string>;
  onChange: (option: string) => void;
  containerStyle?: CSSProperties;
}

const Container = styled.div<ContainerProp>`
  position: relative;
  display: flex;
  height: 48px;
  background: ${COLOR.WHITE};
  border: ${props => (props.active ? 'none' : `1px solid ${COLOR.Gray_200}`)};
  border-radius: 25px;
  border-radius: ${props => (props.active ? '25px 25px 0px 0px' : '25px')};
  box-shadow: ${props =>
    props.active ? '0px 4px 10px 0px rgb(25 20 20 / 0.05)' : 'none'};
  align-items: center;
  z-index: 100;

  :hover {
    cursor: pointer;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 48px;
  height: 220px;
  background-color: ${COLOR.WHITE};
  z-index: 100;
  box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
  border-radius: 0px 0px 25px 25px;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    position: absolute;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    position: absolute;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_200};
    border-radius: 18px;
    border: solid 4px transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: solid 4px transparent;
    background: ${COLOR.Gray_300};
    background-clip: padding-box;
  }

  :hover {
    overflow-y: overlay;
  }
`;

const Text = styled(TEXT.body1)`
  display: flex;
  width: 100%;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
`;

const Body1Text = styled(TEXT.body1)``;

const ItemContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 24px;
  background-color: ${COLOR.WHITE};
  align-items: center;

  :hover {
    background-color: ${COLOR.Gray_50};
  }
`;

const IconContainer = styled.div`
  transform: rotate(90deg);
`;

const Dropdown = (props: DropdownProps) => {
  const {
    defaultValue,
    activeText = true,
    placeholder,
    options,
    onChange,
    containerStyle
  } = props;
  const [active, setActive] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(defaultValue || placeholder);
  return (
    <Container
      onClick={() => setActive(!active)}
      active={active}
      onBlur={() => setActive(false)}
      style={containerStyle}
    >
      <Text style={{ color: activeText ? COLOR.BLACK : COLOR.Gray_300 }}>
        {selected}
        <IconContainer>
          <RXIcon name="ArrowShortRight" color={COLOR.Gray_300} />
        </IconContainer>
      </Text>
      {active && (
        <DropdownContainer>
          {options.map((option, index) => {
            return (
              <Items
                key={index}
                value={option}
                onSelect={() => {
                  setSelected(option);
                  onChange(option);
                }}
              />
            );
          })}
        </DropdownContainer>
      )}
    </Container>
  );
};

const Items = (props: ItemsProps) => {
  const { value, onSelect } = props;
  return (
    <ItemContainer onClick={onSelect}>
      <Body1Text>{value}</Body1Text>
    </ItemContainer>
  );
};

export default Dropdown;
