import styled from 'styled-components';
import { TEXT } from '../../../fonts/text';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../../utils/styling/general';
import NewInfluencerCard from './NewInfluencerCard';
import { InfluencerListResponse } from '../../../types/api/getInfluencer';
import RLoading from '../../atoms/RLoading';
import REmptyState from '../../atoms/REmptyState';
import { calculateAverageData } from '../../../utils';
import { COLOR } from '../../../fonts/color';
import RDraggableWithArrowsComponent from '../../atoms/RDraggableWithArrowsComponent';
import { RXIcon } from 'rn-rx-icons';

interface onSavedProps {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  accountId: string;
  isSaved: boolean;
  addSavedInflu: (accountId: string) => void;
  removeSavedInflu: (accountId: string) => void;
  triggerSaveInfluencer: ({
    accountId,
    isSaved
  }: {
    accountId: string;
    isSaved: boolean;
  }) => Promise<void>;
}

const onSavedHandler = (props: onSavedProps) => {
  const {
    e,
    accountId,
    isSaved,
    addSavedInflu,
    removeSavedInflu,
    triggerSaveInfluencer
  } = props;
  e.preventDefault();
  e.stopPropagation();

  if (isSaved) {
    removeSavedInflu(accountId);
  } else {
    addSavedInflu(accountId);
  }
  triggerSaveInfluencer({ accountId, isSaved });
};

interface SimilarListProps {
  influencerList: InfluencerListResponse[];
  brandSaved: string[];
  addSavedInflu: (accountId: string) => void;
  removeSavedInflu: (accountId: string) => void;
  triggerSaveInfluencer: ({
    accountId,
    isSaved
  }: {
    accountId: string;
    isSaved: boolean;
  }) => Promise<void>;
}

const SimilarListComponent = (props: SimilarListProps) => {
  const {
    influencerList,
    brandSaved,
    addSavedInflu,
    removeSavedInflu,
    triggerSaveInfluencer
  } = props;

  if (influencerList.length === 0)
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={{ width: '100%', alignSelf: 'center' }}
      />
    );

  const components: JSX.Element[] = [];
  influencerList.map(influencer => {
    const {
      tier,
      platform,
      username,
      rating,
      ratingCount,
      followers,
      engagement,
      postCount,
      profile_picture,
      cover_picture,
      id,
      accountId,
      attributes,
      rateCard,
      interests
    } = influencer;

    const isSaved = brandSaved.includes(accountId);
    const avgEngagement = parseInt(calculateAverageData(engagement, postCount));

    components.push(
      <NewInfluencerCard
        key={username}
        tier={tier}
        platform={platform}
        username={username}
        influencerRating={rating}
        ratingCount={ratingCount}
        followers={followers}
        influencerEngagement={avgEngagement}
        profile_picture={profile_picture}
        cover_picture={cover_picture}
        id={id}
        accountId={accountId}
        attributes={attributes}
        prevPath={'directory'}
        isSaved={isSaved}
        rateCard={rateCard}
        category={interests}
        min-width={'273px'}
        min-height={'424.5px'}
        onSavedClick={e =>
          onSavedHandler({
            accountId,
            addSavedInflu,
            removeSavedInflu,
            triggerSaveInfluencer,
            isSaved,
            e
          })
        }
      />
    );
  });

  return (
    <RDraggableWithArrowsComponent
      leftArrowComponent={
        <ArrowContainer style={{ left: -16 }}>
          <RXIcon name="ArrowShortLeft" color={COLOR.White} />
        </ArrowContainer>
      }
      rightArrowComponent={
        <ArrowContainer style={{ right: -16 }}>
          <RXIcon name="ArrowShortRight" color={COLOR.White} />
        </ArrowContainer>
      }
    >
      <GapRowContainer>{components}</GapRowContainer>
    </RDraggableWithArrowsComponent>
  );
};
interface Props {
  influencerList: InfluencerListResponse[];
  brandSaved: string[];
  addSavedInflu: (accountId: string) => void;
  removeSavedInflu: (accountId: string) => void;
  triggerSaveInfluencer: ({
    accountId,
    isSaved
  }: {
    accountId: string;
    isSaved: boolean;
  }) => Promise<void>;
  loading: boolean;
}

const SimilarInfluencer = (props: Props) => {
  const { influencerList, loading } = props;

  if (loading) {
    const loadingStyle = {
      width: '100%',
      alignSelf: 'center'
    };
    return <RLoading containerStyle={loadingStyle} />;
  }

  return (
    <Container>
      <BoldH6Text>People also like</BoldH6Text>
      <SimilarListComponent {...props} />
    </Container>
  );
};

export default SimilarInfluencer;

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 20px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 12px;
  overflow: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const ArrowContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${COLOR.Black};
  top: 194px;
  z-index: 999;
  opacity: 0.6;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;
