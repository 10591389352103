import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { genderFieldToText } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';

interface Props {
  limit: number;
  influencerGender: string;
  influencerAge: string;
  onEdit?: () => void;
}

function DealCardInfluencer(props: Props) {
  const { limit, influencerGender, influencerAge, onEdit } = props;

  return (
    <Container>
      <HeaderContainer>
        <BoldButtonText>Influencer</BoldButtonText>
        {!!onEdit && (
          <EditContainer onClick={onEdit}>
            <RXIcon name="Edit" color={COLOR.Blue_700} size={20} />
            <BoldBlueBu3Text style={{ marginLeft: '4px' }}>
              แก้ไข
            </BoldBlueBu3Text>
          </EditContainer>
        )}
      </HeaderContainer>
      <ContentContainer>
        {genderFieldToText(influencerGender)
          .split(',')
          .map((gender, index) => {
            return (
              <BubbleContainer>
                <BoldB3Text>{gender}</BoldB3Text>
              </BubbleContainer>
            );
          })}
        <BubbleContainer>
          <BoldB3Text>อายุ {influencerAge ?? '13-65'} ปี</BoldB3Text>
        </BubbleContainer>
        <BubbleContainer>
          <BoldB3Text>จำนวน {limit} คน</BoldB3Text>
        </BubbleContainer>
      </ContentContainer>
    </Container>
  );
}

export default DealCardInfluencer;

const Container = styled.div`
  margin-top: 24px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled(RowContainer)`
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px dotted ${COLOR.Gray_300};
  margin-bottom: 12px;
`;

const BubbleContainer = styled.div`
  display: flex;
  height: 25px;
  padding: 8px 16px;
  margin-right: 7px;
  border-radius: 50px;
  background: ${COLOR.Gray_200};
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const BoldButtonText = styled(TEXT.H6_Bold)``;

const BoldB3Text = styled(TEXT.B3_Bold)``;

const BoldBlueBu3Text = styled(BoldB3Text)`
  color: ${COLOR.Blue_700};
`;
