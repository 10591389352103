import { InfluencerPost } from '../../../types/api/getInfluencer';
import PostList from './PostList';

function MockedPostList() {
  return <PostList platformActive="INSTAGRAM" postList={mockInfluencerPosts} />;
}

const mockInfluencerPosts: InfluencerPost[] = [
  {
    image:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post1.jpg',
    username: 'anotherdot.co',
    caption:
      'Exploring the hidden gems of Bali 🌴 #TravelGoals #BaliAdventures',
    permalink: 'https://www.instagram.com/p/C7nqLdHNrmA/',
    createdAt: new Date('2024-01-15'),
    like: 24000,
    comment: 450,
    share: 300,
    view: 125000,
    engagement: 0.09,
    impression: 130000,
    reach: 115000,
    saved: 500,
    profile_picture_url:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg'
  },
  {
    image:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post2.jpg',
    username: 'anotherdot.co',
    caption: 'Morning workout grind 💪🔥 #Fitness #HealthyLiving',
    permalink: 'https://www.instagram.com/p/C7nqLdHNrmA/',
    createdAt: new Date('2024-01-20'),
    like: 18000,
    comment: 320,
    share: 200,
    view: 100000,
    engagement: 0.08,
    impression: 105000,
    reach: 95000,
    saved: 400,
    profile_picture_url:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg'
  },
  {
    image:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post3.jpg',
    username: 'anotherdot.co',
    caption:
      'Best brunch ever at the new cafe in town 🥑🍳 #FoodieLife #BrunchTime',
    permalink: 'https://www.instagram.com/p/C7nqLdHNrmA/',
    createdAt: new Date('2024-01-25'),
    like: 32000,
    comment: 500,
    share: 400,
    view: 150000,
    engagement: 0.1,
    impression: 160000,
    reach: 145000,
    saved: 700,
    profile_picture_url:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg'
  },
  {
    image:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post4.jpg',
    username: 'anotherdot.co',
    caption:
      'Exploring the hidden gems of Bali 🌴 #TravelGoals #BaliAdventures',
    permalink: 'https://www.instagram.com/p/C7nqLdHNrmA/',
    createdAt: new Date('2024-01-15'),
    like: 24000,
    comment: 450,
    share: 300,
    view: 125000,
    engagement: 0.09,
    impression: 130000,
    reach: 115000,
    saved: 500,
    profile_picture_url:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg'
  },
  {
    image:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-mock-post5.jpg',
    username: 'anotherdot.co',
    caption:
      'Exploring the hidden gems of Bali 🌴 #TravelGoals #BaliAdventures',
    permalink: 'https://www.instagram.com/p/C7nqLdHNrmA/',
    createdAt: new Date('2024-01-15'),
    like: 24000,
    comment: 450,
    share: 300,
    view: 125000,
    engagement: 0.09,
    impression: 130000,
    reach: 115000,
    saved: 500,
    profile_picture_url:
      'https://mock-image.s3.ap-southeast-1.amazonaws.com/anotherdot-profile-pic.jpg'
  }
];

export default MockedPostList;
