import styled from 'styled-components';
import Creator from '../../../assets/images/Campaign/Creator.png';
import { TEXT } from '../../../fonts/text';
import RButton from '../../atoms/RButton';
import RModal from '../../atoms/RModal';

interface Props {
  active: boolean;
}
const MaintenanceModal = (props: Props) => {
  const { active } = props;
  return (
    <RModal active={active} setActive={() => {}}>
      <Container>
        <ImageContainer src={Creator} />
        <BoldH5Text>แจ้งปิดระบบชั่วคราว</BoldH5Text>
        <B3Text>เพื่อปรับปรุงและพัฒนาระบบ</B3Text>
        <B3Text>เราจะแจ้งให้ทราบอีกครั้ง</B3Text>
        <B3Text>เมื่อระบบสามารถใช้งานได้ตามปกติ</B3Text>
        <B3Text>ขออภัยในความไม่สะดวกมา ณ ที่นี้</B3Text>
        <RButton
          title="เข้าใจแล้ว"
          onPress={() => window.location.reload()}
          containerStyle={{ marginTop: 28 }}
        />
      </Container>
    </RModal>
  );
};

export default MaintenanceModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px 32px 0px;
  width: 394px;
  background: #ffffff;
  border-radius: 24px;
`;

const ImageContainer = styled.img`
  width: 144px;
  height: 144px;
`;

const B3Text = styled(TEXT.B3_Reg)``;

const BoldH5Text = styled(TEXT.H5_Bold)`
  margin: 16px 0px 8px 0px;
`;
