import { Alert, Slide, Snackbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { ANDTEXT } from '../../fonts/andText';
import { COLOR } from '../../fonts/color';
import {
  useAccountStore,
  useInfluencerStore
} from '../../stores/initialize/Context';
import { InfluencerListResponse } from '../../types/api/getInfluencer';
import { CategoryLifestyle } from '../../types/categoryLiftstyle';
import { genderType, tierType } from '../../types/createDeal';
import { PlatformWithTotalType } from '../../types/deal';
import NewInfluencerCard from '../../components/molecules/Influencer/NewInfluencerCard';
import RecommendHeader from '../../components/atoms/Influencer/RecommendHeader';
import REmptyState from '../../components/atoms/REmptyState';
import RLoading from '../../components/atoms/RLoading';
import {
  CategoryType,
  attribute as attributeOptions
} from '../../components/organisms/CreateCampaign/Options';
import { useNavigate } from 'react-router-dom';
import { calculateAverageData } from '../../utils';
import { BlurredContainer } from '../../utils/styling/general';
import { Feature, featureAccessStore } from '../../stores/featureAccessStore';
import { MockedInfluencerList } from './MockedInfluencerList';
import CategoryFilter from './CategoryFilter/CategoryFilter';
import LifestyleFilter from './LifeStyleFilter/LifestyleFilter';
import { useInfluencerListFilterContext } from './InfluencerListContext';

const transitionProp: React.ComponentType<
  TransitionProps & {
    children: React.ReactElement<any, any>;
  }
> = Slide;

export interface FilterProps {
  gender: genderType[];
  tier: tierType[];
  social: PlatformWithTotalType;
  attribute: string[];
  engagement: number[];
  follower: number[];
  rating: number[];
  location: string[];
  age: number[];
  audience: {
    age: number[];
    gender: genderType[];
    location: string[];
  };
  username: string;
  category: CategoryType | undefined;
}

export interface InfluencerDetail {
  tier: string;
  platform: PlatformWithTotalType;
  username: string;
  followers: number;
  rating: number;
  engagement: number;
  score: number;
  ratingCount: number;
}

interface GetAttibutesFunctionProps {
  categoryLifestyle: CategoryLifestyle[];
  activeCategory: CategoryType | undefined;
  platformActive: PlatformWithTotalType;
}

const getAttributeList = (props: GetAttibutesFunctionProps) => {
  const { activeCategory, platformActive } = props;
  if (!activeCategory && platformActive !== 'LEMON') return attributeOptions;
  return [];

  // Remove due to minimal attributes, reinclude when the amount of attribute increase
  // const currentAttributeList = categoryLifestyle.find(
  //   (cat) => cat.category === activeCategory
  // )?.lifestyle;
  // if (!!currentAttributeList)
  //   return currentAttributeList.map((att) => ({
  //     label: attributeOptions.find((op) => op.value === att)?.label || att,
  //     value: att,
  //   }));
  // return attributeOptions;
};

interface onSavedProps {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  accountId: string;
  isSaved: boolean;
  addSavedInflu: (accountId: string) => void;
  removeSavedInflu: (accountId: string) => void;
  triggerSaveInfluencer: ({
    accountId,
    isSaved
  }: {
    accountId: string;
    isSaved: boolean;
  }) => Promise<void>;
}

const onSavedHandler = (props: onSavedProps) => {
  const {
    e,
    accountId,
    isSaved,
    addSavedInflu,
    removeSavedInflu,
    triggerSaveInfluencer
  } = props;
  e.preventDefault();
  e.stopPropagation();
  if (isSaved) {
    removeSavedInflu(accountId);
  } else {
    addSavedInflu(accountId);
  }
  triggerSaveInfluencer({ accountId, isSaved });
};

interface InfluencerListProps {
  data: InfluencerListResponse[];
  listLoading: boolean;
  finishInitialize: boolean;
  brandSaved: string[];
  addSavedInflu: (accountId: string) => void;
  removeSavedInflu: (accountId: string) => void;
  triggerSaveInfluencer: ({
    accountId,
    isSaved
  }: {
    accountId: string;
    isSaved: boolean;
  }) => Promise<void>;
  activeAttributes: string[];
  scrollViewRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const InfluencerList = (props: InfluencerListProps) => {
  const {
    data,
    listLoading,
    finishInitialize,
    brandSaved,
    addSavedInflu,
    removeSavedInflu,
    triggerSaveInfluencer,
    activeAttributes,
    scrollViewRef
  } = props;
  const navigate = useNavigate();
  const { filters } = useInfluencerListFilterContext();

  if (data.length === 0 && !listLoading && finishInitialize) {
    const emptyStyle = {
      width: '200px',
      height: '200px'
    };
    const containerStyle = {
      width: '100%',
      alignSelf: 'center',
      marginTop: 36
    };
    return (
      <REmptyState
        header="ไม่พบข้อมูล"
        graphic={
          <EmptyStateImage
            src={require('../../assets/images/state/CustomerReview.png')}
            style={emptyStyle}
          />
        }
        containerStyle={containerStyle}
        buttonText="Refresh"
        buttonWidth={131}
        onClick={() => navigate(0)}
      />
    );
  }

  const components: JSX.Element[] = [];
  data.forEach(influencer => {
    const isSaved = brandSaved.includes(influencer.accountId);
    const avgEngagement = parseInt(
      calculateAverageData(influencer.engagement, influencer.postCount)
    );
    components.push(
      <NewInfluencerCard
        key={influencer.id}
        tier={influencer.tier}
        platform={influencer.platform}
        username={influencer.username}
        influencerRating={influencer.rating}
        ratingCount={influencer.ratingCount}
        followers={influencer.followers}
        influencerEngagement={avgEngagement}
        profile_picture={influencer.profile_picture}
        cover_picture={influencer.cover_picture}
        id={influencer.id}
        accountId={influencer.accountId}
        attributes={influencer.attributes}
        activeAttributes={activeAttributes}
        lemonUrl={influencer.lemonUrl}
        rateCard={influencer.rateCard}
        prevPath={'directory'}
        isSaved={isSaved}
        onSavedClick={e =>
          onSavedHandler({
            e,
            accountId: influencer.accountId,
            isSaved,
            addSavedInflu,
            removeSavedInflu,
            triggerSaveInfluencer
          })
        }
        category={influencer.interests}
        activeCategory={filters.categoryActive}
      />
    );
  });
  return (
    <InfluencerListContainer ref={scrollViewRef}>
      {components}
    </InfluencerListContainer>
  );
};

interface SnackBarProps {
  activeSaveSnack: 'success' | 'remove' | 'none';
  setActiveSaveSnack: React.Dispatch<
    React.SetStateAction<'success' | 'remove' | 'none'>
  >;
  activeSnack: boolean;
  setActiveSnack: React.Dispatch<React.SetStateAction<boolean>>;
}

const SnackBarComponent = (props: SnackBarProps) => {
  const { activeSaveSnack, setActiveSaveSnack, activeSnack, setActiveSnack } =
    props;
  if (activeSaveSnack !== 'none') {
    const text =
      activeSaveSnack === 'success'
        ? 'บันทึกเรียบร้อย'
        : 'นำออกจากบันทึกเรียบร้อย';
    const icon: IconName =
      activeSaveSnack === 'success' ? 'CheckCircle' : 'Close';
    return (
      <Snackbar
        open={activeSaveSnack === 'success' || activeSaveSnack === 'remove'}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setActiveSaveSnack('none')}
        TransitionComponent={transitionProp}
        key={'campaignSnackBar'}
      >
        <Alert
          icon={<RXIcon name={icon} color={COLOR.Blue_700} />}
          onClose={() => setActiveSaveSnack('none')}
          sx={{
            height: '48px',
            width: '344px',
            bgcolor: COLOR.White,
            alignItems: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Body2Text>{text}</Body2Text>
        </Alert>
      </Snackbar>
    );
  }
  if (activeSnack)
    return (
      <Snackbar
        open={activeSnack}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setActiveSnack(false)}
        TransitionComponent={transitionProp}
        key={'campaignSnackBar'}
      >
        <Alert
          icon={<RXIcon name="Close" color={COLOR.Blue_700} />}
          onClose={() => setActiveSnack(false)}
          sx={{
            height: '48px',
            width: '744px',
            bgcolor: COLOR.White,
            alignItems: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Body2Text>
            บัญชี Social media นี้ไม่ได้ทำการเชื่อมต่อกับ Account ของ AnotherDot
          </Body2Text>
        </Alert>
      </Snackbar>
    );
  return <></>;
};

interface LoadingProps {
  isLoading: boolean;
}

const LoadingComponent = (props: LoadingProps) => {
  const { isLoading } = props;
  if (isLoading) return <RLoading containerStyle={{ width: '100%' }} />;
  return <></>;
};

interface Props {
  titleList: {
    title: string;
    haveTotal: boolean;
    icon?: IconName | 'Lemon';
    onClick?: () => void;
  }[];
  fromDeal: boolean;
  filterActive: boolean;
  finishInitialize: boolean;
  setFinishInitialize: (int: boolean) => void;
}

const RecommendList = (props: Props) => {
  const {
    titleList,
    filterActive,
    finishInitialize,
    setFinishInitialize,
    fromDeal
  } = props;

  const { brandSaved, addSavedInflu, removeSavedInflu } = useAccountStore();
  const canAccess = featureAccessStore.canAccess(Feature.INFLUENCER_DIRECTORY);
  const { influencerList, loading, triggerSaveInfluencer, categoryLifestyle } =
    useInfluencerStore();
  const { updateFilter, filters } = useInfluencerListFilterContext();

  const { data, metadata } = influencerList;

  const { count } = metadata;

  const { list: listLoading } = loading;

  const sortedCategory: CategoryType[] = [...categoryLifestyle]
    .filter(cat => cat.category !== 'Other')
    .sort((a, b) => a.index - b.index)
    .map(cat => cat.category as CategoryType);

  const [headerTitleList, setHeaderTitleList] = useState<
    { title: string; total: number; icon?: IconName | 'Lemon' }[]
  >([]);
  const [activeSnack, setActiveSnack] = useState<boolean>(false);
  const [activeSaveSnack, setActiveSaveSnack] = useState<
    'success' | 'remove' | 'none'
  >('none');

  const scrollViewRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    let tempTitleList = [...headerTitleList];
    tempTitleList = titleList.map(t => {
      return {
        title: t.title,
        total: t.haveTotal ? count : 0,
        icon: t.icon,
        onClick: t.onClick
      };
    });
    setHeaderTitleList(tempTitleList);
  }, [titleList.length, count]);

  useEffect(() => {
    if (finishInitialize === false && data.length > 0)
      setFinishInitialize(true);
  }, [data.length]);

  return (
    <Container>
      <RecommendHeader
        titleList={headerTitleList}
        filterActive={filterActive}
        fromDeal={fromDeal}
        platform={filters.platform}
      />
      <CategoryFilter
        categoryList={sortedCategory}
        activeCategory={filters.categoryActive}
        setActiveCategory={(att: CategoryType | undefined) => {
          updateFilter('categoryActive', att);
        }}
        fromDeal={fromDeal}
        activeAttributes={filters.attributesActiveList}
        setActiveAttributes={(att: string[]) => {
          updateFilter('attributesActiveList', att);
        }}
      />
      <LifestyleFilter
        lifestyleList={getAttributeList({
          activeCategory: filters.categoryActive,
          categoryLifestyle,
          platformActive: filters.platform
        })}
        activeList={filters.attributesActiveList}
        setActiveList={(att: string[]) => {
          updateFilter('attributesActiveList', att);
        }}
        fromDeal={fromDeal}
      />
      {canAccess ? (
        <>
          <InfluencerList
            data={data}
            listLoading={listLoading}
            brandSaved={brandSaved}
            addSavedInflu={addSavedInflu}
            removeSavedInflu={removeSavedInflu}
            triggerSaveInfluencer={triggerSaveInfluencer}
            finishInitialize={finishInitialize}
            activeAttributes={filters.attributesActiveList}
            scrollViewRef={scrollViewRef}
          />
          <LoadingComponent isLoading={listLoading} />
          <SnackBarComponent
            activeSaveSnack={activeSaveSnack}
            setActiveSaveSnack={setActiveSaveSnack}
            activeSnack={activeSnack}
            setActiveSnack={setActiveSnack}
          />
        </>
      ) : (
        <BlurredContainer>
          <MockedInfluencerList />
        </BlurredContainer>
      )}
    </Container>
  );
};

export default observer(RecommendList);

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfluencerListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const EmptyStateImage = styled.img`
  width: 300px;
  height: 225px;
`;

const Body2Text = styled(ANDTEXT.body2)``;
