import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';

interface Props {
  width: number;
  height: number;
  onClick: (image: File) => Promise<void>;
  adjustTop?: boolean;
}
interface ContainerProps {
  width: number;
  height: number;
  adjustTop?: boolean;
}

function ChangeProfileImageOverlay(props: Props) {
  const { onClick, width, height, adjustTop } = props;
  const onChangeInput = async (image: File) => {
    // data for submit
    if (image && image.type.match('image')) {
      onClick(image);
    }
  };

  return (
    <OverlayContainer width={width} height={height} adjustTop={adjustTop}>
      <ImageInput
        type="file"
        name="myImage"
        accept="image/*"
        onChange={event => {
          if (event.target.files) {
            onChangeInput(event.target.files[0]);
          }
        }}
      />
      <RXIcon name="Camera" color={COLOR.WHITE} />
      <ViewText>Change</ViewText>
    </OverlayContainer>
  );
}

export default ChangeProfileImageOverlay;

const OverlayContainer = styled.label<ContainerProps>`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => props.width + 'px'};
  height: ${props => props.height + 'px'};
  opacity: 0;
  background: ${COLOR.BLACK};
  margin: 3px;
  border-radius: 50%;
  transition: 0.3s ease;
  top: ${props => (props.adjustTop ? '0px' : 'auto')};
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
  :focus {
    opacity: 0;
  }
`;

const ViewText = styled(TEXT.body2BOLD)`
  color: ${COLOR.WHITE};
`;

const ImageInput = styled.input`
  display: none;
`;
