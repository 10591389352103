import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ChatBanners } from '../../../types/chatMessage';
import { toDateDue } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';
import RButton from '../RButton';
import ChatInfluencerDetail from './ChatInfluencerDetail';

interface Props {
  dealContactId: string;
  banners: Record<string, ChatBanners>;
  dealId: string;
  submissionDate: Date;
}

function ChatBanner(props: Props) {
  const { dealContactId, banners, dealId } = props;

  const {
    avatar,
    cash,
    negotiation,
    privateInfo,
    status,
    statusText,
    submissionDate,
    username,
    works,
    isChatConfirm
  } = banners[dealContactId] || {};

  const [isDetailOpen, setIsDetailOpen] = useState<Boolean>(false);
  const navigate = useNavigate();

  return (
    <Container style={{ height: isDetailOpen ? '423px' : '96px' }}>
      <BannerContainer>
        <UserContainer>
          <Image
            src={
              avatar ||
              'https://storage.googleapis.com/rabbitx/1643365400858512GoogleIcon.png'
            }
            style={{
              width: 56,
              height: 56,
              borderRadius: 56
              // border: `3px solid ${COLOR.GREEN}`,
            }}
          />
          <ColumnTextContainer>
            <UserText>{username || 'Pickle'}</UserText>
            <LastMessageTimestamp>
              ส่งงานได้ถึง {toDateDue(submissionDate)}
            </LastMessageTimestamp>
          </ColumnTextContainer>
        </UserContainer>
        <ButtonContainer
          onClick={() => setIsDetailOpen(!isDetailOpen)}
          style={{
            backgroundColor: isDetailOpen
              ? `${COLOR.Blue_50}`
              : `${COLOR.WHITE}`
          }}
        >
          <IconButtonContainer>
            <RXIcon name="Parcel" color={COLOR.Blue_700} />
            <VerticalDashedLine />
            <RXIcon name="Card" color={COLOR.Blue_700} />
          </IconButtonContainer>
          {isDetailOpen ? (
            <RXIcon name="ArrowUp" color={COLOR.Blue_700} />
          ) : (
            <RXIcon name="ArrowDown" color={COLOR.Blue_700} />
          )}
        </ButtonContainer>
      </BannerContainer>
      {isDetailOpen && (
        <ChatInfluencerDetail
          detail={privateInfo}
          cash={cash}
          isChatConfirm={isChatConfirm}
        />
      )}
      <RowContainer
        style={{
          width: '600px',
          display: 'flex',
          justifyContent: 'space-evenly',
          paddingTop: '20px'
        }}
      >
        {isDetailOpen && (
          <RButton
            title="ดูโปรไฟล์"
            onPress={() => {
              navigate(
                `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&status=${status}&page=profile`
              );
            }}
            icon="People"
            iconLocation="lefticon"
            textColor={COLOR.Blue_700}
            backgroundColor={COLOR.WHITE}
            hoverColor={COLOR.Blue_50}
          />
        )}
        {isDetailOpen && (
          <RButton
            title="ตรวจงาน"
            onPress={() => {
              navigate(
                `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&status=${status}&page=work`
              );
            }}
            icon="File"
            iconLocation="lefticon"
          />
        )}
      </RowContainer>
    </Container>
  );
}

export default ChatBanner;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 96px;
  background: ${COLOR.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-self: center;
  align-items: center;
  z-index: 100;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 124px;
  height: 48px;
  background: ${COLOR.WHITE};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  align-items: center;
  justify-content: space-evenly;
`;

const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;

const ColumnTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const UserText = styled(TEXT.buttonBOLD)``;

const ButtonTextBold = styled(TEXT.buttonBOLD)`
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const LastMessageTimestamp = styled(TEXT.body2)`
  color: ${COLOR.Gray_M};
`;

const VerticalDashedLine = styled.div`
  border-left: 1px dashed ${COLOR.Blue_700};
  height: 18px;
`;

const ReviewWorkButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 26px;
  gap: 10px;
  width: 112px;
  height: 40px;
  background: #00d0aa;
  border-radius: 50px;
  border-width: 0px;

  :hover {
    cursor: pointer;
  }
`;
