import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { InfluencerPost } from '../../../types/api/getInfluencer';
import { PlatformType } from '../../../types/deal';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../../utils/styling/general';
import Post from '../../atoms/Post';
import RDraggableWithArrowsComponent from '../../atoms/RDraggableWithArrowsComponent';
import REmptyState from '../../atoms/REmptyState';

const PostListComponent = (props: Props) => {
  const { postList, platformActive } = props;

  const postCount = 6;
  const components: JSX.Element[] = [];

  postList
    .slice(0, postCount)
    .map(post =>
      components.push(<Post work={post} platformActive={platformActive} />)
    );

  return <ImagesContainer>{components}</ImagesContainer>;
};

const PostListBodyComponent = (props: Props) => {
  const { postList } = props;

  if (postList.length === 0)
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={{ width: '100%', alignSelf: 'center' }}
      />
    );
  return (
    <RDraggableWithArrowsComponent
      leftArrowComponent={
        <ArrowContainer style={{ left: -16 }}>
          <RXIcon name="ArrowShortLeft" color={COLOR.White} />
        </ArrowContainer>
      }
      rightArrowComponent={
        <ArrowContainer style={{ right: -16 }}>
          <RXIcon name="ArrowShortRight" color={COLOR.White} />
        </ArrowContainer>
      }
    >
      <PostListComponent {...props} />
    </RDraggableWithArrowsComponent>
  );
};
interface Props {
  platformActive: PlatformType;
  postList: InfluencerPost[];
}

const PostList = (props: Props) => {
  return (
    <Container>
      <GapRowContainer>
        <BoldH6Text>โพสต์ Engagement สูงสุด</BoldH6Text>
        <GrayH8Text>(ข้อมูลย้อนหลัง 90 วัน)</GrayH8Text>
      </GapRowContainer>
      <PostListBodyComponent {...props} />
    </Container>
  );
};

export default PostList;

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  margin-top: 36px;
  gap: 24px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const ImagesContainer = styled(RowContainer)`
  gap: 12px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${COLOR.Black};
  top: 194px;
  z-index: 9999;
  opacity: 0.6;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const GrayH8Text = styled(TEXT.H8_Reg)`
  color: ${COLOR.Gray_D};
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;
