import React from 'react';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { useInfluencerListFilterContext } from '../InfluencerListContext';

interface Props {
  onSubmit: () => void;
}

const FilterFooter = (props: Props) => {
  const { onSubmit } = props;
  const { clearSideBarFilter, closeFilterPanel } =
    useInfluencerListFilterContext();
  return (
    <Container>
      <ClearButtonContainer
        onClick={() => {
          clearSideBarFilter();
          closeFilterPanel();
        }}
      >
        <GrayMBu2Text>ล้างทั้งหมด</GrayMBu2Text>
      </ClearButtonContainer>
      <SubmitButtonContainer onClick={onSubmit}>
        <WhiteBoldBu2Text>แสดงผล</WhiteBoldBu2Text>
      </SubmitButtonContainer>
    </Container>
  );
};

export default FilterFooter;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  padding: 20px 20px 20px 0px;
  margin-left: -20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 50px;
  background-color: ${COLOR.White};
  z-index: 9999;
  width: 352px;
  height: 80px;
`;

const ClearButtonContainer = styled.div`
  display: flex;
  padding: 8px 20px;
  width: 112px;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 180px;
  background: ${COLOR.Blue_700};
  :hover {
    cursor: pointer;
  }
`;

const GrayMBu2Text = styled(TEXT.Bu2_Reg)`
  color: ${COLOR.Gray_M};
`;

const WhiteBoldBu2Text = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.White};
`;
