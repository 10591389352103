import { useState } from 'react';
import styled from 'styled-components';
import REmptyState from '../../../components/atoms/REmptyState';
import RSortIcon from '../../../components/atoms/RSortIcon';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { DealPerformanceTableData, PlatformType } from '../../../types/deal';
import { SortOrderType, TableSortByType } from '../../../types/sort';
import {
  FlexStartColumnContainer,
  RowContainer
} from '../../../utils/styling/general';
import PostTableRow from './PostTableRow';

export const cellWidth: number[] = [19, 11, 8, 11, 16, 12, 16, 7];

interface WidthProps {
  width: number;
}

const defaultTitleContent: { label: string; sortKey: TableSortByType }[] = [
  { label: 'Influencer info', sortKey: 'username' },
  { label: 'สถานะ', sortKey: 'status' },
  { label: 'Budget', sortKey: 'budget' },
  { label: 'รูปแบบโพสต์', sortKey: 'totalPosts' },
  { label: 'Engagement (CPE)', sortKey: 'totalEngagement' },
  { label: 'Reach (CPR)', sortKey: 'totalReach' },
  { label: 'Impression (CPM)', sortKey: 'totalImpression' },
  { label: '', sortKey: '' }
];

const TikToktitleContent: { label: string; sortKey: TableSortByType }[] = [
  { label: 'Influencer info', sortKey: 'username' },
  { label: 'สถานะ', sortKey: 'status' },
  { label: 'Budget', sortKey: 'budget' },
  { label: 'รูปแบบโพสต์', sortKey: 'totalPosts' },
  { label: 'Engagement (CPE)', sortKey: 'totalEngagement' },
  { label: 'View (CPM)', sortKey: 'totalImpression' },
  { label: '', sortKey: '' }
];

interface SortDataProps {
  tableData: DealPerformanceTableData[];
  sortBy: TableSortByType;
  sortOrder: SortOrderType;
}

const sortData = (props: SortDataProps) => {
  const { tableData, sortBy, sortOrder } = props;

  if (sortBy === '' || sortOrder === 'none') return tableData;

  const tempData = [...tableData];

  tempData.sort((a: DealPerformanceTableData, b: DealPerformanceTableData) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      if (sortOrder === 'asc') return aValue.localeCompare(bValue);
      if (sortOrder === 'desc') return bValue.localeCompare(aValue);
    }
    return 0;
  });

  return tempData;
};

interface SortFunctionProps {
  option: TableSortByType;
  sortBy: TableSortByType;
  sortOrder: SortOrderType;
  setSortBy: (sort: TableSortByType) => void;
  setSortOrder: (sort: SortOrderType) => void;
}

const onSortHandler = (props: SortFunctionProps) => {
  const { option, sortBy, sortOrder, setSortBy, setSortOrder } = props;
  if (sortBy !== option) {
    setSortBy(option);
    setSortOrder('desc');
  } else if (sortOrder === 'none') setSortOrder('desc');
  else if (sortOrder === 'desc') setSortOrder('asc');
  else setSortOrder('none');
  setSortBy(option);
};
interface TitleProps {
  titleContent: {
    label: string;
    sortKey: TableSortByType;
  }[];
  sortBy: TableSortByType;
  sortOrder: SortOrderType;
  setSortBy: React.Dispatch<React.SetStateAction<TableSortByType>>;
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrderType>>;
}

const TitleComponent = (props: TitleProps) => {
  const { titleContent, sortBy, sortOrder } = props;
  const components: JSX.Element[] = [];
  titleContent.map((title, index) =>
    components.push(
      <CellContainer width={cellWidth[index]} key={title.label}>
        <TitleContainer>
          <GrayDH8Text>{title.label}</GrayDH8Text>
          {!!title.sortKey && (
            <RSortIcon
              value={title.sortKey}
              sortBy={sortBy}
              sortOrder={sortOrder}
              onClick={() => onSortHandler({ ...props, option: title.sortKey })}
            />
          )}
        </TitleContainer>
      </CellContainer>
    )
  );
  return <TitleListContainer>{components}</TitleListContainer>;
};

interface TableContentProps {
  tableData: DealPerformanceTableData[];
  isTikTok: boolean;
}

const TableContentComponent = (props: TableContentProps) => {
  const { tableData, isTikTok } = props;

  if (tableData.length === 0) {
    const emptyStyle = {
      width: '66%',
      alignSelf: 'center',
      marginTop: 24,
      marginBottom: 24
    };
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/EmptyData.png')}
          />
        }
        header="ไม่มีข้อมูล"
        containerStyle={emptyStyle}
      />
    );
  }

  const components: JSX.Element[] = [];
  tableData.map(data => {
    components.push(
      <PostTableRow data={data} key={data.username} isTikTok={isTikTok} />
    );
  });
  return <TableDataListContainer>{components}</TableDataListContainer>;
};

interface Props {
  tableData: DealPerformanceTableData[];
  platform: PlatformType;
}

const PostTable = (props: Props) => {
  const { tableData, platform } = props;
  const [sortBy, setSortBy] = useState<TableSortByType>('');
  const [sortOrder, setSortOrder] = useState<SortOrderType>('none');

  const sortedTableData = sortData({ tableData, sortBy, sortOrder });

  const isTikTok = platform === 'TIKTOK';
  const titleContent = isTikTok ? TikToktitleContent : defaultTitleContent;
  return (
    <Container>
      <TitleComponent
        titleContent={titleContent}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
      <TableContentComponent tableData={sortedTableData} isTikTok={isTikTok} />
    </Container>
  );
};

export default PostTable;

const Container = styled(FlexStartColumnContainer)`
  gap: 16px;
  width: 100%;
`;

const TitleListContainer = styled(RowContainer)`
  height: 56px;
  border-radius: 8px;
  background: ${COLOR.Gray_50};
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
`;

const TableDataListContainer = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 8px;
`;

const TitleContainer = styled(RowContainer)`
  gap: 4px;
`;

const CellContainer = styled.div<WidthProps>`
  width: ${props => props.width}%;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;

const GrayDH8Text = styled(TEXT.H8_Reg)`
  color: ${COLOR.Gray_D};
`;
