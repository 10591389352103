import { observer } from 'mobx-react';
import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import { COLOR } from '../../../../fonts/color';
import { ActiveProps } from '../../../../types';
import { ITierType, tierType } from '../../../../types/createDeal';
import { numberWithCommas, toCapitalize } from '../../../../utils';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import ANDSwitch from '../../../atoms/ANDSwitch';
import RCheckbox from '../../../atoms/RCheckbox';
import RTextarea from '../../../atoms/RTextarea';
import RSmallInput from '../../../atoms/RSmallInput';

interface Props {
  benefits: string;
  setBenefits: (benefits: string) => void;
  returnProduct: boolean;
  setReturnProduct: (mustReturnProduct: boolean) => void;
  value: number;
  setValue: (value: number) => void;
  isValue: boolean;
  setIsValue: (isValue: boolean) => void;
  isCash: boolean;
  setIsCash: (isCash: boolean) => void;
  influencersTier: ITierType;
  setInfluLevel: (level: ITierType) => void;
  isNegotiateCash: boolean;
  setIsNegotiateCash: (isNegotiateCash: boolean) => void;
  platform: string;
  triggerHeightChange: boolean;
  setTriggerHeightChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export const influLevelDetail = {
  nano: 'ผู้ติดตาม 3,000 - 10,000',
  micro: 'ผู้ติดตาม 10,000 - 50,000',
  mid: 'ผู้ติดตาม 50,000 - 300,000',
  macro: 'ผู้ติดตาม 300,000 ขึ้นไป'
};

function GetTierRewardPlaceHolder(tier: tierType) {
  switch (tier) {
    case 'nano':
      return 'ขั้นต่ำ 300';
    case 'micro':
      return 'ขั้นต่ำ 500';
    case 'mid':
      return 'ขั้นต่ำ 1,000';
    case 'macro':
      return 'ขั้นต่ำ 5,000';
  }
}

function InfluencerReward(props: Props) {
  const {
    benefits,
    setBenefits,
    returnProduct,
    setReturnProduct,
    isValue,
    setIsValue,
    value,
    setValue,
    isCash,
    setIsCash,
    influencersTier,
    setInfluLevel,
    isNegotiateCash,
    setIsNegotiateCash,
    platform,
    triggerHeightChange,
    setTriggerHeightChange
  } = props;

  const [summaryActive, setSummaryActive] = useState<boolean>(true);

  const totalCash =
    influencersTier.nano.cash * influencersTier.nano.value +
    influencersTier.micro.cash * influencersTier.micro.value +
    influencersTier.mid.cash * influencersTier.mid.value +
    influencersTier.macro.cash * influencersTier.macro.value;

  const totalInfluencer =
    influencersTier.nano.value +
    influencersTier.micro.value +
    influencersTier.macro.value +
    influencersTier.mid.value;

  const totalCashValue = value * totalInfluencer + totalCash;

  const warningAcitve = totalInfluencer === 0;

  const ValueComponent = () => {
    if (isValue)
      return (
        <>
          <RTextarea
            inputProp={{
              defaultValue: benefits,
              placeholder: `1. ครีมกันแดด XXX จำนวน 2 ชิ้น \n2. มอยส์เจอร์ไรซ์เซอร์ (สำหรับเทส) 5 ชิ้น`,
              style: {
                display: 'flex',
                width: 568,
                height: 180,
                paddingTop: 16,
                paddingBottom: 16,
                paddingRight: 24,
                paddingLeft: 24,
                gap: 10,
                borderRadius: 16,
                border: `1px solid ${COLOR.Gray_400}`,
                background: `${ANDCOLOR.WHITE}`
              },
              onBlur: e => {
                setBenefits(e.target.value);
              }
            }}
            containerStyle={{ marginTop: '6px' }}
          />
          <SpaceBetweenRowContainer>
            <ContentContainer style={{ gap: 8 }}>
              <BoldHeader8Text>
                มูลค่ารวมของสินค้าที่จะได้รับต่อคน
              </BoldHeader8Text>
              <GapRowContainer>
                <RCheckbox
                  active={returnProduct}
                  onClick={() => setReturnProduct(!returnProduct)}
                  activeColor={ANDCOLOR.BLUE}
                />
                <Body3Text>ต้องการสินค้าคืนหลังจบงาน</Body3Text>
              </GapRowContainer>
            </ContentContainer>
            <RSmallInput
              inputProp={{
                defaultValue: value || '',
                placeholder: 'ขั้นต่ำ 300 ฿ / คน',
                style: {
                  display: 'flex',
                  width: 208,
                  height: 48,
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingRight: 48,
                  paddingLeft: 24,
                  marginBottom: 24,
                  gap: 10,
                  borderRadius: 24,
                  border: `1px solid ${COLOR.Gray_400}`,
                  background: `${COLOR.White}`,
                  textAlign: 'end'
                },
                onKeyPress: e => {
                  if (!/[0-9]|\./.test(e.key)) e.preventDefault();
                },
                onBlur: e => {
                  setValue(parseInt(e.target.value || '0'));
                },
                maxLength: 7
              }}
              containerStyle={{ marginTop: '6px' }}
              unit={'฿'}
            />
          </SpaceBetweenRowContainer>
          {/* <GrayBody3Text>
            Tip : หากมูลค่าของสินค้าเกิน 20,000 บาท ไม่จำเป็นต้องเพิ่มค่าตอบแทน
          </GrayBody3Text> */}
        </>
      );
    return <></>;
  };

  const CashLevelComponent = ({ level }: { level: tierType }) => {
    if (influencersTier[level].value > 0)
      return (
        <SpaceBetweenRowContainer>
          <ContentContainer>
            <BoldHeader8Text>{toCapitalize(level)}</BoldHeader8Text>
            <GrayBody3Text style={{ marginBottom: 20 }}>
              {influLevelDetail[level]}
            </GrayBody3Text>
          </ContentContainer>
          <RSmallInput
            inputProp={{
              defaultValue: influencersTier[level].cash || '',
              placeholder: `${GetTierRewardPlaceHolder(level)} ฿ / คน`,
              style: {
                display: 'flex',
                width: 208,
                height: 48,
                paddingTop: 12,
                paddingBottom: 12,
                paddingRight: 48,
                paddingLeft: 24,
                gap: 10,
                marginBottom: 0,
                borderRadius: 24,
                border: `1px solid ${COLOR.Gray_400}`,
                background: `${COLOR.White}`,
                textAlign: 'end'
              },
              onKeyPress: e => {
                if (!/[0-9]|\./.test(e.key)) e.preventDefault();
              },
              onBlur: e => {
                const tempLevel = { ...influencersTier };
                tempLevel[level].cash = parseInt(e.target.value || '0');
                setInfluLevel(tempLevel);
              },
              maxLength: 7
            }}
            unit={'฿'}
          />
        </SpaceBetweenRowContainer>
      );
    return <></>;
  };

  const CashComponent = () => {
    if (isCash) {
      if (warningAcitve)
        return <PinkB3Text>*คุณยังไม่ได้เลือกจำนวน Influencers</PinkB3Text>;
      return (
        <>
          <CashLevelComponent level="nano" />
          <CashLevelComponent level="micro" />
          <CashLevelComponent level="mid" />
          <CashLevelComponent level="macro" />
          {/* Remove it for now. Have problem with some brand when deal is negotiable */}
          {/* <GapRowContainer>
            <RCheckbox
              active={isNegotiateCash}
              onClick={() => setIsNegotiateCash(!isNegotiateCash)}
              activeColor={ANDCOLOR.BLUE}
            />
            <Body3Text>ต่อรองค่าตอบแทนได้</Body3Text>
          </GapRowContainer> */}
        </>
      );
    }
    return <></>;
  };

  const SummaryComponent = () => {
    if ((isCash && !warningAcitve) || isValue) {
      return (
        <SummaryContainer>
          <SummaryHeaderContainer
            active={summaryActive}
            onClick={() => setSummaryActive(!summaryActive)}
          >
            <BlueBoldHeader7Text>
              สรุปมูลค่าผลิตภัณฑ์และค่าตอบแทน
            </BlueBoldHeader7Text>
            <GapRowContainer style={{ marginBottom: 0 }}>
              <BlueBoldHeader7Text>
                {numberWithCommas(totalCashValue)} บาท
              </BlueBoldHeader7Text>
              <RXIcon
                name={summaryActive ? 'ArrowUp' : 'ArrowDown'}
                color={ANDCOLOR.BLUE}
              />
            </GapRowContainer>
          </SummaryHeaderContainer>
          <SummaryBodyComponent />
        </SummaryContainer>
      );
    }
    return <></>;
  };

  const SummaryBodyComponent = () => {
    if (summaryActive)
      return (
        <SummaryBodyContainer>
          <SummaryContextContainer>
            <BlueBoldHeader7Text>
              มูลค่าสินค้า / บริการ / โปรโมชัน
            </BlueBoldHeader7Text>
            <BlueBoldHeader7Text>
              {numberWithCommas(value * totalInfluencer)} บาท
            </BlueBoldHeader7Text>
          </SummaryContextContainer>
          <SummaryContextContainer>
            <MustReturnComponent />
          </SummaryContextContainer>
          <SubSummaryContextContainer>
            <TotalValueComponent />
          </SubSummaryContextContainer>
          <SummaryContextContainer>
            <BlueBoldHeader7Text>ค่าตอบแทนทั้งหมด</BlueBoldHeader7Text>
            <BlueBoldHeader7Text>
              {numberWithCommas(totalCash)} บาท
            </BlueBoldHeader7Text>
          </SummaryContextContainer>
          <SummaryContextContainer>
            {/* Remove it for now. Have problem with some brand when deal is negotiable */}
            {/* <NegotiateComponent /> */}
          </SummaryContextContainer>
          <SubSummaryContextContainer>
            <TierComponent tier="nano" />
          </SubSummaryContextContainer>
          <SubSummaryContextContainer>
            <TierComponent tier="micro" />
          </SubSummaryContextContainer>
          <SubSummaryContextContainer>
            <TierComponent tier="mid" />
          </SubSummaryContextContainer>
          <SubSummaryContextContainer>
            <TierComponent tier="macro" />
          </SubSummaryContextContainer>
        </SummaryBodyContainer>
      );
    return <></>;
  };

  const MustReturnComponent = () => {
    if (returnProduct)
      return (
        <GapRowContainer>
          <RXIcon name="Check" color={ANDCOLOR.BLUE} size={16} />
          <LightBlueHeader8Text>
            ต้องการของสินค้าคืนหลังจบแคมเปญ
          </LightBlueHeader8Text>
        </GapRowContainer>
      );
    return <></>;
  };

  const TotalValueComponent = () => {
    if (totalInfluencer > 0)
      return (
        <>
          <GapRowContainer style={{ gap: 2, marginLeft: 18, marginBottom: 0 }}>
            <BoldLightBlueHeader6Text>・</BoldLightBlueHeader6Text>
            <LightBlueHeader8TextOverflow>
              {`${benefits || 'จำนวนสินค้า'}`}
            </LightBlueHeader8TextOverflow>
            <LightBlueHeader8Text>
              {' '}
              {` จำนวน ${totalInfluencer} ชิ้น`}
            </LightBlueHeader8Text>
          </GapRowContainer>
          <LightBlueHeader8Text>
            {numberWithCommas(value * totalInfluencer)} บาท
          </LightBlueHeader8Text>
        </>
      );
    return <></>;
  };

  const TierComponent = ({ tier }: { tier: tierType }) => {
    const { value: tierValue, cash: tierCash } = influencersTier[tier];
    if (tierValue > 0)
      return (
        <>
          <GapRowContainer style={{ gap: 2, marginLeft: 18, marginBottom: 0 }}>
            <BoldLightBlueHeader6Text>・</BoldLightBlueHeader6Text>
            <LightBlueHeader8Text>
              {toCapitalize(tier) + ' จำนวน ' + tierValue + ' คน'}
            </LightBlueHeader8Text>
          </GapRowContainer>
          <LightBlueHeader8Text>
            {numberWithCommas(tierCash * tierValue)} บาท
          </LightBlueHeader8Text>
        </>
      );
    return <></>;
  };

  const NegotiateComponent = () => {
    if (isNegotiateCash)
      return (
        <GapRowContainer>
          <RXIcon name="Check" color={ANDCOLOR.BLUE} size={16} />
          <LightBlueHeader8Text>ต่อรองค่าตอบแทนได้</LightBlueHeader8Text>
        </GapRowContainer>
      );
    return <></>;
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>ผลิตภัณฑ์ /</BoldHeader7Text>
        <BoldHeader7Text>ค่าตอบแทน</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <SpaceBetweenRowContainer style={{ width: 569 }}>
          <ContentContainer>
            <GapRowContainer>
              <GrayBody3Text>
                ระบุรายละเอียดและเงื่อนไขให้{' '}
                <UnderlineBody3Text>ชัดเจน</UnderlineBody3Text>
                <RedHeader7Text>*</RedHeader7Text>
              </GrayBody3Text>
            </GapRowContainer>
          </ContentContainer>
          <ANDSwitch
            isActive={isValue}
            onChange={() => {
              setIsValue(!isValue);
              setTriggerHeightChange(!triggerHeightChange);
            }}
          />
        </SpaceBetweenRowContainer>
        <ValueComponent />
        <SubContentContainer>
          <SpaceBetweenRowContainer>
            <ContentContainer>
              <GrayBody3Text>
                <UnderlineBody3Text>ค่าตอบแทน</UnderlineBody3Text> ตามระดับของ
                Influencer <RedHeader7Text>*</RedHeader7Text>
              </GrayBody3Text>
            </ContentContainer>
            <ANDSwitch
              isActive={isCash}
              onChange={() => {
                setIsCash(!isCash);
                setTriggerHeightChange(!triggerHeightChange);
              }}
            />
          </SpaceBetweenRowContainer>
          <CashComponent />
          <SummaryComponent />
        </SubContentContainer>
      </ContentContainer>
    </Container>
  );
}

export default observer(InfluencerReward);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const SubContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-top: 36px;
  border-top: 1px solid ${COLOR.Gray_300};
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const SummaryContainer = styled.div`
  margin-top: 26px;
  width: 568px;
`;

const SummaryHeaderContainer = styled(SpaceBetweenRowContainer)<ActiveProps>`
  display: flex;
  padding: 16px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  gap: 16px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: ${props => (props.active ? '0px' : '10px')};
  border-bottom-left-radius: ${props => (props.active ? '0px' : '10px')};
  border: 0.6px solid ${COLOR.Gray_400};
  :hover {
    cursor: pointer;
  }
`;

const SummaryBodyContainer = styled(SpaceBetweenRowContainer)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
  gap: 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 0.6px solid ${COLOR.Gray_400};
  border-top: 0px;
  background-color: ${ANDCOLOR.LIGHTBLUE};
`;

const SummaryContextContainer = styled(SpaceBetweenRowContainer)`
  width: 520px;
`;
const SubSummaryContextContainer = styled(SpaceBetweenRowContainer)`
  width: 520px;
  margin-top: -12px;
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const LightBlueHeader8Text = styled(ANDTEXT.header8)`
  color: ${ANDCOLOR.BLUE400};
`;

const LightBlueHeader8TextOverflow = styled(ANDTEXT.header8)`
  color: ${ANDCOLOR.BLUE400};
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const BoldLightBlueHeader6Text = styled(ANDTEXT.header6BOLD)`
  color: ${ANDCOLOR.BLUE400};
`;

const BlueBoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BlueBoldHeader8Text = styled(ANDTEXT.header8BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const GrayBody3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const Body3Text = styled(ANDTEXT.body3)``;

const UnderlineBody3Text = styled(ANDTEXT.body3BOLD)`
  text-decoration: underline;
`;

const RedHeader7Text = styled(ANDTEXT.header7)`
  width: 88px;
  white-space: nowrap;
  color: ${ANDCOLOR.PINK};
  margin-left: 2px;
`;

const PinkB3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Warning};
  margin-bottom: 24px;
`;
