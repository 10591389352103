import React from 'react';
import { RowContainer } from '../../../utils/styling/general';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { RXIcon } from 'rn-rx-icons';
import { TEXT } from '../../../fonts/text';
import { PlatformType } from '../../../types/deal';
import { toCapitalize } from '../../../utils';

interface ActiveProps {
  active: boolean;
}

interface PlatformProps extends Props {
  pf: PlatformType;
  havePlatform: boolean;
}

const PlatformComponent = (props: PlatformProps) => {
  const { platformActive, setPlatformActive, pf, havePlatform } = props;

  if (!havePlatform) return <></>;

  const active = platformActive === pf;
  switch (pf) {
    case 'INSTAGRAM':
      return (
        <PlatformContainer onClick={() => setPlatformActive(pf)}>
          <PlatformBorderActive active={active} />
          <InstagramContainer active={active}>
            <RXIcon
              name="Instagram"
              color={active ? COLOR.White : COLOR.Gray_M}
              size={20}
            />
          </InstagramContainer>
          <Bu2Text active={active}>{toCapitalize(pf)}</Bu2Text>
        </PlatformContainer>
      );
    case 'TIKTOK':
      return (
        <PlatformContainer onClick={() => setPlatformActive(pf)}>
          <PlatformBorderActive active={active} />
          <TiktokContainer active={active}>
            <RXIcon name="Tiktok" color={active ? COLOR.White : COLOR.Gray_M} />
          </TiktokContainer>
          <Bu2Text active={active}>{'TikTok'}</Bu2Text>
        </PlatformContainer>
      );
    case 'FACEBOOK':
      return (
        <PlatformContainer onClick={() => setPlatformActive(pf)}>
          <PlatformBorderActive active={active} />
          <FacebookContainer active={active}>
            <RXIcon
              name="FacebookFill"
              color={active ? COLOR.Blue_700 : COLOR.Gray_M}
            />
          </FacebookContainer>
          <Bu2Text active={active}>{toCapitalize(pf)}</Bu2Text>
        </PlatformContainer>
      );
    default:
      return <></>;
  }
};

interface Props {
  platformActive: PlatformType;
  setPlatformActive: React.Dispatch<React.SetStateAction<PlatformType>>;
  haveIG: boolean;
  haveTK: boolean;
  haveFB: boolean;
}

const PlatformTab = (props: Props) => {
  const { haveFB, haveIG, haveTK } = props;
  return (
    <Container>
      <PlatformComponent pf={'INSTAGRAM'} {...props} havePlatform={haveIG} />
      <PlatformComponent pf={'TIKTOK'} {...props} havePlatform={haveTK} />
      <PlatformComponent pf={'FACEBOOK'} {...props} havePlatform={haveFB} />
    </Container>
  );
};

export default PlatformTab;

const Container = styled(RowContainer)`
  width: 100%;
  gap: 24px;
  border-bottom: 1px solid ${COLOR.Gray_300};
  padding-bottom: 12px;
`;

const PlatformContainer = styled(RowContainer)`
  position: relative;
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;

const PlatformBorderActive = styled.div<ActiveProps>`
  position: absolute;
  bottom: -13px;
  width: 100%;
  height: 3px;
  background-color: ${props => (props.active ? COLOR.Blue_700 : 'none')};
  border-radius: 8px;
`;

const InstagramContainer = styled(RowContainer)<ActiveProps>`
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${props =>
    props.active
      ? 'linear-gradient(225deg, #4f5bd5 0.62%, #962fbf 23.93%,#d62976 48.93%,  #fa7e1e 70.81%,    #feda75 96.33%)border-box;'
      : COLOR.White};
  border-radius: 8px;
`;

const TiktokContainer = styled(RowContainer)<ActiveProps>`
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${props => (props.active ? COLOR.Black : COLOR.White)};
  border-radius: 50px;
`;

const FacebookContainer = styled(RowContainer)<ActiveProps>`
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50px;
`;

const Bu2Text = styled(TEXT.Bu2_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
  font-weight: ${props => (props.active ? 700 : 400)};
`;
