import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { TEXT } from '../../../../fonts/text';
import { COLOR } from '../../../../fonts/color';
import { ActiveProps } from '../../../../types';
import { genderType } from '../../../../types/createDeal';
import {
  ColumnContainer,
  RowContainer
} from '../../../../utils/styling/general';
import { useInfluencerListFilterContext } from '../../InfluencerListContext';

const genderDetail = {
  M: {
    text: 'เพศชาย',
    iconName: 'Man'
  },
  W: {
    text: 'เพศหญิง',
    iconName: 'Woman'
  },
  L: {
    text: 'LGBTQIA+'
  }
};

interface IconProps {
  gender: genderType;
  isActive: boolean;
}

const IconComponent = (props: IconProps) => {
  const { gender, isActive } = props;
  if (gender === 'L')
    return (
      <Image
        src={require('../../../../assets/images/CreateCampaign/lgbtq.png')}
      />
    );
  return (
    <RXIcon
      name={genderDetail[gender].iconName as IconName}
      color={isActive ? ANDCOLOR.BLUE : ANDCOLOR.BLACK}
      size={16}
    />
  );
};

interface GenderProps {
  gender: genderType;
}

const GenderComponent = (props: GenderProps) => {
  const { gender } = props;
  const { filters, updateFilter } = useInfluencerListFilterContext();
  const active = filters.audienceGender.includes(gender);
  return (
    <TagContainer
      active={active}
      onClick={() => {
        let tempGender = [...filters.audienceGender];
        if (active) {
          tempGender.splice(tempGender.indexOf(gender), 1);
        } else {
          tempGender.push(gender);
        }
        updateFilter('audienceGender', tempGender);
      }}
    >
      <GapRowContainer>
        <IconComponent isActive={active} gender={gender} />
        <Bu3Text active={active}>{genderDetail[gender].text}</Bu3Text>
      </GapRowContainer>
    </TagContainer>
  );
};

const AudienceGender = () => {
  return (
    <Container>
      <GrayMB3Text>เพศ</GrayMB3Text>
      <WrapRowContainer>
        <GenderComponent gender="M" />
        <GenderComponent gender="W" />
        <GenderComponent gender="L" />
      </WrapRowContainer>
    </Container>
  );
};

export default AudienceGender;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 12px;
`;

const WrapRowContainer = styled(RowContainer)`
  gap: 8px;
  flex-wrap: wrap;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 2px;
`;

const TagContainer = styled.div<ActiveProps>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.4px solid ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
  background-color: ${props => (props.active ? COLOR.Blue_50 : COLOR.White)};
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;

const Bu3Text = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_D)};
`;
