import { useState } from 'react';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { SortOrderType, TableSortByType } from '../../types/sort';

const themeColor = {
  dark: {
    defaultColor: COLOR.Gray_400,
    activeColor: COLOR.Black,
    hoverColor: COLOR.Gray_D
  }
};

interface ColorDeterminerProps {
  isHover: boolean;
  isActive: boolean;
  theme: 'dark';
}

const colorDeterminer = (props: ColorDeterminerProps): string => {
  const { isActive, isHover, theme = 'dark' } = props;
  const { defaultColor, hoverColor, activeColor } = themeColor[theme];

  if (isActive) return activeColor;
  if (isHover) return hoverColor;
  return defaultColor;
};

interface Props {
  value: TableSortByType;
  sortOrder: SortOrderType;
  sortBy: TableSortByType;
  onClick: () => void;
  theme?: 'dark';
}

const RSortIcon = (props: Props) => {
  const { onClick, theme = 'dark', value, sortOrder, sortBy } = props;
  const [isHover, setIsHover] = useState<boolean>(false);

  const componentActive = value === sortBy;

  const topColor = colorDeterminer({
    isHover,
    isActive: componentActive && sortOrder === 'desc',
    theme
  });
  const bottomColor = colorDeterminer({
    isHover,
    isActive: componentActive && sortOrder === 'asc',
    theme
  });

  return (
    <Container
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
      >
        <path
          d="M7.09825 6.00226H0.901676C0.150691 6.00226 -0.269987 5.19852 0.193985 4.65016L3.2923 0.988355C3.65264 0.562476 4.34732 0.562476 4.70772 0.988355L7.80598 4.65016C8.26998 5.19852 7.84931 6.00226 7.09825 6.00226Z"
          fill={topColor}
        />
        <path
          d="M0.901676 8.66797H7.09825C7.84931 8.66797 8.26998 9.4717 7.80598 10.0201L4.70772 13.6818C4.34732 14.1078 3.65264 14.1078 3.2923 13.6818L0.193985 10.0201C-0.269987 9.4717 0.150691 8.66797 0.901676 8.66797Z"
          fill={bottomColor}
        />
      </svg>
    </Container>
  );
};

export default RSortIcon;

const Container = styled.div`
  margin-left: 4px;
  :hover {
    cursor: pointer;
  }
`;
