import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { Deal } from '../../../../types/deal';
import CampaignOngoingImage from './PendingCampaignImage';
import CampaignOngoingInfo from './PendingCampaignInfo';
import PendingCampaignImage from './PendingCampaignImage';
import PendingCampaignInfo from './PendingCampaignInfo';

interface Props {
  navigate: NavigateFunction;
  deal: Deal;
  drawerStatus: string;
  deleteCampaign: ({ dealId }: { dealId: string }) => Promise<void>;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 672px;
  height: 176px;
  box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
  border-radius: 18px 18px 18px 18px;

  :hover {
    cursor: pointer;
  }
`;

function PendingCampaignCardDetail(props: Props) {
  const { navigate, deal, drawerStatus } = props;
  return (
    <Container
      onClick={() => {
        navigate(`/deal?dealId=${deal.dealId}&status=edit`);
        localStorage.setItem('drawerStatus', drawerStatus);
      }}
    >
      <PendingCampaignImage {...props} />
      <PendingCampaignInfo {...props} />
    </Container>
  );
}

export default observer(PendingCampaignCardDetail);
