import styled from 'styled-components';
import FreeTrialBannerBackground from '../assets/images/FreeTrialBannerBackground.svg';
import AnotherDotLogoTextWhite from '../assets/images/AnotherDotLogoTextWhite.png';
import { FontNotoSansRegular, FontPoppinsRegular } from '../fonts/font';
import { COLOR } from '../fonts/color';

const FreeTrialBanner = () => {
  return (
    <Container>
      <SvgImage />
      <ContentContainer>
        <ParagraphContainer>
          <AnotherDotImg src={AnotherDotLogoTextWhite} alt="Another Dot Logo" />
          <StyledText>
            หากคุณต้องการรับประสบการณ์อย่างเต็ม
            <br /> รูปแบบ สามารถทดลองใช้ฟรีได้ โดยการกดปุ่ม “ทดลองใช้ฟรี”
            เพื่อติดต่อทีมงาน
          </StyledText>
        </ParagraphContainer>
        <Link href="https://bit.ly/4cNLrYP" target="_blank">
          ทดลองใช้ฟรี
        </Link>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  id: 'free-trial-banner';
  width: 694.393px;
  height: 140.073px;
  flex-shrink: 0;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 4.438rem;
  left: calc(
    50% - 347.1965px + 120px
  ); // where 347.1965px = a half width of banner and 120px = a half width of sidebar
  z-index: 9999;
  font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
`;

const SvgImage = styled(FreeTrialBannerBackground)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it's behind all content */
  border-radius: 20px;
  alt="Banner Background";
`;

const ParagraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const ContentContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--radius-radius-full, 64px);
`;

const Link = styled.a`
  display: flex;
  width: 136px;
  height: 48px;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: var(--Neutral-10, #fff);
  color: ${COLOR.Blue_800};
  cursor: pointer;
  text-decoration: inherit;

  /* Desktop/Button/Bu2(Medium)_Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`;

const StyledText = styled.p`
  width: 259px;
  color: rgba(255, 255, 255, 0.8);

  /* Desktop/Body/Reg_B3 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const AnotherDotImg = styled.img`
  width: 133.498px;
  height: var(--radius-radius-full, 64px);
`;

export default FreeTrialBanner;
