import { IGetInfluWorksResponse } from '../../types/api/getInfluWork';
import { objectToQuerystring } from '../../utils';
import { GET, _GET } from '../../utils/Networking';

export type TDealStatus =
  | 'pending'
  | 'counterPending'
  | 'ongoing'
  | 'check'
  | 'done'
  | 'rejected'
  | 'cancelled';

export const listDeals = async ({
  limit = 10,
  offset = 0,
  sortBy = 'createdAt',
  sortOrder = 'desc',
  status
}: {
  limit: number;
  offset: number;
  sortBy: string;
  sortOrder: string;
  status: any;
}) => {
  if (offset < 0) {
    throw new Error('offset is less than 0');
  }
  const queries = { limit, offset, sortBy, sortOrder, status, new: true };
  const qs = objectToQuerystring(queries);
  const { metadata, data } = await GET(`/deals${qs}`);
  return {
    metadata: metadata.length > 0 ? metadata[0] : { count: 0, limit, offset },
    data
  };
};

export const getDeal = ({ dealId }: { dealId: string }) => {
  if (!dealId) {
    throw new Error('dealId is undefined');
  }
  return GET(`/deals/${dealId}`);
};

export const getInfluWorks = async ({
  dealContactId
}: {
  dealContactId: string;
}) => {
  if (!dealContactId) {
    throw new Error('dealContactId is undefined');
  }

  const data = await _GET<IGetInfluWorksResponse[]>(
    `/brands/deals/reviews?dealContacts=["${dealContactId}"]`
  );
  return data[0];
};
