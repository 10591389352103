import { dealKeys } from './../types/createDeal.d';
import { Deal, initateDeal } from './../types/deal.d';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Work } from '../types/work';
import { getErrorMessage } from '../utils/Networking';
import {
  getFeedDealDetail,
  getFeedInfluWork,
  listFeedDeals,
  listFeedImages,
  listDiscoveryWork,
  listFeedExpandWork
} from './logic/feed';

type DescribableDealId = {
  dealId?: string;
};

type Query = {
  limit: number;
  offset: number;
  seed?: number;
};

const initialDealKeys: dealKeys = {
  name: '',
  nameEditted: false,
  photos: [],
  gender: '',
  age: '13-65',
  detail: '',
  detailEditted: false,
  productDetail: '',
  productEditted: false,

  targetInfluencer: '',
  criteria: {
    gender: '',
    ages: '13-65',
    minFollower: '3000',
    selectedIG: false,
    tiktokMinFollower: '3000',
    facebookMinFollower: '3000',
    selectedTK: false,
    selectedLM: false,
    selectedFB: false
  },
  secret: false,
  isSecret: false,

  location: '',

  category: '',

  isCash: false,
  isValue: false,
  benefits: '',
  benefitsEditted: false,
  value: 0,
  valueEditted: false,
  cash: 0,
  cashEditted: false,
  limit: 0,

  isStrict: false,

  isHashtags: false,
  hashtags: [],

  isTiktokHashtags: false,
  tiktokHashtags: [],

  isFacebookHashtags: false,
  facebookHashtags: [],

  isKeyMessage: false,
  keyMessage: '',

  isCaptionGuide: false,
  caption: '',

  isTiktokCaptionGuide: false,
  tiktokCaptionGuide: '',

  isFacebookCaptionGuide: false,
  facebookCaptionGuide: '',

  posts: {
    // IG_individual_post: 3,
    // IG_story: 2,
    IG_individual_post: 0,
    IG_album_post: 0,
    IG_album_images: 0,
    IG_story: 0,
    IG_tv: 0,
    IG_reels: 0,
    TK_video: 0,
    LM_article: 0,
    FB_individual_post: 0,
    FB_album_images: 0,
    FB_album_post: 0,
    FB_reels: 0,

    isExamples: false,
    exampleDetail: '',
    examples: [],

    isTiktokExamples: false,
    tiktokExamples: [],
    tiktokExampleDetail: '',

    isFacebookExamples: false,
    facebookExamples: [],
    facebookExampleDetail: '',
    postMedias: []
  },

  startDate: null,
  endDate: null,

  postingDate: null,
  submissionDate: null,

  createdDate: null,

  brandApprove: false,
  isNegotiateScopeOfWorks: false,
  isNegotiateCash: false,
  isNegotiateProductValue: false,

  isCheckBefore: false,

  version: '1.0'
};
const initialWork: Work = {
  accountId: '',
  isDeleted: false,
  isExist: true,
  caption: '',
  comments_count: 0,
  id: '',
  like_count: 0,
  media_type: 'IMAGE',
  media_url: '',
  thumbnail_url: '',
  owner: {
    id: ''
  },
  permalink: '',
  shortcode: '',
  timestamp: '',
  username: '',
  dealContactId: '',
  dealId: '',
  postType: 'IG_individual_post',
  workId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  reach: 0,
  impressions: 0,
  replies: 0,
  engagement: 0,
  video_views: 0,
  shares: 0,
  postId: '',
  followers_count: 0,
  influencerid: '',
  version: 0,
  influencerId: '',
  platform: 'Instagram',
  share_count: 0,
  title: '',
  video_description: '',
  cover_image_url: '',
  view_count: 0,
  height: 0,
  width: 0,
  share_url: '',
  duration: 0,
  embed_link: '',
  embed_html: '',
  create_time: 0,
  is_comment_enabled: false,
  saved: 0,
  created_time: '',
  full_picture: '',
  message: '',
  permalink_url: '',
  status_type: '',
  from: {
    id: '',
    name: ''
  },
  deletedAt: new Date(),
  previousWork: undefined,
  postCreatedAt: new Date(),
  profile_picture_url: ''
};

export class FeedStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  feedDeals = {
    data: [] as any,
    metadata: { count: 0, limit: 0, offset: 0, seed: Math.random() },
    next: () => {}
  };

  @observable
  feedImages = {
    data: [] as any,
    metadata: [] as any,
    next: () => {}
  };

  @observable
  loading = {
    feedImages: false,
    feedDeals: false,
    feedInfluWork: false,
    feedDealDetail: false,
    feedExpand: false,
    feedDiscover: false
  };

  @observable
  error = {
    feedDeals: false,
    feedDealDetail: null,
    feedImages: null,
    feedInfluWork: null,
    feedExpand: null
  };

  @observable
  feedInfluWork: Work = initialWork;

  @observable
  feedDealDetail: dealKeys = initialDealKeys;

  @observable
  discoveryWork = {
    data: {
      foodCat: [] as any,
      beautyCat: [] as any,
      fashionCat: [] as any
    },
    metadata: { count: 0, offset: 0, limit: 10 }
  };

  @observable
  feedExpandWork = {
    data: [] as any,
    metadata: { count: 0, offset: 0, limit: 10 },
    next: () => {}
  };

  @action
  public setFeedDealsLoading = (loading: boolean) => {
    this.loading.feedDeals = loading;
  };

  @action
  listFeedImages = async ({ offset, url }: { offset: number; url: string }) => {
    try {
      this.loading.feedImages = true;
      const result = await listFeedImages({ offset, url });
      if (offset > 0) {
        const prevData = this.feedImages.data;
        this.feedImages = {
          ...this.feedImages,
          ...result,
          data: [...prevData, ...result.data]
        };
      } else {
        this.feedImages = { ...result, next: () => {} };
      }
      this.feedImages.next = result.metadata.next
        ? () => {
            this.listFeedImages({
              offset: offset + 3,
              url: result.metadata.next
            });
          }
        : () => {};
      return result;
    } catch (error) {
      this.error.feedImages = getErrorMessage(error);
      this.feedImages = {
        data: [],
        metadata: [],
        next: () => {}
      };
    } finally {
      this.loading.feedImages = false;
    }
  };

  @action
  listFeedDeals = async (query: Query) => {
    try {
      this.setFeedDealsLoading(true);
      const result = await listFeedDeals({
        limit: query.limit,
        offset: query.offset,
        seed: query.seed
      });
      if (query.offset >= query.limit) {
        runInAction(() => {
          const prevData = this.feedDeals.data;
          this.feedDeals = {
            ...this.feedDeals,
            ...result,
            data: [...prevData, ...result.data]
          };
        });
      } else {
        runInAction(() => {
          this.feedDeals = { ...result, next: () => {} };
        });
      }
      runInAction(() => {
        this.feedDeals.next = () => {
          this.listFeedDeals({
            limit: query.limit,
            offset: query.offset + query.limit,
            seed: result.metadata.seed
          });
        };
      });

      return result;
    } catch (error) {
      this.error.feedDeals = getErrorMessage(error);
    } finally {
      this.setFeedDealsLoading(false);
    }
  };

  @action
  listDiscoveryImage = async () => {
    this.loading.feedDiscover = true;
    const fetchDiscoveryWork = await listDiscoveryWork();
    this.discoveryWork.data = fetchDiscoveryWork;
    this.loading.feedDiscover = false;
    return;
  };

  @action
  clearFeedExpandWork = () => {
    this.feedExpandWork.data = [];
  };

  @action
  listFeedExpandWork = async ({
    limit,
    offset,
    category,
    filter
  }: {
    limit: number;
    offset: number;
    category: string;
    filter?: { sortBy: string; platforms: string };
  }) => {
    try {
      if (!filter)
        filter = {
          platforms: 'Default',
          sortBy: 'Reach'
        };

      this.loading.feedExpand = true;
      const result = await listFeedExpandWork({
        limit,
        offset,
        category,
        filter
      });

      if (offset >= limit) {
        const prevData = this.feedExpandWork.data;
        this.feedExpandWork = {
          ...this.feedExpandWork,
          ...result,
          data: [...prevData, ...result.data]
        };
      } else {
        this.feedExpandWork = { ...result, next: () => {} };
      }

      this.feedExpandWork.next = () => {
        this.listFeedExpandWork({
          limit,
          offset: offset + limit,
          category: category,
          filter: filter
        });
      };

      return result;
    } catch (error) {
      this.error.feedExpand = getErrorMessage(error);
    } finally {
      this.loading.feedExpand = false;
    }
  };

  @action
  getFeedInfluWork = async ({
    feedId,
    type
  }: {
    feedId: string;
    type: string;
  }) => {
    try {
      this.feedInfluWork = initialWork;
      this.loading.feedInfluWork = true;
      this.error.feedInfluWork = null;
      const feedInfluWork = await getFeedInfluWork({ feedId, type });
      this.feedInfluWork = feedInfluWork;
      return feedInfluWork;
    } catch (error) {
      this.error.feedInfluWork = getErrorMessage(error);
      return error;
    } finally {
      this.loading.feedInfluWork = false;
    }
  };

  @action
  getFeedDealDetail = async (fn: DescribableDealId) => {
    try {
      this.feedDealDetail = initialDealKeys;
      this.loading.feedDealDetail = true;
      this.error.feedDealDetail = null;
      const feedDealDetail = await getFeedDealDetail({ dealId: fn.dealId });
      runInAction(() => {
        this.feedDealDetail = feedDealDetail;
      });
      return feedDealDetail;
    } catch (error) {
      this.error.feedDealDetail = getErrorMessage(error);
      return error;
    } finally {
      runInAction(() => {
        this.loading.feedDealDetail = false;
      });
    }
  };
}

export const feedStore = new FeedStore();
