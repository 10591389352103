import { observer } from 'mobx-react';
import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import shortid from 'shortid';
import styled from 'styled-components';
import { PostMedia } from '../../../../class/ScopeOfWork';
import { COLOR } from '../../../../fonts/color';
import { TEXT } from '../../../../fonts/text';
import { ActiveProps } from '../../../../types';
import { dealKeys } from '../../../../types/createDeal';
import { RowContainer } from '../../../../utils/styling/general';
import Rradiobutton from '../../../atoms/Rradiobutton';
import ANDCheckbox from '../../../atoms/ANDCheckbox';
import { ANDCOLOR } from '../../../../fonts/andColor';
import ANDButton from '../../../atoms/ANDButton';

interface Props {
  setLmPostMedias: (postMedia: PostMedia) => void;
  setAddActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function LMScopeOfWorkSelector(props: Props) {
  const { setLmPostMedias, setAddActive } = props;

  const handleSave = () => {
    const media: PostMedia = {
      id: shortid.generate(),
      image: 0,
      video: 0,
      article: 1,
      postType: 'LM_article'
    };
    setLmPostMedias(media);
  };

  const renderArticle = () => {
    return (
      <ActivePostContainer>
        <ActivePostHeaderContainer>
          <RowContainer>
            <RXIcon name="Article" color={ANDCOLOR.BLUE} />
            <BlueBody1Text>Lemon8 Article</BlueBody1Text>
          </RowContainer>
          <ANDCheckbox active={true} containerStyle={{ borderRadius: 24 }} />
        </ActivePostHeaderContainer>
        <RowContainer
          style={{
            justifyContent: 'space-around',
            marginTop: '16px'
          }}
        >
          <ANDButton
            title="ยกเลิก"
            backgroundColor={ANDCOLOR.WHITE}
            onPress={() => setAddActive(false)}
            containerStyle={{
              minWidth: '0px',
              width: '99px',
              height: '40px'
            }}
            textColor={ANDCOLOR.BLUE}
          />
          <ANDButton
            title="ยืนยัน"
            onPress={handleSave}
            containerStyle={{
              minWidth: '0px',
              width: '99px',
              height: '40px'
            }}
          />
        </RowContainer>
      </ActivePostContainer>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <BoldHeader8Text>
          เลือกรูปแบบของโพสต์ <RedText>*</RedText>
        </BoldHeader8Text>
      </HeaderContainer>
      {renderArticle()}
    </Container>
  );
}

export default observer(LMScopeOfWorkSelector);

const Container = styled.div`
  position: absolute;
  right: 0;
  width: 320px;
  padding: 24px;
  background-color: ${ANDCOLOR.WHITE};
  border: 1px solid ${COLOR.Gray_400};
  z-index: 999;
  border-radius: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostContainer = styled.div<ActiveProps>`
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  padding: 12px 16px 12px 16px;
  margin-top: 16px;
  background: ${props => (props.active ? ANDCOLOR.LIGHTBLUE : ANDCOLOR.WHITE)};
  border-radius: 24px;
  border: ${props => (props.active ? 'none' : `1px solid ${COLOR.Gray_200}`)};

  :hover {
    cursor: pointer;
  }
`;

const ActivePostContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ANDCOLOR.LIGHTBLUE};
  padding: 16px;
  margin-top: 16px;
  border-radius: 24px;
`;

const ActivePostHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ChoiceContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
`;

const Body1Text = styled(TEXT.body1)`
  margin-left: 8px;
`;

const BoldBody1Text = styled(TEXT.body1BOLD)``;

const GrayBody1Text = styled(TEXT.body1)`
  color: ${COLOR.Gray_D};
  margin-left: 8px;
`;

const BlueBody1Text = styled(TEXT.body1)`
  color: ${ANDCOLOR.BLUE};
  margin-left: 4px;
`;

const BlueBody2Text = styled(TEXT.body2)`
  color: ${ANDCOLOR.BLUE};
  margin-left: 25px;
`;

const BoldHeader8Text = styled(TEXT.H8_Bold)``;

const RedText = styled(TEXT.body2)`
  color: ${ANDCOLOR.PINK};
`;
