import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from 'styled-components';
import { scopeOfWorkType } from '../../../class/ScopeOfWork';
import Review from '../../../components/molecules/Influencer/Review';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { IInfluencerReview } from '../../../types/api/getInfluencer';
import { tierType } from '../../../types/createDeal';
import {
  Deal,
  DealPerformance,
  DealPerformanceTableData,
  DealsChartData,
  PlatformType
} from '../../../types/deal';
import { TierWorkData, Work } from '../../../types/work';
import {
  BlurredContainer,
  FlexStartColumnContainer
} from '../../../utils/styling/general';
import { convertInfTierToTierList } from '../function';
import EngagementSummary from './EngagementSummary';
import InfluencerPerformance from './InfluencerPerformance';
import SummaryOverview from './SummaryOverview';
import TierSummary from './TierSummary';
import {
  Feature,
  featureAccessStore
} from '../../../stores/featureAccessStore';
import {
  MockedEngagementSummary,
  MockedInfluencerPerformance,
  MockedOverviewPerformanceChart,
  MockedTierSummary
} from './MockedPerformanceDetials';
import FreeTrialBanner from '../../../reusables/FreeTrialBanner';

interface Props {
  deal: Deal;
  dealPerformance: DealPerformance;
  campaignWorks: Work[];
  dealReviews: {
    reviews: IInfluencerReview[];
    reviewOverall: {
      starCount: number[];
      avgRating: number;
      totalReview: number;
      tagCount: number[];
    };
  };
  reviewLoading: boolean;
  workEachTier: {
    nano: TierWorkData;
    micro: TierWorkData;
    mid: TierWorkData;
    macro: TierWorkData;
  };
  chartData: DealsChartData;
  tableData: DealPerformanceTableData[];
  img: string;
  name: string;
}

const PerformanceDetails = (props: Props) => {
  const {
    deal,
    dealPerformance,
    campaignWorks,
    dealReviews,
    reviewLoading,
    workEachTier,
    tableData,
    chartData,
    img,
    name
  } = props;

  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  const canAccessFeature = featureAccessStore.canAccess(
    Feature.CAMPAIGN_PERFORMANCE
  );

  const { influencersTier, posts, platforms } = deal;
  const { reviewOverall, reviews } = dealReviews;
  const { starCount } = reviewOverall;
  const { postMedias } = posts;
  const platform = (
    platforms?.[0] ?? 'Instagram'
  ).toUpperCase() as PlatformType;

  let postTypes: Record<scopeOfWorkType, number> = {
    IG_individual_post: 0,
    IG_album_post: 0,
    IG_story: 0,
    IG_tv: 0,
    IG_reels: 0,
    TK_video: 0,
    LM_article: 0,
    FB_individual_post: 0,
    FB_album_post: 0,
    FB_reels: 0
  };

  let tierTypes: Record<tierType, number> = {
    nano: 0,
    micro: 0,
    mid: 0,
    macro: 0
  };

  campaignWorks.map(work => {
    postTypes[work.postType] += 1;
  });

  tableData.map(table => {
    tierTypes[table.tier as tierType] += 1;
  });

  const dealPostTypes = postMedias.map(post => post.postType);
  const dealTierTypes = convertInfTierToTierList(influencersTier);
  const { engagement, reach, impressions, cpe, cpr, cpm } = dealPerformance;
  const filteredReviews = reviews
    .map(review => ({ ...review, brand: { img, name } }))
    .filter(review => {
      if (selectedFilter === 0) return true;
      return (
        review.review.rate > selectedFilter - 1 &&
        review.review.rate <= selectedFilter
      );
    });

  return (
    <Container>
      {!canAccessFeature ? <FreeTrialBanner /> : null}
      <FlexStartColumnContainer style={{ marginBottom: 16 }}>
        <BoldH4Text>Campaign performance analysis</BoldH4Text>
        {/* <GrayDB4Text>ข้อมูลล่าสุด (31 มกราคม พ.ศ. 2567)</GrayDB4Text> */}
      </FlexStartColumnContainer>
      <SummaryOverview.Container>
        <SummaryOverview.Header />
        <SummaryOverview.ContentContainer>
          <SummaryOverview.Stats
            engagement={engagement}
            reach={reach}
            impression={impressions}
            cpe={cpe}
            cpr={cpr}
            cpm={cpm}
            platform={platform}
          />
          {canAccessFeature ? (
            <SummaryOverview.PerformanceChart
              chartData={chartData}
              platform={platform}
            />
          ) : (
            <BlurredContainer>
              <MockedOverviewPerformanceChart />
            </BlurredContainer>
          )}
        </SummaryOverview.ContentContainer>
      </SummaryOverview.Container>
      {canAccessFeature ? (
        <>
          <EngagementSummary
            workEachTier={workEachTier}
            dealPerformance={dealPerformance}
            platform={platform}
            dealPostTypes={dealPostTypes}
          />
          <TierSummary
            tierList={influencersTier}
            tierInsight={workEachTier}
            platform={platform}
          />
          <InfluencerPerformance
            campaignWorks={campaignWorks}
            dealPostType={dealPostTypes}
            dealTierType={dealTierTypes}
            tableData={tableData}
            postTypes={postTypes}
            tierTypes={tierTypes}
            platform={platform}
          />
        </>
      ) : (
        <BlurredContainer>
          <MockedEngagementSummary />
          <MockedTierSummary />
          <MockedInfluencerPerformance />
        </BlurredContainer>
      )}

      <Review
        reviews={filteredReviews}
        reviewOverall={reviewOverall}
        starCount={starCount}
        selectedFilter={selectedFilter}
        selectFilter={setSelectedFilter}
        loading={reviewLoading}
        title="Review หลังจบแคมเปญ"
      />
    </Container>
  );
};

export default observer(PerformanceDetails);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BoldH4Text = styled(TEXT.H4_Bold)``;

const GrayDB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;
