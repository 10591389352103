import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { limitText, toDecimalNumber } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';
import ValueDetails from '../../atoms/ValueDetails';

interface Props {
  name: string;
  photos: { url: string; file: File }[] | { url: string }[];
  category: string;
  value: number;
  cash: number;
  criteria: {
    gender: string;
    ages: string;
    minFollower: string;
    selectedIG: boolean | false;
    tiktokMinFollower: string;
    selectedTK: boolean | false;
  };
  checkDetail?: () => void;
  isDraft?: boolean;
  haveBack?: () => void;
  haveEdit?: boolean;
}
function DealCardHeader(props: Props) {
  const {
    name,
    photos,
    category,
    value = '0',
    cash = '0',
    criteria,
    checkDetail,
    isDraft = false,
    haveBack,
    haveEdit = false
  } = props;

  const displayValue = value ? `${value} ฿` : '-';
  const displayCash = cash ? `${cash} ฿` : '-';

  const displayFollower = () => {
    let igFollower = '-';
    let tkFollower = '-';

    if (criteria.minFollower) {
      igFollower = toDecimalNumber({
        number: parseInt(criteria.minFollower),
        toFixed: 0
      });
    }
    if (criteria.tiktokMinFollower) {
      tkFollower = toDecimalNumber({
        number: parseInt(criteria.tiktokMinFollower),
        toFixed: 0
      });
    }
    return `${igFollower} / ${tkFollower}`;
  };

  const isActive = () => {
    if (haveEdit || !haveBack) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <PreviewImage src={photos[0]?.url} />
      {haveBack && (
        <BackContainer onClick={haveBack}>
          <RXIcon name="ArrowShortLeft" color={COLOR.WHITE} />
          <WhiteBoldBu3Text>กลับ</WhiteBoldBu3Text>
        </BackContainer>
      )}
      <PreviewTagContainer active={isDraft}>
        <WhiteBoldH6Text>ตัวอย่างแคมเปญที่จะเผยแพร่</WhiteBoldH6Text>
      </PreviewTagContainer>
      <DetailContainer>
        <WhiteB3Reg>{category}</WhiteB3Reg>
        <NameContainer>
          <WhiteBoldH5Text>{limitText(name, 60)}</WhiteBoldH5Text>
        </NameContainer>
        <RowContainer style={{ justifyContent: 'space-between' }}>
          <ValueDetails
            icon="MoneyFill"
            value={displayValue}
            description="มูลค่าสินค้า"
          />
          <ValueDetails
            icon="BudgetFill"
            value={displayCash}
            description="เงินค่าตอบแทน"
          />
          <ValueDetails
            icon="Follower"
            value={displayFollower()}
            description="Followers IG / TikTok"
          />
          <WidthContainer>
            {!isDraft && (
              <DetailInfoContainer active={isActive()} onClick={checkDetail}>
                <GrayMBoldBu3Text>
                  {haveEdit ? 'แก้ไขรายละเอียดแคมเปญ' : 'รายละเอียดแคมเปญ'}
                </GrayMBoldBu3Text>
              </DetailInfoContainer>
            )}
          </WidthContainer>
        </RowContainer>
      </DetailContainer>
    </Container>
  );
}

export default DealCardHeader;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 745px;
  flex-direction: column;
  justify-content: space-between;
  height: 328px;
  padding: 24px 40px;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.24) 24.03%,
    rgba(137, 137, 137, 0.16) 43.24%,
    rgba(255, 255, 255, 0.16) 52.18%
  );
`;

const PreviewImage = styled.img`
  position: absolute;
  top: 0px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  background: ${COLOR.Gray_300};
  z-index: -1;
`;

const PreviewTagContainer = styled.div<ActiveProps>`
  display: flex;
  width: 672px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background: ${COLOR.Blue_700};
  border-radius: 16px;
  opacity: ${props => (props.active ? 1 : 0)};
`;

const BackContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 28px;
  left: 32px;
  z-index: 999;

  :hover {
    cursor: pointer;
  }
`;

const DetailContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NameContainer = styled.div`
  padding-bottom: 4px;
  margin-top: 6px;
  margin-bottom: 4px;
  max-height: 88px;
  /* white-space: nowrap; */
  word-wrap: break-word;
  color: ${COLOR.WHITE};
`;

const WidthContainer = styled.div`
  display: flex;
  width: 176px;
  justify-content: flex-end;
`;

const DetailInfoContainer = styled.div<ActiveProps>`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  background: ${COLOR.WHITE};
  border-radius: 50px;
  opacity: ${props => (props.active ? 1 : 0)};
  cursor: pointer;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};

  transition: all 0.2s;
  :hover {
    background: ${COLOR.Gray_50};
  }
`;

const WhiteBoldBu3Text = styled(TEXT.Bu3_Bold)`
  color: ${COLOR.WHITE};
`;

const WhiteB3Reg = styled(TEXT.B3_Reg)`
  color: ${COLOR.WHITE};
`;

const GrayMBoldBu3Text = styled(TEXT.Bu3_Bold)`
  color: ${COLOR.Gray_M};
`;

const WhiteBoldH5Text = styled(TEXT.H5_Bold)`
  color: ${COLOR.WHITE};
`;

const WhiteBoldH6Text = styled(TEXT.H6_Bold)`
  color: ${COLOR.WHITE};
`;
