import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { DiscoverPage } from '../../../../pages/DiscoverPage';
import { IGetInfluencerResponse } from '../../../../types/api/getInfluencer';
import { Deal } from '../../../../types/deal';
import { Work } from '../../../../types/work';
import { SpaceBetweenRowContainer } from '../../../../utils/styling/general';
import RLoading from '../../../atoms/RLoading';
import InviteConfirmModal from '../../../molecules/CampaignManagement/InviteConfirmModal';
import InviteModal from '../../../molecules/CampaignManagement/InviteModal';
import DiscoverWorkDetail from '../../../molecules/Discover/Influencer/DiscoverWorkDetail';

interface Props {
  work: Work;
  loading: boolean;
  navigate: NavigateFunction;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  getInfluencerById: ({
    id,
    isForSocial
  }: {
    id: string;
    isForSocial: boolean;
  }) => Promise<IGetInfluencerResponse | undefined>;
  influencer: IGetInfluencerResponse | undefined;
  influLoading: boolean;
  sendOfferInfluencer: (
    accountId: string | null,
    influencerId: string,
    dealId: string,
    savedInflu: boolean
  ) => Promise<any>;
  brandId: string;
  deals: Deal[];
  lastPage: DiscoverPage;
}

const DiscoverWork = (props: Props) => {
  const {
    work,
    loading,
    influLoading,
    getInfluencerById,
    sendOfferInfluencer,
    deals,
    influencer
  } = props;

  const [active, setActive] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [influencerid, setInfluencerid] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    getInfluencerById({ id: work?.influencerid || '', isForSocial: true });
  }, [loading]);

  useEffect(() => {
    setInfluencerid(work.influencerid || '');
  }, [work?.influencerid]);

  const inviteConfirmHandler = () => {
    if (selected.length === 0) {
      return;
    }
    selected.forEach(dealId => {
      //send offer to influ without invite limit increase on deal
      const accountId = influencer?.account.id || null;
      sendOfferInfluencer(accountId, influencerid, dealId, true);
    });

    //close bottom sheet
    setActive(false);

    //open invitedCard modal
    setShowModal(true);
  };

  return loading || influLoading ? (
    <RLoading containerStyle={{ marginTop: 130 }} />
  ) : (
    <Container>
      <DetailContainer>
        <DiscoverWorkDetail
          {...props}
          setActive={setActive}
          setSelected={setSelected}
        />
      </DetailContainer>
      <InviteModal
        active={active}
        setActive={setActive}
        onConfirm={inviteConfirmHandler}
        selected={selected}
        setSelected={setSelected}
        deals={deals}
      />
      <InviteConfirmModal active={showModal} setActive={setShowModal} />
    </Container>
  );
};

export default observer(DiscoverWork);

const Container = styled(SpaceBetweenRowContainer)``;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1200px;
`;
