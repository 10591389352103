import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from '../components/atoms/ScrollToTop';
import WorkModal from '../components/molecules/CampaignManagement/WorkModal';
import { useDealStore } from '../stores/initialize/Context';
import { Deal } from '../types/deal';
import { Work } from '../types/work';
import { useQuery } from '../utils';
import Performance from '../page/CampaignPerformance';

interface GetFirstDealProps {
  campaignList: {
    ongoing: Deal[];
    pending: Deal[];
    done: Deal[];
  };
}

const getFirstDeal = (props: GetFirstDealProps) => {
  const { campaignList } = props;
  if (campaignList.ongoing.length > 0) {
    return campaignList.ongoing[campaignList.ongoing.length - 1].dealId;
  } else if (campaignList.done.length > 0) {
    return campaignList.done[campaignList.done.length - 1].dealId;
  }
};

const PerformancePage = () => {
  const [selectedDeal, setSelectedDeal] = useState<string>('');

  const { campaignList, loading } = useDealStore();
  const { campaignList: listLoading } = loading;
  const firstDeal = getFirstDeal({ campaignList });

  let query = useQuery();
  const dealId = query.get('dealId') as string;

  useEffect(() => {
    if (!dealId && !!firstDeal) setSelectedDeal(firstDeal);
    else setSelectedDeal(dealId);
  }, [dealId, listLoading]);

  // TODO: handle when there is no deal at all
  return (
    <Container>
      <ScrollToTop />
      <Performance selectedDeal={selectedDeal} />
    </Container>
  );
};

export default observer(PerformancePage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 36px 0px 36px;
`;
