import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { ChannelMap } from '../../../stores/webSocketStore';
import { Deal, StatusType } from '../../../types/deal';
import CampaignImage from './CampaignImage';
import CampaignInfo from './CampaignInfo';
import CampaignPerformance from './CampaignPerformance';

interface Props {
  navigate: NavigateFunction;
  deal: Deal;
  status: 'ongoing' | 'pending' | 'done';
  drawerStatus: string;
  activateShareModal: () => void;
  connectWebsocket: ({ channel }: { channel: string }) => void;
  socket: ChannelMap;
  increment: ({
    dealId,
    status,
    key,
    isIncrease
  }: {
    dealId: string;
    status: 'pending' | 'ongoing' | 'done';
    key: 'pendingSize' | 'ongoingSize' | 'checkSize' | 'doneSize';
    isIncrease: boolean;
  }) => void;
  dealNotification: ({
    dealId,
    status,
    key
  }: {
    dealId: string;
    status: 'pending' | 'ongoing' | 'done';
    key: 'notiPending' | 'notiOngoing' | 'notiCheck' | 'notiDone';
  }) => void;
}

type MessageType = {
  type: 'pendingSize' | 'ongoingSize' | 'checkSize' | 'doneSize' | 'chat';
  isIncrease: boolean;
  status: 'pending' | 'ongoing' | 'check' | 'done' | 'cancelled' | 'rejected';
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  box-shadow: 0px 4px 10px 0px rgb(25 20 20 / 0.05);
  border-radius: 18px 18px 18px 18px;

  :hover {
    cursor: pointer;
  }
`;

const OnClickContainer = styled.div``;

function CampaignCardDetail(props: Props) {
  const {
    navigate,
    deal,
    status,
    activateShareModal,
    drawerStatus,
    connectWebsocket,
    socket,
    increment,
    dealNotification
  } = props;

  const [reconnect, setReconnect] = useState<boolean>(true);
  const { dealId } = deal;

  const channelName = 'deal-' + dealId;

  const getNotiStatus = (status: string) => {
    switch (status) {
      case 'check':
        return 'notiCheck';
      case 'ongoing':
        return 'notiOngoing';
      case 'done':
        return 'notiDone';
      case 'pending':
        return 'notiPending';
      default:
        return 'notiPending';
    }
  };

  useEffect(() => {
    if (status === 'ongoing') {
      connectWebsocket({ channel: channelName });
      return () => {
        setReconnect(false);
        socket[channelName]?.close();
      };
    }
  }, []);

  useEffect(() => {
    if (socket[channelName]) {
      socket[channelName].onmessage = event => {
        const message: MessageType = JSON.parse(event.data);
        const { type, isIncrease, status: dealContactStatus } = message;
        if (type === 'chat') {
          dealNotification({
            dealId,
            status: status,
            key: getNotiStatus(dealContactStatus)
          });
        } else {
          increment({
            dealId: dealId,
            status: 'ongoing',
            key: type,
            isIncrease: isIncrease
          });
        }
      };
      socket[channelName].onclose = () => {
        if (reconnect) {
          connectWebsocket({ channel: channelName });
          return () => {
            setReconnect(false);
            socket[channelName]?.close();
          };
        }
      };
    }
  }, [socket[channelName]]);

  return (
    <Container>
      <OnClickContainer
        onClick={() => {
          navigate(`/deal?dealId=${deal.dealId}&status=pending`);
          localStorage.setItem('drawerStatus', drawerStatus);
        }}
      >
        <CampaignImage {...props} />
      </OnClickContainer>
      <CampaignInfo {...props} />
      <CampaignPerformance
        activateShareModal={activateShareModal}
        status={status}
        dealId={deal.dealId}
        navigate={navigate}
      />
    </Container>
  );
}

export default observer(CampaignCardDetail);
