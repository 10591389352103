import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  navigation: NavigateFunction;
}

const RBrandLogo = (props: Props) => {
  const { navigation } = props;

  return (
    <LogoContainer
      src={require('../../assets/images/AnotherDotLogoBlack.png')}
      onClick={() => navigation('/')}
    />
  );
};

export default RBrandLogo;

const LogoContainer = styled.img`
  margin-top: 8px;
  width: 120px;
  height: 23px;
  margin-right: 20px;
  align-self: center;
  :hover {
    cursor: pointer;
  }
`;
