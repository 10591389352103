import styled from 'styled-components';
import { AuthSignUpProp } from '../../../types/auth';
import RegisterCardDetail from '../../molecules/Register/RegisterCardDetail';
import RegisterCardFooter from '../../molecules/Register/RegisterCardFooter';

interface Props {
  changePage: () => void;
  authSignUp: AuthSignUpProp;
  setAuthSignUp: ({
    value,
    property
  }: {
    value: string;
    property:
      | 'teamEmail'
      | 'password'
      | 'rePassword'
      | 'fullName'
      | 'position'
      | 'workingEmail'
      | 'phoneNumber';
  }) => void;
  register: () => Promise<string[]>;
  authSignUpError: string[];
  validateTeamEmail: boolean;
  validatePassword: boolean;
  validateRePassword: boolean;
}

const RegisterCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 567px;
  height: 800px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  padding: 50px 88px;
`;

function RegisterCard(props: Props) {
  return (
    <RegisterCardContainer>
      <RegisterCardDetail {...props} />
      <RegisterCardFooter />
    </RegisterCardContainer>
  );
}

export default RegisterCard;
