import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import {
  FontAktivGroteskRegular,
  FontAktivGroteskThaiRegular,
  FontNotoSansRegular
} from '../../fonts/font';
import { TEXT } from '../../fonts/text';
import { ActiveProps } from '../../types';

interface Props {
  value: number;
  changeValue: (value: number) => void;
  limit?: number;
  lowerLimit?: number;
  containerStyle?: React.CSSProperties;
}

function RCounter(props: Props) {
  const {
    value,
    changeValue,
    limit = 10,
    lowerLimit = 0,
    containerStyle
  } = props;

  const pressNegative = () => {
    if (value > lowerLimit) {
      changeValue(value - 1);
    }
  };

  const pressPositive = () => {
    if (value < limit) {
      changeValue(value + 1);
    }
  };

  const inputProp = {
    type: 'number',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseInt(e.target.value);
      if (newValue > limit || newValue < lowerLimit) {
        return;
      }
      changeValue(newValue);
    },
    placeholder: '0',
    value: value,
    min: lowerLimit,
    max: limit
  };

  return (
    <Container style={containerStyle}>
      <ButtonContainer onClick={pressNegative}>
        <RXIcon
          name="Minus"
          size={16}
          color={value !== 0 ? COLOR.Blue_700 : COLOR.Gray_200}
        />
      </ButtonContainer>
      <Input {...inputProp} />
      <ButtonContainer onClick={pressPositive}>
        <RXIcon
          name="Plus"
          size={16}
          color={value !== 30 ? COLOR.Blue_700 : COLOR.Gray_200}
        />
      </ButtonContainer>
    </Container>
  );
}

export default RCounter;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 128px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  background: ${COLOR.WHITE};
  border: 1px solid ${COLOR.Gray_200};
  border-radius: 24px;

  :hover {
    border: 1px solid ${COLOR.Blue_700};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }
`;

const Input = styled.input`
  font-family: ${FontNotoSansRegular};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border: none;
  outline: none;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLOR.Gray_300};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${COLOR.Gray_300};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${COLOR.Gray_300};
  }
`;

const ButtonContainer = styled.label`
  margin-top: 3px;
  :hover {
    cursor: pointer;
  }
`;
