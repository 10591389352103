import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from '../components/atoms/ScrollToTop';
import LoginCard from '../components/organisms/Login/LoginCard';
import LoginLogo from '../components/organisms/Login/LoginLogo';
import { useAuthStore, useInfluencerStore } from '../stores/initialize/Context';

function LoginPage() {
  const navigate = useNavigate();
  const { setAuthLogin, login, authLoginError, isLoginActive, loadIsLoggedIn } =
    useAuthStore();
  const { initializeDirectory } = useInfluencerStore();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loadIsLoggedIn()) {
      navigate('/');
    }
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        LoginPress();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const LoginPress = async () => {
    if (loading || !isLoginActive) return;
    setLoading(true);
    const { isRegistered, loggedIn } = await login();
    setLoading(false);
    if (loggedIn) {
      if (!isRegistered) {
        navigate('/createprofile', { state: 'fromLogin' });
      } else {
        initializeDirectory();
        navigate('/campaign');
      }
    }
  };

  return (
    <Container>
      <ScrollToTop />
      <ContentContainer>
        <LogoContainer>
          <LoginLogo />
        </LogoContainer>
        <LoginCard
          setAuthLogin={setAuthLogin}
          login={LoginPress}
          authLoginError={authLoginError}
          isLoginActive={isLoginActive}
          loading={loading}
        />
      </ContentContainer>
    </Container>
  );
}

export default observer(LoginPage);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1280px;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 80px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 280px;
  margin-right: 120px;
`;
