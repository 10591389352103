import { action, makeAutoObservable, observable } from 'mobx';
import { IInfluencerDetails } from '../types/api/getInfluencer';
import { GET, POST } from '../utils/Networking';
import { getOfferInfluencer, getSavedInfluencer } from './logic/influencers';

export class OfferStore {
  @observable
  brandSaved = {
    data: [],
    metadata: { count: 0, offset: 0, limit: 10 }
  }; // brandSaved influencer list

  @observable
  influencerInfo?: IInfluencerDetails;

  @observable
  offerLoading = false;

  @observable
  deals: Record<
    string,
    {
      data: { id: string }[];
      metadata: { count: 0; offset: 0; limit: 10 };
      invite: { invited: number };
    }
  > = {};

  constructor() {
    makeAutoObservable(this);
  }

  @action
  getBrandSaveInfluencer = async (limit = 10, offset = 0) => {
    this.offerLoading = true;
    const influencers = await getSavedInfluencer({ limit, offset });
    if (offset === 0) this.brandSaved = influencers;
    else {
      const prevData = this.brandSaved.data;
      this.brandSaved = {
        ...influencers,
        data: prevData.concat(influencers.data)
      };
    }
    this.offerLoading = false;
    return influencers;
  };

  @action
  getInfluencerInfo = async (id: string) => {
    try {
      if (id === '' || id === null) return;
      this.offerLoading = true;
      this.influencerInfo = undefined;
      const influencer = await GET(`/brands/offer/Influencers/${id}`);
      this.influencerInfo = influencer;
    } catch (error) {
      console.log(error);
    } finally {
      this.offerLoading = false;
    }
  };

  @action
  sendOfferInfluencer = async (
    accountId: string | null,
    influencerId: string,
    dealId: string,
    savedInflu: boolean
  ) => {
    if (!savedInflu) {
      this.deals[dealId].invite.invited++;
      this.deals[dealId].data = this.deals[dealId].data.map(influencer => {
        if (influencer.id === accountId) {
          return {
            ...influencer,
            invited: true
          };
        }
        return influencer;
      });
    }
    const dealContact = await POST('/brands/offer', {
      accountId,
      influencerId,
      dealId
    });
    return dealContact;
  };

  /**
   *
   * Send offers to many influencer in once
   *
   * @param influencerIds
   * @param dealId
   * @returns dealContacts: Array<DealContact>
   */
  @action
  sendOfferInfluencers = async (
    influencerIds: Array<string>,
    dealId: string,
    savedInflu: boolean
  ) => {
    //unused, check if influencerId received is actually influId or accId
    const sendPromise = influencerIds.map(influencerId => {
      return this.sendOfferInfluencer(null, influencerId, dealId, savedInflu);
    });
    const dealContacts = await Promise.all(sendPromise);
    return dealContacts;
  };

  @action
  getOfferInfluencers = async (dealId: string, limit = 10, offset = 0) => {
    const influencers = await getOfferInfluencer({ dealId, limit, offset });
    if (offset === 0) this.deals[dealId] = influencers;
    else {
      const prevData = this.deals[dealId].data;
      this.deals[dealId] = {
        ...influencers,
        data: prevData.concat(influencers.data)
      };
    }
    return influencers;
  };
}

export default new OfferStore();
