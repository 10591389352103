import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { TEXT } from '../../../../fonts/text';
import { COLOR } from '../../../../fonts/color';
import { ActiveProps } from '../../../../types';
import {
  ColumnContainer,
  RowContainer
} from '../../../../utils/styling/general';
import { useInfluencerListFilterContext } from '../../InfluencerListContext';

interface RatingProps {
  rating: number;
}
const RatingComponent = (props: RatingProps) => {
  const { rating } = props;
  const { filters, updateFilter } = useInfluencerListFilterContext();
  const active = filters.rating.includes(rating);
  return (
    <TagContainer
      active={active}
      onClick={() => {
        let tempRating = [...filters.rating];
        if (active) {
          tempRating.splice(tempRating.indexOf(rating), 1);
        } else {
          tempRating.push(rating);
        }
        updateFilter('rating', tempRating);
      }}
    >
      <GapRowContainer>
        <RXIcon name="StarFilled" color={COLOR.Yellow_D} size={16} />
        <GrayDBu3Text active={active}>{rating}</GrayDBu3Text>
      </GapRowContainer>
    </TagContainer>
  );
};

const FilterRating = () => {
  return (
    <Container>
      <GrayMB3Text>Rating</GrayMB3Text>
      <WrapRowContainer>
        <RatingComponent rating={1} />
        <RatingComponent rating={2} />
        <RatingComponent rating={3} />
        <RatingComponent rating={4} />
        <RatingComponent rating={5} />
      </WrapRowContainer>
    </Container>
  );
};

export default FilterRating;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 12px;
`;

const WrapRowContainer = styled(RowContainer)`
  gap: 8px;
  flex-wrap: wrap;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
`;

const TagContainer = styled.div<ActiveProps>`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.4px solid ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_M)};
  background-color: ${props => (props.active ? COLOR.Blue_50 : COLOR.White)};
  :hover {
    cursor: pointer;
  }
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;

const GrayDBu3Text = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.Blue_700 : COLOR.Gray_D)};
`;
