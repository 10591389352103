import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { DiscoverPage } from '../../../pages/DiscoverPage';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import FilterModal from './FilterModal';

interface Props {
  navigate: NavigateFunction;
  page: DiscoverPage;
  setPage: React.Dispatch<React.SetStateAction<DiscoverPage>>;
  listFeedExpandWork: ({
    limit,
    offset,
    category,
    filter
  }: {
    limit: number;
    offset: number;
    category: string;
    filter?:
      | {
          sortBy: string;
          platforms: string;
        }
      | undefined;
  }) => Promise<
    | {
        metadata: any;
        data: any;
      }
    | undefined
  >;
  clearFeedExpandWork: () => void;
}

function DiscoveryTabDetail(props: Props) {
  const { navigate, page, setPage, listFeedExpandWork, clearFeedExpandWork } =
    props;
  const [hoverStatus, setHoverStatus] = useState<string>('');
  const [performance, setPerformance] = useState<string>('Reach');
  const [channel, setChannel] = useState<string>('All');
  const [modalActive, setModalActive] = useState<boolean>(false);
  const onConfirm = () => {
    if (listFeedExpandWork) {
      setModalActive(false);
      clearFeedExpandWork();
      listFeedExpandWork({
        limit: 10,
        offset: 0,
        category: page,
        filter: {
          sortBy: performance,
          platforms: channel
        }
      });
    }
  };

  const renderTabName = () => {
    switch (page) {
      case 'Food & Beverage':
        return <BoldH5Text>🍔 ดาวเด่นสายกิน</BoldH5Text>;
      case 'Fashion & Accessory':
        return <BoldH5Text>👗 ตัวตึงสายแฟ</BoldH5Text>;
      case 'Beauty & Cosmetics':
        return <BoldH5Text>💄 ดาวรุ่งสายบิวตี้</BoldH5Text>;
    }
  };

  const renderDetail = () => {
    if (page !== 'Default' && page !== 'Campaign') {
      return (
        <CategoryContainer>
          <IconContainer onClick={() => setPage('Default')}>
            <RXIcon name="ArrowShortLeft" size={32} />
          </IconContainer>
          <BoldH5Text>{renderTabName()}</BoldH5Text>
          <OptionIconContainer onClick={() => setModalActive(true)}>
            <RXIcon name="Option" size={32} />
          </OptionIconContainer>
        </CategoryContainer>
      );
    } else {
      return (
        <Container>
          <WithFocusContainer
            onMouseOver={() => {
              if (hoverStatus !== 'influencer') {
                setHoverStatus('influencer');
              }
            }}
            onMouseLeave={() => {
              setHoverStatus('');
            }}
          >
            <BoldH5Text
              onClick={() => {
                setPage('Default');
                navigate('?page=influencer');
              }}
            >
              AnotherDot Influencer
            </BoldH5Text>
            {page !== 'Default' && hoverStatus === 'influencer' && (
              <Hover page="Default" />
            )}
            {page === 'Default' && <Focus page="Default" />}
          </WithFocusContainer>
          <WithFocusContainer
            onMouseOver={() => {
              if (hoverStatus !== 'campaign') {
                setHoverStatus('campaign');
              }
            }}
            onMouseLeave={() => {
              setHoverStatus('');
            }}
          >
            <BoldH5Text
              onClick={() => {
                setPage('Campaign');
                navigate('?page=campaign');
              }}
            >
              Campaign on AnotherDot
            </BoldH5Text>
            {page !== 'Campaign' && hoverStatus === 'campaign' && (
              <Hover page="Campaign" />
            )}
            {page === 'Campaign' && <Focus page="Campaign" />}
          </WithFocusContainer>
        </Container>
      );
    }
  };
  return (
    <>
      {renderDetail()}
      <FilterModal
        active={modalActive}
        onClose={() => setModalActive(false)}
        onConfirm={onConfirm}
        onDefault={() => {
          setPerformance('Reach');
          setChannel('All');
        }}
        performance={performance}
        setPerformance={setPerformance}
        channel={channel}
        setChannel={setChannel}
      />
    </>
  );
}

export default DiscoveryTabDetail;

const Container = styled(RowContainer)`
  width: inherit;
  justify-content: space-around;
`;

const CategoryContainer = styled(SpaceBetweenRowContainer)`
  width: inherit;
`;

const BoldH5Text = styled(TEXT.H5_Bold)`
  :hover {
    cursor: pointer;
  }
`;

const WithFocusContainer = styled(ColumnContainer)`
  align-items: flex-start;
  justify-content: flex-start;
`;

const Focus = styled.div<{ page: string }>`
  position: absolute;
  top: 188px;
  width: ${props => (props.page === 'Default' ? '275px' : '315px')};
  height: 4px;
  background: ${COLOR.Blue_700};
  border-radius: 2px;
`;

const Hover = styled.div<{ page: string }>`
  position: absolute;
  top: 188px;
  width: ${props => (props.page === 'Default' ? '275px' : '315px')};
  height: 4px;
  background: ${COLOR.Blue_50};
  border-radius: 2px;
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 25px;
  :hover {
    cursor: pointer;
    background: ${COLOR.Gray_50};
  }
`;

const OptionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  :hover {
    cursor: pointer;
    background: ${COLOR.Gray_50};
  }
`;
