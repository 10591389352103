import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { Account } from '../../../types/account';
import AccountDetail from '../../molecules/Setting/AccountDetail';
import ContactUs from '../../molecules/Setting/ContactUs';
import DeleteAccount from '../../molecules/Setting/DeleteAccount';
import PrivacyDetails from '../../molecules/Setting/PrivacyDetails';
import TermOfUse from '../../molecules/Setting/TermOfUse';

interface Props {
  navigate: NavigateFunction;
  page: string;
  account: Account;
}

const SettingContent = (props: Props) => {
  const { navigate, page } = props;
  const renderContent = () => {
    switch (page) {
      case 'profile':
        return <AccountDetail {...props} />;
      case 'privacy':
        return <PrivacyDetails />;
      case 'termsofuse':
        return <TermOfUse />;
      case 'contacts':
        return <ContactUs />;
      case 'deleteaccount':
        return <DeleteAccount navigate={navigate} />;
    }
  };

  return <Container>{renderContent()}</Container>;
};

export default SettingContent;

const Container = styled.div`
  position: relative;
  width: 100%;
  flex-direction: column;
`;
