import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { RowContainer } from '../../../utils/styling/general';
import CategoryCard from '../../../components/atoms/RCategoryCard';
import RDraggableWithArrowsComponent from '../../../components/atoms/RDraggableWithArrowsComponent';
import { CategoryType } from '../../../components/organisms/CreateCampaign/Options';

interface ClickFunctionProps extends Props {
  cat: CategoryType;
}

const onChipClick = (props: ClickFunctionProps) => {
  const {
    activeCategory: active,
    cat,
    setActiveCategory: setActive,
    activeAttributes,
    setActiveAttributes
  } = props;
  let tempAttributes = [...activeAttributes];
  tempAttributes = [];
  setActiveAttributes(tempAttributes);
  if (active === cat) setActive(undefined);
  else setActive(cat);
};

const ListComponent = (props: Props) => {
  const { categoryList, activeCategory: active } = props;
  const components: JSX.Element[] = [];
  categoryList.forEach(cat => {
    const activeCategory = active === cat;
    components.push(
      <CategoryCard
        key={cat}
        category={cat}
        active={activeCategory}
        onClick={() => onChipClick({ cat, ...props })}
      />
    );
  });
  return <>{components}</>;
};

interface Props {
  activeCategory: CategoryType | undefined;
  categoryList: CategoryType[];
  setActiveCategory: (cat: CategoryType | undefined) => void;
  activeAttributes: string[];
  setActiveAttributes: (att: string[]) => void;
}

const CategoryList = (props: Props) => {
  return (
    <RDraggableWithArrowsComponent
      leftArrowComponent={
        <ArrowContainer style={{ left: -16 }}>
          <RXIcon name="ArrowShortLeft" color={COLOR.White} />
        </ArrowContainer>
      }
      rightArrowComponent={
        <ArrowContainer style={{ right: -16 }}>
          <RXIcon name="ArrowShortRight" color={COLOR.White} />
        </ArrowContainer>
      }
    >
      <Container>
        <ListComponent {...props} />
      </Container>
    </RDraggableWithArrowsComponent>
  );
};

export default CategoryList;

const Container = styled(RowContainer)`
  gap: 8px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${COLOR.Black};
  top: 32px;
  z-index: 9;
  opacity: 0.4;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;
