import { NavigateFunction } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import Facebook from '../../../assets/images/registerFrom/Facebook.svg';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { useAccountStore } from '../../../stores/initialize/Context';
import { ActiveProps } from '../../../types';
import { Account } from '../../../types/account';
import { toMonthYear } from '../../../utils';
import ChangeProfileImageOverlay from '../../atoms/ChangeProfileImageOverlay';
import RInfoDescription from '../../atoms/RInfoDescription';
import RToolTip from '../../atoms/RToolTip';
import { useState } from 'react';

interface CheckProps {
  isVerified: boolean;
}

const CheckComponent = (props: CheckProps) => {
  const { isVerified } = props;
  if (isVerified) {
    return <RXIcon name="Check" color={COLOR.WHITE} size={30} />;
  }
  return (
    <ClockContainer>
      <RXIcon name="ClockFill" color={COLOR.ORANGE} size={32} />
    </ClockContainer>
  );
};

const HeaderComponent = () => {
  const { account, accounts, changeProfileImage } = useAccountStore();
  const { companyName, isVerified, img, createdAt, name } = account;
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  return (
    <BrandHeaderContainer>
      <ImagesContainer>
        <ChangeProfileImageOverlay
          width={106}
          height={106}
          onClick={changeProfileImage}
        />
        <ImageContainer src={img} />
        <CheckContainer
          active={isVerified}
          onMouseEnter={() => {
            setTooltipActive(true);
          }}
          onMouseLeave={() => {
            setTooltipActive(false);
          }}
        >
          <CheckComponent isVerified={isVerified} />
        </CheckContainer>
        {!isVerified && (
          <TooltipContainer active={tooltipActive}>
            <RToolTip
              iconName="ClockFill"
              text="อยู่ระหว่างการยืนยันบัญชี"
              top={true}
              right={false}
            />
          </TooltipContainer>
        )}
      </ImagesContainer>
      <WhiteBoldH6Text style={{ marginBottom: '4px' }}>{name}</WhiteBoldH6Text>
      <WhiteB3Text style={{ marginBottom: '8px' }}>
        {companyName || ' '}
      </WhiteB3Text>
      <WhiteB5Text>Joined {toMonthYear({ dueDate: createdAt })}</WhiteB5Text>
    </BrandHeaderContainer>
  );
};
interface Props {
  navigate: NavigateFunction;
  account: Account;
}

function AccountDetail(props: Props) {
  const { navigate, account } = props;
  const { email, contactName, contactTelephone, facebook, instagram, website } =
    account;

  const renderBrandInfo = () => {
    return (
      <ContentContainer>
        <HeaderContainer>
          <BoldH5Text>Brand Information</BoldH5Text>
        </HeaderContainer>
        <InfoContainer>
          <RInfoDescription
            iconName="Email"
            value="อีเมลสำหรับเข้าสู่ระบบ"
            description={email}
            style={{
              height: '51px',
              justifyContent: 'space-between',
              marginLeft: '8px',
              marginBottom: '28px'
            }}
          />
        </InfoContainer>
        <InfoContainer>
          <RInfoDescription
            iconName="Profile"
            value="ชื่อ - นามสกุลสำหรับติดต่อ"
            description={contactName}
            style={{
              height: '51px',
              justifyContent: 'space-between',
              marginLeft: '8px',
              marginBottom: '28px'
            }}
          />
        </InfoContainer>
        <InfoContainer>
          <RInfoDescription
            iconName="Phone"
            value="เบอร์โทร สำหรับติดต่อ"
            description={contactTelephone}
            style={{
              height: '51px',
              justifyContent: 'space-between',
              marginLeft: '8px',
              marginBottom: '28px'
            }}
          />
        </InfoContainer>
      </ContentContainer>
    );
  };

  const renderSocialInfo = () => {
    return (
      <ContentContainer style={{ marginTop: '24px' }}>
        <HeaderContainer>
          <BoldH5Text>Social Media</BoldH5Text>
        </HeaderContainer>
        {facebook && (
          <InfoContainer>
            <Facebook width={20} height={20} />
            <RInfoDescription
              value="Facebook"
              description={facebook}
              style={{
                height: '51px',
                justifyContent: 'space-between',
                marginLeft: '8px',
                marginBottom: '28px'
              }}
            />
          </InfoContainer>
        )}
        {instagram && (
          <InfoContainer>
            <Image
              src={require('../../../assets/images/registerFrom/Instagram.png')}
            />
            <RInfoDescription
              value="Instagram"
              description={instagram}
              style={{
                height: '51px',
                justifyContent: 'space-between',
                marginLeft: '8px',
                marginBottom: '28px'
              }}
            />
          </InfoContainer>
        )}
        {/* <InfoContainer>
          <Image
            src={require("../../../assets/images/registerFrom/Tiktok.png")}
          />
          <RInfoDescription
            value="Tiktok"
            description={tiktok}
            style={{
              height: "51px",
              justifyContent: "space-between",
              marginLeft: "8px",
              marginBottom: "28px",
            }}
          />
        </InfoContainer> */}
        {website && (
          <InfoContainer>
            <RInfoDescription
              iconName="Web"
              value="Website"
              description={website}
              style={{
                height: '51px',
                justifyContent: 'space-between',
                marginLeft: '8px',
                marginBottom: '28px'
              }}
            />
          </InfoContainer>
        )}
      </ContentContainer>
    );
  };

  return (
    <Container>
      <HeaderComponent />
      {renderBrandInfo()}
      {renderSocialInfo()}
      <TextContainer>
        <B3Text>
          หากต้องการแก้ไขข้อมูล สามารถติดต่อได้ที่{' '}
          <BlueBoldBu3Text onClick={() => navigate('/setting?page=contacts')}>
            Contact Us
          </BlueBoldBu3Text>
        </B3Text>
        <WarningBoldBu3Text
          onClick={() => navigate('/setting?page=deleteaccount')}
        >
          Delete Account
        </WarningBoldBu3Text>
      </TextContainer>
    </Container>
  );
}

export default AccountDetail;

const Container = styled.div``;

const ContentContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const HeaderContainer = styled.div`
  padding: 24px 56px 13px 56px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const InfoContainer = styled.div`
  display: flex;
  margin-left: 56px;
`;

const TextContainer = styled.div`
  display: flex;
  margin: 32px 0px 40px 32px;
  justify-content: space-between;
`;

const BrandHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 240px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${COLOR.BLACK};
  margin-bottom: 24px;
`;

const ImagesContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const ImageContainer = styled.img`
  width: 112px;
  height: 112px;
  border: 3px solid white;
  border-radius: 50%;
`;

const CheckContainer = styled.div<{ active: boolean }>`
  position: absolute;
  bottom: 3px;
  right: 0px;
  display: flex;
  width: 29px;
  height: 29px;
  justify-content: center;
  align-items: center;
  border: 3px solid white;
  border-radius: 50%;
  background: ${props => (props.active ? COLOR.Blue_700 : COLOR.ORANGE)};
  overflow: hidden;
  z-index: 99;
`;

const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
`;

const TooltipContainer = styled.div<ActiveProps>`
  position: absolute;
  bottom: -58px;
  left: 68px;
  display: flex;
  width: 207px;
  opacity: ${props => (props.active ? 1 : 0)};
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const B3Text = styled(TEXT.B3_Reg)``;

const BlueBoldBu3Text = styled(TEXT.Bu3_Bold)`
  color: ${COLOR.Blue_700};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const WarningBoldBu3Text = styled(TEXT.Bu3_Bold)`
  color: ${COLOR.Warning};
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const WhiteBoldH6Text = styled(TEXT.H6_Bold)`
  color: ${COLOR.White};
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
`;

const WhiteB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.WHITE};
`;
