import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from '../components/atoms/ScrollToTop';
import ShareModal from '../components/atoms/ShareModal';
import UnverifiedModal from '../components/molecules/CreateCampaign/UnverifiedModal';
import UseDraftModal from '../components/molecules/CreateCampaign/UseDraftModal';
import CampaignCard from '../components/organisms/Campaign/CampaignCard';
import {
  useAccountStore,
  useCreateDealStore,
  useDealStore,
  useWebSocketStore
} from '../stores/initialize/Context';
import { useQuery } from '../utils';

const MainCampaign = () => {
  const {
    deals,
    listDeals,
    loading,
    dealInfluSizeIncrement,
    deleteCampaign,
    dealNotification
  } = useDealStore();
  const { isDraftAvailable, clearNewDraft } = useCreateDealStore();
  const { account } = useAccountStore();
  const { connectWebsocket, socket } = useWebSocketStore();
  const navigate = useNavigate();
  let query = useQuery();
  const location = useLocation();
  const createUnverifiedDeal = (location.state as boolean) || false;

  // can only get string not object
  const status = query.get('status') as string;

  const [page, setPage] = useState<number>(1);
  const [draftModalActive, setDraftModalActive] = useState<string>('');
  const [unverifiedModal, setUnverifiedModal] =
    useState<boolean>(createUnverifiedDeal);
  const [shareDealDetail, setShareDealDetail] = useState<{
    img: string;
    title: string;
    dealId: string;
  }>({
    img: '',
    title: '',
    dealId: ''
  });

  const initializeOngoing = async () => {
    await listDeals({
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      status: 'ongoing'
    });
  };

  const initializePending = async () => {
    await listDeals({
      status: 'pending',
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc'
    });
  };

  const initializeDone = async () => {
    await listDeals({
      status: 'done',
      limit: 10,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'desc'
    });
  };

  const activateShareModal = (img: string, title: string, dealId: string) => {
    setShareDealDetail({
      img,
      title,
      dealId
    });
  };

  useEffect(() => {
    switch (status) {
      case 'ongoing':
        setPage(1);
        break;
      case 'pending':
        setPage(2);
        break;
      case 'done':
        setPage(3);
        break;
      default:
        setPage(1);
        break;
    }
  }, [status]);

  useEffect(() => {
    switch (page) {
      case 1:
        initializeOngoing();
        return;
      case 2:
        initializePending();
        return;
      case 3:
        initializeDone();
        return;
    }
  }, [page, account.brandId]);

  useEffect(() => {
    initializeOngoing();
    initializePending();
    initializeDone();
  }, []);

  return (
    <Container>
      <ScrollToTop />
      <UseDraftModal
        modalVisible={draftModalActive}
        setModalVisible={setDraftModalActive}
        navigate={navigate}
        clearDraft={clearNewDraft}
      />
      <ShareModal
        dealDetail={shareDealDetail}
        onClose={() => setShareDealDetail({ img: '', title: '', dealId: '' })}
      />
      <UnverifiedModal
        modalVisible={unverifiedModal}
        onClose={() => setUnverifiedModal(false)}
        navigate={navigate}
      />

      <ContentContainer>
        <CampaignCard
          navigate={navigate}
          deals={deals}
          page={page}
          setPage={(page: number) => {
            setPage(page);
          }}
          initializePending={initializePending}
          loading={loading}
          drawerStatus={status}
          activateShareModal={activateShareModal}
          isDraftAvailable={isDraftAvailable}
          setDraftModalActive={setDraftModalActive}
          connectWebsocket={connectWebsocket}
          socket={socket}
          increment={dealInfluSizeIncrement}
          deleteCampaign={deleteCampaign}
          dealNotification={dealNotification}
        />
      </ContentContainer>
    </Container>
  );
};

export default observer(MainCampaign);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 36px 0px 36px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
`;

const DrawerContainer = styled.div`
  display: flex;
`;
