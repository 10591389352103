import styled from 'styled-components';
import { TEXT } from '../../../../fonts/text';
import { ColumnContainer } from '../../../../utils/styling/general';
import ANDAgeSlider from '../../../../components/atoms/ANDAgeSlider';
import { COLOR } from '../../../../fonts/color';
import { useInfluencerListFilterContext } from '../../InfluencerListContext';

function AudienceAge() {
  const { updateFilter, filters } = useInfluencerListFilterContext();

  return (
    <Container>
      <GrayB3Text>อายุ</GrayB3Text>
      <ANDAgeSlider
        age={filters.ageRange}
        setAge={(ageRange: number[]) => {
          updateFilter('ageRange', ageRange);
        }}
        containerStyle={{ margin: 0, padding: 0 }}
        silderStyle={{ width: 288 }}
      />
    </Container>
  );
}

export default AudienceAge;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 12px;
`;

const GrayB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;
