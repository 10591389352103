export const FacebookHandleClick = (shareUrl: string) => {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    'facebook-share-dialog',
    'width=626,height=436'
  );
  return false;
};

export const LINEHandleClick = (shareUrl: string) => {
  window.open(
    `https://social-plugins.line.me/lineit/share?url=${shareUrl}`,
    'line-share-dialog',
    'width=626,height=436'
  );
  return false;
};

export const TwitterHandleClick = (shareUrl: string) => {
  window.open(
    `https://twitter.com/intent/tweet?url=${shareUrl}`,
    'twitter-share-dialog',
    'width=626,height=436'
  );
  return false;
};

export const MessengerHandleClick = (shareUrl: string) => {
  const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

  window.open(
    `https://www.facebook.com/dialog/send?app_id=${APP_ID}&link=${shareUrl}&redirect_uri=app.pickle.co.th`,
    'messenger-share-dialog',
    'width=626,height=436'
  );
  return false;
};
