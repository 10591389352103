import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TEXT } from '../../fonts/text';
import { IInfluencer } from '../../types/api/listInfluencers';
import { ChatMessage } from '../../types/chatMessage';
import { StatusType } from '../../types/deal';
import { useQuery } from '../../utils';
import InfluencerCards from './InfluencerCards';
import REmptyState from '../../components/atoms/REmptyState';
import RLoading from '../../components/atoms/RLoading';
import { observer } from 'mobx-react';

interface Props {
  influencers: IInfluencer[];
  status: StatusType;
  getLastMessage?: (dealContactId: string) => void;
  unsubscribeLastMessage?: (dealContactId: string) => void;
  lastMessage?: any;
  dueDate?: Date;
  dealId: string;
  setDealContactId: React.Dispatch<React.SetStateAction<string>>;
  setRejectActive: React.Dispatch<React.SetStateAction<boolean>>;
  brandId: string;
  changeDeliverStatus: ({
    dealContactId,
    status
  }: {
    dealContactId: string;
    status: 'DELIVERED' | 'TODELIVER';
  }) => Promise<void>;
  changeInfluDeliverStatus: ({
    dealContactId,
    status
  }: {
    dealContactId: string;
    status: string;
  }) => Promise<void>;
  shouldUpdate: boolean;
  toggleShouldUpdate: () => void;
  chatBadge: Record<string, number>;
  loading: boolean;
}

function InfluencerList(props: Props) {
  const {
    influencers,
    status,
    dueDate,
    getLastMessage,
    lastMessage,
    dealId,
    setRejectActive,
    setDealContactId,
    brandId,
    unsubscribeLastMessage,
    changeDeliverStatus,
    changeInfluDeliverStatus,
    shouldUpdate,
    toggleShouldUpdate,
    chatBadge,
    loading
  } = props;

  const location = useLocation();
  const query = useQuery();
  const queryDealContactId = query.get('dealContactId') as string;
  const state = location.state;

  const isFromNotiToWork = state === 'toWork';
  const isFromNotiToChat = state === 'toChat';
  const navigate = useNavigate();

  if (isFromNotiToWork) {
    navigate(
      `/influencer?dealId=${dealId}&dealContactId=${queryDealContactId}&page=work`,
      { state: status }
    );
  }

  if (isFromNotiToChat) {
    navigate(
      `/influencer?dealId=${dealId}&dealContactId=${queryDealContactId}&page=chat`,
      { state: status }
    );
  }

  const [openedMenu, setOpenedMenu] = useState<string>('');
  const getFolowerLabel = () => {
    switch (status) {
      case 'pending':
        return <Body2Text>Followers</Body2Text>;
      default:
        return <Body2Text>Last Message</Body2Text>;
    }
  };

  const InfluencerListComponent = () => {
    // if (loading) return <RLoading />;
    if (influencers.length === 0)
      return (
        <EmptyStateContainer>
          <REmptyState
            buttonWidth={131}
            header="ไม่พบข้อมูล"
            descriptions={[
              'สามารถค้นหาอินฟลูเอนเซอร์ที่น่าสนใจได้ที่หน้า Discover '
            ]}
            buttonText="Discover"
            onClick={() => {
              navigate('/discover?page=influencer');
            }}
          />
        </EmptyStateContainer>
      );
    return (
      <>
        {influencers.map(influencerDetail => {
          // TODO: Add isCounterPending
          const {
            influencer,
            createdAt,
            dealContactId,
            invited = false,
            isNegotiating = false,
            unread = false,
            deliverStatus = undefined,
            status: influStatus
          } = influencerDetail;
          const {
            followers_count = 0,
            followers_count_facebook = 0,
            followers_count_tiktok = 0,
            img = '',
            accountId: influencerId
          } = influencer || {};

          return (
            <InfluencerCards
              key={influencerDetail.influencerId}
              name={
                influencerDetail.influencer.displayName ||
                influencerDetail.influencer.name
              }
              dealId={dealId}
              image={img}
              status={status}
              igFollower={followers_count}
              tkFollower={followers_count_tiktok}
              fbFollower={followers_count_facebook}
              date={createdAt}
              dealContactId={dealContactId}
              isInvited={invited}
              isNegotiating={isNegotiating}
              unread={unread}
              dueDate={dueDate}
              deliverStatus={deliverStatus}
              getLastMessage={getLastMessage}
              lastMessage={lastMessage}
              setRejectActive={setRejectActive}
              setDealContactId={setDealContactId}
              accountId={brandId}
              influencerId={influencerId || ''}
              influStatus={influStatus}
              unsubscribeLastMessage={unsubscribeLastMessage}
              changeDeliverStatus={changeDeliverStatus}
              changeInfluDeliverStatus={changeInfluDeliverStatus}
              openedMenu={openedMenu}
              setOpenedMenu={setOpenedMenu}
              shouldUpdate={shouldUpdate}
              toggleShouldUpdate={toggleShouldUpdate}
              haveChatNoti={
                chatBadge[dealContactId] !== undefined &&
                chatBadge[dealContactId] !== null &&
                chatBadge[dealContactId] !== 0
              }
            />
          );
        })}
      </>
    );
  };

  return (
    <Container>
      <LabelContainer>
        <Body2Text>Influencers</Body2Text>
        <Body2Text>Status</Body2Text>
        {getFolowerLabel()}
        <Body2Text>Date and Time</Body2Text>
      </LabelContainer>
      <InfluencerListComponent />
    </Container>
  );
}

export default observer(InfluencerList);

const Container = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  height: 430px;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 16px 16px;
`;

const LabelContainer = styled.div`
  display: flex;
  height: 32px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const Body2Text = styled(TEXT.Bu3_Reg)`
  display: flex;
  width: 180px;
  justify-content: center;
`;
