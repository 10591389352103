import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import { COLOR } from '../../../../fonts/color';
import { RowContainer } from '../../../../utils/styling/general';
import ANDAgeSlider from '../../../atoms/ANDAgeSlider';

interface Props {
  influencerAge: number[];
  audienceAge: number[];
  setInfluencerAge: (age: number[]) => void;
  setAudienceAge: (age: number[]) => void;
}

function InfluencerAge(props: Props) {
  const { influencerAge, setInfluencerAge, audienceAge, setAudienceAge } =
    props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>อายุ</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer>
          <RXIcon name="PlaceholderFill" color={ANDCOLOR.BLUE} size={20} />
          <Body3Text>
            เลือกช่วงอายุของ{' '}
            <UnderlineBody3Text>Influencer </UnderlineBody3Text>
          </Body3Text>
        </GapRowContainer>
        <ANDAgeSlider
          age={influencerAge}
          setAge={(age: number[]) => {
            setInfluencerAge([...age]);
          }}
        />
        <Body4Text>Tip : เลือกอายุ Influencer เพื่อโปรโมตแบรนด์</Body4Text>
        <GapRowContainer style={{ marginTop: 36 }}>
          <Body3Text>
            เลือกช่วงอายุที่ตรงกับ{' '}
            <UnderlineBody3Text>กลุ่มลูกค้าเป้าหมาย </UnderlineBody3Text>
          </Body3Text>
        </GapRowContainer>
        <ANDAgeSlider
          age={audienceAge}
          setAge={(age: number[]) => {
            setAudienceAge([...age]);
          }}
          color={ANDCOLOR.PURPLE}
        />
        <Body4Text>Tip : เลือกอายุ Audience ตามกลุ่มลูกค้าเป้าหมาย</Body4Text>
      </ContentContainer>
    </Container>
  );
}

export default InfluencerAge;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const UnderlineBody3Text = styled(ANDTEXT.body3BOLD)`
  text-decoration: underline;
`;
const Body4Text = styled(ANDTEXT.body4)`
  color: ${COLOR.Gray_D};
`;
