import styled from 'styled-components';
import { useAccountStore, useAuthStore } from '../stores/initialize/Context';
import { COLOR } from '../fonts/color';
import { PLACEHOLDER_PROFILE } from '../utils';
import { featureAccessStore, Tier } from '../stores/featureAccessStore';
import { RXIcon } from 'rn-rx-icons';

import FreeTier from '../assets/images/FreeTier.svg';
import { observer } from 'mobx-react';

type Props = {
  onClick?: () => void;
};

const BrandLogoWithStatus = (props: Props) => {
  const { account, accountLoading } = useAccountStore();
  const { tier } = featureAccessStore;

  const { authLoading } = useAuthStore();
  const { onClick } = props;

  if (authLoading || accountLoading) {
    return (
      <ImgContainer className="brand-profile-image">
        <ImgPlaceHolder />
      </ImgContainer>
    );
  }
  return (
    <ImgContainer
      className="brand-profile-image"
      onClick={onClick}
      style={{ marginRight: tier === 'free' ? '15px' : 0 }}
    >
      <Img src={account.img || PLACEHOLDER_PROFILE} alt={PLACEHOLDER_PROFILE} />
      <StatusComponent tier={tier} isAccountVerified={account.isVerified} />
    </ImgContainer>
  );
};

type StatusComponentProps = { tier: Tier; isAccountVerified: boolean };
const StatusComponent = (props: StatusComponentProps) => {
  const { tier, isAccountVerified } = props;

  if (!isAccountVerified) {
    return (
      <ClockContainer>
        <RXIcon name="ClockFill" color={COLOR.ORANGE} size={12} />
      </ClockContainer>
    );
  }
  if (tier === 'free') {
    return (
      <FreetierContainer>
        <FreeTier width={30} height={16} />
      </FreetierContainer>
    );
  } else {
    return (
      <CheckContainer active={isAccountVerified}>
        <RXIcon name="Check" color={COLOR.White} size={12} />
      </CheckContainer>
    );
  }
};

export default observer(BrandLogoWithStatus);

const ImgContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
const ImgPlaceHolder = styled.img`
  width: 36px;
  height: 36px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50px;
  background-color: ${COLOR.Gray_M};
`;
const Img = styled.img`
  width: 36px;
  height: 36px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50px;
  background-color: ${COLOR.Gray_M};
`;

const ClockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLOR.WHITE};
  position: absolute;
  top: 24px;
  left: 23px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid white;
`;

const FreetierContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99;
`;

const CheckContainer = styled.div<{ active: boolean }>`
  position: absolute;
  top: 24px;
  left: 23px;
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border: 2.5px solid white;
  border-radius: 50%;
  background: ${props => (props.active ? COLOR.Herbal : COLOR.ORANGE)};
  overflow: hidden;
  z-index: 99;
`;
