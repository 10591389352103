import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { FlexStartColumnContainer } from '../../../utils/styling/general';
import CategoryList from './CategoryList';
import { CategoryType } from '../../../components/organisms/CreateCampaign/Options';

interface Props {
  activeCategory: CategoryType | undefined;
  categoryList: CategoryType[];
  setActiveCategory: (att: CategoryType | undefined) => void;
  fromDeal: boolean;
  activeAttributes: string[];
  setActiveAttributes: (att: string[]) => void;
}

const CategoryFilter = (props: Props) => {
  const {
    activeCategory,
    categoryList,
    setActiveCategory,
    fromDeal,
    activeAttributes,
    setActiveAttributes
  } = props;

  if (fromDeal) return <></>;
  return (
    <Container>
      <GrayH8Text>Category</GrayH8Text>
      <CategoryList
        activeCategory={activeCategory}
        categoryList={categoryList}
        setActiveCategory={setActiveCategory}
        activeAttributes={activeAttributes}
        setActiveAttributes={setActiveAttributes}
      />
    </Container>
  );
};

export default CategoryFilter;

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 8px;
`;

const GrayH8Text = styled(TEXT.H8_Reg)`
  color: ${COLOR.Gray_M};
`;
