import React from 'react';
import styled from 'styled-components';
import { newDealKeys } from '../../../types/createDeal';
import { pageIndex } from '../../../pages/NewCreateCampaign/NewCreateCampaignPage';
import { TODAYSTRING } from '../../../utils';
import DealCardHeader from '../../molecules/NewCreateCampaign/Preview/DealCardHeader';
import DealCardInfluencer from '../../molecules/NewCreateCampaign/Preview/DealCardInfluencer';
import DealCardScopeOfWork from '../../molecules/NewCreateCampaign/Preview/DealCardScopeOfWork';
import DealCardDetail from '../../molecules/NewCreateCampaign/Preview/DealCardDetail';
import { observer } from 'mobx-react';
import ScrollToTop from '../../atoms/ScrollToTop';

interface Props {
  deal: newDealKeys;
  setPage: React.Dispatch<React.SetStateAction<pageIndex>>;
}

function Preview(props: Props) {
  const { deal, setPage } = props;

  const {
    name,
    platforms,
    photos,
    category,
    detail,
    age,
    gender,
    criteria,
    benefits,
    isValue,
    isCash,
    value,
    startDate,
    endDate,
    postingDate,
    submissionDate,
    location,
    isNegotiateCash,
    posts,
    hashtags,
    influencersTier,
    attribute,
    reviewTag,
    influencersCaption,
    examples,
    mustReturnProduct,
    secret,
    ads
  } = deal;

  return (
    <Container>
      <DealCardHeader
        name={name}
        photos={photos}
        category={category}
        value={value}
        tier={influencersTier}
        isDraft={true}
      />
      <CampaignDetailContainer>
        <DealCardInfluencer
          setPage={setPage}
          platform={platforms}
          influencersTier={influencersTier}
          gender={gender}
          audienceGender={criteria.gender}
          location={location}
          audienceLocation={criteria.location}
          age={age}
          audienceAge={criteria.ages}
          attribute={attribute}
          reviewTag={reviewTag}
        />
        <DealCardScopeOfWork
          setPage={setPage}
          postMedia={posts.postMedias}
          caption={influencersCaption}
          hashtags={hashtags}
          examples={examples}
          documents={[]}
          influencersTier={influencersTier}
          value={value}
          benefits={benefits}
          platform={platforms}
          isValue={isValue}
          isCash={isCash}
          mustReturnProduct={mustReturnProduct}
          isNegotiateCash={isNegotiateCash}
        />
        <DealCardDetail
          setPage={setPage}
          photos={photos}
          name={name}
          detail={detail}
          category={category}
          isSecret={secret}
          ads={ads}
          startDate={startDate}
          endDate={endDate}
          postingDate={postingDate}
          submissionDate={submissionDate}
        />
      </CampaignDetailContainer>
    </Container>
  );
}

export default observer(Preview);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 688px;
  padding-bottom: 72px;
`;
const CampaignDetailContainer = styled.div`
  padding-top: 72px;
  gap: 48px;
`;
