import { action, makeAutoObservable, runInAction } from 'mobx';
import { GET } from '../utils/Networking';

export enum Feature {
  INSIGHT_PORTFOLIO = 'insightPortfolio',
  CAMPAIGN_PERFORMANCE = 'campaignPerformance',
  INFLUENCER_DIRECTORY = 'influencerDirectory'
}

export type Tier = 'free' | 'trial' | 'paid';

type RequestResponse = {
  featureAccess: {
    insightPortfolio: false;
    campaignPerformance: false;
    influencerDirectory: false;
    //  activeCampaignCount: 2
  };
  tier: 'free';
  tierExpiredDate: null;
};
export class FeatureAccessStore {
  featureAccess: Record<string, boolean> = {};
  tier: Tier = 'free';
  loading: boolean = true;
  error: Error | null = null;

  constructor() {
    makeAutoObservable(this);
    this.getFeatureAccess();
  }

  @action
  async getFeatureAccess() {
    try {
      this.loading = true;

      const response: RequestResponse = await GET('/brands/account/tier');

      runInAction(() => {
        this.featureAccess = response.featureAccess;
        this.tier = response.tier;
        this.loading = false;
        this.error = null;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error as Error;
        this.loading = false;
      });
    }
  }

  canAccess(feature: Feature): boolean {
    return this.featureAccess[feature] ? true : false;
  }
}

export const featureAccessStore = new FeatureAccessStore();
