import styled from 'styled-components';
import { ColumnContainer } from '../../../utils/styling/general';
import { TEXT } from '../../../fonts/text';
import { COLOR } from '../../../fonts/color';
import { RXIcon } from 'rn-rx-icons';
import { PlatformType } from '../../../types/deal';
import { toCapitalize } from '../../../utils';

const IconComponent = (props: Props) => {
  const { platform } = props;
  switch (platform) {
    case 'INSTAGRAM':
      return (
        <InstagramContainer>
          <RXIcon name="Instagram" size={32} color={COLOR.White} />
        </InstagramContainer>
      );
    case 'TIKTOK':
      return (
        <TiktokIconContainer>
          <RXIcon name="Tiktok" size={36} color={COLOR.White} />
        </TiktokIconContainer>
      );
    case 'FACEBOOK':
      return (
        <FacebookContainer>
          <FacebookBackground />
          <FacebookIconContainer>
            <RXIcon name="FacebookFill" color={COLOR.Blue_700} size={44} />
          </FacebookIconContainer>
        </FacebookContainer>
      );
    default:
      return <></>;
  }
};

interface Props {
  platform: PlatformType;
}

const EmptySocialMedia = (props: Props) => {
  const { platform } = props;
  const platformText =
    platform === 'TIKTOK' ? 'TikTok' : toCapitalize(platform);

  return (
    <Container>
      {/* <WhiteBoldB3Text>ไม่สามารถแสดงข้อมูลได้</WhiteBoldB3Text> */}
      <IconComponent platform={platform} />
      <WhiteB3Text>
        {`บัญชีผู้ใช้นี้ยังไม่ได้
        เชื่อมต่อกับ ${platformText}`}
      </WhiteB3Text>
    </Container>
  );
};

export default EmptySocialMedia;

const Container = styled(ColumnContainer)`
  border-radius: 12px;
  width: 33%;
  height: 260px;
  background: rgba(1, 1, 1, 0.7);
  backdrop-filter: blur(20px);
  padding: 24px 20px;
  justify-content: center;
  gap: 16px;
`;

const TiktokIconContainer = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 44px;
  background-color: ${COLOR.Black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InstagramContainer = styled.div`
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
`;

const FacebookContainer = styled.div`
  position: relative;
  display: flex;
  width: 44px;
  height: 44px;
`;

const FacebookBackground = styled.div`
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 50px;
  background-color: ${COLOR.White};
  z-index: 1;
  top: 4px;
  left: 4.5px;
`;

const FacebookIconContainer = styled.div`
  z-index: 2;
`;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.White};
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
  white-space: pre-line;
  text-align: center;
`;
