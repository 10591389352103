import { CategoryType } from '../../components/organisms/CreateCampaign/Options';
import { genderType, tierType } from '../../types/createDeal';
import { PlatformWithTotalType } from '../../types/deal';
import { SortByType, SortOrderType } from '../../types/sort';
import { createContext, useContext, useState } from 'react';
import { useInfluencerStore } from '../../stores/initialize/Context';

export type InfluencerListFilter = {
  influGender: genderType[];
  ageRange: number[];
  tier: tierType[];
  platform: PlatformWithTotalType;
  attribute: string[];
  engagementRange: number[];
  followerRange: number[];
  rating: number[];
  audienceGender: genderType[];
  audienceAgeRange: number[];
  location: string[];
  audienceLocation: string[];
  keyword: string;
  attributesActiveList: string[];
  categoryActive: CategoryType | undefined;
  sortBy: SortByType;
  sortOrder: SortOrderType;
};

type InfluencerListContextType = {
  maxEngagement: number;
  maxActiveFollower: number;
  filters: InfluencerListFilter;
  isOpenFilterPanel: boolean;
  updateFilter: (key: keyof InfluencerListFilter, value: any) => void;
  clearSideBarFilter: () => void;
  toggleFilterPanel: () => void;
  openFilterPanel: () => void;
  closeFilterPanel: () => void;
  updateMaxEngagement: (num: number) => void;
  updateMaxActiveFollower: (num: number) => void;
};

const InfluencerListContext = createContext<InfluencerListContextType>(
  undefined as unknown as InfluencerListContextType
);

export const useInfluencerListFilterContext = () => {
  const filterContext = useContext(InfluencerListContext);
  if (filterContext === undefined) throw new Error(`context doesn't setup yet`);
  return filterContext;
};

export const InfluencerListContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [maxEngagement, setMaxEngagement] = useState(0);
  const [maxActiveFollower, setMaxActiveFollower] = useState(0);
  const resultFilter = useFilter();

  const updateMaxEngagement = (num: number) => {
    setMaxEngagement(num);
  };
  const updateMaxActiveFollower = (num: number) => {
    setMaxActiveFollower(num);
  };

  const value: InfluencerListContextType = {
    maxEngagement,
    maxActiveFollower,
    updateMaxEngagement,
    updateMaxActiveFollower,
    ...resultFilter
  };

  return (
    <InfluencerListContext.Provider value={value}>
      {children}
    </InfluencerListContext.Provider>
  );
};

const useFilter = () => {
  const store = useInfluencerStore();
  const initialFilterValues: InfluencerListFilter = {
    influGender: store.filterDirectory.gender,
    ageRange: store.filterDirectory.age,
    tier: store.filterDirectory.tier,
    platform: store.filterDirectory.social,
    attribute: store.filterDirectory.attribute,
    engagementRange: store.filterDirectory.engagement,
    followerRange: store.filterDirectory.follower,
    rating: store.filterDirectory.rating,
    audienceGender: store.filterDirectory.audience.gender,
    audienceAgeRange: store.filterDirectory.audience.age,
    location: store.filterDirectory.location,
    audienceLocation: store.filterDirectory.audience.location,
    keyword: store.filterDirectory.username,
    attributesActiveList: store.filterDirectory.attribute,
    categoryActive: store.filterDirectory.category,
    sortBy: 'avgEngagement',
    sortOrder: 'desc'
  };
  const defaultFilterValues: InfluencerListFilter = {
    influGender: [],
    ageRange: [15, 60],
    tier: [],
    platform: 'ALL',
    attribute: [],
    engagementRange: [0, 1000000],
    followerRange: [0, 1000000],
    rating: [],
    audienceGender: [],
    audienceAgeRange: [15, 60],
    location: ['all'],
    audienceLocation: ['all'],
    keyword: '',
    attributesActiveList: [],
    categoryActive: undefined,
    sortBy: 'default',
    sortOrder: 'none'
  };
  const [filters, setFilters] = useState(initialFilterValues);
  const [isOpenFilterPanel, setIsOpenFilterPanel] = useState(false);

  const updateFilter = (key: keyof InfluencerListFilter, value: any) => {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [key]: value
      };
    });
  };

  const clearSideBarFilter = () => {
    setFilters(prev => {
      return {
        ...defaultFilterValues,
        platform: prev.platform,
        categoryActive: prev.categoryActive,
        attributesActiveList: prev.attributesActiveList,
        sortBy: prev.sortBy,
        sortOrder: prev.sortOrder
      };
    });
    store.setFilterDirectory({
      ...defaultFilterValues,
      username: defaultFilterValues.keyword,
      gender: defaultFilterValues.audienceGender,
      social: defaultFilterValues.platform,
      engagement: defaultFilterValues.engagementRange,
      follower: defaultFilterValues.followerRange,
      age: defaultFilterValues.audienceAgeRange,
      audience: {
        age: defaultFilterValues.audienceAgeRange,
        gender: defaultFilterValues.audienceGender,
        location: defaultFilterValues.audienceLocation
      },
      category: undefined
    });
  };

  const toggleFilterPanel = () => {
    setIsOpenFilterPanel(previousStatus => !previousStatus);
  };
  const openFilterPanel = () => {
    setIsOpenFilterPanel(true);
  };
  const closeFilterPanel = () => {
    setIsOpenFilterPanel(false);
  };

  return {
    filters,
    isOpenFilterPanel,
    updateFilter,
    clearSideBarFilter,
    toggleFilterPanel,
    openFilterPanel,
    closeFilterPanel
  };
};
