import { ChartData } from 'chart.js';
import { DealsChartData, PlatformType } from '../../../../types/deal';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { TEXT } from '../../../../fonts/text';
import { ActiveProps } from '../../../../types';
import { DataSetProps } from './PerformanceChart';

interface Props {
  dateFormat: 'daily' | 'weekly' | 'monthly';
  setDateFormat: React.Dispatch<
    React.SetStateAction<'daily' | 'weekly' | 'monthly'>
  >;
  data: DealsChartData | undefined;
  setChartData: React.Dispatch<
    React.SetStateAction<ChartData<'line', number[], string> | undefined>
  >;
  hoveredIndex: number | null;
  platformActive: PlatformType;
  getDataSet: (props: DataSetProps) => ChartData<'line', number[], string>;
}

const PerformanceChartSwitch = (props: Props) => {
  const {
    dateFormat,
    setDateFormat,
    data,
    setChartData,
    hoveredIndex,
    platformActive,
    getDataSet
  } = props;
  return (
    <SwitchContainer>
      <SwitchButtonContainer
        active={dateFormat === 'daily'}
        onClick={() => {
          setDateFormat('daily');
          setChartData(
            getDataSet({
              insightData: data?.daily,
              hoveredIndex,
              dateFormat: 'daily',
              platformActive
            })
          );
        }}
      >
        <SwitchText active={dateFormat === 'daily'}>Daily</SwitchText>
      </SwitchButtonContainer>
      <SwitchButtonContainer
        active={dateFormat === 'weekly'}
        onClick={() => {
          setDateFormat('weekly');
          setChartData(
            getDataSet({
              insightData: data?.weekly,
              hoveredIndex,
              dateFormat: 'weekly',
              platformActive
            })
          );
        }}
      >
        <SwitchText active={dateFormat === 'weekly'}>Weekly</SwitchText>
      </SwitchButtonContainer>
      <SwitchButtonContainer
        active={dateFormat === 'monthly'}
        onClick={() => {
          setDateFormat('monthly');
          setChartData(
            getDataSet({
              insightData: data?.monthly,
              hoveredIndex,
              dateFormat: 'monthly',
              platformActive
            })
          );
        }}
      >
        <SwitchText active={dateFormat === 'monthly'}>Monthly</SwitchText>
      </SwitchButtonContainer>
    </SwitchContainer>
  );
};

export default PerformanceChartSwitch;

const SwitchContainer = styled.div`
  display: flex;
  height: 48px;
  padding: 4px;
  align-items: flex-start;
  border-radius: 40px;
  border: 0.6px solid ${COLOR.Gray_300};
  margin-right: 20px;
`;

const SwitchButtonContainer = styled.div<ActiveProps>`
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background-color: ${props => (props.active ? COLOR.Black : 'none')};
  :hover {
    cursor: pointer;
  }
`;
const SwitchText = styled(TEXT.Bu3_Reg)<ActiveProps>`
  color: ${props => (props.active ? COLOR.White : COLOR.Gray_D)};
  font-weight: ${props => (props.active ? 700 : 400)};
`;
