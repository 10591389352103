import { action, observable } from 'mobx';
import { ImageProp } from '../components/organisms/CreateCampaign/ImageSelector';
import { minusHours, minusMinutes } from '../utils';
import { PATCH, uploadFileAsync } from '../utils/Networking';

export class EditDealStore {
  @observable
  editing: boolean = false;

  @action
  editDeal = async ({
    dealId,
    photos,
    edittedName,
    edittedLimit,
    startDate,
    dueDate,
    postingDate,
    submissionDate
  }: {
    dealId: string;
    photos: ImageProp[];
    edittedName: string;
    edittedLimit: number;
    startDate: Date;
    dueDate: Date;
    postingDate: Date;
    submissionDate: Date;
  }) => {
    const photosProm = await Promise.all(
      photos.map(photo => {
        const { file } = photo;
        if (!!file) return uploadFileAsync(file);
        return { imageUrl: photo.url };
      })
    );
    const photosArray = photosProm.map(({ imageUrl }) => imageUrl);
    const editedDeal = await PATCH(`/deals/${dealId}`, {
      photos: photosArray,
      name: edittedName,
      limit: edittedLimit,
      startDate: startDate,
      postingDate: postingDate,
      dueDate: dueDate,
      submissionDate: submissionDate
    });
    this.editing = false;
    return editedDeal;
  };
}

export const editDealStore = new EditDealStore();
