import { observer } from 'mobx-react';
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { ISavedInfluencer } from '../../../types/api/getInfluencer';
import { difTime, genderFieldToText } from '../../../utils';
import { ColumnContainer } from '../../../utils/styling/general';
import REmptyState from '../../atoms/REmptyState';
import RLoading from '../../atoms/RLoading';
import FavoriteInfluencerCard from '../Influencer/FavoriteInfluencerCard';
interface Props {
  influList: {
    data: any;
    metadata: {
      count: number;
      offset: number;
      limit: number;
    };
  };
  navigate: NavigateFunction;
  loading: boolean;
}

function FavoriteInfluList(props: Props) {
  const { influList, navigate, loading } = props;

  return (
    <Container>
      {influList?.data?.length === 0 ? (
        loading ? (
          <RLoading containerStyle={{ alignSelf: 'center' }} />
        ) : (
          <REmptyState
            graphic={
              <EmptyStateImage
                src={require('../../../assets/images/state/NoFavorite.png')}
              />
            }
            header="ไม่พบอินฟลูเอนเซอร์ที่คุณบันทึกไว้"
            descriptions={[
              'ยังไม่มี Influencer ที่คุณบันทึกไว้',
              'เริ่มค้นหาได้เลย'
            ]}
            buttonText="Discover"
            onClick={() => {
              navigate('/discover');
            }}
          />
        )
      ) : (
        <>
          <CardContainer>
            {influList.data.map((influencer: ISavedInfluencer) => {
              const { account, instagram, tiktok, facebook } = influencer;
              const {
                name,
                img,
                profileImages,
                id: accountId,
                interests,
                rateCard,
                review,
                gender,
                birthday
              } = account;
              const { avgRating = 0, totalReview = 0 } = review;
              const id = instagram?.id ?? tiktok?.id ?? facebook?.id ?? '';
              const instagramFollowers = instagram?.followers_count ?? 0;
              const tiktokFollowers = tiktok?.followers_count ?? 0;
              const facebookFollowers = facebook?.followers_count ?? 0;
              const age = difTime(birthday, true, false, false, true);
              return (
                <FavoriteInfluencerCard
                  username={name}
                  influencerRating={avgRating}
                  totalRating={totalReview}
                  ratingCount={0}
                  profile_picture={img}
                  cover_picture={profileImages[0]}
                  id={id}
                  accountId={accountId}
                  category={interests ?? []}
                  rateCard={rateCard}
                  instagramFollowers={instagramFollowers}
                  tiktokFollowers={tiktokFollowers}
                  facebookFollowers={facebookFollowers}
                  gender={genderFieldToText(gender)}
                  age={age}
                />
              );
            })}
          </CardContainer>
          {loading && <RLoading />}
        </>
      )}
    </Container>
  );
}

export default observer(FavoriteInfluList);

const Container = styled(ColumnContainer)`
  display: flex;
  padding: 32px;
  width: 100%;
  align-items: center;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  width: 100%;
`;

const EmptyStateImage = styled.img`
  width: 225px;
  height: 225px;
`;
