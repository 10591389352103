import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { FontNotoSansBold } from '../../../fonts/font';
import { TEXT } from '../../../fonts/text';
import { PasswordCodeProp } from '../../../types/resetpassword';
import RButton from '../../atoms/RButton';

interface Props {
  changePage: () => void;
  changeBack: () => void;
  email: string;
  code: PasswordCodeProp;
  checkIsValidCode: ({ code }: { code: string }) => Promise<boolean>;
}

interface FormControlProps {
  id: 'first' | 'second' | 'third' | 'fourth' | 'fifth' | 'sixth';
  isError: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

function ForgotPasswordCodeDetail(props: Props) {
  const { changePage, changeBack, email, code, checkIsValidCode } = props;
  const [codeError, setCodeError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const CodePress = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const inputs = document.querySelectorAll(
        'input.resetCode'
      ) as NodeListOf<HTMLInputElement>;
      let tempCode = '';
      inputs.forEach(input => {
        tempCode += input.value;
      });
      const result = await checkIsValidCode({ code: tempCode });
      setLoading(false);
      if (result) {
        code.value = tempCode;
        changePage();
      } else {
        setCodeError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderText = () => {
    if (codeError) {
      return <BottomRedText>รหัสไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง</BottomRedText>;
    }
    return (
      <BottomText>
        อีเมลจะใช้เวลา ในการส่งประมาณ 3-5 นาที ยังไม่ได้รับอีเมล?{' '}
        <LinkContainer to={forgotpasswordLink}>
          <LinkText>ส่งอีกครั้ง</LinkText>
        </LinkContainer>
      </BottomText>
    );
  };

  function OTPInput() {
    const inputs = document.querySelectorAll(
      'input.resetCode'
    ) as NodeListOf<HTMLInputElement>;
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', event => {
        if (event.key === 'Backspace') {
          if (!!inputs[i].value) {
            inputs[i].value = '';
            return;
          }
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    }
  }

  useEffect(() => {
    OTPInput();
  }, []);

  return (
    <Container>
      <LargeText>รหัสยืนยัน</LargeText>
      <SmallText>
        กรอกรหัส 6 หลักที่คุณได้รับในอีเมล <BoldText> {email}</BoldText>
      </SmallText>

      <FormContainer>
        <Formcontrol
          className="resetCode"
          type="text"
          id="first"
          isError={codeError}
          maxLength={1}
          onChange={e => (code.valueArray[0] = e.target.value)}
        />
        <Formcontrol
          className="resetCode"
          type="text"
          id="second"
          isError={codeError}
          maxLength={1}
          onChange={e => (code.valueArray[1] = e.target.value)}
        />
        <Formcontrol
          className="resetCode"
          type="text"
          id="third"
          isError={codeError}
          maxLength={1}
          onChange={e => (code.valueArray[2] = e.target.value)}
        />
        <Formcontrol
          className="resetCode"
          type="text"
          id="fourth"
          isError={codeError}
          maxLength={1}
          onChange={e => (code.valueArray[3] = e.target.value)}
        />
        <Formcontrol
          className="resetCode"
          type="text"
          id="fifth"
          isError={codeError}
          maxLength={1}
          onChange={e => (code.valueArray[4] = e.target.value)}
        />
        <Formcontrol
          className="resetCode"
          type="text"
          id="sixth"
          isError={codeError}
          maxLength={1}
          onChange={e => (code.valueArray[5] = e.target.value)}
        />
      </FormContainer>
      {renderText()}
      <ButtonContainer>
        <RButton
          title="กลับ"
          onPress={changeBack}
          active={!loading}
          backgroundColor={COLOR.White}
          hoverColor={COLOR.Blue_50}
          textColor={COLOR.Blue_700}
        />
        <RButton
          title="ยืนยัน"
          onPress={CodePress}
          active={!loading}
          containerStyle={{ marginLeft: 14 }}
        />
      </ButtonContainer>
    </Container>
  );
}

export default ForgotPasswordCodeDetail;

const LargeText = styled(TEXT.H4_Bold)`
  margin-bottom: 16px;
`;

const SmallText = styled(TEXT.B4_Reg)``;

const BoldText = styled(TEXT.B4_Bold)``;

const BottomText = styled(TEXT.B4_Reg)`
  margin-top: 35px;
`;

const BottomRedText = styled(TEXT.B4_Reg)`
  margin-top: 35px;
  color: ${COLOR.Warning};
`;

const LinkContainer = styled(Link)`
  margin-top: 16px;
  text-decoration: none;
  width: 90px;
`;

const LinkText = styled(TEXT.Bu3_Bold)`
  color: ${COLOR.Blue_700};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const FormContainer = styled.div`
  margin-top: 28px;
`;

const Formcontrol = styled.input<FormControlProps>`
  font-family: ${FontNotoSansBold};
  font-weight: 700;
  font-size: 34px;
  line-height: 52px;
  -webkit-transition: none;
  transition: none;
  width: 45px;
  height: 56px;
  margin-right: ${props => (props.id === 'sixth' ? '0px' : '24px')};
  text-align: center;
  border: 1px solid ${COLOR.Gray_300};
  color: ${COLOR.Black};
  border-radius: 10px;
  caret-color: transparent;
  border-color: ${props => (props.isError ? COLOR.Warning : COLOR.Gray_300)};

  &:focus {
    border-color: ${COLOR.Black};
    outline: 0;
    transition:
      color 0.15s ease,
      background-color 0.15s ease,
      border-color 0.15s ease,
      box-shadow 0.15s ease;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
  align-self: flex-end;
`;

const forgotpasswordLink = `/forgotpassword`;
