import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import Facebook from '../../../assets/images/registerFrom/Facebook.svg';
import Twitter from '../../../assets/images/registerFrom/Twitter.svg';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import RInfoDescription from '../../atoms/RInfoDescription';

function ContactUs() {
  const lineLink = 'https://lin.ee/WHP5sdH';
  const instagramLink = 'https://instagram.com/picklethailand';
  const facebookLink = 'https://facebook.com/anotherdot.co';
  const tiktokLink = 'https://www.tiktok.com/@anotherdot.co';
  // const twitterLink = "https://twitter.com/picklethailand";
  // const youtubeLink =
  //   "https://www.youtube.com/channel/UCwfXkDPsVl8OWpJw8drJf_w";
  const websiteLink = 'https://anotherdot.co';

  return (
    <Container>
      <HeaderContainer>
        <BoldH5Text>ติดต่อเรา</BoldH5Text>
      </HeaderContainer>
      <InfoContainer>
        <RInfoDescription
          iconName="Email"
          value="ที่อยู่บริษัท"
          description=""
          style={{
            justifyContent: 'space-between',
            marginLeft: '8px'
          }}
        />
      </InfoContainer>
      <ColumnContainer>
        <BoldH7Text>บริษัท อนาเธอร์ ดอท จำกัด</BoldH7Text>
        <B3Text>
          เลขที่ 3 ซอยพระรามเก้า 34 แขวงหัวหมาก เขตบางกะปิ กรุงเทพมหานคร 10240
        </B3Text>
      </ColumnContainer>
      <InfoContainer>
        <RInfoDescription
          iconName="Phone"
          value="เบอร์โทรศัพท์"
          description="098-829-9341"
          style={{
            height: '51px',
            justifyContent: 'space-between',
            marginLeft: '8px',
            marginBottom: '28px'
          }}
        />
      </InfoContainer>
      <InfoContainer>
        <RInfoDescription
          iconName="Email"
          value="อีเมลสำหรับติดต่อ"
          description="contact@anotherdot.co"
          style={{
            height: '51px',
            justifyContent: 'space-between',
            marginLeft: '8px',
            marginBottom: '28px'
          }}
        />
      </InfoContainer>
      <InfoContainer>
        <RInfoDescription
          iconName="Email"
          value="ช่องทางอื่นๆ"
          description=""
          style={{
            justifyContent: 'space-between',
            marginLeft: '8px',
            marginBottom: '16px'
          }}
        />
      </InfoContainer>
      <PlatformContainer>
        <ImageContainer href={lineLink} target="_blank">
          <Image
            src={require('../../../assets/images/registerFrom/Line.png')}
          />
        </ImageContainer>
        <ImageContainer href={facebookLink} target="_blank">
          <Facebook width={35} height={35} />
        </ImageContainer>
        <ImageContainer href={instagramLink} target="_blank">
          <Image
            src={require('../../../assets/images/registerFrom/Instagram.png')}
          />
        </ImageContainer>
        <ImageContainer href={tiktokLink} target="_blank">
          <Image
            src={require('../../../assets/images/registerFrom/Tiktok.png')}
            style={{
              width: '42px',
              height: '42px',
              marginTop: '-2px'
            }}
          />
        </ImageContainer>
        {/* <ImageContainer href={twitterLink} target="_blank">
          <Twitter width={35} height={35} style={{ borderRadius: 50 }} />
        </ImageContainer> */}
        {/* <ImageContainer href={youtubeLink} target="_blank">
          <Image
            src={require("../../../assets/images/registerFrom/Youtube.png")}
            style={{
              width: "42px",
              height: "42px",
              marginTop: "-2px",
            }}
          />
        </ImageContainer> */}
        <ImageContainer href={websiteLink} target="_blank">
          <RXIcon name="Web" size={40} />
        </ImageContainer>
      </PlatformContainer>
    </Container>
  );
}

export default ContactUs;

const Container = styled.div`
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

const HeaderContainer = styled.div`
  padding: 24px 0px 13px 56px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const InfoContainer = styled.div`
  display: flex;
  margin-left: 56px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 84px;
`;

const PlatformContainer = styled.div`
  display: flex;
  width: 434px;
  justify-content: space-between;
  padding: 0px 0px 30px 84px;
`;

const ImageContainer = styled.a``;

const Image = styled.img`
  width: 35px;
  height: 35px;

  :hover {
    cursor: pointer;
  }
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const B3Text = styled(TEXT.B3_Reg)``;
