import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from 'styled-components';
import CompanyCardDetail from '../../molecules/Survey/CompanyCardDetail';
import SurveyCard from './SurveyCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 512px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.102);
  border-radius: 24px;
  margin-top: 158px;
  padding: 37px 60px;
`;

function CompanyCard() {
  const [page, setPage] = useState<number>(1);

  const renderComponent = () => {
    switch (page) {
      case 1:
        return (
          <Container>
            <CompanyCardDetail changePage={() => setPage(2)} />
          </Container>
        );
      case 2:
        return <SurveyCard />;
    }
  };
  return <>{renderComponent()}</>;
}

export default observer(CompanyCard);
