import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { IInfluencerReview } from '../../../types/api/getInfluencer';
import {
  difTime,
  PLACEHOLDER_IMAGE,
  reviewTagBinaryConverter
} from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';

interface Props {
  reviewDetail: IInfluencerReview;
  isLast?: boolean;
  setImageList?: React.Dispatch<React.SetStateAction<string[]>>;
  setStartIndex?: React.Dispatch<React.SetStateAction<number>>;
}

function InfluencerReviewCard(props: Props) {
  const { reviewDetail, isLast = false, setImageList, setStartIndex } = props;
  const { brand, media, doneAt, review } = reviewDetail;
  const images: string[] = [];

  media.every(singleMedia => {
    if (images.length > 4) {
      return false;
    } else {
      switch (singleMedia.media_type) {
        case 'IMAGE':
          if (!!singleMedia.media_url) images.push(singleMedia.media_url);
          return true;
        case 'VIDEO':
          if (!!singleMedia.thumbnail_url)
            images.push(singleMedia.thumbnail_url);
          return true;
        case 'CAROUSEL_ALBUM':
          singleMedia.children?.data.every(child => {
            if (images.length > 4) return false;
            if (child.media_type === 'VIDEO') {
              if (!!child.thumbnail_url) images.push(child.thumbnail_url);
            } else if (child.media_type === 'IMAGE') {
              if (!!child.media_url) images.push(child.media_url);
            }
            return true;
          });
          return true;
        default:
          return true;
      }
    }
  });

  let tag: string[] = [];
  if (!!review?.reviewTag?.length) {
    tag = review.reviewTag.reduce(
      (result: string[], reviewTag: number, index: number) => {
        if (reviewTag !== 0) {
          result.push(reviewTagBinaryConverter(index));
        }
        return result;
      },
      []
    );
  }

  return (
    <Container isLast={isLast}>
      <HeaderContainer>
        <RowContainer>
          <ProfileImageContainer src={brand.img || PLACEHOLDER_IMAGE} />
          <ColumnContainer>
            <BoldBu2Text>{brand.name}</BoldBu2Text>
            <StarContainer>
              {new Array(5).fill('0').map((a, index) => {
                if (index < Math.floor(review.rate)) {
                  return (
                    <RXIcon
                      key={`star${index}`}
                      name="StarFilled"
                      color={COLOR.YELLOW}
                      size={20}
                    />
                  );
                }
                return (
                  <RXIcon
                    key={`star${index}`}
                    name="Star"
                    color={COLOR.YELLOW}
                    size={20}
                  />
                );
              })}
            </StarContainer>
          </ColumnContainer>
        </RowContainer>
        <GrayMB2Text>{difTime(doneAt, true, true)} ago</GrayMB2Text>
      </HeaderContainer>
      {!!tag.length && (
        <TagContainer>
          {tag.map((reviewTag, index) => {
            return (
              <ReviewTag key={reviewTag + index}>
                <Blue700B2Text>{reviewTag}</Blue700B2Text>
              </ReviewTag>
            );
          })}
        </TagContainer>
      )}
      {!!review.commend && (
        <CommentContainer>
          <B2Text>{review.commend}</B2Text>
        </CommentContainer>
      )}
      <ImagesContainer>
        {images.map((image, index) => {
          const haveMargin = (index + 1) % 5 !== 0 || index === 0;
          return (
            <ImageContainer
              src={image}
              onClick={() => {
                if (!!setStartIndex) setStartIndex(index);
                if (!!setImageList) setImageList(images);
              }}
              active={haveMargin}
            />
          );
        })}
      </ImagesContainer>
    </Container>
  );
}

export default InfluencerReviewCard;

const Container = styled.div<{ isLast: boolean }>`
  width: 100%;
  margin-top: 24px;
  border-bottom: ${props =>
    props.isLast ? 'none' : `1px dashed ${COLOR.Gray_200}`};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReviewTag = styled.div`
  padding: 8px 16px;
  margin-right: 11px;
  background-color: ${COLOR.Blue_50};
  border-radius: 25px;
`;

const CommentContainer = styled.div`
  margin-top: 11px;
  margin-bottom: 12px;
`;

const ProfileImageContainer = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 8px;
  background: ${COLOR.Gray_200};
  border: 2px solid ${COLOR.Blue_400};
  border-radius: 50px;
  object-fit: cover;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StarContainer = styled.div`
  display: flex;
  margin-left: -3px;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
`;

const ImageContainer = styled.img<ActiveProps>`
  width: 120px;
  height: 120px;
  margin-right: ${props => (props.active ? '16px' : '0px')};
  margin-bottom: 24px;
  background: ${COLOR.Gray_200};
  border-radius: 16px;
  object-fit: cover;

  :hover {
    cursor: pointer;
  }
`;

const Blue700B2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.Blue_700};
`;

const B2Text = styled(TEXT.B2_Reg)``;

const GrayMB2Text = styled(TEXT.B2_Reg)`
  color: ${COLOR.Gray_M};
`;

const BoldBu2Text = styled(TEXT.Bu2_Bold)``;
