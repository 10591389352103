import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { ColumnContainer, RowContainer } from '../../../utils/styling/general';
import { attribute as attributeOptions } from '../../organisms/CreateCampaign/Options';
import { useState } from 'react';

interface ListProps {
  attributes: string[];
  tooltipActive: boolean;
  setTooltipActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttributesList = (props: ListProps) => {
  const { attributes, tooltipActive, setTooltipActive } = props;
  const components: JSX.Element[] = [];
  attributes.map((att, index) => {
    const label =
      attributeOptions.find(option => option.value === att)?.label || att;

    if (index === 5) {
      const otherAttributes = attributes
        .filter((_, index) => index > 4)
        .map(att => {
          const label =
            attributeOptions.find(option => option.value === att)?.label || att;
          return (
            <AttributeContainer>
              <Bu3Text>{label}</Bu3Text>
            </AttributeContainer>
          );
        });
      components.push(
        <AttributeContainer
          onMouseEnter={() => setTooltipActive(true)}
          onMouseLeave={() => setTooltipActive(false)}
        >
          <Bu3Text>{`+ ${attributes.length - index}`}</Bu3Text>
          <TooltipContainer active={tooltipActive}>
            <AttributesContainer>{otherAttributes}</AttributesContainer>
          </TooltipContainer>
        </AttributeContainer>
      );
    } else if (index < 5) {
      components.push(
        <AttributeContainer>
          <Bu3Text>{label}</Bu3Text>
        </AttributeContainer>
      );
    }
  });

  return <AttributesContainer>{components}</AttributesContainer>;
};
interface Props {
  attributes: string[];
}

const CardAttributes = (props: Props) => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);
  const { attributes = [] } = props;

  const filteredAttributes = attributes.filter(att =>
    attributeOptions.find(option => option.value === att)
  );

  if (filteredAttributes.length === 0) return <></>;

  return (
    <GapColumnContainer>
      <WhiteB3Text>Lifestyle</WhiteB3Text>
      <AttributesList
        attributes={filteredAttributes}
        tooltipActive={tooltipActive}
        setTooltipActive={setTooltipActive}
      />
    </GapColumnContainer>
  );
};

export default CardAttributes;

const AttributesContainer = styled(RowContainer)`
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
`;

const AttributeContainer = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0.4px solid ${COLOR.Black};
  background-color: ${COLOR.Gray_100};
  position: relative;
`;

const TooltipContainer = styled.div<ActiveProps>`
  position: absolute;
  top: 36px;
  right: 0px;
  gap: 8px;
  padding: 24px;
  background: ${COLOR.Black};
  border-radius: 12px;
  border: 0.6px solid ${COLOR.Gray_300};
  align-items: flex-start;
  flex-wrap: wrap;
  display: ${props => (props.active ? 'flex' : 'none')};
  width: 300px;
  opacity: ${props => (props.active ? 1 : 0)};
  z-index: 99999;
`;

const GapColumnContainer = styled(ColumnContainer)`
  gap: 8px;
  align-items: flex-start;
`;

const Bu3Text = styled(TEXT.Bu3_Reg)``;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
  opacity: 0.8;
`;
