import styled from 'styled-components';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import { COLOR } from '../../../../fonts/color';
import {
  FontAktivGroteskRegular,
  FontAktivGroteskThaiRegular
} from '../../../../fonts/font';
import { ActiveProps } from '../../../../types';
import { influencerCaption, tierType } from '../../../../types/createDeal';
import { RowContainer } from '../../../../utils/styling/general';
import RTextarea from '../../../atoms/RTextarea';

interface Props {
  haveNano: boolean;
  haveMicro: boolean;
  haveMid: boolean;
  haveMacro: boolean;
  caption: influencerCaption;
  setCaption: (caption: influencerCaption) => void;
}

function Brief(props: Props) {
  const { haveMacro, haveMicro, haveMid, haveNano, caption, setCaption } =
    props;

  const warningActive = !haveMacro && !haveMicro && !haveNano && !haveMid;

  const TextAreaComponent = () => {
    return (
      <RTextarea
        inputProp={{
          placeholder:
            'ครีมกันแดดจาก XXXXX! ตัวนี้เป็นครีมกันแดดที่ไม่มีส่วนผสมของแอลกอฮอล์และน้ำหอมจึงทำให้ผิว แพ้ง่ายสามารถใช้ได้โดยไม่ทำให้เกิดการระคายเคือง ใช้ได้ทุกวันเพื่อปกป้องผิวจากแดด',
          style: {
            display: 'flex',
            width: 568,
            height: 180,
            paddingTop: 16,
            paddingBottom: 16,
            paddingRight: 24,
            paddingLeft: 24,
            gap: 10,
            borderRadius: 16,
            border: `1px solid ${COLOR.Gray_400}`,
            background: `${ANDCOLOR.WHITE}`
          },
          defaultValue: caption.nano,
          onBlur: e => {
            setCaption({
              ...caption,
              nano: e.target.value.trim(),
              micro: e.target.value.trim(),
              mid: e.target.value.trim(),
              macro: e.target.value.trim()
            });
          }
        }}
      />
    );
  };

  const CaptionComponent = () => {
    const components: JSX.Element[] = [];
    if (haveNano || haveMicro || haveMid || haveMacro) {
      components.push(<TextAreaComponent key={''} />);
    }
    return <>{components}</>;
  };

  const WarningComponent = () => {
    if (warningActive)
      return <PinkB3Text>*คุณยังไม่ได้เลือกจำนวน Influencers</PinkB3Text>;
    return <></>;
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>บรีฟงาน</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <GapRowContainer active={warningActive}>
          <Body3Text>ระบุสิ่งที่ Influencer แต่ละระดับต้องทำในแคมเปญ</Body3Text>
        </GapRowContainer>
        <WarningComponent />
        <CaptionComponent />
      </ContentContainer>
    </Container>
  );
}

export default Brief;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 568px;
`;

const GapRowContainer = styled(RowContainer)<ActiveProps>`
  gap: 8px;
  align-items: center;
  margin-bottom: ${props => (props.active ? '8px' : '16px')};
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const TextBox = styled.textarea`
  display: flex;
  width: 568px;
  height: 180px;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid ${COLOR.Gray_400};
  background: ${ANDCOLOR.WHITE};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLOR.Gray_300};
    opacity: 1; /* Firefox */
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${COLOR.Gray_300};
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${COLOR.Gray_300};
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
  white-space: nowrap;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;

const UnderlineBody3Text = styled(ANDTEXT.body3BOLD)`
  text-decoration: underline;
`;

const PinkB3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Warning};
  margin-bottom: 24px;
`;

const ButtonText = styled(ANDTEXT.button)``;
