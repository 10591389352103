import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DealPerformanceTableData, PlatformType } from '../../../types/deal';
import { Work } from '../../../types/work';
import { FlexStartColumnContainer } from '../../../utils/styling/general';
import PostList from './PostList';
import PostTable from './PostTable';

const itemPerPage = 12;

interface ContentProps extends Props {
  page: number;
}

const ContentComponent = (props: ContentProps) => {
  const { displayState, campaignWorks, tableData, page, platform } = props;

  const startIndex = (page - 1) * itemPerPage;
  const endIndex = page * itemPerPage;
  if (displayState === 'grid')
    return (
      <PostList campaignWorks={campaignWorks.slice(startIndex, endIndex)} />
    );
  return (
    <PostTable
      tableData={tableData.slice(startIndex, endIndex)}
      platform={platform}
    />
  );
};

interface Props {
  displayState: 'grid' | 'table';
  campaignWorks: Work[];
  tableData: DealPerformanceTableData[];
  platform: PlatformType;
}

const InfluencerPerformanceData = (props: Props) => {
  const { displayState, campaignWorks, tableData, platform } = props;
  const [page, setPage] = useState<number>(1);
  const pageCount = Math.ceil(
    (displayState === 'grid' ? campaignWorks.length : tableData.length) /
      itemPerPage
  );

  useEffect(() => {
    setPage(1);
  }, [displayState]);

  return (
    <Container>
      <ContentComponent {...props} page={page} />
      <Pagination
        count={pageCount}
        page={page}
        defaultValue={page}
        onChange={(_, value) => setPage(value)}
        style={{ alignSelf: 'flex-end' }}
      />
    </Container>
  );
};

export default InfluencerPerformanceData;

const Container = styled(FlexStartColumnContainer)`
  width: 100%;
  gap: 16px;
`;
