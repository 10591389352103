import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { TDealStatus } from '../../../stores/logic/deals';
import { toDecimalNumber } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';

interface Props {
  status: TDealStatus;
  waitingForEdit: boolean;
  workUpdated: boolean;
  followers: number;
  message?: string;
  lastMessageAt?: string;
}

const StatusTag = (props: Props) => {
  const {
    status,
    workUpdated,
    waitingForEdit,
    followers,
    message,
    lastMessageAt
  } = props;

  if (workUpdated) {
    return (
      <UpdatedContainer>
        <RXIcon name="Edit" color={COLOR.Blue_700} size={16} />
        <Blue700B4Text>มีการอัพเดต</Blue700B4Text>
      </UpdatedContainer>
    );
  }

  if (waitingForEdit) {
    return (
      <WaitingForEditContainer>
        <RXIcon name="Waiting" color={COLOR.Yellow_M} size={16} />
        <LemonB4Text>รอการแก้ไข</LemonB4Text>
      </WaitingForEditContainer>
    );
  }

  switch (status) {
    case 'pending':
    case 'counterPending':
      return (
        <TextContainer>
          <BoldB4Text>{toDecimalNumber({ number: followers })}</BoldB4Text>
          <B4Text> Followers</B4Text>
        </TextContainer>
      );
    case 'ongoing':
      return (
        <TextContainer>
          <MessageText>{message}</MessageText>
          <GrayMB5Text>{lastMessageAt}</GrayMB5Text>
        </TextContainer>
      );
    case 'check':
      return (
        <CheckContainer>
          <RXIcon name="File" color={COLOR.Green_M} size={16} />
          <GreenMB4Text>ตรวจงาน</GreenMB4Text>
        </CheckContainer>
      );
    case 'done':
      return (
        <CheckContainer>
          <RXIcon name="CheckCircle" color={COLOR.Green_M} size={16} />
          <GreenMB4Text>เสร็จสิ้น</GreenMB4Text>
        </CheckContainer>
      );
    case 'rejected':
    case 'cancelled':
      return (
        <CancelledContainer>
          <RXIcon name="CloseCircle" color={COLOR.Warning} size={16} />
          <WarningB4Text>ยกเลิก</WarningB4Text>
        </CancelledContainer>
      );
  }
};

export default StatusTag;

const Container = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
`;

const UpdatedContainer = styled(Container)`
  border: 0.6px solid ${COLOR.Blue_500};
  background: ${COLOR.Blue_50};
`;

const WaitingForEditContainer = styled(Container)`
  border: 0.6px solid ${COLOR.Yellow_M};
  background: ${COLOR.Yellow_L};
`;

const CheckContainer = styled(Container)`
  border: 0.6px solid ${COLOR.Herbal};
  background: ${COLOR.Green_L};
`;

const CancelledContainer = styled(Container)`
  border: 0.6px solid ${COLOR.Raspberry};
  background: ${COLOR.Red_L};
`;

const TextContainer = styled(RowContainer)`
  width: 100%;
  gap: 4px;
`;

const Blue700B4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Blue_700};
`;

const LemonB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Lemon};
`;

const WarningB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Warning};
`;

const GreenMB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Green_M};
`;

const B4Text = styled(TEXT.B4_Reg)``;

const MessageText = styled(TEXT.B4_Reg)`
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: start;
`;

const BoldB4Text = styled(TEXT.B4_Bold)``;

const GrayMB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_M};
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: end;
`;
