import { Alert, Slide, Snackbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import ScrollToTop from '../../components/atoms/ScrollToTop';
import CreateDealModal from '../../components/molecules/CreateCampaign/CreateDealModal';
import CampaignFooter from '../../components/organisms/NewCreateCampaign/CampaignFooter';
import Detail from '../../components/organisms/NewCreateCampaign/Detail';
import Preview from '../../components/organisms/NewCreateCampaign/Preview';
import ScopeOfWork from '../../components/organisms/NewCreateCampaign/ScopeOfWork';
import Summary from '../../components/organisms/NewCreateCampaign/Summary';
import TabNavigator from '../../components/organisms/NewCreateCampaign/TabNavigator';
import TargetInfluencer from '../../components/organisms/NewCreateCampaign/TargetInfluencer';
import { ANDCOLOR } from '../../fonts/andColor';
import { ANDTEXT } from '../../fonts/andText';
import { COLOR } from '../../fonts/color';
import {
  useAccountStore,
  useCreateDealStore
} from '../../stores/initialize/Context';
import { useQuery } from '../../utils';
import { ColumnContainer, RowContainer } from '../../utils/styling/general';

export type pageIndex = 1 | 2 | 3 | 4;

const transitionProp: React.ComponentType<
  TransitionProps & {
    children: React.ReactElement<any, any>;
  }
> = Slide;

function NewCreateCampaign() {
  const {
    newDeal,
    setDealData,
    saveNewDraft,
    createNewDeal,
    newIndexSucces,
    clearDealDocuments,
    getAvgData,
    averageData
  } = useCreateDealStore();
  const { getAuthToken } = useAccountStore();
  const [page, setPage] = useState<pageIndex>(1);
  const [activeSnack, setActiveSnack] = useState<boolean>(false);
  const [createDealActive, setCreateDealActive] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [triggerHeightChange, setTriggerHeightChange] =
    useState<boolean>(false);
  const [samePageNavigate, setSamePageNavigate] = useState<boolean>(false);

  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const tierRef = useRef<HTMLDivElement>(null);
  const queryPage = parseInt(query.get('page') || '1') as pageIndex;
  const state = location.state;

  const { influencersTier, platforms } = newDeal;

  const emptyData = {
    value: 0,
    avgEngagement: 0,
    avgReachs: 0,
    avgImpressions: 0
  };
  const nanoData =
    averageData.find(
      data => data.tier === 'nano' && data.platform === newDeal.platforms
    ) || emptyData;
  const microData =
    averageData.find(
      data => data.tier === 'micro' && data.platform === newDeal.platforms
    ) || emptyData;
  const midData =
    averageData.find(
      data => data.tier === 'mid' && data.platform === newDeal.platforms
    ) || emptyData;
  const macroData =
    averageData.find(
      data => data.tier === 'macro' && data.platform === newDeal.platforms
    ) || emptyData;

  const scrollToTier = () => {
    if (!!tierRef.current)
      tierRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const saveDealDraft = () => {
    setActiveSnack(true);
    saveNewDraft();
  };

  const createDeal = async () => {
    setCreateDealActive(true);
    const deal = await createNewDeal(onProgress);
    setCreateDealActive(false);
    navigate(`/deal?dealId=${deal.dealId}&status=recommend`);
  };

  const onProgress = ({ total, loaded }: { total: number; loaded: number }) => {
    const percent = Math.ceil((loaded / total) * 100);
    setProgress(percent);
  };

  const PageComponent = () => {
    switch (page) {
      case 1:
        return (
          <TargetInfluencer
            deal={newDeal}
            setDealData={setDealData}
            tierRef={tierRef}
          />
        );
      case 2:
        return (
          <ScopeOfWork
            deal={newDeal}
            setDealData={setDealData}
            triggerHeightChange={triggerHeightChange}
            setTriggerHeightChange={setTriggerHeightChange}
          />
        );
      case 3:
        return <Detail deal={newDeal} setDealData={setDealData} />;
      case 4:
        return <Preview deal={newDeal} setPage={setPage} />;
    }
  };

  useEffect(() => {
    setPage(queryPage);
    if (!newDeal.documents[0]) clearDealDocuments();
  }, [queryPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    if (!getAuthToken()) {
      navigate('/login');
    }
    getAvgData();
  }, []);

  useEffect(() => {
    if (samePageNavigate) {
      scrollToTier();
      setSamePageNavigate(false);
    } else
      setTimeout(() => {
        if (state === 'tier') scrollToTier();
      }, 100);
  }, [state, samePageNavigate]);

  return (
    <>
      <Container ref={containerRef}>
        <ScrollToTop />
        <PageLayoutContainer>
          <TabNavigator
            navigate={navigate}
            page={page}
            setPage={setPage}
            indexSuccess={newIndexSucces}
          />
          <ContentContainer>
            <PageComponent />
            <Summary
              page={page}
              nano={{ ...nanoData, value: influencersTier.nano.value }}
              micro={{ ...microData, value: influencersTier.micro.value }}
              mid={{ ...midData, value: influencersTier.mid.value }}
              macro={{ ...macroData, value: influencersTier.macro.value }}
              platform={platforms}
              containerRef={containerRef}
              triggerHeightChange={triggerHeightChange}
              navigation={navigate}
              setSamePageNavigate={setSamePageNavigate}
            />
          </ContentContainer>
        </PageLayoutContainer>
      </Container>
      <CampaignFooter
        indexSuccess={newIndexSucces}
        page={page}
        setPage={setPage}
        saveDraft={saveDealDraft}
        navigate={navigate}
        createDeal={createDeal}
      />
      <Snackbar
        open={activeSnack}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setActiveSnack(false)}
        TransitionComponent={transitionProp}
        key={'campaignSnackBar'}
      >
        <Alert
          icon={<RXIcon name="CheckCircle" color={ANDCOLOR.BLUE} />}
          onClose={() => setActiveSnack(false)}
          sx={{
            height: '48px',
            width: '344px',
            bgcolor: COLOR.WHITE,
            alignItems: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Body2Text>บันทึกเรียบร้อย</Body2Text>
        </Alert>
      </Snackbar>
      <CreateDealModal
        modalVisible={createDealActive}
        setModalVisible={setCreateDealActive}
        progress={progress}
      />
    </>
  );
}

export default observer(NewCreateCampaign);

const Container = styled(RowContainer)`
  width: 100%;
  min-height: 100vh;
  align-items: flex-start;
  overscroll-behavior: none;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  min-width: 1200px;
  width: 100%;
  position: relative;
  padding-left: 36px;
  margin-bottom: 36px;
`;

const PageLayoutContainer = styled(ColumnContainer)`
  position: relative;
  align-items: flex-start;
  width: 100%;
`;

const Body2Text = styled(ANDTEXT.body2)``;
