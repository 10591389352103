import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../../fonts/color';
import { TEXT } from '../../../../fonts/text';
import ImageOverlay from '../../../atoms/ImageOverlay';

interface Props {
  brandImg: string;
  brandName: string;
  img: string;
  tags: 'Popular' | 'High Reach' | undefined;
}

function DiscoverCampaignCardImage(props: Props) {
  const { brandImg, brandName, img, tags } = props;

  const renderBrandInfo = () => {
    return (
      <UserContainer>
        <BrandImage src={brandImg} />
        <BoldB2Text>{brandName}</BoldB2Text>
      </UserContainer>
    );
  };

  const renderTag = () => {
    if (tags === 'Popular') {
      return (
        <TagContainer background={COLOR.PINK}>
          <RXIcon name="Popular" color={COLOR.WHITE} />
          <WhiteBoldB4Text>Popular</WhiteBoldB4Text>
        </TagContainer>
      );
    } else if (tags === 'High Reach') {
      return (
        <TagContainer background={COLOR.YELLOW}>
          <RXIcon name="Signal" color={COLOR.WHITE} />
          <WhiteBoldB4Text>High Reach</WhiteBoldB4Text>
        </TagContainer>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Container>
      <ImageContainer>
        {renderBrandInfo()}
        <Image src={img} />
        {renderTag()}
        <ImageOverlay
          zIndex={99}
          gradient={
            'linear-gradient(180deg,rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.12) 28%,rgba(0, 0, 0, 0.04) 100%)'
          }
        />
      </ImageContainer>
    </Container>
  );
}

export default DiscoverCampaignCardImage;

const Container = styled.div``;

const ImageContainer = styled.div`
  position: relative;
  width: 322px;
  height: 322px;
  overflow: hidden;
  border-radius: 16px;
`;

const UserContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 999;
`;

const BrandImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const TagContainer = styled.div<{ background: string }>`
  position: absolute;
  bottom: 24px;
  left: 24px;
  display: flex;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  gap: 4px;
  background: ${props => props.background};
  border-radius: 24px;
  z-index: 999;
`;

const Image = styled.img`
  width: 322px;
  height: 322px;
  object-fit: cover;
`;

const BoldB2Text = styled(TEXT.B2_Bold)`
  color: ${COLOR.White};
  max-width: 216px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WhiteBoldB4Text = styled(TEXT.B4_Bold)`
  margin-top: 2px;
  color: ${COLOR.White};
`;
