import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ANDTEXT } from '../../../../fonts/andText';
import { ANDCOLOR } from '../../../../fonts/andColor';
import {
  ColumnContainer,
  PureContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import { RXIcon } from 'rn-rx-icons';
import { pageIndex } from '../../../../pages/NewCreateCampaign/NewCreateCampaignPage';
import { PlatformType } from '../../../../types/deal';
import { ITierType, tierType } from '../../../../types/createDeal';
import { toCapitalize } from '../../../../utils';
import { changwat } from '../../../organisms/CreateCampaign/Options';

interface Props {
  setPage?: (index: pageIndex) => void;
  platform: PlatformType;
  influencersTier: ITierType;
  gender: string;
  audienceGender: string;
  location: string;
  audienceLocation: string;
  age: string;
  audienceAge: string;
  attribute: string[];
  reviewTag: string[];
  width?: number;
  haveEdit?: boolean;
}

function DealCardInfluencer(props: Props) {
  const {
    setPage,
    platform,
    influencersTier,
    gender,
    audienceAge,
    age,
    audienceGender,
    location,
    audienceLocation,
    attribute,
    reviewTag,
    haveEdit = true
  } = props;

  const [active, setActive] = useState<boolean>(true);

  const countExists = (value: number) => {
    if (value >= 1) return 1;
    return 0;
  };

  const uniqueTierCount = () => {
    let count = 0;
    if (influencersTier.nano.value >= 1) count++;
    if (influencersTier.micro.value >= 1) count++;
    if (influencersTier.mid.value >= 1) count++;
    if (influencersTier.macro.value >= 1) count++;
    return count;
  };

  const TierComponent = ({ tier }: { tier: tierType }) => {
    if (influencersTier[tier].value > 0)
      return (
        <Body2Text style={{ width: 408 }}>
          {toCapitalize(tier) + ' ' + influencersTier[tier].value + ' คน'}
        </Body2Text>
      );
    return <></>;
  };

  const GenderComponent = ({ gender }: { gender: string }) => {
    let genderText = '';
    if (gender.includes('W') && gender.includes('M') && gender.includes('L'))
      genderText = 'ทุกเพศ';
    else if (gender.includes('W') && gender.includes('M'))
      genderText = 'ชาย, หญิง';
    else if (gender.includes('M') && gender.includes('L'))
      genderText = 'ชาย, LGBT';
    else if (gender.includes('W') && gender.includes('L'))
      genderText = 'หญิง, LGBT';
    else if (gender.includes('W')) genderText = 'หญิง';
    else if (gender.includes('M')) genderText = 'ชาย';
    else if (gender.includes('L')) genderText = 'LGBT';

    return <Body2Text>เพศ : {genderText}</Body2Text>;
  };

  const AgeComponent = ({ age }: { age: string }) => {
    let ageArr = age.split('-');
    if (ageArr.length > 1)
      return <Body2Text>อายุ : {ageArr[0] + ' - ' + ageArr[1]}</Body2Text>;
    return <></>;
  };

  const AttributeComponent = () => {
    if (attribute.length === 0) return <></>;
    const components: JSX.Element[] = [];
    attribute.map(att => components.push(<Body2Text>{att}</Body2Text>));
    return (
      <FullWidthRowContainer>
        <BoldHeader7Text>Attribute ({attribute.length})</BoldHeader7Text>
        <DetailContainer>{components}</DetailContainer>
      </FullWidthRowContainer>
    );
  };

  const TagComponent = () => {
    if (reviewTag.length === 0) return <></>;
    const components: JSX.Element[] = [];
    reviewTag.map(att => components.push(<Body2Text>{att}</Body2Text>));
    return (
      <FullWidthRowContainer>
        <BoldHeader7Text>แท็กรีวิว ({reviewTag.length})</BoldHeader7Text>
        <DetailContainer>{components}</DetailContainer>
      </FullWidthRowContainer>
    );
  };

  const ContentComponent = () => {
    if (active)
      return (
        <ContentContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>ช่องทาง</BoldHeader7Text>
            <DetailContainer>
              <Body2Text>{toCapitalize(platform)}</Body2Text>
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>ระดับ ({uniqueTierCount()})</BoldHeader7Text>
            <DetailContainer>
              <TierComponent tier="nano" />
              <TierComponent tier="micro" />
              <TierComponent tier="mid" />
              <TierComponent tier="macro" />
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>ข้อมูลกลุ่ม Influencer</BoldHeader7Text>
            <DetailContainer>
              <GenderComponent gender={gender} />
              <AgeComponent age={age} />
              <Body2Text>
                จังหวัด :{' '}
                {changwat.find(province => province.value === location)?.label}
              </Body2Text>
            </DetailContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <BoldHeader7Text>ข้อมูลกลุ่มลูกค้าของแบรนด์</BoldHeader7Text>
            <DetailContainer>
              <GenderComponent gender={audienceGender} />
              <AgeComponent age={audienceAge} />
              <Body2Text>
                จังหวัด :{' '}
                {
                  changwat.find(province => province.value === audienceLocation)
                    ?.label
                }
              </Body2Text>
            </DetailContainer>
          </FullWidthRowContainer>
          <AttributeComponent />
          <TagComponent />
        </ContentContainer>
      );
    return <></>;
  };

  const EditComponent = () => {
    if (haveEdit)
      return (
        <EditContainer
          onClick={() => {
            if (!!setPage) setPage(1);
          }}
        >
          <RXIcon name="Edit" size={16} color={ANDCOLOR.BLUE} />
          <ButtonSmallText>แก้ไข</ButtonSmallText>
        </EditContainer>
      );
    return <></>;
  };

  return (
    <Container>
      <TitleContainer>
        <BoldHeader6Text>ขั้นตอนที่ 1 : ข้อมูลเชิงลึก</BoldHeader6Text>
        <RowContainer>
          <EditComponent />
          <IconContainer onClick={() => setActive(!active)}>
            <RXIcon
              name={active ? 'ArrowUp' : 'ArrowDown'}
              color={ANDCOLOR.BLUE}
            />
          </IconContainer>
        </RowContainer>
      </TitleContainer>
      <ContentComponent />
    </Container>
  );
}

export default DealCardInfluencer;

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 16px;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  align-items: flex-start;
  width: 100%;
`;

const DetailContainer = styled(ColumnContainer)`
  align-items: flex-start;
  width: 65%;
  gap: 4px;
`;

const TitleContainer = styled(SpaceBetweenRowContainer)`
  border-bottom: 1px solid ${ANDCOLOR.BLUE};
  padding-bottom: 16px;
  margin-bottom: 24px;
`;

const EditContainer = styled(RowContainer)`
  :hover {
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  margin-left: 16px;
  :hover {
    cursor: pointer;
  }
`;

const BoldHeader6Text = styled(ANDTEXT.header6BOLD)`
  color: ${ANDCOLOR.BLUE};
`;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  min-width: 300px;
`;

const Body2Text = styled(ANDTEXT.body2)`
  max-width: 448px;
  word-wrap: break-word;
  white-space: normal;
`;

const ButtonSmallText = styled(ANDTEXT.buttonSmall)`
  margin-left: 4px;
  color: ${ANDCOLOR.BLUE};
`;
