import { useEffect, useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled, { CSSProperties } from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';

export interface ImageProp {
  url: string;
  file?: File;
}

interface Props {
  images?: ImageProp[];
  haveLarge?: boolean;
  smallImage: number[];
  containerStyle?: CSSProperties;
  onSelect: (photoArray: ImageProp[]) => void;
}
// TODO: [For optimization] imageList can be removed and use only images
function ImageSelector(props: Props) {
  const { images, haveLarge, smallImage, containerStyle, onSelect } = props;
  const [imageList, setImageList] = useState<ImageProp[]>([]);
  // const filesLimit = 4;

  const fileLimit = haveLarge ? smallImage.length + 1 : smallImage.length;

  // Not use now, Multiple Image Upload
  // const uploadMultipleFiles = async (e: any) => {
  //   const { files } = e.target;
  //   let tempImage = new Array<{ url: string }>();
  //   if (Array.from(files).length > filesLimit) {
  //     e.preventDefault();
  //     return;
  //   }
  //   await Promise.all(
  //     Array.from(files).map(async (element: any, index: number) => {
  //       // const uploadedImage = await uploadFileAsync(element);
  //       tempImage.push({
  //         url: URL.createObjectURL(element),
  //         // imageUrl: uploadedImage.imageUrl,
  //       });
  //     })
  //   );
  //   setImageList(tempImage);
  // };

  useEffect(() => {
    setImageList(images || []);
  }, []);

  const uploadSingleFile = async (e: any) => {
    const { files } = e.target;
    if (!files) {
      return;
    }
    const fileArray: File[] = [files[0]] || [];
    let tempImage = [...imageList];
    if (!files[0].type.split('/')[1].includes('svg')) {
      tempImage[imageList.length] = {
        url: URL.createObjectURL(files[0]),
        file: files[0]
        // imageUrl: uploadedImage.imageUrl,
      };
    }
    onSelect(tempImage);
    setImageList(tempImage);
  };

  const onClickHandle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    imageList.splice(index, 1);
    let tempRemovedArray = [...imageList];
    onSelect(tempRemovedArray);
    setImageList(tempRemovedArray);
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const fileArray = e.dataTransfer.files || [];
    let tempImage = [...imageList];
    Array.from(fileArray).map((file, index) => {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg'
      ) {
        tempImage[tempImage.length] = {
          url: URL.createObjectURL(file),
          file: file
        };
      }
    });
    onSelect(tempImage);

    setImageList(tempImage);
  };

  const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderLargeImage = () => {
    if (!haveLarge) {
      return;
    }
    return (
      <LargeImageContainer>
        <ImageInput
          type="file"
          name="myImage"
          onChange={uploadSingleFile}
          accept="image/png, image/jpeg, image/jpg"
        />
        <PreviewImage
          src={imageList[0]?.url}
          style={{ opacity: imageList[0] ? 1 : 0 }}
        />
        {!!imageList[0]?.url && (
          <ButtonContainer onClick={e => onClickHandle(e, 0)}>
            <RXIcon name="Close" size={24} color={COLOR.Gray_300} />
          </ButtonContainer>
        )}
        <RXIcon name="Plus" size={36} color={COLOR.Gray_300} />
        <Body1Text style={{ marginTop: '10px' }}>
          ควรเป็นรูปที่น่าสนใจเกี่ยวกับสินค้า แบรนด์
          <br />
          หรือการโปรโมท สามารถใส่ได้หลายรูป *
        </Body1Text>
      </LargeImageContainer>
    );
  };

  return (
    <Container
      style={containerStyle}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
    >
      {renderLargeImage()}
      <SmallImagesContainer>
        {smallImage.map((e, index) => {
          const actualIndex = haveLarge ? index + 1 : index;
          return (
            <SmallImageContainer>
              <ImageInput
                type="file"
                name="myImage"
                onChange={uploadSingleFile}
                accept="image/png, image/jpeg, image/jpg"
              />
              <PreviewImage
                src={imageList[actualIndex]?.url}
                style={{ opacity: imageList[actualIndex] ? 1 : 0 }}
              />
              {!!imageList[actualIndex]?.url && (
                <ButtonContainer onClick={e => onClickHandle(e, actualIndex)}>
                  <RXIcon name="Close" size={24} color={COLOR.Gray_300} />
                </ButtonContainer>
              )}
              <RXIcon name="Plus" size={20} color={COLOR.Gray_300} />
              <Body1Text>เพิ่มรูป</Body1Text>
            </SmallImageContainer>
          );
        })}
      </SmallImagesContainer>
    </Container>
  );
}

export default ImageSelector;

const Container = styled.div``;

const LargeImageContainer = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 216px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.Gray_50};
  border: none;
  border-radius: 16px;

  :hover {
    cursor: pointer;
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const PreviewImage = styled.img`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  object-fit: cover;
  z-index: 100;
`;

const ButtonContainer = styled.button`
  position: absolute;
  top: -12px;
  right: -12px;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.Gray_200};
  border: none;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1000;
`;

const SmallImagesContainer = styled.label`
  display: flex;
  flex-direction: row;
`;

const SmallImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 120px;
  margin: 16px 16px 0px 0px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.Gray_50};
  border: none;
  border-radius: 16px;

  :hover {
    cursor: pointer;
  }
`;

const Body1Text = styled(TEXT.body1)`
  color: ${COLOR.Gray_300};
  text-align: center;
`;
