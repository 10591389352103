import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../fonts/color';
import { TEXT } from '../fonts/text';

function PrivacyPolicyPage() {
  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <RXIcon name="Policy" size={32} />
          <BoldHeader5Text style={{ marginLeft: '8px' }}>
            นโยบายความเป็นส่วนตัว
          </BoldHeader5Text>
        </HeaderContainer>
        <HeaderTextContainer>
          <BoldBody1Text>1. บทนำ</BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
                   บริษัท แรบบิท ดิจิทัล กรุ๊ป จำกัด (“บริษัท”)
            ได้ออกแบบแพลตฟอร์ม Pickle (“แพลตฟอร์ม”)
            เพื่อทำหน้าที่เป็นสื่อกลางในการให้บริการและเชื่อมต่อระหว่าง
            Influencer และ Marketer (รวมเรียกว่า “ผู้ใช้บริการ”)
            ให้มีประสิทธิภาพมากที่สุด 
            <br />
            <br />
                   กรุณาศึกษานโยบายความเป็นส่วนตัวนี้โดยละเอียด
            เพื่อให้เข้าใจถึงเงื่อนไขและความรับผิดชอบของทางบริษัททีมีต่อทั้ง
            Influencer และ Marketer ในการเข้าใช้งานแพลตฟอร์มของทางบริษัท
            เมื่อผู้ใช้บริการได้กดเครื่องหมาย “ยอมรับ” หรือ “Accept”
            ที่แสดงบนหน้าจอของผู้ใช้บริการ ก่อนเริ่มการใช้บริการของแพลตฟอร์ม
            ทางบริษัทใคร่ขอเข้าใจถึงการยินยอมและยอมรับในนโยบายฉบับนี้
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text> 2. จุดประสงค์ของการเก็บข้อมูล</BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
                   ทางบริษัทมีความจำเป็นต้องขอเก็บรวบรวมข้อมูลของผู้ใช้บริการและยืนยันจะไม่เปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับการยินยอมล่วงหน้า
            เพื่อวัตถุประสงค์ดังต่อไปนี้ี้
            <br />
            <br />
            <li>
              เพื่อพัฒนาระบบและสร้างประสบการณ์การบริการในรูปแบบใหม่ที่จะสามารถตอบโจทย์ความต้องการให้กับทั้ง
              Influencer และ Marketer
            </li>
            <li>
              เพื่อใช้ในการระบุตัวตนของผู้ใช้บริการ
              และใช้ในการติดต่อประสานงานระหว่างกับทางบริษัท และกับบุคคลอื่น
              ที่มีความเกี่ยวข้องกับการให้บริการของทางบริษัท 
            </li>
            <li>
              เพื่อป้องกันการแอบอ้างจากบุคคลที่ไม่ได้รับอนุญาตจากทางบริษัทในการเข้าใช้งาน
            </li>
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>
            3. รายการข้อมูลที่ถูกจัดเก็บ และวิธีการจัดการข้อมูล
          </BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
            ข้อมูลที่ทางบริษัทจำเป็นต้องเก็บรวบรวม มีดังต่อไปนี้
            <br />
            <br />
            <li>
              ข้อมูลส่วนบุคคลของ Influencer
              ที่จำเป็นต่อการลงทะเบียนเพื่อเข้าสู่แพลตฟอร์ม อันได้แก่ ชื่อ
              นามสกุล อีเมล เบอร์โทรศัพท์ ความถนัดและความสนใจ
            </li>
            <li>
              ข้อมูลและบัญชีของ Influencer ที่ได้ผูกไว้กับสื่อสังคมออนไลน์
              (Social Media Account) ทั้ง Facebook Account, Facebook Page,
              Instagram Account ซึ่งได้มีการใส่ข้อมูลไว้ในบัญชีดังกล่าวอยู๋แล้ว
              อันได้แก่ ประวัติส่วนตัว เพศ ถิ่นที่อยู่ และวิธีการติดต่อ
              และอื่นๆ 
            </li>
            <li>
              หากมีบุคคลใด เชื่อมต่อผ่าน Facebook Page เดียวกันกับ Influencer
              บนแพลตฟอร์ม
              ทางบริษัทจะถือว่าบุคคลดังกล่าวเป็นตัวแทนหรือเป็นบุคคลเดียวกันกับ
              Influencer และข้อมูลทั้งหมดทั้งของ Influencer
              และบุคคลดังกล่าวจะถูกรวมกัน ถ้า Influencer
              ไม่ต้องการให้เกิดเหตุการณ์ดังกล่าวขึ้น
              กรุณาดูแลและตรวจสอบสิทธิการเข้าถึง Facebook Page ของท่านด้วย
            </li>
            <li>
              ในกรณีของ Marketer
              จำเป็นจะต้องสร้างบัญชีสำหรับการเข้าถึงแพลตฟอร์มของบริษัท
              ซึ่งมีเนื้อหาอันเกี่ยวเนื่องกับตัว Marketer
              เพื่อใช้ในการลงทะเบียนเข้าสู่ระบบ และเพื่อยืนยันถึงการเป็นเจ้าของ
              หรือเป็นผู้ที่ได้รับการยินยอมให้ดำเนินการแทน Marketer
              ในการติดต่อสื่อสารกับทาง Influencer และกับทางบริษัท
            </li>
            <li>
              ข้อมูลที่ถูกจัดเก็บในรูปแบบของการจัดเก็บฐานข้อมูลแบบอัตโนมัติ
              ซึ่งจะถูกจัดเก็บในระหว่างการใช้งานแพลตฟอร์ม ผ่านระบบคุกกี้
              (Cookies) ซึ่งจะถูกบันทึกในคอมพิวเตอร์
              เพื่อใช้ในการจัดเก็บและบริหารจัดการข้อมูลของผู้ใช้บริการ
            </li>
            <br />
            อย่างไรก็ตาม ทางบริษัทจะแจ้งความประสงค์ในการเก็บข้อมูล
            ให้ผู้ใช้บริการได้ทราบและยินยอมก่อนการใช้งาน และในกรณีที่บริษัทหรือ
            Marketer
            มีความประสงค์ที่จะนำข้อมูลข้างต้นบางส่วนของผู้ใช้บริการไปใช้งานหรือเปิดเผยต่อสาธารณะชนหรือต่อบุคคลที่สาม
            ทางบริษัทหรือ Marketer
            จะแจ้งให้ผู้ใช้บริการได้ทราบและขอความยินยอมจากตัวผู้ใช้บริการก่อนการนำข้อมูลนั้นไปใช้
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>4. การจัดเก็บและรักษาข้อมูล</BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
            บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการตลอดระยะเวลาที่ผู้ใช้บริการใช้บริการแพลตฟอร์มของทางบริษัท
            ทั้งนี้ บริษัทใคร่ขอเรียนว่า
            หากผู้ใช้บริการได้ทำการลบข้อมูลหรือทำการขอยกเลิกการลงทะเบียน
            ข้อมูลจะถูกลบออกจากแพลตฟอร์ม
            และทางบริษัทไม่สามารถที่จะกู้คืนข้อมูลดังกล่าวกลับมาได้
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>
            5. การเข้าถึงข้อมูลของผู้ใช้บริการ และความปลอดภัย
          </BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
            5.1 การเข้าถึงข้อมูลของผู้ใช้บริการ
            <br />
            ตามข้อมูลของในหัวข้อที่ 3 (รายการข้อมูลที่ถูกจัดเก็บ
            และวิธีการจัดการข้อมูล)
            ผู้ที่มีสิทธิในการเข้าถึงข้อมูลของผู้ใช้บริการนอกจากทางบริษัท
            มีดังต่อไปนี้
            <br />
            <br />
            <li>
              ตัวผู้ใช้บริการแพลตฟอร์มมีหน้าที่ดูแลและปรับแก้ข้อมูลส่วนบุคคลให้ถูกต้องและมีความเป็นปัจจุบันอยู่เสมอ
            </li>
            <li>
              Marketer อาจหมายถึงบุคคลหรือบริษัท
              ผู้เป็นเจ้าของสินค้าหรือบริการที่ได้ลงทะเบียนไว้ในแพลตฟอร์มตามที่ได้กําหนดไปเรียบร้อยแล้ว
              เพื่อการโปรโมทสินค้าและบริการของทาง Marketer
              อีกทั้งยังเป็นข้อมูลที่ Marketer
              ใช้เพื่อประกอบการตัดสินใจเลือกและให้ Influencer
              ได้รับงานที่มีคุณภาพและตรงกับความถนัดและความต้องการมากที่สุด 
            </li>
            <li>
              บริษัทภายนอก หรือ บุคคลภายนอกใดๆ
              ที่ต้องการทำการตลาดสำหรับสินค้าหรือบริการกับผู้ใช้บริการ
              โดยที่ผู้ใช้บริการไม่ได้ถอนความยินยอมในการเปิดเผยข้อมูล
              เพื่อให้บรรลุตามจุดมุ่หมายของทางบริษัท
            </li>
            <br />
            หากบริษัทมีความประสงค์ที่จะเปิดเผยข้อมูลส่วนบุคคลของท่านแก่บุคคลหรือบริษัทอื่นนอกเหนือจากที่ได้ระบุไว้
            ทางบริษัทจะแจ้งขอความยินยอมจากทางผู้ใช้บริการก่อนที่จะดำเนินการใดๆ
            <br />
            <br />
            5.2 ความปลอดภัยของข้อมูล
            <br />
            <br />
            บริษัทได้คำนึงถึงความปลอดภัยในข้อมูลของผู้ใช้บริการเป็นหลัก
            ทางบริษัทได้มีระบบป้องกันความปลอดภัยของข้อมูล
            โดยการเจาะจงผู้ที่สามารถเข้าถึงข้อมูลได้ ไว้กับฐานข้อมูลและเซิฟเวอร์
            อีกทั้งทางบริษัทได้มีการเก็บสำรองข้อมูลส่วนบุคคลของผู้ใช้บริการผ่านเซอร์วิสของทาง
            Google
            เพื่อความปลอดภัยในการเข้าถึงและการจัดการข้อมูลที่เป็นระบบมากที่สุด
            อย่างไรก็ดี ทางบริษัท ใคร่ขอแนะนำให้ผู้ใช้บริการ
            ระมัดระวังการเข้าถึงแพลตฟอร์มของทางบริษัทเองด้วย
            โดยการหลีกเลี่ยงการเข้าถึงแพลตฟอร์มผ่านทางพื้นที่สาธารณะ
            เพื่อป้องกันอันตรายที่อาจเกิดขึ้นได้
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>
            6. การลบ เปลี่ยนแปลง หรือเข้าถึงข้อมูลส่วนบุคคล
          </BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
                   หากผู้ใช้บริการมีความประสงค์จะแก้ไข
            หรือลบข้อมูลส่วนตัวใดๆที่บริษัทถือครองไว้
            ผู้ใช้บริการสามารถติดต่อกับบริษัทได้ผ่านช่องทาง
            หรือผู้ใช้บริการมีความประสงค์ที่จะเข้าถึงข้อมูลส่วนบุคคลที่บริษัทถือครองไว้ 
            <br />
            <br />
            <li>https://www.pickle.co.th/</li>
            <br />
            ไลน์ @341ehmij   หรือ คิวอาร์โค้ด
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>7. ข้อจำกัดความรับผิดชอบ</BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
                   
            ทางบริษัทขอจํากัดความรับผิดต่อความเสียหายอันเกิดจากความสะเพร่าหรือประมาท
            ของทางบริษัทที่ส่งผลต่อการรั่วไหลของข้อมูลส่วนบุคคลของผู้ใช้บริการไปสู่สาธารณะ
            หรือบุคคลภายนอก
            อันมีผลทําให้ผู้ใช้บริการได้รับความเสื่อมเสียหรือเสียหายเท่านั้น
            โดยทางบริษัทจะพิจารณาชดเชยค่าเสียหายให้แก่ผู้ใช้บริการดังกล่าวเป็นจำนวนอันสมควร 
            <br />
            <br />
                   ทั้งนี้ทางบริษัทจะไม่ขอรับผิดชอบต่อข้อมูลหรือเนื้อหาที่อยู่นอกเหนือขอบเขตของการให้บริการของทางแพลตฟอร์ม
            หรือปัญหาที่อาจเกิดจากการดําเนินการของบุคคลภายนอกหรือบริษัทอื่น
            แม้จะกระทำผ่านแพลตฟอร์มของทางบริษัทหรือไม่ก็ตาม
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>
            8. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวของบริษัท
          </BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer>
          <Body2Text>
                   ทางบริษัทขอสงวนสิทธิในการแก้ไขนโยบายความเป็นส่วนตัวให้มีความถูกต้อง
            สอดคล้องกับหลักกฎหมายและมีเป็นปัจจุบันอยู่เสมอ
            เพื่อการให้บริการที่มีประสิทธิภาพ และมีความปลอดภัยสูงสุด ทั้งนี้
            <br />
            <br />
            นโยบายฉบับแก้ไขแล้วจะมีผลบังคับใช้ทันทีหลังจากประกาศลงบนแพลตฟอร์ม
            โดยบริษัทจะแจ้งให้ทางผู้ใช้บริการแพลตฟอร์มทราบถึงการเปลี่ยนแปลงล่วงหน้าก่อนการประกาศลงบนแพลตฟอร์มเพื่อเริ่มใช้งาน
            ทั้งนี้ ผู้ใช้บริการควรที่จะศึกษานโยบายดังกล่าวอยู่ตลอดเวลา
            เพื่อให้เข้าใจถึงข้อกำหนดของทางบริษัท
          </Body2Text>
        </TextContainer>
        <HeaderTextContainer>
          <BoldBody1Text>9. ช่องทางการติดต่อ</BoldBody1Text>
        </HeaderTextContainer>
        <TextContainer style={{ paddingBottom: '32px' }}>
          <Body2Text>
                   หากผู้ใช้บริการมีข้อสงสัยหรือคำถามเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้
            สามารถติดต่อกับทางบริษัทได้ผ่านช่องทาง 
            <br />
            <br />
                   เว็บไซต์ https://www.pickle.co.th/  อีเมล 
            contact@picklebutnotcucumber.com เบอร์ติดต่อ  บริษัท แรบบิท พิกเคิล
            จำกัด 098-829-9341LINE Official @341ehmij   หรือ สแกนคิวอาร์โค้ด
          </Body2Text>
        </TextContainer>
      </ContentContainer>
    </Container>
  );
}

export default PrivacyPolicyPage;

const Container = styled.div`
  display: flex;
  border-radius: 16px;
  align-self: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(148, 114, 114, 0.05);
`;

const ContentContainer = styled.div`
  width: 672px;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 144px 56px 13px 56px;
  margin-bottom: 20px;
  align-items: center;
  border-bottom: 1px solid ${COLOR.Gray_200};
`;

const HeaderTextContainer = styled.div`
  padding: 32px 40px 0px 56px;
`;

const TextContainer = styled.div`
  padding: 8px 40px 0px 80px;
`;

const BoldHeader5Text = styled(TEXT.header5BOLD)``;

const Body2Text = styled(TEXT.body2)``;

const BoldBody1Text = styled(TEXT.body1BOLD)``;
