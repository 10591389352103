import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import RLoading from '../components/atoms/RLoading';
import ScrollToTop from '../components/atoms/ScrollToTop';
import DefaultDrawer from '../reusables/DefaultDrawer';
import DiscoverWorkDrawer from '../components/molecules/Layout/DiscoverWorkDrawer';
import DiscoverCampaignCard from '../components/organisms/Discover/Campaign/DiscoverCampaignCard';
import DiscoveryTab from '../components/organisms/Discover/DiscoverTab';
import DiscoverExpandWork from '../components/organisms/Discover/Influencer/DiscoverExpandWork';
import DiscoveryInfluencer from '../components/organisms/Discover/Influencer/DiscoverInfluencer';
import DiscoverWork from '../components/organisms/Discover/Influencer/DiscoverWork';
import {
  useAccountStore,
  useDealStore,
  useFeedStore,
  useInfluencerStore,
  useOfferStore
} from '../stores/initialize/Context';
import { useQuery } from '../utils';
import { ColumnContainer, RowContainer } from '../utils/styling/general';
import DiscoverCampaignDetailsPage from './Discover/Campaign/DiscoverCampaignDetailsPage';

export type DiscoverPage =
  | 'Default'
  | 'Beauty & Cosmetics'
  | 'Food & Beverage'
  | 'Fashion & Accessory'
  | 'Work'
  | 'Campaign';

interface DrawerProps {
  page: DiscoverPage;
  navigation: NavigateFunction;
}
const DrawerComponent = (props: DrawerProps) => {
  const { page, navigation } = props;
  const location = useLocation();
  const { pathname } = location;
  if (page === 'Work') return <DiscoverWorkDrawer navigation={navigation} />;
  return <DefaultDrawer navigation={navigation} pathname={pathname} />;
};

const DiscoveryPage = () => {
  const query = useQuery();
  const pageQuery = query.get('page') as string;
  const workId = query.get('workId') as string;
  const queryDealId = query.get('dealId') as string;
  const navigate = useNavigate();
  const {
    discoveryWork,
    listDiscoveryImage,
    feedImages,
    listFeedImages,
    feedExpandWork,
    listFeedExpandWork,
    feedDeals,
    feedDealDetail,
    getFeedDealDetail,
    listFeedDeals,
    loading,
    getFeedInfluWork,
    feedInfluWork,
    clearFeedExpandWork
  } = useFeedStore();

  const {
    getInfluencerById,
    influencerSocial,
    loading: influloading,
    triggerSaveInfluencer
  } = useInfluencerStore();

  const { sendOfferInfluencer } = useOfferStore();
  const { getCampaignList, campaignList } = useDealStore();
  const { ongoing: deals } = campaignList;
  const { account } = useAccountStore();
  const { brandId } = account;

  const [page, setPage] = useState<DiscoverPage>('Default');

  const [lastPage, setLastPage] = useState<DiscoverPage>('Default');

  const [dealId, setDealId] = useState<string>('');

  useEffect(() => {
    setPage('Default');
    listDiscoveryImage();
    getCampaignList();
    listFeedImages({
      offset: 0,
      url: ''
    });
  }, []);

  useEffect(() => {
    if (!workId) {
      if (lastPage !== 'Default') {
        setPage(lastPage);
      } else {
        setPage('Default');
      }
    }
    getFeedInfluWork({ feedId: workId, type: 'submitted' });
  }, [workId]);

  useEffect(() => {
    if (pageQuery === null) navigate('/discover?page=influencer');
    else if (pageQuery === 'campaign') {
      listFeedDeals({
        offset: 0,
        limit: 10
      });
    }
    window.scrollTo(0, 0);
  }, [pageQuery]);

  useEffect(() => {
    if (page !== 'Default' && page !== 'Work') {
      listFeedExpandWork({
        offset: 0,
        limit: 10,
        category: page
      });
    }
  }, [page]);

  useEffect(() => {
    if (queryDealId) {
      getFeedDealDetail({ dealId: queryDealId });
    }
  }, [queryDealId]);

  const renderPage = () => {
    if (pageQuery === 'work') {
      return (
        <DiscoverWork
          work={feedInfluWork}
          loading={loading.feedInfluWork}
          setPage={setPage}
          navigate={navigate}
          getInfluencerById={getInfluencerById}
          influencer={influencerSocial}
          influLoading={influloading.social}
          sendOfferInfluencer={sendOfferInfluencer}
          brandId={brandId}
          deals={deals}
          lastPage={lastPage}
        />
      );
    } else if (pageQuery === 'campaign') {
      if (queryDealId) {
        return (
          <DiscoverCampaignDetailsPage
            dealDetail={feedDealDetail}
            navigate={navigate}
            setPage={setPage}
            loading={loading.feedDealDetail}
          />
        );
      } else {
        return (
          <DiscoverCampaignCard
            deals={feedDeals.data}
            next={feedDeals.next}
            loading={loading.feedDeals}
            metadata={feedDeals.metadata}
            navigate={navigate}
          />
        );
      }
    } else {
      if (page === 'Default') {
        return (
          <DiscoveryInfluencer
            discoveryWork={discoveryWork}
            feedImages={feedImages}
            loading={loading.feedImages}
            page={page}
            setPage={setPage}
            navigate={navigate}
            clearFeedExpandWork={clearFeedExpandWork}
          />
        );
      } else {
        return (
          <DiscoverExpandWork
            feed={feedExpandWork}
            loading={loading.feedExpand}
            navigate={navigate}
            page={page}
            setPage={setPage}
            setLastPage={setLastPage}
          />
        );
      }
    }
  };

  return (
    <Container>
      <ScrollToTop />
      <ContentContainer>
        <DiscoveryTab
          navigate={navigate}
          page={page}
          setPage={setPage}
          listFeedExpandWork={listFeedExpandWork}
          clearFeedExpandWork={clearFeedExpandWork}
        />
        {loading.feedDiscover ? (
          <RLoading
            containerStyle={{ marginTop: '0px', marginBottom: '0px' }}
          />
        ) : (
          renderPage()
        )}
      </ContentContainer>
    </Container>
  );
};

export default observer(DiscoveryPage);

const Container = styled(RowContainer)`
  align-items: flex-start;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  padding: 20px 36px 36px 36px;
`;

const PageLayoutContainer = styled(ColumnContainer)`
  position: relative;
  align-items: flex-start;
  width: 100%;
`;

const TabContainer = styled.div`
  display: flex;
`;
