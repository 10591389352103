import { observer } from 'mobx-react';
import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import Facebook from '../../../assets/images/registerFrom/Facebook.svg';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { useAuthStore } from '../../../stores/initialize/Context';
import { uploadFileAsync } from '../../../utils/Networking';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import RButton from '../../atoms/RButton';
import RInput from '../../atoms/RInput';
import { interestCategory } from '../../organisms/CreateCampaign/Options';

interface Props {
  changePage: () => void;
}

function CompanyCardDetail(props: Props) {
  const { changePage } = props;
  const { authCompanyInfo, setAuthCompanyInfo, sendCompanyInfo } =
    useAuthStore();
  const [selected, setSelected] = useState<string>('');
  // const [progress, setProgress] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const SendInfo = async () => {
    try {
      setLoading(true);
      await sendCompanyInfo();
      setLoading(false);
      changePage();
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFileToDatabase = async (
    uri: File,
    size: { width: number; height: number }
  ) => {
    const onProgress = ({}: // total,
    // loaded,
    {
      // total: number;
      // loaded: number;
    }) => {
      // show upload progress
      // const percent = Math.ceil((loaded / total) * 100);
      // setProgress(percent);
    };
    // TODO : Move upload to end of process not here
    const uploadedImages = await uploadFileAsync(uri, size, onProgress);
    authCompanyInfo.brandIMG = uploadedImages.imageUrl;
  };

  const onChangeInput = async (imageList: File) => {
    // data for submit
    await uploadFileToDatabase(imageList, {
      width: 250,
      height: 250
    });
  };

  const checkActive = () => {
    if (
      !!authCompanyInfo.brandName &&
      !!authCompanyInfo.contactName &&
      !!authCompanyInfo.contactTelephone &&
      !!authCompanyInfo.category &&
      (!!authCompanyInfo.facebook ||
        !!authCompanyInfo.instagram ||
        !!authCompanyInfo.website ||
        !!authCompanyInfo.tiktok)
    ) {
      if (
        authCompanyInfo.category === 'Others' &&
        !authCompanyInfo.otherCategory
      ) {
        setActive(false);
        return;
      }
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const customInstagram = (
    <Image src={require('../../../assets/images/registerFrom/Instagram.png')} />
  );
  const customFacebook = <Facebook width={20} height={20} />;

  return (
    <Container>
      <LargeText>Company Information</LargeText>
      <LogoContainer>
        <LogoInput
          type="file"
          name="myImage"
          onChange={event => {
            if (event.target.files) {
              setUrl(URL.createObjectURL(event.target.files[0]));
              onChangeInput(event.target.files[0]);
            }
          }}
        />
        <OverlayContainer onClick={() => {}}>
          <ViewButton>
            <RXIcon name="Camera" color={COLOR.WHITE} />
            <ViewText>Change</ViewText>
          </ViewButton>
        </OverlayContainer>
        <BrandImage src={url} style={{ opacity: url ? 1 : 0 }} />
        <LogoText>
          <RXIcon name="Plus" size={36} color={COLOR.Gray_300} />
          Brand Logo
        </LogoText>
      </LogoContainer>
      <SmallRedText>
        *จำเป็นต้องใส่ บัญชีจะได้รับการยืนยันเมื่อมีโลโก้เท่านั้น
      </SmallRedText>
      <RInput
        label="ชื่อแบรนด์"
        type="text"
        required={true}
        containerStyle={{ marginTop: 22 }}
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'brandName'
            });
            checkActive();
          },
          placeholder: 'Brand Name'
        }}
      />
      <RInput
        label="ชื่อ-นามสกุล สำหรับติดต่อ"
        type="text"
        required={true}
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'contactName'
            });
            checkActive();
          },
          placeholder: 'Contact Name'
        }}
      />
      <RInput
        label="เบอร์โทร สำหรับติดต่อ"
        type="number"
        required={true}
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'contactTelephone'
            });
            checkActive();
          },
          maxLength: 12,
          placeholder: '000-000-0000'
        }}
      />
      <LabelText>
        หมวดหมู่แบรนด์ <RedText>*</RedText>
      </LabelText>
      <Dropdown
        activeText={!!authCompanyInfo.category}
        placeholder="เลือกหมวดหมู่สินค้าของแบรนด์"
        options={interestCategory.map(category => category.value)}
        onChange={(option: string) => {
          setAuthCompanyInfo({
            value: option,
            property: 'category'
          });
          setSelected(option);
          checkActive();
        }}
      />
      {selected === 'Others' && (
        <RInput
          label="โปรดระบุหมวดหมู่แบรนด์"
          type="text"
          required={true}
          inputProp={{
            onChange: e => {
              setAuthCompanyInfo({
                value: e.target.value,
                property: 'otherCategory'
              });
              checkActive();
            },
            placeholder: 'โปรดระบุหมวดหมู่แบรนด์ของคุณ'
          }}
          containerStyle={{ marginTop: 23 }}
        />
      )}
      <MediumText>
        ช่องทางติดต่อแบรนด์
        <ShadowText>โปรดกรอกอย่างน้อย 1 ช่องทาง</ShadowText>
      </MediumText>
      <RInput
        customIcon={customInstagram}
        label="Instagram"
        type="text"
        containerStyle={{ marginTop: 21 }}
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'instagram'
            });
            checkActive();
          },
          placeholder: 'instagram.com/username'
        }}
      />
      <RInput
        customIcon={customFacebook}
        label="Facebook"
        type="text"
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'facebook'
            });
            checkActive();
          },
          placeholder: 'facebook.com/username'
        }}
      />
      <RInput
        icon="TiktokFill"
        label="TikTok"
        type="text"
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'tiktok'
            });
            checkActive();
          },
          placeholder: 'tiktok.com/@username'
        }}
      />
      <RInput
        icon="Web"
        label="Website"
        type="text"
        inputProp={{
          onChange: e => {
            setAuthCompanyInfo({
              value: e.target.value,
              property: 'website'
            });
            checkActive();
          },
          placeholder: 'www.website.com'
        }}
      />
      <SendButtonContainer>
        <RButton
          title="ยืนยัน"
          onPress={SendInfo}
          active={active && !loading}
        />
      </SendButtonContainer>
    </Container>
  );
}

export default observer(CompanyCardDetail);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.Gray_200};
  width: 144px;
  height: 144px;
  margin-top: 30px;
  border: none;
  border-radius: 50%;
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const LogoInput = styled.input`
  display: none;
`;

const OverlayContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 144px;
  opacity: 0;
  background: ${COLOR.BLACK};
  transition: 0.3s ease;
  top: 0px;
  border-radius: 50%;
  z-index: 9999;
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const ViewText = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.WHITE};
`;

const ViewButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${COLOR.BLACK};
  border-radius: 24px;
  border: none;
  cursor: pointer;
`;

const LogoText = styled(TEXT.Bu3_Bold)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR.Gray_300};
`;

const LargeText = styled(TEXT.H4_Bold)`
  align-self: center;
`;

const MediumText = styled(TEXT.H6_Bold)`
  margin-top: 40px;
  flex-wrap: nowrap;
`;

const ShadowText = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
  margin-left: 10px;
  margin-top: 24px;
`;

const LabelText = styled(TEXT.H7_Bold)`
  margin-top: 0px;
  margin-bottom: 10px;
`;

const RedText = styled(TEXT.H7_Bold)`
  color: ${COLOR.Warning};
`;

const SmallRedText = styled(TEXT.B4_Reg)`
  color: ${COLOR.Warning};
  text-align: center;
  margin-top: 10px;
`;

const SendButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
`;

const BrandImage = styled.img`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 144px;
  border: none;
  border-radius: 50%;
  z-index: 100;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;
