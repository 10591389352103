import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ITierType } from '../../../types/createDeal';
import { PlatformType } from '../../../types/deal';
import { TierWorkData } from '../../../types/work';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import TierSummaryComponent from './TierSummaryComponent';
import { useState } from 'react';
import { ActiveProps } from '../../../types';
import ExplanationTooltip from './ExplanationTooltip';

interface Props {
  tierList: ITierType;
  tierInsight: {
    nano: TierWorkData;
    micro: TierWorkData;
    mid: TierWorkData;
    macro: TierWorkData;
  };
  platform: PlatformType;
}

const TierSummary = (props: Props) => {
  const { tierList, tierInsight, platform } = props;

  const [active, setAcitve] = useState<boolean>(false);

  return (
    <Container>
      <HeaderContainer active={active}>
        <BoldH6Text>Tier Summary</BoldH6Text>
        <GapRowContainer>
          <ExplanationTooltip />
          <IconContainer onClick={() => setAcitve(!active)}>
            <RXIcon
              name={active ? 'ArrowUp' : 'ArrowDown'}
              color={COLOR.Gray_M}
              size={24}
            />
          </IconContainer>
        </GapRowContainer>
      </HeaderContainer>
      {active && (
        <ContentContainer>
          <TierSummaryComponent
            tier="nano"
            current={tierInsight.nano.uniqueInfluencer.size}
            insight={tierInsight.nano}
            max={tierList.nano.value}
            platform={platform}
          />
          <TierSummaryComponent
            tier="micro"
            current={tierInsight.micro.uniqueInfluencer.size}
            insight={tierInsight.micro}
            max={tierList.micro.value}
            platform={platform}
          />
          <TierSummaryComponent
            tier="mid"
            current={tierInsight.mid.uniqueInfluencer.size}
            insight={tierInsight.mid}
            max={tierList.mid.value}
            platform={platform}
          />
          <TierSummaryComponent
            tier="macro"
            current={tierInsight.macro.uniqueInfluencer.size}
            insight={tierInsight.macro}
            max={tierList.macro.value}
            platform={platform}
          />
        </ContentContainer>
      )}
    </Container>
  );
};

export default TierSummary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${COLOR.Gray_300};
`;

const GapRowContainer = styled(RowContainer)`
  gap: 16px;
`;

const HeaderContainer = styled(SpaceBetweenRowContainer)<ActiveProps>`
  position: relative;
  padding: 24px;
  border-bottom: ${props =>
    props.active ? `0.6px solid ${COLOR.Gray_300}` : 'none'};
`;

const ContentContainer = styled(RowContainer)`
  padding: 24px;
  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
`;

const IconContainer = styled.div``;

const BoldH6Text = styled(TEXT.H6_Bold)``;
