import { useState } from 'react';
import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { RowContainer } from '../../../utils/styling/general';
import ATag from '../ATag';

interface ArrowComponentProps {
  active: boolean;
  setActive: (value: boolean) => void;
  display: boolean;
}

const ArrowComponent = (props: ArrowComponentProps) => {
  const { active, setActive, display } = props;
  if (!display) return <></>;
  const iconName: IconName = active ? 'ArrowUp' : 'ArrowDown';
  return (
    <IconContainer onClick={() => setActive(!active)}>
      <RXIcon name={iconName} color={COLOR.Gray_M} size={16} />
    </IconContainer>
  );
};

interface ChildrenComponentProps {
  childActive: boolean;
  children?: {
    text: string;
    onClick: () => void;
    active: boolean;
    haveTotal?: number;
  }[];
}

const ChildrenComponent = (props: ChildrenComponentProps) => {
  const { children, childActive } = props;
  if (!!children && childActive) {
    const components: JSX.Element[] = [];
    children.map(child => {
      const { onClick, text, active, haveTotal } = child;
      const showTotal = !!haveTotal || haveTotal === 0;
      components.push(
        <DetailContainer
          key={text}
          onClick={onClick}
          active={active}
          style={{ paddingLeft: 52 }}
        >
          <GapRowContainer>
            <Bu3Text>
              {text}
              {!!showTotal && <GrayMBu3Text> ({haveTotal})</GrayMBu3Text>}
            </Bu3Text>
          </GapRowContainer>
        </DetailContainer>
      );
    });
    return <>{components}</>;
  }
  return <></>;
};

interface IconProps {
  icon: IconName | 'AI';
  iconColor?: string;
}

const IconComponent = (props: IconProps) => {
  const { icon, iconColor } = props;
  if (icon === 'AI')
    return (
      <ATag text="AI" width={19} height={20} TextStyle={BoldBlue700B5Text} />
    );
  return <RXIcon name={icon} size={20} color={iconColor} />;
};
interface DetailProps {
  icon: IconName | 'AI';
  text: string;
  iconColor?: string;
  onClick: () => void;
  active: boolean;
  children?: {
    text: string;
    onClick: () => void;
    active: boolean;
    haveTotal?: number;
  }[];
}

const MenuDetail = (props: DetailProps) => {
  const { icon, text, iconColor, onClick, active, children } = props;
  const [dropdownActive, setDropdownActive] = useState<boolean>(true);
  const displayArrow = !!children;
  return (
    <>
      <DetailContainer
        onClick={() => {
          setDropdownActive(!dropdownActive);
          onClick();
        }}
        active={active}
      >
        <GapRowContainer>
          <IconComponent icon={icon} iconColor={iconColor} />
          <Bu3Text>{text}</Bu3Text>
        </GapRowContainer>
        <ArrowComponent
          active={dropdownActive}
          setActive={setDropdownActive}
          display={displayArrow}
        />
      </DetailContainer>
      <ChildrenComponent childActive={dropdownActive} children={children} />
    </>
  );
};

export default MenuDetail;

const DetailContainer = styled.div<ActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  padding: 8px 20px 8px 28px;
  justify-content: space-between;
  border-radius: 12px;
  background-color: ${props => (props.active ? COLOR.Gray_50 : COLOR.White)};
  :hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const Bu3Text = styled(TEXT.Bu3_Reg)``;

const GrayMBu3Text = styled(TEXT.Bu3_Reg)`
  color: ${COLOR.Gray_M};
`;

const BoldBlue700B5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;
