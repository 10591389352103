import { createGlobalStyle } from 'styled-components';
import './aktiv.css';
import FCIconicBoldItalic from './FC-Iconic-Bold-Italic.ttf';
import FCIconicBold from './FC-Iconic-Bold.ttf';
import FCIconicItalic from './FC-Iconic-Italic.ttf';
import FCIconicRegular from './FC-Iconic-Regular.ttf';
import PoppinsRegular from './Poppins-Regular.ttf';
import PoppinsBold from './Poppins-Bold.ttf';
import NotoSansThaiRegular from './NotoSansThai-Regular.ttf';
import NotoSansThaiBold from './NotoSansThai-Bold.ttf';
import './poppins.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @font-face {
    font-family: 'FC Iconic';
    src: url(${FCIconicRegular}) format("truetype");
  }
  @font-face {
    font-family: 'FC Iconic Bold';
    src: url(${FCIconicBold}) format("truetype");
  }
  @font-face {
    font-family: 'FC Iconic Italic';
    src: url(${FCIconicItalic}) format("truetype");
  }
  @font-face {
    font-family: 'FC Iconic Bold Italic';
    src: url(${FCIconicBoldItalic}) format("truetype");
  }
  /* @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsRegular}) format('truetype');
    font-display: auto;
  }
  @font-face {
    font-family: 'Poppins Bold';
    src: url(${PoppinsBold}) format('truetype');
    font-display: auto;
  }
  @font-face {
    font-family: 'Noto Sans';
    src: url(${NotoSansThaiRegular}) format('truetype');
    font-display: auto;
  }
  @font-face {
    font-family: 'Noto Sans Bold';
    src: url(${NotoSansThaiBold}) format('truetype');
    font-display: auto;
  } */
`;
export const FontFCIconicRegular = 'FC Iconic';
export const FontFCIconicBold = 'FC Iconic Bold';

export const FontAktivGroteskRegular = 'aktiv-grotesk';

export const FontAktivGroteskThaiRegular = 'aktiv-grotesk-thai';

// export const FontPoppinsRegular = "Poppins";
// export const FontPoppinsBold = "Poppins Bold";

// export const FontNotoSansRegular = "Noto Sans";
// export const FontNotoSansBold = "Noto Sans Bold";

export const FontPoppinsRegular = 'poppins';
export const FontPoppinsBold = 'poppins';

export const FontNotoSansRegular = 'noto sans thai';
export const FontNotoSansBold = 'noto sans thai';
