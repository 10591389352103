import React, { Fragment, useState } from 'react';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import styled from 'styled-components';

interface Props {
  hasFooter: boolean;
  isConfirmed: boolean;
  actionCode?: keyof IActionType;
  params?: { [key: string]: string };
}

export interface IActionType {
  JOB_CONFIRM: JSX.Element;
  NOTIFY_ADDRESS: JSX.Element;
  ADDRESS_NOT_FOUND: JSX.Element;
  NOTIFY_BANK: JSX.Element;
  BANK_NOT_FOUND: JSX.Element;
  BANNED_INFLU: JSX.Element;
}
const Text = styled(TEXT.caption1)``;
const BoldText = styled(TEXT.caption1BOLD)``;

const ConfirmFooter = (props: Props) => {
  const [activeDealOngoingtutorial, setActiveDealOngoingtutorial] =
    useState(false);
  const { isConfirmed, hasFooter, actionCode, params } = props;
  if (isConfirmed || !hasFooter) {
    return null;
  }

  const notifyAddress = (
    <Text
      style={{
        color: COLOR.Blue_700
      }}
    >
      เมื่อจัดส่งสินค้าเรียบร้อย อย่าลืมกลับมาแจ้ง Influencer ด้วยนะคะ
      <Text
        style={{
          color: COLOR.Blue_700,
          textDecorationLine: 'underline'
        }}
      >
        วิธีแจ้งจัดส่งสินค้า
      </Text>
    </Text>
  );

  const bannedInflu = (
    <Text
      style={{
        color: COLOR.Warning
      }}
    >
      <Text style={{ color: COLOR.Warning }}>หมายเหตุ </Text>: Pickle
      ขอปฏิเสธการรับผิดชอบหากเกิดปัญหาขึ้นในภายหลัง ไม่ว่าจากกรณีใดทั้งสิ้น
    </Text>
  );

  const addressNotFound = (
    <Text
      style={{
        color: COLOR.Warning
      }}
    >
      <BoldText style={{ color: COLOR.Warning }}>
        {params?.influUsername}{' '}
      </BoldText>
      ยังไม่ได้อัปเดตข้อมูลที่อยู่และข้อมูลธุรกรรม เมื่อข้อมูลถูกอัปเดตแล้ว
      Pickle จะแจ้งทางแชทนี้อีกครั้ง หรือแบรนด์สามารถขอข้อมูล
      ด้วยตนเองทันทีทางแชทนี้
    </Text>
  );

  const notifyBank = (
    <div>
      <Text style={{ color: COLOR.Blue_700 }}>
        เมื่อโอนเงินเรียบร้อยแล้ว อย่าลืมกลับมาแจ้ง Influencer
        พร้อมแนบหลักฐานการโอนทางแชทนี้ด้วยนะคะ
      </Text>
    </div>
  );

  const jobConfirm = (
    <Text
      style={{
        color: COLOR.Blue_700
      }}
    >
      รออินฟลูเอ็นเซอร์อ่านรายละเอียด และกดยืนยันการรับงาน
    </Text>
  );

  const FOOTER: IActionType = {
    JOB_CONFIRM: jobConfirm,
    NOTIFY_ADDRESS: notifyAddress,
    ADDRESS_NOT_FOUND: addressNotFound,
    NOTIFY_BANK: notifyBank,
    BANK_NOT_FOUND: addressNotFound,
    BANNED_INFLU: bannedInflu
  };

  const isAlert =
    actionCode?.includes('NOT_FOUND') || actionCode?.includes('BANNED');

  return (
    <Fragment>
      <div style={{ marginTop: 15 }}>
        {!isAlert ? (
          <div
            style={{
              border: `1px dashed ${COLOR.Blue_700}`
            }}
          />
        ) : (
          <div
            style={{
              border: `1px dashed ${COLOR.Warning}`
            }}
          />
        )}
        <div
          style={{
            padding: '10px 15px 10px 15px',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          {actionCode && FOOTER[actionCode]}
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmFooter;
