import { IconName, RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../fonts/color';
import { TEXT } from '../../fonts/text';

interface Props {
  options: Array<OptionProps>;
  left?: string;
  top?: string;
}

interface ContainerProps {
  left?: string;
  top?: string;
}

interface OptionContainerProps {
  isSingle: boolean;
}

interface HandleClickProps {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  action: () => void;
}

const handleClick = (props: HandleClickProps) => {
  const { e, action } = props;
  e.stopPropagation();
  action();
};

export interface OptionProps {
  title: string;
  color: string;
  icon: IconName | null;
  iconColor: string | null;
  action: () => void;
}

const MoreOptions = (props: Props) => {
  const { options, left, top } = props;

  return (
    <Container left={left} top={top}>
      {options.map((option, index) => (
        <OptionContainer
          key={index}
          onClick={e => handleClick({ e, action: option.action })}
          isSingle={options.length === 1}
        >
          {option.icon && (
            <RXIcon
              name={option.icon}
              color={option.iconColor || COLOR.Black}
            />
          )}
          <Bu2Text style={{ color: option.color || COLOR.Black }}>
            {option.title}
          </Bu2Text>
        </OptionContainer>
      ))}
    </Container>
  );
};

export default MoreOptions;

const Container = styled.div<ContainerProps>`
  position: absolute;
  right: 0;
  top: 56px;
  left: ${props => props.left};
  top: ${props => props.top};
`;

const OptionContainer = styled.div<OptionContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 5px;

  width: 227px;
  height: 56px;
  z-index: 999;
  background-color: ${COLOR.White};

  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
  transition: all 0.2s;

  ${props =>
    props.isSingle
      ? 'border-radius: 16px;'
      : `
        &:first-child {
          border-radius: 16px 16px 0 0;
        }
        &:last-child {
          border-radius: 0 0 16px 16px;
        }
      `}

  &:hover {
    cursor: pointer;
    background-color: ${COLOR.Gray_50};
  }
`;

const Bu2Text = styled(TEXT.Bu2_Reg)``;
