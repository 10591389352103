import { action, computed, makeAutoObservable, observable } from 'mobx';
import { clearArray, ValidateEmail } from '../utils/index';
import { GET, POST } from '../utils/Networking';

export class ResetPasswordStore {
  @observable
  email = {
    value: ''
  };

  @observable
  emailError: string[] = [];

  @observable
  code = {
    valueArray: ['0', '0', '0', '0', '0', '0'],
    value: '',
    password: '',
    rePassword: ''
  };

  @observable
  changeError = [];

  @observable
  isCodeValid = false;

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  get isEmailSendActive() {
    return !!this.email.value;
  }

  @computed
  get isRepasswordSendActive() {
    return (
      !!this.code.password &&
      !!this.code.rePassword &&
      this.code.password === this.code.rePassword
    );
  }

  @computed
  get validateNewPassword() {
    return this.code.password.length >= 8;
  }

  @computed
  get validateNewRePassword() {
    return (
      this.code.rePassword.length > 0 &&
      this.code.password === this.code.rePassword
    );
  }

  @action
  setEmail = ({ value }: { value: string }) => {
    this.email.value = value;
  };

  @action
  sendForgetPasswordEmail = async () => {
    clearArray(this.emailError);
    if (!ValidateEmail(this.email.value)) {
      this.emailError.push('อีเมลไม่ถูกต้อง');
    }
    if (this.emailError.length > 0) {
      return;
    }
    await POST(
      '/brands/auth/resetPassword',
      {
        email: this.email.value
      },
      null,
      () => {},
      (error: any) => {
        this.emailError.push(error.response.data);
      }
    );
    return;
  };

  @action
  checkIsValidCode = async ({ code }: { code: string }) => {
    if (code.length !== 6) {
      this.isCodeValid = false;
      return;
    }
    try {
      const result = await GET('/brands/auth/resetValid/' + code);
      // await sleep(1000);
      this.isCodeValid = result;
      return result;
    } catch (error) {
      console.log(error);
      this.isCodeValid = false;
      return false;
    }
  };

  @computed
  get getIsCodeValid() {
    return this.isCodeValid;
  }

  @observable
  resetingPassword = false;

  @action
  resetPassword = async () => {
    this.resetingPassword = true;
    // validate password
    if (this.code.password.length < 8) {
      //   this.changeError.push('กรุณาใส่รหัสผ่านมากกว่า 8 ตัว\n');
    }
    if (this.code.password !== this.code.rePassword) {
      //   this.changeError.push('รหัสผ่านไม่ตรงกัน');
    }

    if (this.changeError.length > 0) {
      this.resetingPassword = false;
      return;
    }

    await POST(
      '/brands/auth/changePassword',
      {
        code: this.code.value,
        email: this.email.value,
        password: this.code.password
      },
      null,
      () => {},
      error => {
        // this.changeError.push(error.response.data);
        // this.resetingPassword = false;
      }
    );

    this.code.password = '';
    this.code.rePassword = '';
    this.code.value = '';
    this.code.valueArray = ['0', '0', '0', '0', '0', '0'];
    this.resetingPassword = false;
  };
}

export const resetPasswordStore = new ResetPasswordStore();
