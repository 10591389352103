import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ITiktokInfluencer, RateCard } from '../../../types/api/getInfluencer';
import {
  calculateAverageData,
  calculatePercentage,
  getTierByFollower,
  rateCardTypeText,
  toCapitalize,
  toDecimalNumber
} from '../../../utils';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import EmptyRateCard from './EmptyRateCard';
import EmptySocialMedia from './EmptySocialMedia';

interface WidthProps {
  width: string | number;
}
interface RateCardProps {
  rateCard: RateCard[];
}

const TikTokRateCard = (props: RateCardProps) => {
  const { rateCard } = props;

  if (rateCard.length === 0) return <EmptyRateCard />;

  const components: JSX.Element[] = [];

  rateCard.map(card => {
    const text = rateCardTypeText(card) || 'TikTok Video';
    const rate =
      toDecimalNumber({
        number: card.rate,
        isZeroDash: false
      }) + ' ฿';
    components.push(
      <RateCardContainer width={100 / rateCard.length}>
        <WhiteBoldH7Text>{rate}</WhiteBoldH7Text>
        <RateCardText>{text}</RateCardText>
      </RateCardContainer>
    );
  });

  return (
    <FullWidthRowContainer>
      <GapColumnContainer>
        <GrayB4Text>ราคาต่อโพสต์</GrayB4Text>
        <SpaceEvenlyRowContainer>{components}</SpaceEvenlyRowContainer>
      </GapColumnContainer>
    </FullWidthRowContainer>
  );
};
interface Props {
  tiktok: ITiktokInfluencer;
  rateCard: RateCard[];
}

const TiktokDetail = (props: Props) => {
  const { tiktok, rateCard } = props;

  if (!tiktok) return <EmptySocialMedia platform="TIKTOK" />;

  const {
    followers_count = 0,
    username,
    monthData = {
      engagement: 0,
      viewCount: 0,
      postCount: 0
    }
  } = tiktok;
  const { engagement, viewCount, postCount } = monthData;
  const tier = toCapitalize(getTierByFollower(followers_count));
  const avgEngagement = calculateAverageData(engagement, postCount);
  const engagementPercentage = calculatePercentage(
    parseFloat(avgEngagement),
    followers_count
  );
  const avgView = calculateAverageData(viewCount, postCount);
  const onClick = () => window.open(`https://tiktok.com/@${username}`);

  return (
    <Container>
      <GapRowContainer>
        <PlatformContainer onClick={onClick}>
          <RXIcon name="Tiktok" color={COLOR.White} size={32} />
        </PlatformContainer>
        <FollowersContainer>
          <FullWidthRowContainer>
            <TierContainer>
              <BlueBoldB5Text>{tier}</BlueBoldB5Text>
            </TierContainer>
            <IconContainer onClick={onClick}>
              <RXIcon name="ExternalLink" color={COLOR.White} />
            </IconContainer>
          </FullWidthRowContainer>
          <FullWidthRowContainer>
            <WhiteBoldB3Text onClick={onClick}>{username}</WhiteBoldB3Text>
            <TextContainer>
              <WhiteBoldH7Text>
                {toDecimalNumber({
                  number: followers_count,
                  isZeroDash: false
                })}
              </WhiteBoldH7Text>
              <WhiteH9Text>Followers</WhiteH9Text>
            </TextContainer>
          </FullWidthRowContainer>
        </FollowersContainer>
      </GapRowContainer>
      <SpaceEvenlyRowContainer>
        <ColumnContainer>
          <TextContainer>
            <WhiteBoldH7Text>
              {toDecimalNumber({ number: avgEngagement, isZeroDash: false })}
            </WhiteBoldH7Text>
            <WhiteBoldH9Text>({engagementPercentage}%)</WhiteBoldH9Text>
          </TextContainer>
          <GrayB4Text>Avg.Engagement</GrayB4Text>
        </ColumnContainer>
        <ColumnContainer>
          <WhiteBoldH7Text>
            {toDecimalNumber({ number: avgView, isZeroDash: false })}
          </WhiteBoldH7Text>
          <GrayB4Text>Avg.View</GrayB4Text>
        </ColumnContainer>
      </SpaceEvenlyRowContainer>
      <Dashline />
      <TikTokRateCard rateCard={rateCard} />
    </Container>
  );
};

export default TiktokDetail;

const Container = styled(ColumnContainer)`
  border-radius: 12px;
  width: 33%;
  height: 260px;
  background: rgba(1, 1, 1, 0.7);
  backdrop-filter: blur(20px);
  padding: 24px 20px;
  align-items: flex-start;
  gap: 16px;
`;

const PlatformContainer = styled(RowContainer)`
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: ${COLOR.Black};
  border-radius: 50px;
  :hover {
    cursor: pointer;
  }
`;

const TierContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  background: ${COLOR.Blue_50};
  backdrop-filter: blur(2px);
`;

const GapColumnContainer = styled(ColumnContainer)`
  flex: 1;
  gap: 8px;
  align-items: flex-start;
`;

const FollowersContainer = styled(ColumnContainer)`
  flex: 1;
  gap: 8px;
  align-items: flex-start;
  width: 80%;
`;

const GapRowContainer = styled(RowContainer)`
  width: 100%;
  gap: 8px;
`;

const Dashline = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 0.6px solid ${COLOR.Gray_M};
`;

const RateCardContainer = styled(ColumnContainer)<WidthProps>`
  width: ${props => props.width}%;
`;

const FullWidthRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const SpaceEvenlyRowContainer = styled(RowContainer)`
  justify-content: space-evenly;
  width: 100%;
`;

const TextContainer = styled(RowContainer)`
  gap: 4px;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const BlueBoldB5Text = styled(TEXT.B5_Bold)`
  color: ${COLOR.Blue_700};
`;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.White};
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`;

const WhiteBoldH7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;

const WhiteBoldH9Text = styled(TEXT.H9_Bold)`
  color: ${COLOR.White};
`;

const GrayB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_200};
`;

const RateCardText = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_200};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
`;

const GrayB5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_400};
`;
