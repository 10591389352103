import styled from 'styled-components';
import { COLOR } from './color';
import {
  FontAktivGroteskRegular,
  FontAktivGroteskThaiRegular,
  FontNotoSansBold,
  FontNotoSansRegular,
  FontPoppinsBold,
  FontPoppinsRegular
} from './font';

const commonTextStyle = styled.span`
  color: ${COLOR.BLACK};
`;

export const TEXT = {
  header1: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 96px;
    line-height: 100px;
  `,
  header2: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 60px;
    line-height: 64px;
  `,
  header3: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 52px;
  `,
  header4: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 43.52px;
  `,
  header5: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  `,
  header6: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  `,
  header7: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
  header8: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.92px;
  `,
  body1: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.48px;
  `,
  body2: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.92px;
  `,
  caption1: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.36px;
  `,
  caption2: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 12.8px;
  `,
  overline: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
  `,
  button: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 23.04px;
  `,
  header1BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 52px;
  `,
  header2BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 60px;
    line-height: 64px;
  `,
  header3BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 61.44px;
  `,
  header4BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
  `,
  header5BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  `,
  header6BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  header7BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  `,
  header8BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.92px;
  `,
  body1BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  `,
  body2BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.92px;
  `,
  caption1BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 15.36px;
  `,
  caption2BOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 12.8px;
  `,
  buttonBOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  `,
  overlineBOLD: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  `,
  content1: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 800;
    font-size: 72px;
    line-height: 92.16px;
  `,
  content2: styled(commonTextStyle)`
    font-family: ${FontAktivGroteskRegular}, ${FontAktivGroteskThaiRegular},
      sans-serif;
    font-weight: 800;
    font-size: 56px;
    line-height: 71.68px;
  `,

  // ANOTHERDOT TEXT DESIGN
  // EN
  H1_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 96px;
    line-height: 144px; /* 150% */
  `,
  H2_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 60px;
    line-height: 88px; /* 146.667% */
  `,
  H3_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 48px;
    line-height: 72px; /* 150% */
  `,
  H4_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 34px;
    line-height: 52px; /* 152.941% */
  `,
  H5_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 24px;
    line-height: 36px; /* 150% */
  `,
  H6_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 20px;
    line-height: 32px; /* 160% */
  `,
  H7_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  H8_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `,
  H9_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 12px;
    line-height: 18px; /* 150% */
  `,
  B1_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 18px;
    line-height: 28px; /* 155.556% */
  `,
  B2_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  B3_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `,
  B4_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 12px;
    line-height: 18px; /* 150% */
  `,
  B5_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 10px;
    line-height: 16px; /* 160% */
  `,
  Bu1_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 18px;
    line-height: 28px; /* 155.556% */
  `,
  Bu2_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  Bu3_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `,
  Bu4_Reg: styled(commonTextStyle)`
    font-family: ${FontNotoSansRegular};
    font-size: 12px;
    line-height: 20px; /* 166.667% */
  `,
  T1_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  T2_Reg: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 12px;
    line-height: 20px; /* 166.667% */
  `,
  H1_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 96px;
    line-height: 144px; /* 150% */
  `,
  H2_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 60px;
    line-height: 88px; /* 146.667% */
  `,
  H3_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 48px;
    line-height: 72px; /* 150% */
  `,
  H4_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 34px;
    line-height: 52px; /* 152.941% */
  `,
  H5_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 24px;
    line-height: 36px; /* 150% */
  `,
  H6_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 20px;
    line-height: 32px; /* 160% */
  `,
  H7_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  H8_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `,
  H9_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 12px;
    line-height: 18px; /* 150% */
  `,
  B1_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 18px;
    line-height: 28px; /* 155.556% */
  `,
  B2_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  B3_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `,
  B4_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 12px;
    line-height: 18px; /* 150% */
  `,
  B5_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 10px;
    line-height: 16px; /* 160% */
  `,
  Bu1_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 18px;
    line-height: 28px; /* 155.556% */
  `,
  Bu2_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  Bu3_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 14px;
    line-height: 22px; /* 157.143% */
  `,
  Bu4_Bold: styled(commonTextStyle)`
    font-family: ${FontNotoSansBold};
    font-size: 12px;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
  `,
  T1_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; /* 150% */
  `,
  T2_Bold: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-weight: 700;
    font-size: 12px;
    line-height: 20px; /* 166.667% */
  `
};
