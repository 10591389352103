import React, { useEffect, useRef, useState } from 'react';
import { MessageImage, MessageText } from 'react-web-gifted-chat';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import Forward from '../../../../../assets/images/icons/Forward.svg';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import CustomMessageVideo from './CustomMessageVideo';

const Image = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;

const Caption1Text = styled(TEXT.caption1)``;

const Caption2Text = styled(TEXT.caption2)``;

const convertToChatTime = (t: Date) => {
  const time = new Date(t);
  return time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

const CustomBubble = (props: any) => {
  const {
    user,
    previousMessage,
    currentMessage,
    nextMessage,
    forwarding,
    dealContactId
  } = props;

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (!forwarding) setIsSelected(false);
  }, [forwarding]);

  const isLastMessage = Object.keys(nextMessage).length === 0;

  const isSameMinuteAsNext =
    convertToChatTime(currentMessage.createdAt) ===
      convertToChatTime(nextMessage.createdAt) && !nextMessage.system;

  const isCurrentUser =
    user.id === currentMessage.user?.id || user.id === currentMessage.user?._id;

  const {
    user: { _id: currentUserId }
  } = currentMessage;

  const isMedia = currentMessage.image || currentMessage.video;

  const isCucumber = currentUserId === 'SYSTEMCUCUMBER';

  const isSameMinuteAsPrev =
    convertToChatTime(currentMessage.createdAt) ===
      convertToChatTime(previousMessage.createdAt) &&
    !previousMessage.system &&
    previousMessage?.user?._id === currentMessage.user._id;

  function renderMessageText() {
    if (currentMessage && currentMessage.text) {
      const {
        containerStyle,
        wrapperStyle,
        optionTitles,
        ...messageTextProps
      } = props;

      if (props.renderMessageText) {
        return props.renderMessageText(messageTextProps);
      }
      return <MessageText {...messageTextProps} />;
    }
    return null;
  }

  function renderMessageImage() {
    if (currentMessage && currentMessage.image) {
      const { containerStyle, wrapperStyle, ...messageImageProps } = props;

      if (props.renderMessageImage) {
        return props.renderMessageImage(messageImageProps);
      }
      return (
        <MessageImage
          {...messageImageProps}
          imageStyle={{ borderRadius: '25px' }}
        />
      );
    }
    return null;
  }

  function renderMessageVideo() {
    if (currentMessage && currentMessage.video) {
      return <CustomMessageVideo {...props} />;
    }
    return null;
  }

  const isAlert =
    currentMessage?.action?.ACTION_CODE?.includes('NOT_FOUND') ||
    currentMessage?.action?.ACTION_CODE?.includes('BANNED');

  return (
    <div
      style={{
        alignItems: isCurrentUser ? 'flex-end' : 'flex-start',
        marginRight: isCurrentUser ? '36px' : '0px',
        marginTop: isSameMinuteAsPrev ? 5 : 12
      }}
    >
      {!isCurrentUser && !isSameMinuteAsPrev && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Image
            src={
              isCucumber
                ? require('../../../assets/images/AnotherDotBotIcon.png')
                : currentMessage.user.avatar
            }
            style={{
              width: 30,
              height: 30,
              borderRadius: 20,
              marginRight: 6.4
            }}
          />
          <Caption1Text>
            {isCucumber ? 'AnotherDot Assistant' : currentMessage.user.name}
          </Caption1Text>
        </div>
      )}
      <div
        style={{
          borderWidth: 1,
          maxWidth: '516px',
          backgroundColor:
            isCucumber || isMedia
              ? COLOR.WHITE
              : isCurrentUser
                ? COLOR.Blue_700
                : COLOR.WHITE,
          borderRadius: 25,
          marginLeft: 30,
          marginTop: 8,
          marginRight: isCucumber ? 35 : 0,
          border:
            isCurrentUser || isMedia
              ? undefined
              : `1px solid ${
                  isCucumber
                    ? isAlert
                      ? COLOR.Warning
                      : COLOR.Blue_700
                    : COLOR.Gray_200
                }`
        }}
      >
        {renderMessageText()}
        {renderMessageVideo()}
        {renderMessageImage()}
      </div>
      {!isSameMinuteAsNext ? (
        <Caption2Text
          style={{
            color: COLOR.Gray_300,
            marginTop: '4px',
            marginLeft: isCurrentUser ? 0 : 35,
            marginBottom: isLastMessage ? 50 : 0,
            alignItems: isCurrentUser ? 'flex-end' : 'flex-start',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {convertToChatTime(currentMessage.createdAt)}
        </Caption2Text>
      ) : null}
    </div>
  );
};

export default CustomBubble;
