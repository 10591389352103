import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { genderFieldToText } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';

interface Props {
  detail: string;
  productDetail: string;
  gender: string;
  age: string;
  location: string;
  isNegotiateCash: boolean;
  onEdit?: () => void;
  isShowUndefined?: boolean;
}

function DealCardCampaign(props: Props) {
  const {
    detail,
    productDetail,
    gender,
    age,
    location,
    isNegotiateCash,
    onEdit,
    isShowUndefined = true
  } = props;
  const changWat = location === 'all' ? 'ทุกจังหวัด' : location;

  return (
    <Container>
      {isNegotiateCash && (
        <NegoContainer>
          <TagContainer>
            <WhiteBoldB3Text>ยื่นข้อเสนอได้</WhiteBoldB3Text>
            <B2Text>เงินค่าตอบแทน</B2Text>
          </TagContainer>
          <RXIcon name="ArrowShortRight" color={COLOR.Gray_300} />
        </NegoContainer>
      )}
      <HeaderContainer>
        <H6Text>Campaign</H6Text>
        {!!onEdit && (
          <EditContainer onClick={onEdit}>
            <RXIcon name="Edit" color={COLOR.Blue_700} size={20} />
            <BlueBoldBody1Text style={{ marginLeft: '4px' }}>
              แก้ไข
            </BlueBoldBody1Text>
          </EditContainer>
        )}
      </HeaderContainer>
      {(isShowUndefined || detail) && (
        <DetailContainer>
          <BoldH7Text style={{ marginBottom: '8px' }}>
            รายละเอียดแคมเปญ
          </BoldH7Text>
          <ActivableB2Text active={!!detail}>
            {detail || 'ยังไม่ได้ระบุ'}
          </ActivableB2Text>
        </DetailContainer>
      )}

      {(isShowUndefined || productDetail) && (
        <DetailContainer>
          <BoldH7Text style={{ marginBottom: '8px' }}>
            รายละเอียดสินค้า/บริการ/โปรโมชั่น
          </BoldH7Text>
          <ActivableB2Text active={!!productDetail}>
            {productDetail || 'ยังไม่ได้ระบุ'}
          </ActivableB2Text>
        </DetailContainer>
      )}
      <DetailContainer>
        <BoldH7Text style={{ marginBottom: '8px' }}>
          กลุ่มลูกค้าของแบรนด์
        </BoldH7Text>
        <B2Text style={{ marginBottom: '8px' }}>
          เพศ :{' '}
          <ActivableB2Text active={!!gender}>
            {genderFieldToText(gender)}
          </ActivableB2Text>
        </B2Text>
        <B2Text style={{ marginBottom: '8px' }}>
          อายุ : {`${age} ปี` || 'ยังไม่ได้ใส่รายละเอียดสินค้า'}
        </B2Text>
        <B2Text>
          จังหวัด : {`${changWat}` || 'ยังไม่ได้ใส่รายละเอียดสินค้า'}
        </B2Text>
      </DetailContainer>
    </Container>
  );
}

export default DealCardCampaign;

const Container = styled.div`
  margin-top: 32px;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const HeaderContainer = styled(RowContainer)`
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px dotted ${COLOR.Gray_300};
  margin-bottom: 12px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NegoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${COLOR.Gray_300};
  border-radius: 50px;
  padding: 8px;
  margin-bottom: 24px;
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const H6Text = styled(TEXT.H6_Bold)``;

const BoldH7Text = styled(TEXT.H7_Bold)``;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  display: flex;
  width: 86px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: ${COLOR.ORANGE};
  border-radius: 50px;
  color: ${COLOR.White};
`;

const ActivableB2Text = styled(TEXT.B2_Reg)<ActiveProps>`
  margin-bottom: 16px;
  color: ${props => (props.active ? COLOR.BLACK : COLOR.Warning)};
  white-space: pre-wrap;
`;

const BlueBoldBody1Text = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};
`;

const B2Text = styled(TEXT.B2_Reg)``;
