import { useState } from 'react';
import styled from 'styled-components';
import { ActiveProps } from '../../../types';
import { RXIcon } from 'rn-rx-icons';
import AToolTip from '../../../components/atoms/AToolTip';
import { COLOR } from '../../../fonts/color';

const ExplanationTooltip = () => {
  const [tooltipActive, setTooltipActive] = useState<boolean>(false);

  return (
    <TooltipContainer className="tooltip-container">
      <IconContainer
        onMouseEnter={() => {
          setTooltipActive(true);
        }}
        onMouseLeave={() => {
          setTooltipActive(false);
        }}
      >
        <RXIcon name="Info" color={COLOR.Gray_M} size={24} />
      </IconContainer>
      <TooltipContentContainer active={tooltipActive}>
        <AToolTip
          text={[
            `คำอธิบายเพิ่มเติม`,
            `1. ข้อมูลอัพเดททุกวัน (ยกเว้นมีอินฟลูส่งภายในวันจะอัพเดททันที)`,
            `2. ข้อมูลจะแสดงเฉพาะผู้ใช้งานที่ส่งงานเข้ามาแล้วเท่านั้น`,
            `3. ข้อมูลจะอัพเดทให้สิ้นสุดหลังจากส่งงานวันสุดท้าย +30 วัน`
          ]}
        />
      </TooltipContentContainer>
    </TooltipContainer>
  );
};

export default ExplanationTooltip;

const TooltipContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const TooltipContentContainer = styled.div<ActiveProps>`
  position: absolute;
  top: 30px;
  right: 0px;
  display: flex;
  width: 307px;
  opacity: ${props => (props.active ? 1 : 0)};
  z-index: ${props => (props.active ? 99 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
`;
