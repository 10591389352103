import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import RLoading from '../components/atoms/RLoading';
import ScrollToTop from '../components/atoms/ScrollToTop';
import RecommendList from '../reusables/InfluencerList/RecommendList';
import Search from '../reusables/InfluencerList/Search';
import { TEXT } from '../fonts/text';
import { useInfluencerStore } from '../stores/initialize/Context';
import { PlatformWithTotalType } from '../types/deal';
import {
  FlexStartColumnContainer,
  SpaceBetweenRowContainer
} from '../utils/styling/general';
import { Feature, featureAccessStore } from '../stores/featureAccessStore';
import {
  InfluencerListContextProvider,
  useInfluencerListFilterContext
} from '../reusables/InfluencerList/InfluencerListContext';
import SidebarFilterModal from '../reusables/InfluencerList/SidebarFilter/SidebarFilter';
import FreeTrialBanner from '../reusables/FreeTrialBanner';

const DirectoryPage = observer(() => {
  const {
    max,
    setFilterDirectory,
    loading,
    getInfluencerList,
    influencerList,
    clearInfluencerList
  } = useInfluencerStore();
  const [finishInitialize, setFinishInitialize] = useState(false);
  const canAccess = featureAccessStore.canAccess(Feature.INFLUENCER_DIRECTORY);

  const getNewInfluencerList = () => {
    if (!canAccess) {
      return;
    }
    clearInfluencerList();
    if (!loading.list || (loading.list && finishInitialize)) {
      const value = {
        username: filters.keyword,
        gender: filters.influGender,
        tier: filters.tier,
        social: filters.platform,
        attribute: filters.attributesActiveList,
        engagement: filters.engagementRange,
        follower: filters.followerRange,
        rating: filters.rating,
        location: filters.location,
        age: filters.ageRange,
        audience: {
          age: filters.audienceAgeRange,
          gender: filters.audienceGender,
          location: filters.audienceLocation
        },
        category: filters.categoryActive
      };
      setFilterDirectory(value);
      getInfluencerList({
        filter: value,
        withUnfiltered: false,
        limit: 12,
        offset: 0,
        sortBy: filters.sortBy,
        sortOrder: filters.sortOrder,
        sortByMatch: false,
        firstInitialize: influencerList.data.length < 12
      });
    }
  };

  const { data, next } = influencerList;

  const {
    filters,
    updateFilter,
    isOpenFilterPanel,
    updateMaxEngagement,
    updateMaxActiveFollower
  } = useInfluencerListFilterContext();

  const { list: listLoading } = loading;

  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);

  const onTitleClick = (title: PlatformWithTotalType) => {
    updateFilter('platform', title);
  };

  useEffect(() => {
    updateFilter('engagementRange', [0, max.engagement]);
    updateFilter('followerRange', [0, max.activeFollower]);
    updateMaxEngagement(max.engagement);
    updateMaxActiveFollower(max.activeFollower);
  }, [max.engagement, max.activeFollower]);

  useEffect(() => {
    if (!canAccess) return;
    const handleScroll = async () => {
      if (data.length === 0 || listLoading || loadMoreLoading) return;

      const scrollPosition = window.scrollY + window.innerHeight; // Current scroll position compared to the total height of the page
      const pageHeight = document.documentElement.scrollHeight; // Total height of the page

      const scrollPercentage = (scrollPosition / pageHeight) * 100; // Calculate percentage of page scrolled

      const threshold = 80; // fetch when scroll > 80% of overall page

      if (scrollPercentage <= threshold) return;

      setLoadMoreLoading(true);
      await next();
      setLoadMoreLoading(false);
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [data.length, listLoading, loadMoreLoading, canAccess]);

  useEffect(() => {
    getNewInfluencerList();
  }, [
    filters.platform,
    filters.categoryActive,
    filters.attributesActiveList,
    filters.sortBy,
    filters.sortOrder,
    filters.keyword
  ]);

  if (featureAccessStore.loading) {
    return (
      <Container>
        <ContentContainer>
          <RLoading />
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollToTop />
      {!canAccess ? <FreeTrialBanner /> : null}
      <SidebarFilterModal getNewInfluencerList={getNewInfluencerList} />
      <ContentContainer>
        <ColumnContainer>
          <SpaceBetweenRowContainer>
            <FlexStartColumnContainer>
              <BoldH4Text>Search & Select</BoldH4Text>
              <B2Text>AnotherDot คัดเลือก Influencer เพื่อแบรนด์ของคุณ</B2Text>
            </FlexStartColumnContainer>
            <Search containerWidth={360} />
          </SpaceBetweenRowContainer>
          {/* // this is reusable module */}
          <RecommendList
            titleList={[
              {
                title: 'ทั้งหมด',
                haveTotal: false,
                onClick: () => onTitleClick('ALL')
              },
              {
                title: 'Instagram',
                haveTotal: false,
                icon: 'Instagram',
                onClick: () => onTitleClick('INSTAGRAM')
              },
              {
                title: 'TikTok',
                haveTotal: false,
                icon: 'Tiktok',
                onClick: () => onTitleClick('TIKTOK')
              },
              {
                title: 'Facebook',
                haveTotal: false,
                icon: 'FacebookCircle',
                onClick: () => onTitleClick('FACEBOOK')
              },
              {
                title: 'Lemon8',
                haveTotal: false,
                icon: 'Lemon',
                onClick: () => onTitleClick('LEMON')
              }
            ]}
            fromDeal={false}
            filterActive={isOpenFilterPanel}
            finishInitialize={finishInitialize}
            setFinishInitialize={setFinishInitialize}
          />
        </ColumnContainer>
      </ContentContainer>
    </Container>
  );
});

const WithFilterContext = () => {
  return (
    <InfluencerListContextProvider>
      <DirectoryPage />
    </InfluencerListContextProvider>
  );
};

export default WithFilterContext;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 36px 0px 36px;
  width: 100%;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 64px;
  gap: 60px;
  width: 100%;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  position: relative;
`;

const BoldH4Text = styled(TEXT.H4_Bold)``;

const B2Text = styled(TEXT.B2_Reg)``;
