import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { Deal, PlatformType } from '../../../types/deal';
import RButton from '../../atoms/RButton';
import RModal from '../../atoms/RModal';
import Rradiobutton from '../../atoms/Rradiobutton';
import REmptyState from '../../atoms/REmptyState';
import { RowContainer } from '../../../utils/styling/general';
import RPlatformIcon from '../../atoms/RPlatformIcon';

interface SelectProps {
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  dealId: string;
}

const select = (props: SelectProps) => {
  const { dealId, selected, setSelected } = props;
  const newSelect = selected;
  const existIndex = newSelect.findIndex(oldSelect => oldSelect === dealId);

  if (selected.includes(dealId)) {
    newSelect.splice(existIndex, 1);
  } else {
    newSelect.push(dealId);
  }
  setSelected([...newSelect]);
};

interface DealListProps {
  deals: Deal[];
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const DealListComponent = (props: DealListProps) => {
  const { deals, selected, setSelected } = props;

  if (deals.length === 0)
    return (
      <REmptyState
        graphic={
          <EmptyStateImage
            src={require('../../../assets/images/state/NothingHere.png')}
          />
        }
        header="ไม่มีข้อมูล"
        descriptions={[
          'คุณยังไม่สร้างแคมเปญ หรือ',
          'ยังไม่มีแคมเปญที่กำลังเผยแพร่อยู่ในตอนนี้'
        ]}
        containerStyle={{
          height: 530
        }}
      />
    );

  return (
    <DealListContainer>
      {deals.map(deal => {
        const active = !!selected.find(select => select === deal.dealId);
        const platformIcon = deal?.platforms?.[0]
          ? (deal.platforms[0].toUpperCase() as PlatformType)
          : '';
        const marginLeft = platformIcon === 'FACEBOOK' ? -3 : 0;
        return (
          <DealContainer
            onClick={() =>
              select({ dealId: deal.dealId, selected, setSelected })
            }
            active={active}
          >
            <GapRowContainer>
              {platformIcon !== '' && (
                <RPlatformIcon
                  platform={platformIcon}
                  iconSize={20}
                  dimension={24}
                />
              )}
              <Bu2Text active={active} style={{ marginLeft }}>
                {deal.name}
              </Bu2Text>
            </GapRowContainer>
            <Rradiobutton active={active} />
          </DealContainer>
        );
      })}
    </DealListContainer>
  );
};

interface Props {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  deals: Deal[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  selected: string[];
  onConfirm: () => void;
}

const InviteModal = (props: Props) => {
  const { active, setActive, deals, setSelected, selected, onConfirm } = props;

  const buttonStyle = {
    width: '458px',
    marginTop: '24px',
    marginBottom: '32px'
  };
  const buttonText = deals.length === 0 ? 'สร้างแคมเปญ' : 'ส่งคำเชิญ';
  const buttonActive = selected.length > 0 || deals.length === 0;

  return (
    <RModal
      active={!!active}
      setActive={() => {
        setSelected([]);
        setActive(false);
      }}
    >
      <Container>
        <IconContainer
          onClick={() => {
            setSelected([]);
            setActive(false);
          }}
        >
          <RXIcon name="Close" />
        </IconContainer>
        <BoldH5Text style={{ marginBottom: '8px' }}>ส่งคำเชิญ</BoldH5Text>
        <B3Text style={{ marginBottom: '8px' }}>
          โปรดเลือกแคมเปญที่ต้องการให้อินฟลูเอนเซอร์มาสมัคร
        </B3Text>
        <DealListComponent
          deals={deals}
          selected={selected}
          setSelected={setSelected}
        />
        <RButton
          title={buttonText}
          onPress={onConfirm}
          containerStyle={buttonStyle}
          active={buttonActive}
        />
      </Container>
    </RModal>
  );
};

export default InviteModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 522px;
  height: 530px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 24px;
  background: ${COLOR.WHITE};
  position: relative;
`;

const DealContainer = styled.div<ActiveProps>`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 458px;
  height: 48px;
  background: ${props => (props.active ? COLOR.Blue_50 : COLOR.WHITE)};
  border: ${props => (props.active ? 'none' : '1px solid #eaeaea')};
  border-radius: 25px;

  :hover {
    cursor: pointer;
    background: ${props => (props.active ? COLOR.Blue_50 : COLOR.Gray_50)};
  }
`;

const DealListContainer = styled.div`
  height: 530px;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    position: absolute;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    position: absolute;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLOR.Gray_200};
    border-radius: 18px;
    border: solid 4px transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: solid 4px transparent;
    background: ${COLOR.Gray_300};
    background-clip: padding-box;
  }

  :hover {
    overflow-y: overlay;
  }
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
`;

const EmptyStateImage = styled.img`
  width: 164px;
  height: 164px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 24px;
`;

const BoldH5Text = styled(TEXT.H5_Bold)`
  margin-top: 32px;
`;

const Bu2Text = styled(TEXT.Bu2_Reg)<ActiveProps>`
  text-overflow: ellipsis;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  width: 280px;
  color: ${props => (props.active ? COLOR.Black : COLOR.Gray_M)};
`;

const B3Text = styled(TEXT.B3_Reg)``;
