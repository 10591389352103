import { IMessage, RenderMessageImageProps } from 'react-web-gifted-chat';
import styled from 'styled-components';

interface Props extends RenderMessageImageProps<IMessage> {
  setShowImage: (img: string) => void;
}

function CustomMessageImage(props: Props) {
  const { currentMessage, setShowImage } = props;
  const image = currentMessage?.image;

  if (image) {
    return <ImageContainer src={image} onClick={() => setShowImage(image)} />;
  }

  return <Container></Container>;
}

export default CustomMessageImage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const ImageContainer = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
  :hover {
    cursor: pointer;
  }
`;
