import styled from 'styled-components';
import { ANDTEXT } from '../../../../fonts/andText';
import { COLOR } from '../../../../fonts/color';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../../utils/styling/general';
import ANDSwitch from '../../../atoms/ANDSwitch';

interface Props {
  isSecret: boolean;
  setIsSecret: (isSecret: boolean) => void;
}

function Public(props: Props) {
  const { isSecret, setIsSecret } = props;

  return (
    <Container>
      <TitleContainer>
        <BoldHeader7Text>ประเภทของ แคมเปญ</BoldHeader7Text>
      </TitleContainer>
      <ContentContainer>
        <SpaceBetweenRowContainer style={{ width: 568 }}>
          <ContentContainer>
            <GapRowContainer>
              <BoldHeader8Text>
                เปิดเป็นสาธารณะให้ Influencer ทุกคนเข้าร่วมแคมเปญได้
              </BoldHeader8Text>
            </GapRowContainer>
            <Body3Text>
              (หากไม่เปิดสาธารณะ คุณต้องเลือก Influencer เข้าแคมเปญเองจากหน้า
              Search & Select)
            </Body3Text>
          </ContentContainer>
          <ANDSwitch
            isActive={!isSecret}
            onChange={() => setIsSecret(!isSecret)}
          />
        </SpaceBetweenRowContainer>
      </ContentContainer>
    </Container>
  );
}

export default Public;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 688px;
  gap: 32px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleContainer = styled.div`
  width: 88px;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 20px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;

const BoldHeader7Text = styled(ANDTEXT.header7BOLD)`
  width: 88px;
`;

const Body3Text = styled(ANDTEXT.body3)`
  color: ${COLOR.Gray_D};
`;
