import styled from 'styled-components';
import { IGetInfluencerResponse } from '../../../../types/api/getInfluencer';
import { Work } from '../../../../types/work';
import {
  ColumnContainer,
  RowContainer
} from '../../../../utils/styling/general';
import DiscoverInfluProfile from '../../../atoms/Discover/DiscoverInfluProfile';
import DiscoverPreviousWork from '../../../atoms/Discover/DiscoverPreviousWork';
import DiscoverWorkCardDetail from '../../../atoms/Discover/DiscoverWorkCardDetail';

interface Props {
  work: Work;
  influencer: IGetInfluencerResponse | undefined;
  influLoading: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function DiscoverWorkDetail(props: Props) {
  return (
    <Container>
      <ColumnContainer style={{ gap: 32 }}>
        <RowContainer style={{ alignItems: 'flex-start', gap: 32 }}>
          <DiscoverWorkCardDetail {...props} />
          <DiscoverInfluProfile {...props} />
        </RowContainer>
        <DiscoverPreviousWork {...props} />
      </ColumnContainer>
    </Container>
  );
}

export default DiscoverWorkDetail;

const Container = styled.div``;
