import { observer } from 'mobx-react';
import { useState } from 'react';
import { RXIcon } from 'rn-rx-icons';
import shortid from 'shortid';
import styled from 'styled-components';
import { PostMedia, scopeOfWorkType } from '../../../../class/ScopeOfWork';
import { ANDCOLOR } from '../../../../fonts/andColor';
import { ANDTEXT } from '../../../../fonts/andText';
import { TEXT } from '../../../../fonts/text';
import { ActiveProps, NullableActiveProps } from '../../../../types';
import { RowContainer } from '../../../../utils/styling/general';
import ANDButton from '../../../atoms/ANDButton';
import ANDCheckbox from '../../../atoms/ANDCheckbox';
import ANDCounter from '../../../atoms/ANDCounter';
import { COLOR } from '../../../../fonts/color';

interface Props {
  setIgPostMedias: (postMedia: PostMedia) => void;
  setInactive: () => void;
}

function IGScopeOfWorkSelector(props: Props) {
  const { setIgPostMedias, setInactive } = props;
  const [selected, setSelected] = useState<scopeOfWorkType | ''>('');
  const [value, setValue] = useState<number[]>([0, 0]);

  const isSingleImage = !!value[0];
  const isSingleVideo = !!value[1];

  const selectType = (type: scopeOfWorkType) => {
    setSelected(type);
    if (type === 'IG_reels') {
      setValue([0, 1]);
      return;
    }
    setValue([0, 0]);
  };

  const handleSave = () => {
    if (!selected) {
      return;
    }
    const media: PostMedia = {
      id: shortid.generate(),
      image: value[0],
      video: value[1],
      article: 0,
      postType: selected
    };
    setIgPostMedias(media);
    setInactive();
  };

  const AlbumComponent = () => {
    if (selected === 'IG_album_post')
      return (
        <ActivePostContainer>
          <ActivePostHeaderContainer>
            <RowContainer>
              <RXIcon name="Multi" color={ANDCOLOR.BLUE} />
              <BlueBody1Text>Multi Post</BlueBody1Text>
            </RowContainer>
            <ANDCheckbox active={true} containerStyle={{ borderRadius: 24 }} />
          </ActivePostHeaderContainer>
          <div style={{ width: '100%', padding: '0px 16px' }}>
            <MultiPostContainer>
              <LabelContainer>
                <RXIcon name="Image" color={ANDCOLOR.BLUE} />
                <BlueBody1Text>รูปภาพ</BlueBody1Text>
              </LabelContainer>
              <ANDCounter
                value={value[0]}
                changeValue={newValue => {
                  setValue([newValue, value[1]]);
                }}
                containerStyle={{ height: '40px' }}
              />
            </MultiPostContainer>
            <MultiPostContainer>
              <LabelContainer>
                <RXIcon name="VideoNoFill" color={ANDCOLOR.BLUE} />
                <BlueBody1Text>วีดีโอ</BlueBody1Text>
              </LabelContainer>
              <ANDCounter
                value={value[1]}
                changeValue={newValue => {
                  setValue([value[0], newValue]);
                }}
                containerStyle={{ height: '40px' }}
              />
            </MultiPostContainer>
          </div>
        </ActivePostContainer>
      );
    return (
      <PostContainer active={false} onClick={() => selectType('IG_album_post')}>
        <RowContainer>
          <RXIcon name="Multi" color={COLOR.Gray_D} />
          <GrayBody1Text>Multi Post</GrayBody1Text>
        </RowContainer>
        <ANDCheckbox active={false} containerStyle={{ borderRadius: 24 }} />
      </PostContainer>
    );
  };

  const StoryComponent = () => {
    if (selected === 'IG_story')
      return (
        <ActivePostContainer>
          <ActivePostHeaderContainer>
            <RowContainer>
              <RXIcon name="StoryCircle" color={ANDCOLOR.BLUE} />
              <BlueBody1Text>Story Post</BlueBody1Text>
            </RowContainer>
            <ANDCheckbox active={true} containerStyle={{ borderRadius: 24 }} />
          </ActivePostHeaderContainer>
          <div style={{ width: '100%', padding: '0px 16px' }}>
            <MultiPostContainer>
              <LabelContainer>
                <RXIcon name="Image" color={ANDCOLOR.BLUE} />
                <BlueBody1Text>รูปภาพ</BlueBody1Text>
              </LabelContainer>
              <ANDCounter
                value={value[0]}
                changeValue={newValue => {
                  setValue([newValue, value[1]]);
                }}
                containerStyle={{ height: '40px' }}
              />
            </MultiPostContainer>
            <MultiPostContainer>
              <LabelContainer>
                <RXIcon name="VideoNoFill" color={ANDCOLOR.BLUE} />
                <BlueBody1Text>วีดีโอ</BlueBody1Text>
              </LabelContainer>
              <ANDCounter
                value={value[1]}
                changeValue={newValue => {
                  setValue([value[0], newValue]);
                }}
                containerStyle={{ height: '40px' }}
              />
            </MultiPostContainer>
          </div>
        </ActivePostContainer>
      );
    return (
      <PostContainer active={false} onClick={() => selectType('IG_story')}>
        <RowContainer>
          <RXIcon name="StoryCircle" color={COLOR.Gray_D} />
          <GrayBody1Text>Story</GrayBody1Text>
        </RowContainer>
        <ANDCheckbox active={false} containerStyle={{ borderRadius: 24 }} />
      </PostContainer>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <BoldHeader8Text>
          เลือกรูปแบบของโพสต์ <RedText>*</RedText>
        </BoldHeader8Text>
      </HeaderContainer>

      {selected === 'IG_individual_post' ? (
        <ActivePostContainer>
          <ActivePostHeaderContainer>
            <RowContainer>
              <RXIcon name="Image" color={ANDCOLOR.BLUE} />
              <BlueBody1Text>Single Post</BlueBody1Text>
            </RowContainer>
            <ANDCheckbox active={true} containerStyle={{ borderRadius: 24 }} />
          </ActivePostHeaderContainer>
          <RowContainer
            style={{
              justifyContent: 'space-between',
              padding: '0px 16px',
              gap: 8
            }}
          >
            <SinglePostContainer
              active={isSingleImage}
              onClick={() => setValue([1, 0])}
            >
              <RXIcon
                name="Image"
                color={isSingleImage ? ANDCOLOR.WHITE : ANDCOLOR.BLUE}
              />
              <BlueBody1Text active={isSingleImage}>รูปภาพ</BlueBody1Text>
            </SinglePostContainer>
            <SinglePostContainer
              active={isSingleVideo}
              onClick={() => setValue([0, 1])}
            >
              <RXIcon
                name="VideoNoFill"
                color={isSingleVideo ? ANDCOLOR.WHITE : ANDCOLOR.BLUE}
              />
              <BlueBody1Text active={isSingleVideo}>วิดีโอ</BlueBody1Text>
            </SinglePostContainer>
          </RowContainer>
        </ActivePostContainer>
      ) : (
        <PostContainer
          active={false}
          onClick={() => {
            selectType('IG_individual_post');
          }}
        >
          <RowContainer>
            <RXIcon name="Image" color={COLOR.Gray_D} />
            <GrayBody1Text>Single Post</GrayBody1Text>
          </RowContainer>
          <ANDCheckbox active={false} containerStyle={{ borderRadius: 24 }} />
        </PostContainer>
      )}
      <AlbumComponent />
      <StoryComponent />
      <PostContainer
        active={selected === 'IG_reels'}
        onClick={() => selectType('IG_reels')}
      >
        <RowContainer>
          <RXIcon
            name="Reels"
            color={selected === 'IG_reels' ? ANDCOLOR.BLUE : COLOR.Gray_D}
          />

          {selected === 'IG_reels' ? (
            <Body1Text active={selected === 'IG_reels'}>Reels</Body1Text>
          ) : (
            <GrayBody1Text>Reels</GrayBody1Text>
          )}
        </RowContainer>
        <RadioContainer active={selected === 'IG_reels'}>
          <RadioContent active={selected === 'IG_reels'} />
        </RadioContainer>
      </PostContainer>
      {!!selected && (
        <RowContainer
          style={{
            justifyContent: 'space-around',
            marginTop: '16px'
          }}
        >
          <ANDButton
            title="ยกเลิก"
            backgroundColor={ANDCOLOR.WHITE}
            onPress={() => {
              setSelected('');
              setValue([0, 0]);
              setInactive();
            }}
            containerStyle={{
              minWidth: '0px',
              width: '99px',
              height: '40px'
            }}
            textColor={ANDCOLOR.BLUE}
          />
          <ANDButton
            title="ยืนยัน"
            onPress={handleSave}
            containerStyle={{
              minWidth: '0px',
              width: '99px',
              height: '40px'
            }}
            active={!!value[0] || !!value[1]}
          />
        </RowContainer>
      )}
    </Container>
  );
}

export default observer(IGScopeOfWorkSelector);

const Container = styled.div`
  position: absolute;
  right: 0;
  width: 320px;
  padding: 24px;
  border: 1px solid ${COLOR.Gray_400};
  border-radius: 24px;
  z-index: 999;
  background-color: ${ANDCOLOR.WHITE};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PostContainer = styled.div<NullableActiveProps>`
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  padding: 12px 16px 12px 16px;
  margin-top: 16px;
  background: ${props => (props.active ? ANDCOLOR.LIGHTBLUE : ANDCOLOR.WHITE)};
  border-radius: 24px;
  border: ${props => (props.active ? 'none' : `1px solid ${COLOR.Gray_200}`)};

  :hover {
    cursor: pointer;
  }
`;

const ActivePostContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${ANDCOLOR.LIGHTBLUE};
  padding: 16px;
  margin-top: 16px;
  border-radius: 24px;
`;

const ActivePostHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const RadioContainer = styled.div<ActiveProps>`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background: ${ANDCOLOR.WHITE};
  border: ${props =>
    props.active
      ? `1px solid ${ANDCOLOR.BLUE}`
      : `1px solid ${COLOR.Gray_200}`};
  border-radius: 50px;
`;

const RadioContent = styled.div<ActiveProps>`
  display: flex;
  width: 12px;
  height: 12px;
  background: ${props => (props.active ? ANDCOLOR.BLUE : ANDCOLOR.WHITE)};
  border-radius: 50px;
`;

const SinglePostContainer = styled.div<NullableActiveProps>`
  display: flex;
  width: 104px;
  height: 40px;
  padding: 11px 0px;
  justify-content: center;
  align-items: center;
  background: ${props => (props.active ? ANDCOLOR.BLUE : ANDCOLOR.WHITE)};
  border-radius: 24px;
`;

const MultiPostContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 11px 0px;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-top: 16px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Body1Text = styled(TEXT.body1)<{ active?: boolean }>`
  color: ${props => (props.active ? ANDCOLOR.BLUE : ANDCOLOR.BLACK)};
  margin-left: 8px;
`;

const BlueBody1Text = styled(TEXT.body1)<NullableActiveProps>`
  color: ${props => (props.active ? ANDCOLOR.WHITE : ANDCOLOR.BLUE)};
  margin-left: 4px;
`;

const GrayBody1Text = styled(TEXT.body1)`
  color: ${COLOR.Gray_D};
  margin-left: 8px;
`;

const BoldHeader8Text = styled(ANDTEXT.header8BOLD)``;
const RedText = styled(TEXT.body2)`
  color: ${ANDCOLOR.PINK};
`;

const BoldBody1Text = styled(TEXT.body1BOLD)``;
