import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';

const Container = styled.div`
  margin-top: 15px;
`;

const Text = styled(TEXT.B2_Reg)``;

const LinkContainer = styled(Link)`
  text-decoration: none;
`;

const LinkText = styled(TEXT.Bu2_Bold)`
  color: ${COLOR.Blue_700};
  text-decoration: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    color: ${COLOR.Blue_800};
  }
`;

const loginLink = `/login`;

function RegisterCardFooter() {
  return (
    <Container>
      <Text>
        มีบัญชีอยู่แล้ว?{' '}
        <LinkContainer to={loginLink}>
          <LinkText>เข้าสู่ระบบ</LinkText>
        </LinkContainer>
      </Text>
    </Container>
  );
}

export default RegisterCardFooter;
