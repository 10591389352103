import React, { Fragment } from 'react';
import CustomMarkdown from './CustomMarkdown';
import { ToastContainer, toast as Toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontFCIconicBold, FontFCIconicRegular } from '../../../fonts/font';
import { COLOR } from '../../../fonts/color';
import ConfirmFooter, { IActionType } from './ConfirmFooter';
import { User } from 'react-web-gifted-chat';
import { ChatMessage } from '../../../types/chatMessage';
import styled from 'styled-components';

// noinspection RegExpRedundantEscape,RegExpSimplifiable,RegExpUnnecessaryNonCapturingGroup
const WWW_REGEX =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

function urlify(text: string) {
  return text.replace(WWW_REGEX, function (url) {
    return `[${url}](${url})`;
  });
}

const commentStyle: Record<string, React.CSSProperties> = {
  body: {
    fontFamily: FontFCIconicRegular,
    fontSize: 18,
    lineHeight: 20
  },
  strong: {
    fontWeight: 'normal',
    fontFamily: FontFCIconicBold,
    fontSize: 18,
    lineHeight: 20
  },
  link: {
    color: COLOR.PINK,
    textDecorationLine: 'underline'
  }
};

const currentUserStyles = {
  ...commentStyle.body,
  color: COLOR.WHITE
};

const partnerUserStyles = {
  ...commentStyle.body,
  color: COLOR.BLACK
};

const WWW_URL_PATTERN = /^www\./i;

interface IActionMessage {
  ACTION_CODE: keyof IActionType;
  STATUS: string;
  PARAMS: { [key: string]: string };
}

interface Props {
  user: User;
  currentMessage: ChatMessage;
}

const CustomMessageText = (props: Props) => {
  const { user, currentMessage } = props;
  const isCurrentUser =
    user.id === currentMessage.user?.id || user.id === currentMessage.user?._id;
  const { text } = currentMessage;

  const isCucumber =
    currentMessage.user?.id === 'SYSTEMCUCUMBER' ||
    currentMessage.user?._id === 'SYSTEMCUCUMBER';

  const onTextPress = async () => {
    await navigator.clipboard.writeText(text || '');
    Toast('copied to clipboard!');
  };

  const message = () => {
    // use text only for now.
    const msg = (currentMessage.mdText || currentMessage.text || '').toString();
    // const msg = (currentMessage.text || "").toString();

    return urlify(msg);
  };

  const action = currentMessage.action as IActionMessage | undefined;
  return (
    <Fragment>
      <div
        style={{
          padding: '10px 15px 15px 15px',
          maxWidth: '400px'
        }}
        onClick={onTextPress}
      >
        <CustomMarkdown
          message={message()}
          userType={isCucumber ? 'bot' : isCurrentUser ? 'current' : 'partner'}
          // components={components}
        />
      </div>
      {!!action && (
        <ConfirmFooter
          {...props}
          hasFooter={!!action}
          isConfirmed={action.STATUS === 'PRESSED'}
          actionCode={action.ACTION_CODE}
          params={action.PARAMS}
        />
      )}
    </Fragment>
  );
};

export default CustomMessageText;
