import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import useWindowDimensions from '../../../utils/hook/UseWindowDimensions';

const Banner = () => {
  const { width } = useWindowDimensions();

  const navigateToDestination = () => {
    window.open('https://bit.ly/3yZJx9H');
  };

  const bannerWidth = width - 312 < 1128 ? 1128 : width - 312;
  const bannerHeight = bannerWidth / 5.64;

  return (
    <Container>
      <ImageContainer
        width={bannerWidth}
        height={bannerHeight}
        onClick={navigateToDestination}
        src={require('../../../assets/images/Banner/AnotherDot.webp')}
      ></ImageContainer>
      <CarouselIndicator />
    </Container>
  );
};

export default Banner;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;

  :hover {
    cursor: pointer;
  }
`;

const CarouselIndicator = styled.div`
  height: 6px;
  width: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: ${COLOR.Gray_300};
  border-radius: 50px;
`;
