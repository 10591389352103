import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { PlatformType } from '../../../types/deal';
import { toDecimalNumber } from '../../../utils';
import {
  ColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import RPlatformIcon from '../RPlatformIcon';

interface FollowersProps {
  followers: number;
  platform: PlatformType;
}

const FollowersComponent = (props: FollowersProps) => {
  const { followers, platform } = props;
  if (!followers) return <></>;
  const iconSize = 16;
  const containerSize = 20;
  return (
    <GapRowContainer>
      <RPlatformIcon
        platform={platform}
        iconSize={iconSize}
        dimension={containerSize}
      />
      <WhiteBoldH7Text>
        {toDecimalNumber({ number: followers })}
      </WhiteBoldH7Text>
    </GapRowContainer>
  );
};

const BodyComponent = (props: Props) => {
  const { instagramFollowers, tiktokFollowers, facebookFollowers } = props;
  if (!instagramFollowers && !tiktokFollowers && !facebookFollowers)
    return (
      <>
        <Gray300B5Text>Followers</Gray300B5Text>
        <WhiteH9Text>ไม่มีข้อมูล Social Media</WhiteH9Text>
      </>
    );
  return (
    <FollowersContainer>
      <Gray300B5Text>Followers</Gray300B5Text>
      <FullWidthRowContainer>
        <FollowersComponent
          platform="INSTAGRAM"
          followers={instagramFollowers}
        />
        <FollowersComponent platform="TIKTOK" followers={tiktokFollowers} />
        <FollowersComponent platform="FACEBOOK" followers={facebookFollowers} />
      </FullWidthRowContainer>
    </FollowersContainer>
  );
};

interface Props {
  instagramFollowers: number;
  tiktokFollowers: number;
  facebookFollowers: number;
}

const FavoriteInfluencerCardSocial = (props: Props) => {
  return (
    <FullWidthSpaceRowContainer>
      <BodyComponent {...props} />
    </FullWidthSpaceRowContainer>
  );
};

export default FavoriteInfluencerCardSocial;

const FollowersContainer = styled(ColumnContainer)`
  align-items: flex-start;
  height: 100%;
  width: 100%;
  gap: 4px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 4px;
  width: 33%;
  position: relative;
`;

const FullWidthRowContainer = styled(RowContainer)`
  width: 100%;
`;

const FullWidthSpaceRowContainer = styled(SpaceBetweenRowContainer)`
  width: 100%;
`;

const WhiteBoldH7Text = styled(TEXT.H7_Bold)`
  color: ${COLOR.White};
`;

const WhiteH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.White};
`;

const Gray300B5Text = styled(TEXT.B5_Reg)`
  color: ${COLOR.Gray_300};
`;
