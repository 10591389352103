import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { RXIcon } from 'rn-rx-icons';
import { COLOR } from '../../../../fonts/color';
import { PlatformType } from '../../../../types/deal';
import { toCapitalize } from '../../../../utils';
import { LegendType, circleColors } from './PerformanceChart';
import styled from 'styled-components';
import { TEXT } from '../../../../fonts/text';
import { RowContainer } from '../../../../utils/styling/general';
import { forwardRef } from 'react';

interface BackgroundProps {
  color: string;
}

interface Props {
  type: LegendType;
  legendActive: Record<LegendType, { index: number; active: boolean }>;
  setLegendActive: React.Dispatch<
    React.SetStateAction<
      Record<
        LegendType,
        {
          index: number;
          active: boolean;
        }
      >
    >
  >;
  platformActive: PlatformType;
}

const PerformanceChartLegend = forwardRef<
  ChartJSOrUndefined<'line', number[], string>,
  Props
>(function LegendComponent(props: Props, ref) {
  const { type, legendActive, platformActive, setLegendActive } = props;
  const colorActive = legendActive[type].active;
  const isTiktok = platformActive === 'TIKTOK';
  const shouldShowView = isTiktok && type === 'impression';
  const legendText = shouldShowView ? 'View' : toCapitalize(type);

  if (type === 'reach' && isTiktok) return <></>;

  const onLegendClick = (
    chartRef: React.ForwardedRef<ChartJSOrUndefined<'line', number[], string>>
  ) => {
    if (chartRef && typeof chartRef !== 'function' && chartRef.current) {
      let tempLegendActive = { ...legendActive };
      const { active, index } = tempLegendActive[type];
      tempLegendActive[type].active = !active;
      setLegendActive(tempLegendActive);
      chartRef.current.getDatasetMeta(index).hidden = active;
      chartRef.current.update();
    }
  };

  return (
    <ChartSelectorContainer onClick={() => onLegendClick(ref)}>
      <CheckCircle color={colorActive ? COLOR.Blue_700 : COLOR.White}>
        <RXIcon
          name="Check"
          color={colorActive ? COLOR.White : COLOR.Gray_400}
          size={16}
        />
      </CheckCircle>
      <GrayB4Text>{legendText}</GrayB4Text>
      <Circle color={circleColors[type]} />
    </ChartSelectorContainer>
  );
});

export default PerformanceChartLegend;

const ChartSelectorContainer = styled(RowContainer)`
  background-color: ${COLOR.Gray_50};
  padding: 8px 12px;
  border-radius: 40px;
  gap: 4px;
  :hover {
    cursor: pointer;
  }
`;
const Circle = styled.div<BackgroundProps>`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: ${props => props.color};
`;

const CheckCircle = styled.div<BackgroundProps>`
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: ${props => props.color};
`;

const GrayB4Text = styled(TEXT.B4_Reg)`
  color: ${COLOR.Gray_D};
`;
