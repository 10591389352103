import React from 'react';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import RButton from '../../atoms/RButton';
import RModal from '../../atoms/RModal';
import Rradiobutton from '../../atoms/Rradiobutton';

interface Props {
  active: boolean;
  onClose: () => void;
  onDefault: () => void;
  onConfirm: () => void;
  performance: string;
  setPerformance: React.Dispatch<React.SetStateAction<string>>;
  channel: string;
  setChannel: React.Dispatch<React.SetStateAction<string>>;
}

function FilterModal(props: Props) {
  const {
    active,
    onClose,
    onConfirm,
    performance,
    channel,
    setPerformance,
    setChannel,
    onDefault
  } = props;
  const performanceList = ['Reach', 'Engagement'];
  const channelList = [
    { name: 'ทุกช่องทาง', value: 'All' },
    { name: 'ช่องทาง', value: 'Instagram' },
    { name: 'ช่องทาง', value: 'Tiktok' }
  ];

  return (
    <RModal active={active} setActive={onClose}>
      <Container>
        <HeaderContainer>
          <BoldH5Text>Filters</BoldH5Text>
          <IconContainer onClick={onClose}>
            <RXIcon name="Close" />
          </IconContainer>
        </HeaderContainer>
        <FilterContainer>
          <BoldH7Text>Performance</BoldH7Text>
          {performanceList.map(perf => {
            return (
              <ReasonContainer onClick={() => setPerformance(perf)}>
                <Rradiobutton
                  active={performance === perf}
                  notActiveColor={COLOR.Gray_300}
                />
                <Bu2Text>{perf}</Bu2Text>
              </ReasonContainer>
            );
          })}
          <BoldH7Text style={{ marginTop: 16 }}>Channel</BoldH7Text>
          {channelList.map(channelElement => {
            return (
              <ReasonContainer onClick={() => setChannel(channelElement.value)}>
                <Rradiobutton
                  active={channel === channelElement.value}
                  notActiveColor={COLOR.Gray_300}
                />
                <Bu2Text>{channelElement.name}</Bu2Text>
                {channelElement.value === 'Tiktok' && (
                  <TiktokContainer>
                    <RXIcon name="Tiktok" color={COLOR.WHITE} />
                    <WhiteBoldB3Text style={{ color: COLOR.WHITE }}>
                      TikTok
                    </WhiteBoldB3Text>
                  </TiktokContainer>
                )}
                {channelElement.value === 'Instagram' && (
                  <InstagramContainer>
                    <RXIcon name="Instagram" color={COLOR.WHITE} />
                    <WhiteBoldB3Text style={{ color: COLOR.WHITE }}>
                      Instagram
                    </WhiteBoldB3Text>
                  </InstagramContainer>
                )}
              </ReasonContainer>
            );
          })}
        </FilterContainer>
        <ButtonContainer>
          <RButton
            title="ค่าเริ่มต้น"
            onPress={onDefault}
            backgroundColor={COLOR.WHITE}
            textColor={COLOR.Blue_700}
            hoverColor={COLOR.Blue_50}
          />
          <RButton title="ยืนยัน" onPress={onConfirm} />
        </ButtonContainer>
      </Container>
    </RModal>
  );
}

export default FilterModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 608px;
  height: 560px;
  border-radius: 24px;
  background: ${COLOR.WHITE};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 608px;
  height: 76px;
  border-radius: 24px 24px 0px 0px;
  border: 1px solid ${COLOR.Gray_200};
  background: ${COLOR.WHITE};
`;

const IconContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 32px;
  :hover {
    cursor: pointer;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 458px;
  height: 340px;
  margin-top: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 75px;
  margin-top: 24px;
  gap: 16px;
  width: 608px;
  border-top: 1px solid ${COLOR.Gray_200};
`;

const ReasonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  margin-top: 8px;
  gap: 8px;
  width: 360px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;

  :hover {
    cursor: pointer;
  }
`;
const InstagramContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  margin-left: 16px;

  width: 128px;
  height: 32px;

  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
  border-radius: 16px;
`;
const TiktokContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  margin-left: 16px;

  width: 128px;
  height: 32px;

  background: ${COLOR.BLACK};
  border-radius: 16px;
`;
const BoldH5Text = styled(TEXT.H5_Bold)``;
const BoldH7Text = styled(TEXT.H7_Bold)``;

const Bu2Text = styled(TEXT.Bu2_Reg)``;

const WhiteBoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.White};
`;
