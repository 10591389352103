import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Deal, PlatformType } from '../../../types/deal';
import DealCardDetail from '../../molecules/NewCreateCampaign/Preview/DealCardDetail';
import DealCardInfluencer from '../../molecules/NewCreateCampaign/Preview/DealCardInfluencer';
import DealCardScopeOfWork from '../../molecules/NewCreateCampaign/Preview/DealCardScopeOfWork';

interface Props {
  deal: Deal;
  detailWidth: number;
}

function NewDealDetail(props: Props) {
  const { deal, detailWidth } = props;

  const {
    name,
    platforms = ['Instagram'],
    photos,
    category,
    detail,
    age,
    gender,
    criteria,
    benefits,
    value,
    startDate,
    postingDate,
    submissionDate,
    location,
    isNegotiateCash,
    posts,
    hashtags,
    influencersTier,
    attribute,
    reviewTag,
    influencersCaption,
    examples,
    mustReturnProduct,
    ads,
    secret,
    dueDate
  } = deal;

  const platform = (
    platforms?.[0] || 'Instagram'
  ).toUpperCase() as PlatformType;
  const photosWithUri = photos.map(photo => ({ url: photo }));
  const isCash =
    influencersTier?.nano.cash > 0 ||
    influencersTier?.micro.cash > 0 ||
    influencersTier?.mid.cash > 0 ||
    influencersTier?.macro.cash > 0;

  const isValue = value > 0;

  return (
    <Container>
      <CampaignDetailContainer>
        <DealCardInfluencer
          platform={platform}
          influencersTier={influencersTier}
          gender={gender}
          audienceGender={criteria.gender}
          location={location}
          audienceLocation={location}
          age={age}
          audienceAge={criteria.ages}
          attribute={attribute}
          reviewTag={reviewTag}
          width={detailWidth}
          haveEdit={false}
        />
        <DealCardScopeOfWork
          postMedia={posts.postMedias}
          caption={influencersCaption}
          hashtags={hashtags}
          examples={examples}
          documents={[]}
          influencersTier={influencersTier}
          value={value}
          benefits={benefits[0]}
          platform={platform}
          isValue={isValue}
          isCash={isCash}
          mustReturnProduct={mustReturnProduct}
          isNegotiateCash={isNegotiateCash}
          haveEdit={false}
        />
        <DealCardDetail
          photos={photosWithUri}
          name={name}
          detail={detail}
          category={category}
          isSecret={secret}
          ads={ads}
          startDate={startDate.toString()}
          endDate={dueDate.toString()}
          postingDate={postingDate.toString()}
          submissionDate={submissionDate.toString()}
          haveEdit={false}
        />
      </CampaignDetailContainer>
    </Container>
  );
}

export default observer(NewDealDetail);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
  padding-bottom: 72px;
`;
const CampaignDetailContainer = styled.div`
  gap: 48px;
  width: 100%;
`;
