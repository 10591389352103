import { IconName, RXIcon } from 'rn-rx-icons';
import { COLOR } from '../../fonts/color';
import styled from 'styled-components';
import { TEXT } from '../../fonts/text';

interface Props {
  icon: IconName;
  value: string | number;
  description: string;
  valueColor?: string;
  descriptionColor?: string;
}

export default function ValueDetails(props: Props) {
  const {
    icon,
    value,
    description,
    valueColor = COLOR.WHITE,
    descriptionColor = COLOR.WHITE
  } = props;
  return (
    <Container>
      <ValueContainer>
        <RXIcon name={icon} size={18} color={valueColor} />
        <ValueText textColor={valueColor}>{value}</ValueText>
      </ValueContainer>
      <DescriptionText textColor={descriptionColor}>
        {description}
      </DescriptionText>
    </Container>
  );
}

const Container = styled.div``;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BoldB2Text = styled(TEXT.B3_Bold)``;

const ValueText = styled(BoldB2Text)<{ textColor: string }>`
  margin-left: 5px;
  color: ${props => props.textColor};
`;

const B4Text = styled(TEXT.B4_Reg)``;

const DescriptionText = styled(B4Text)<{ textColor: string }>`
  color: ${props => props.textColor};
  margin-left: 3px;
`;
