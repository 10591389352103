import styled from 'styled-components';
import { COLOR } from './color';
import {
  FontPoppinsRegular,
  FontPoppinsBold,
  FontNotoSansRegular,
  FontNotoSansBold
} from './font';

const commonTextStyle = styled.span`
  color: ${COLOR.BLACK};
`;

export const ANDTEXT = {
  header1: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 96px;
    font-weight: 400;
    line-height: 144px; /* 150% */
  `,
  header2: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 60px;
    font-weight: 400;
    line-height: 88px; /* 146.667% */
  `,
  header3: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 48px;
    font-weight: 400;
    line-height: 72px; /* 150% */
  `,
  header4: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 34px;
    font-weight: 400;
    line-height: 52px; /* 152.941% */
  `,
  header5: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 24px;
    font-weight: 400;
    line-height: 36px; /* 150% */
  `,
  header6: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 20px;
    font-weight: 400;
    line-height: 32px; /* 160% */
  `,
  header7: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
  header8: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  `,
  header9: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
  `,
  body1: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 18px;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  `,
  body2: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
  body3: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  `,
  body4: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
  `,
  body5: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 10px;
    font-weight: 400;
    line-height: 16px; /* 160% */
  `,
  button: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 18px;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  `,
  buttonMedium: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
  buttonSmall: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  `,
  field1: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  `,
  field2: styled(commonTextStyle)`
    font-family: ${FontPoppinsRegular}, ${FontNotoSansRegular};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
  `,
  header1BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 96px;
    font-weight: 700;
    line-height: 144px; /* 150% */
  `,
  header2BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 60px;
    font-weight: 700;
    line-height: 88px; /* 146.667% */
  `,
  header3BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 48px;
    font-weight: 700;
    line-height: 72px; /* 150% */
  `,
  header4BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 34px;
    font-weight: 700;
    line-height: 52px; /* 152.941% */
  `,
  header5BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 24px;
    font-weight: 700;
    line-height: 36px; /* 150% */
  `,
  header6BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 20px;
    font-weight: 700;
    line-height: 32px; /* 160% */
  `,
  header7BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  `,
  header8BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 14px;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
  `,
  header9BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 12px;
    font-weight: 700;
    line-height: 18px; /* 150% */
  `,
  body1BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
  `,
  body2BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  `,
  body3BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 14px;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
  `,
  body4BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 12px;
    font-weight: 700;
    line-height: 18px; /* 150% */
  `,
  body5BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 10px;
    font-weight: 700;
    line-height: 16px; /* 160% */
  `,
  buttonBOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
  `,
  buttonMediumBOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  `,
  buttonSmallBOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 14px;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
  `,
  field1BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
  `,
  field2BOLD: styled(commonTextStyle)`
    font-family: ${FontPoppinsBold}, ${FontNotoSansBold};
    font-size: 12px;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
  `
};
