import React, { useState } from 'react';
import RInput from '../../components/atoms/RInput';
import { COLOR } from '../../fonts/color';
import { useInfluencerListFilterContext } from './InfluencerListContext';

interface Props {
  containerWidth: number;
}
const Search = (props: Props) => {
  const { containerWidth } = props;
  const { filters, updateFilter } = useInfluencerListFilterContext();

  const [tempText, setTempText] = useState<string>(filters.keyword);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateFilter('keyword', e.currentTarget.value);
    }
  };

  const inputStyle = {
    backgroundColor: COLOR.White,
    border: `1px solid ${COLOR.Gray_400}`,
    height: 48,
    width: containerWidth
  };

  return (
    <RInput
      type={'text'}
      inputProp={{
        placeholder: 'ค้นหา Influencer',
        value: tempText,
        onChange: e => setTempText(e.currentTarget.value),
        defaultValue: tempText,
        onBlur: e => {
          updateFilter('keyword', e.currentTarget.value);
        },
        style: inputStyle,
        onKeyDown: e => onKeyDown(e)
      }}
      onClear={() => {
        setTempText('');
        updateFilter('keyword', '');
      }}
      inputIcon={'Search'}
      clearActive={!!tempText}
      containerStyle={{
        backgroundColor: COLOR.White,
        width: containerWidth,
        alignItems: 'center',
        gap: 10
      }}
    />
  );
};

export default Search;
