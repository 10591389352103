import styled from 'styled-components';
import HierarchyDropdown from '../../../../components/atoms/Dropdown/HierarchyDropdown';
import { regionalProvinces } from '../../../../components/organisms/CreateCampaign/Options';
import { useInfluencerListFilterContext } from '../../InfluencerListContext';
import { ColumnContainer } from '../../../../utils/styling/general';
import { TEXT } from '../../../../fonts/text';
import { COLOR } from '../../../../fonts/color';

function AudienceLocation() {
  const { filters, updateFilter } = useInfluencerListFilterContext();

  return (
    <Container>
      <GrayMB3Text>จังหวัด</GrayMB3Text>
      <HierarchyDropdown
        placeholder="เลือกจังหวัด"
        defaultValue={filters.audienceLocation}
        options={regionalProvinces}
        onChange={(option: string[]) => {
          let tempLocation = [...filters.audienceLocation];
          tempLocation = option;
          updateFilter('audienceLocation', tempLocation);
        }}
        nonChildElement={['ทุกจังหวัด']}
        containerStyle={{ width: 304 }}
        totalText="ทุกจังหวัด"
      />
    </Container>
  );
}

export default AudienceLocation;

const Container = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 12px;
`;

const GrayMB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.Gray_M};
`;
