import { action, makeObservable, observable } from 'mobx';
import { GET } from '../utils/Networking';

export class NetworkStore {
  @observable
  isMaintenance = false;
  isOffline = false;

  constructor() {
    makeObservable(this);
    this.checkBackendStatus();
  }

  @action
  checkBackendStatus = async () => {
    if (navigator.onLine) {
      await GET('/').catch(e => {
        console.log(e);
        this.isMaintenance = true;
      });
    } else {
      this.isOffline = true;
    }
  };
}

export const networkStore = new NetworkStore();
