import { useState } from 'react';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { DealsChartData, PlatformType } from '../../../types/deal';
import { toDecimalNumber } from '../../../utils';
import {
  FlexEndColumnContainer,
  FlexStartColumnContainer,
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';
import PerformanceChart from './PerformanceChart/PerformanceChart';
import ExplanationTooltip from './ExplanationTooltip';

const SummaryOverview = () => {
  return <></>;
};

export default SummaryOverview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${COLOR.Gray_300};
`;

const HeaderContainer = styled(SpaceBetweenRowContainer)`
  position: relative;
  padding: 24px;
  border-bottom: 0.6px solid ${COLOR.Gray_300};
`;

const ContentContainer = styled(FlexStartColumnContainer)`
  padding: 24px;
  width: 100%;
  gap: 12px;
`;

const StatsContainer = styled(RowContainer)`
  width: 100%;
  gap: 12px;
`;

const GapRowContainer = styled(RowContainer)`
  gap: 8px;
`;

const EngagementContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 106px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background-color: ${COLOR.Blue_50};
`;

const ReachContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 106px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background-color: ${COLOR.Yellow_L};
`;

const ImpressionContainer = styled.div`
  display: flex;
  padding: 24px;
  height: 106px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background-color: ${COLOR.Green_L};
`;

const BlueCircle = styled.div`
  background-color: ${COLOR.Blue_700};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const YellowCircle = styled.div`
  background-color: ${COLOR.Lemon};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const GreenCircle = styled.div`
  background-color: ${COLOR.Herbal};
  width: 12px;
  height: 12px;
  border-radius: 1000px;
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;

const BoldBlue600H5Text = styled(TEXT.H5_Bold)`
  color: ${COLOR.Blue_600};
`;

const Blue900H7Text = styled(TEXT.H7_Reg)`
  color: ${COLOR.Blue_900};
`;

const BoldYellowMH5Text = styled(TEXT.H5_Bold)`
  color: ${COLOR.Yellow_M};
`;

const YellowDH7Text = styled(TEXT.H7_Reg)`
  color: ${COLOR.Yellow_D};
`;

const BoldHerbalBlue600H5Text = styled(TEXT.H5_Bold)`
  color: ${COLOR.Herbal};
`;

const ForestH7Text = styled(TEXT.H7_Reg)`
  color: ${COLOR.Forest};
`;

const GrayDH9Text = styled(TEXT.H9_Reg)`
  color: ${COLOR.Gray_D};
`;

const Header = () => {
  return (
    <HeaderContainer>
      <BoldH6Text>Summary Overview</BoldH6Text>
      <ExplanationTooltip />
    </HeaderContainer>
  );
};

interface SummaryOverviewStatsProps {
  engagement: number;
  reach: number;
  impression: number;
  cpe: number;
  cpr: number;
  cpm: number;
  platform: PlatformType;
}

const Stats = (props: SummaryOverviewStatsProps) => {
  const { engagement, cpe, platform, reach, cpr, impression, cpm } = props;
  const isTiktok = platform === 'TIKTOK';
  const impressionText = isTiktok ? 'View' : 'Impression';
  return (
    <StatsContainer>
      <EngagementContainer>
        <GapRowContainer>
          <BlueCircle />
          <Blue900H7Text>Engagement</Blue900H7Text>
        </GapRowContainer>
        <FlexEndColumnContainer>
          <BoldBlue600H5Text>
            {toDecimalNumber({ number: engagement, isZeroDash: false })}
          </BoldBlue600H5Text>
          <GrayDH9Text>Total CPE = {cpe.toFixed(1)} ฿</GrayDH9Text>
        </FlexEndColumnContainer>
      </EngagementContainer>
      {platform !== 'TIKTOK' && (
        <ReachContainer>
          <GapRowContainer>
            <YellowCircle />
            <YellowDH7Text>Reach</YellowDH7Text>
          </GapRowContainer>
          <FlexEndColumnContainer>
            <BoldYellowMH5Text>
              {toDecimalNumber({ number: reach, isZeroDash: false })}
            </BoldYellowMH5Text>
            <GrayDH9Text>Total CPR = {cpr.toFixed(1)} ฿</GrayDH9Text>
          </FlexEndColumnContainer>
        </ReachContainer>
      )}
      <ImpressionContainer>
        <GapRowContainer>
          <GreenCircle />
          <ForestH7Text>{impressionText}</ForestH7Text>
        </GapRowContainer>
        <FlexEndColumnContainer>
          <BoldHerbalBlue600H5Text>
            {toDecimalNumber({ number: impression, isZeroDash: false })}
          </BoldHerbalBlue600H5Text>
          <GrayDH9Text>Total CPM = {cpm.toFixed(1)} ฿</GrayDH9Text>
        </FlexEndColumnContainer>
      </ImpressionContainer>
    </StatsContainer>
  );
};

interface SummaryPerformanceChart {
  platform: PlatformType;
  chartData: DealsChartData;
}
const CustomPerformanceChart = (props: SummaryPerformanceChart) => {
  const { chartData, platform } = props;
  const [dateFormat, setDateFormat] = useState<'daily' | 'weekly' | 'monthly'>(
    'daily'
  );
  return (
    <PerformanceChart
      data={chartData}
      dateFormat={dateFormat}
      setDateFormat={setDateFormat}
      platformActive={platform}
    />
  );
};

SummaryOverview.Header = Header;
SummaryOverview.Container = Container;
SummaryOverview.ContentContainer = ContentContainer;
SummaryOverview.Stats = Stats;
SummaryOverview.PerformanceChart = CustomPerformanceChart;
