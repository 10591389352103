import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import RButton from '../../atoms/RButton';
import RModal from '../../atoms/RModal';
import Rradiobutton from '../../atoms/Rradiobutton';

interface Props {
  active: boolean;
  setActive: () => void;
  username?: string;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  onReject: () => void;
}

function RejectInfluModal(props: Props) {
  const { active, setActive, reason, setReason, onReject } = props;
  const reasonList = [
    'ติดต่ออินฟลูเอนเซอร์ไม่ได้',
    'ไม่ตั้งใจจะกดรับ/กดผิด',
    'อินฟลูเอนเซอร์คุณสมบัติไม่ตรงตามบรีฟ',
    'อินฟลูเอนเซอร์ไม่ทำตามเงื่อนไขที่ตกลง'
  ];

  return (
    <RModal
      active={!!active}
      setActive={() => {
        setActive();
        setReason('');
      }}
    >
      <Container>
        <BoldH5Text style={{ marginBottom: '8px' }}>
          ยกเลิกการร่วมงาน
        </BoldH5Text>
        <B3Text style={{ marginBottom: '8px' }}>
          โปรดระบุเหตุผลในการยกเลิกงาน
        </B3Text>
        {reasonList.map(selected => {
          return (
            <ReasonContainer
              key={selected}
              onClick={() => setReason(selected)}
              active={reason === selected}
            >
              <GrayMBu2Text active={reason === selected}>
                {selected}
              </GrayMBu2Text>
              <Rradiobutton active={reason === selected} />
            </ReasonContainer>
          );
        })}
        <div style={{ marginTop: '24px' }}>
          <RButton
            title="ยืนยันการยกเลิก"
            onPress={onReject}
            containerStyle={{ width: '360px' }}
            active={!!reason}
          />
        </div>
        <GrayMB3Text style={{ padding: '24px 32px 0px 32px' }}>
          ขอบคุณสำหรับความคิดเห็นของคุณ! เราจะนำไปปรับปรุง
          <br />
          และพัฒนาแอปพลิเคชั่นให้ดียิ่งขึ้น
        </GrayMB3Text>
      </Container>
    </RModal>
  );
}

export default RejectInfluModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 424px;
  height: 506px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 24px;
  background: ${COLOR.WHITE};
`;

const ReasonContainer = styled.div<ActiveProps>`
  box-sizing: border-box;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  width: 360px;
  height: 48px;
  background: ${props => (props.active ? COLOR.Blue_50 : COLOR.WHITE)};
  border: ${props => (props.active ? 'none' : '1px solid #eaeaea')};
  border-radius: 25px;
  flex: none;
  order: 0;
  flex-grow: 0;

  :hover {
    cursor: pointer;
    background: ${props => (props.active ? COLOR.Blue_50 : COLOR.Gray_50)};
  }
`;

const BoldH5Text = styled(TEXT.H5_Bold)``;

const GrayMBu2Text = styled(TEXT.Bu2_Reg)<{ active: boolean }>`
  color: ${props => (props.active ? COLOR.Black : COLOR.Gray_M)};
`;

const B3Text = styled(TEXT.B3_Reg)``;
const GrayMB3Text = styled(B3Text)`
  color: ${COLOR.Gray_M};
`;
