import { action, computed, makeAutoObservable, observable } from 'mobx';
import { PKNotification } from '../types/notification';
import { objectToQuerystring } from '../utils';
import { GET, POST } from '../utils/Networking';
import { getNumberOfNotiBadge } from './../utils/Badge';

export interface PKNotifications {
  data: Array<PKNotification>;
  metadata: Array<{ count: number; limit: number; offset: number }>;
}

export class NotificationStore {
  @observable
  notifications: PKNotifications = { data: [], metadata: [] };

  @observable
  notificationsLoading: boolean = false;

  @observable
  unreadNotis: number = 0;

  @observable
  chatBadgeLoading = false;

  @observable
  chatBadge: Record<string, number> = {};

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  get unreadNotification() {
    return this.notifications?.data?.filter(notification => notification.unread)
      .length;
  }

  @action
  getUnreadNotis = async () => {
    const result = await getNumberOfNotiBadge();
    this.unreadNotis = result;
  };

  @action
  setUnreadNotis = (number: number) => {
    this.unreadNotis = number;
  };

  @action
  readUnreadNotification = async () => {
    this.notificationsLoading = true;
    const unread = this.notifications?.data
      ?.filter(notification => notification.unread)
      .map(notification => notification.notificationId);

    this.notifications.data?.forEach((notification, index) => {
      if (notification.unread) {
        if (this.notifications?.data[index]?.unread) {
          this.notifications.data[index].unread = false;
        }
      }
    });

    const numberOfUnreadNoti = unread?.length || 0;
    if (numberOfUnreadNoti > 0) {
      await POST('/brands/notifications/read', { notifications: unread });
    }
    this.notificationsLoading = false;
  };

  @action
  readSeenNotification = async () => {
    try {
      const unseen = this.notifications?.data
        ?.filter(notification => !notification.seen)
        .map(notification => notification.notificationId);

      this.notifications.data?.forEach((notification, index) => {
        if (!notification.seen) {
          if (!this.notifications?.data[index]?.seen) {
            this.notifications.data[index].seen = true;
          }
        }
      });

      const numberOfUnreadNoti = unseen?.length || 0;

      if (numberOfUnreadNoti > 0) {
        await GET('/brands/notifications/seen');
      }
    } catch (error) {
      console.log(error);
    }
  };
  getUnreadStatus = ({ index }: { index: number }) => {
    return computed(() => {
      if (this.notifications?.data.length > index) {
        return this.notifications?.data[index]?.unread;
      }
      return false;
    }).get();
  };

  @action
  _readNotification = async ({
    notificationId
  }: {
    notificationId: string;
  }) => {
    const unreads: string[] = [];
    this.notifications.data?.forEach((notification, index) => {
      if (notification.notificationId === notificationId) {
        if (this.notifications?.data[index]?.unread) {
          unreads.push(notification.notificationId);
          this.notifications.data[index].unread = false;
        }
      }
    });
    if (unreads.length > 0) {
      await POST('/brands/notifications/read', {
        notifications: unreads
      });
    }
  };

  @action
  getNotifications = async ({ offset = 0, limit = 10, refresh = true }) => {
    if (this.notificationsLoading) {
      return;
    }
    this.notificationsLoading = true;
    try {
      const queryString = {
        limit,
        offset
      };
      const result = await GET(
        `/brands/notifications${objectToQuerystring(queryString)}`
      );

      if (refresh) {
        this.notifications = { data: [], metadata: [] };
        this.notifications.metadata = result.metadata;
        this.notifications.data = result.data;
        return;
      }

      if (!this.notifications.data) {
        this.notifications.data = [];
      }
      this.notifications.metadata = result.metadata;
      this.notifications.data.push(...result.data);
    } catch (error) {
      console.log(error);
    } finally {
      this.notificationsLoading = false;
    }
  };

  @action
  refreshNotifications = () => {
    this.getNotifications({ refresh: true });
  };

  @action
  getChatBadge = async () => {
    this.chatBadgeLoading = true;
    try {
      const chatBadge = await GET('/brands/notifications/chatbadge');
      this.chatBadge = chatBadge;
    } catch (error) {
      console.log(error);
      this.chatBadgeLoading = false;
    }
  };

  _handleNotification = () => {
    // Vibration.vibrate(500);
    // this.notifications = [notification, ...this.notifications];
    this.getNotifications({}).catch(error => console.log(error));
  };
}

export const notificationStore = new NotificationStore();
