import styled from 'styled-components';
import Facebook from '../../../assets/images/Social/Facebook.png';
import Instagram from '../../../assets/images/Social/Instagram.png';
import LINE from '../../../assets/images/Social/LINE.png';
import Messenger from '../../../assets/images/Social/Messenger.png';
import Twitter from '../../../assets/images/Social/Twitter.png';
import { TEXT } from '../../../fonts/text';
import {
  FacebookHandleClick,
  LINEHandleClick,
  MessengerHandleClick,
  TwitterHandleClick
} from './ShareFunction';

interface Props {
  shareUrl: string;
  type: 'Facebook' | 'Line' | 'Messenger' | 'Twitter' | 'Instagram';
}

const ShareButton = (props: Props) => {
  const { shareUrl, type } = props;
  const handleClick = () => {
    switch (type) {
      case 'Facebook':
        FacebookHandleClick(shareUrl);
        break;
      case 'Line':
        LINEHandleClick(shareUrl);
        break;
      case 'Messenger':
        MessengerHandleClick(shareUrl);
        break;
      case 'Twitter':
        TwitterHandleClick(shareUrl);
        break;
      case 'Instagram':
        break;
    }
  };

  const socialImage = () => {
    switch (type) {
      case 'Facebook':
        return Facebook;
      case 'Line':
        return LINE;
      case 'Messenger':
        return Messenger;
      case 'Twitter':
        return Twitter;
      case 'Instagram':
        return Instagram;
    }
  };

  return (
    <Container onClick={handleClick}>
      <Image src={socialImage()} />
      <B5Text>{type}</B5Text>
    </Container>
  );
};

export default ShareButton;

const Container = styled.button`
  border: none;
  background: none;

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 12px;
`;

const B5Text = styled(TEXT.B5_Reg)``;
