import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import RLoading from '../../components/atoms/RLoading';
import { useAccountStore, useDealStore } from '../../stores/initialize/Context';
import {
  Deal,
  DealPerformance,
  DealPerformanceTableData
} from '../../types/deal';
import { Work, WorkEachTier } from '../../types/work';
import PerformanceCardHeader from './components/PerformanceCardHeader';
import PerformancePageContent from './components/PerformancePageContent';
import { calculatePerformanceData, emptyWorkEachTier } from './function';
import { useNavigate } from 'react-router-dom';

interface Props {
  selectedDeal: string;
}

const Performance = (props: Props) => {
  const { selectedDeal } = props;
  const {
    getDeal,
    deal,
    getWorks,
    campaignWorks,
    loading,
    dealReviews,
    getDealReviews,
    getDealHistory,
    dealHistory
  } = useDealStore();

  const { account } = useAccountStore();

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [pageData, setPageData] = useState<{
    dealPerformance: DealPerformance;
    workEachTier: WorkEachTier;
    performanceTableData: DealPerformanceTableData[];
  }>({
    dealPerformance: {
      like: 0,
      comment: 0,
      replies: 0,
      saved: 0,
      impressions: 0,
      engagement: 0,
      reach: 0,
      share: 0,
      cpe: 0,
      cpr: 0,
      cpm: 0
    },
    workEachTier: { ...emptyWorkEachTier },
    performanceTableData: []
  });
  const [swapLoading, setSwapLoading] = useState<boolean>(false);

  const navigation = useNavigate();

  const {
    name,
    category,
    influencersTier,
    value,
    photos,
    cash,
    platforms = [],
    dueDate,
    createdAt,
    postingDate,
    submissionDate,
    pendingContacts,
    ongoingContacts,
    checkContacts,
    doneContacts,
    posts,
    analysis,
    version
  } = deal;

  const { img, name: accountName } = account;
  const { dealPerformance, workEachTier, performanceTableData } = pageData;

  const isDone = new Date() > new Date(dueDate);
  const isNewDeal = !!version && version === 2;
  const influencerCount =
    pendingContacts.length +
    ongoingContacts.length +
    checkContacts.length +
    doneContacts.length;
  const totalInfluencer =
    influencersTier.nano.value +
    influencersTier.micro.value +
    influencersTier.mid.value +
    influencersTier.macro.value;
  const totalPostCount = totalInfluencer * posts.postMedias.length;

  const initialize = async () => {
    const { works, deal: dealData } = await getData();
    await calculateData(works, dealData);
  };

  const getData = async () => {
    setShowDetail(false);
    const deal = await getDeal({ dealId: selectedDeal });
    const works = await getWorks({ dealId: selectedDeal });
    getDealReviews({
      dealId: selectedDeal,
      limit: 10,
      offset: 0,
      sortOrder: 'desc',
      filter: 0
    });
    getDealHistory({ dealId: selectedDeal });
    return { works, deal };
  };

  const calculateData = async (works: Work[], deal: Deal) => {
    const calculatedData = calculatePerformanceData({ works, deal });
    if (!!calculatedData) setPageData({ ...calculatedData });
  };

  const swapContent = (bool: boolean) => {
    setShowDetail(bool);
    setSwapLoading(true);
    setTimeout(() => {
      setSwapLoading(false);
    }, 500);
  };

  useEffect(() => {
    initialize();
  }, [selectedDeal]);

  if (
    !selectedDeal ||
    loading.deal ||
    loading.influWorks ||
    loading.dealHistory
  ) {
    return <RLoading />;
  }

  return (
    <Container>
      <PerformanceCardHeader
        name={name}
        photos={photos.map(photo => {
          return { url: photo };
        })}
        category={category}
        value={value}
        checkDetail={() => setShowDetail(true)}
        tier={influencersTier}
        cash={cash}
        platforms={platforms}
        isDone={isDone}
        createdAt={createdAt}
        dueDate={dueDate}
        postingDate={postingDate}
        submissionDate={submissionDate}
        influencerCount={influencerCount}
        totalInfluencer={totalInfluencer}
        postCount={campaignWorks.length}
        totalPost={totalPostCount}
        showDetail={showDetail}
        setShowDetail={bool => swapContent(bool)}
      />
      <PerformancePageContent
        showDetail={showDetail}
        deal={deal}
        analysis={analysis}
        campaignWorks={campaignWorks}
        dealReviews={dealReviews}
        reviewLoading={loading.review}
        dealPerformance={dealPerformance}
        workEachTier={workEachTier}
        tableData={performanceTableData}
        chartData={dealHistory}
        img={img}
        name={accountName}
        isNewDeal={isNewDeal}
        navigation={navigation}
        swapLoading={swapLoading}
      />
    </Container>
  );
};

export default observer(Performance);

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 36px;
`;
