import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { Work } from '../../../types/work';
import { ColumnContainer, RowContainer } from '../../../utils/styling/general';
import ProfileFullScreenModal from '../../molecules/CampaignManagement/ProfileFullScreenModal';
import { useState } from 'react';

interface Props {
  work: Work;
}

function DiscoverPreviousWork(props: Props) {
  const { work } = props;
  const { previousWork } = work;

  const [index, setIndex] = useState<number>(0);
  const [imageList, setImageList] = useState<Array<string>>([]);

  return (
    <Container>
      {previousWork?.length > 0 && (
        <>
          <BoldH6Text>ผลงานที่ผ่านมา</BoldH6Text>
          <ImageContainers>
            {previousWork.map((work: string, workIndex: number) => {
              return (
                <ImageContainer
                  src={work}
                  onClick={() => {
                    setImageList(previousWork);
                    setIndex(workIndex);
                  }}
                />
              );
            })}
          </ImageContainers>
        </>
      )}
      <ProfileFullScreenModal
        imageList={imageList}
        resetImage={() => setImageList([])}
        startIndex={index}
      />
    </Container>
  );
}

export default DiscoverPreviousWork;

const Container = styled(ColumnContainer)`
  align-self: flex-start;
  align-items: flex-start;
`;

const ImageContainers = styled(RowContainer)`
  gap: 32px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const ImageContainer = styled.img`
  width: 150px;
  height: 150px;
  background: ${COLOR.Gray_300};
  border-radius: 16px;
  object-fit: cover;
  :hover {
    cursor: pointer;
  }
`;

const BoldH6Text = styled(TEXT.H6_Bold)``;
