import { Alert, Slide, Snackbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import {
  useAccountStore,
  useInfluencerStore
} from '../../../stores/initialize/Context';
import { difTime } from '../../../utils';
import { RowContainer } from '../../../utils/styling/general';
import { BodySize } from '../../atoms/Influencer/BodySize';
import { Category } from '../../atoms/Influencer/Category';

interface Props {
  username: string;
  img: string;
  interests: string[] | undefined;
  lastOnline: string;
  avgRating: number;
  navigate: NavigateFunction;
  dealContactId: string;
  dealId: string;
  page: string;
  accountId: string;
  status?: string;
  bodySize?: {
    weight: string;
    height: string;
    bust: string;
    waist: string;
    hip: string;
    footwear: string;
  };
}

interface ProfileImageProps {
  isProfile: boolean;
}

function InfluencerDetailHeader(props: Props) {
  const {
    username,
    img,
    interests = [],
    lastOnline,
    avgRating = 0,
    navigate,
    dealId,
    dealContactId,
    page,
    accountId,
    status = 'pending',
    bodySize
  } = props;

  const { brandSaved } = useAccountStore();
  const { triggerSaveInfluencer } = useInfluencerStore();

  const isSaved = brandSaved.includes(accountId);
  const snackMessage = isSaved
    ? ['บันทึกไว้ที่', 'เรียบร้อยแล้ว']
    : ['นำรายชื่อออกจาก', 'แล้ว'];

  const [activeSnackbar, setActiveSnackbar] = useState<boolean>(false);

  const transitionProp: React.ComponentType<
    TransitionProps & {
      children: React.ReactElement<any, any>;
    }
  > = Slide;

  const saveInfluencer = () => {
    if (isSaved) {
      triggerSaveInfluencer({
        accountId,
        isSaved: true
      });
    } else {
      triggerSaveInfluencer({
        accountId,
        isSaved: false
      });
    }
    setActiveSnackbar(true);
  };

  const SaveIconComponent = () => {
    if (isSaved) return <RXIcon name={'SaveFill'} color={COLOR.Blue_700} />;
    return <RXIcon name={'Save'} color={COLOR.Blue_700} />;
  };

  return (
    <Container>
      <HeaderContainer>
        <DetailContainer>
          <ImageContainer
            isProfile={page === 'profile'}
            src={img}
            onClick={() => {
              navigate(
                `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&page=profile`
              );
            }}
          />
          <NameContainer>
            <BoldH5Text
              onClick={() => {
                navigate(
                  `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&page=profile`
                );
              }}
            >
              {username}
            </BoldH5Text>
            <RowContainer style={{ marginTop: 8 }}>
              <RXIcon name="Clock" size={20} />
              <B5Text>Active {difTime(lastOnline)} ago</B5Text>
            </RowContainer>
          </NameContainer>
          <ReviewContainer>
            <RXIcon name="StarFilled" color={COLOR.YELLOW} size={20} />
            <BoldB4Text>{avgRating.toFixed(1)}</BoldB4Text>
          </ReviewContainer>
        </DetailContainer>
        <ButtonContainer>
          {/* TODO : Re missing design */}
          {status !== 'pending' && (
            <ChatButton
              onClick={() => {
                navigate(
                  `/influencer?dealId=${dealId}&dealContactId=${dealContactId}&page=chat`
                );
              }}
            >
              <IconContainer>
                <RXIcon name="Chat" color={COLOR.Blue_700} />
              </IconContainer>
              <BoldBu1Text>Message</BoldBu1Text>
            </ChatButton>
          )}
          <SaveButton onClick={saveInfluencer}>
            <SaveIconComponent />
          </SaveButton>
        </ButtonContainer>
      </HeaderContainer>
      <Category interests={interests} />
      {!!bodySize && <BodySize bodySize={bodySize} />}
      <Snackbar
        open={activeSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setActiveSnackbar(false)}
        TransitionComponent={transitionProp}
        key={'saveSnackbar'}
      >
        <Alert
          icon={<RXIcon name="CheckCircle" color={COLOR.WHITE} />}
          variant="filled"
          onClose={() => setActiveSnackbar(false)}
          sx={{
            height: '48px',
            width: '344px',
            bgcolor: COLOR.BLACK,
            alignItems: 'center',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
          }}
        >
          <WhiteB3Text>
            {snackMessage[0]} <Blue300BoldB3Text>Favorites</Blue300BoldB3Text>{' '}
            {snackMessage[1]}
          </WhiteB3Text>
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default observer(InfluencerDetailHeader);

const Container = styled.div`
  padding: 24px 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ImageContainer = styled.img<ProfileImageProps>`
  width: 50px;
  height: 50px;
  background: ${COLOR.Gray_200};
  border-radius: 50px;
  object-fit: cover;
  :hover {
    cursor: ${props => (props.isProfile ? 'normal' : 'pointer')};
  }
`;

const NameContainer = styled.div`
  margin-left: 16px;
`;

const ReviewContainer = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  margin-left: 16px;
  padding: 0px 10px 0px 8px;
  border-radius: 20px;
  background: ${COLOR.Gray_50};
`;

const IconContainer = styled.div``;

const BoldH5Text = styled(TEXT.H5_Bold)`
  :hover {
    cursor: pointer;
  }
`;

const BoldBu1Text = styled(TEXT.Bu1_Bold)`
  color: ${COLOR.Blue_700};
`;

const BoldB4Text = styled(TEXT.B4_Bold)`
  margin-top: 3px;
  margin-left: 4px;
`;

const B5Text = styled(TEXT.B5_Reg)`
  margin-left: 4px;
  margin-top: 1px;
`;

const WhiteB3Text = styled(TEXT.B3_Reg)`
  color: ${COLOR.White};
`;

const Blue300BoldB3Text = styled(TEXT.B3_Bold)`
  color: ${COLOR.Blue_300};
`;

const SaveButton = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  :hover {
    cursor: pointer;
    background: ${COLOR.Gray_50};
  }
`;

const ChatButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 141px;
  height: 48px;
  background: ${COLOR.White};
  border: 1px solid ${COLOR.Blue_700};
  border-radius: 50px;

  :hover {
    cursor: pointer;
    background: ${COLOR.Blue_50};
  }
`;
