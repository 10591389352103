import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from '../../components/atoms/ScrollToTop';
import SettingContent from '../../components/organisms/Setting/SettingContent';
import { useAccountStore } from '../../stores/initialize/Context';
import { useQuery } from '../../utils';
import { RowContainer } from '../../utils/styling/general';

function Setting() {
  const navigate = useNavigate();
  let query = useQuery();
  const page = query.get('page') as string;
  const { account } = useAccountStore();
  return (
    <Container>
      <ScrollToTop />
      <ContentContainer>
        <SettingContent navigate={navigate} page={page} account={account} />
      </ContentContainer>
    </Container>
  );
}

export default observer(Setting);

const Container = styled(RowContainer)`
  align-items: flex-start;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  min-width: 1200px;
  width: 100%;
  padding: 20px 36px 36px 36px;
`;
