import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { IconName } from 'rn-rx-icons';
import styled from 'styled-components';
import { useChatStore, useDealStore } from '../../../stores/initialize/Context';
import { Account } from '../../../types/account';
import { IGetInfluencerResponse } from '../../../types/api/getInfluencer';
import { IInfluencerList } from '../../../types/api/listInfluencers';
import { Work } from '../../../types/work';
import { PLACEHOLDER_PROFILE } from '../../../utils';
import FinishWorkModal from '../../molecules/CampaignManagement/FinishWorkModal';
import InfluencerDetailHeader from '../../molecules/CampaignManagement/InfluencerDetailHeader';
import InfluencerWork from '../../molecules/CampaignManagement/InfluencerWork';

interface Props {
  influencer: IGetInfluencerResponse | undefined;
  influencers: Record<
    'pending' | 'ongoing' | 'check' | 'done',
    IInfluencerList
  >;
  lastOnline: string;
  dealContactId: string;
  displayImage: (workList: Work[], index: number) => void;
  openFinishWorkModal: () => void;
  navigate: NavigateFunction;
  brandAccount: Account;
  finishWorkModal: boolean;
  closeFinishModal: () => void;
  getDealInfluReview: ({
    dealContactId,
    rate,
    comment,
    reviewTag
  }: {
    dealContactId: string;
    influencerName: string;
    rate: string;
    comment: string;
    reviewTag: number[];
  }) => Promise<void>;
  removeToReviewInflu: ({ dealContactId }: { dealContactId: string }) => void;
}

function InfluencerCheckWork(props: Props) {
  const {
    influencer,
    influencers,
    lastOnline,
    dealContactId,
    displayImage,
    openFinishWorkModal,
    navigate,
    brandAccount,
    finishWorkModal,
    closeFinishModal,
    getDealInfluReview,
    removeToReviewInflu
  } = props;
  const { deal, influWorks, getInfluWorks } = useDealStore();
  const { forwardImages, forwardVideos } = useChatStore();
  const { account, instagram } = influencer || {};

  const {
    displayName = '',
    name = '',
    img,
    interests,
    id: accountId = '',
    bodySize,
    review = {
      avgRating: 5
    }
  } = account || {};

  const initialize = async () => {
    if (dealContactId) {
      await getInfluWorks({ dealContactId });
    }
  };

  useEffect(() => {
    initialize().catch(error => {
      console.log(error);
    });
  }, [dealContactId]);

  const { works, dealContact } = influWorks || {};
  const { scopeOfWorks, status } = dealContact || {};
  const { posts: originalPosts } = deal;

  const requirements = scopeOfWorks || originalPosts;

  const hasInstagram =
    requirements.IG_individual_post > 0 ||
    requirements.IG_album_post > 0 ||
    requirements.IG_story > 0 ||
    requirements.IG_reels > 0 ||
    requirements.IG_tv > 0;

  const hasTiktok = requirements.TK_video > 0;

  const hasLemon = requirements.LM_article > 0;

  const hasFacebook =
    requirements.FB_individual_post > 0 ||
    requirements.FB_album_post > 0 ||
    requirements.FB_reels > 0;

  const IGTypes: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[] = [
    {
      title: 'Single-Post',
      value: 'IG_individual_post',
      icon: 'Image',
      requirement: requirements.IG_individual_post
    },
    {
      title: 'Multi Post',
      value: 'IG_album_post',
      icon: 'Multi',
      requirement: requirements.IG_album_post
    },
    {
      title: 'Story',
      value: 'IG_story',
      icon: 'Story',
      requirement: requirements.IG_story
    },
    {
      title: 'IGTV',
      value: 'IG_tv',
      icon: 'TV',
      requirement: requirements.IG_tv
    },
    {
      title: 'Reels',
      value: 'IG_reels',
      icon: 'Reels',
      requirement: requirements.IG_reels
    }
  ];

  const TKTypes: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[] = [
    {
      title: 'TikTok Video',
      value: 'TK_video',
      icon: 'Tiktok',
      requirement: requirements.TK_video
    }
  ];

  const FBTypes: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[] = [
    {
      title: 'Single-Post',
      value: 'FB_individual_post',
      icon: 'Image',
      requirement: requirements.FB_individual_post
    },
    {
      title: 'Multi Post',
      value: 'FB_album_post',
      icon: 'Multi',
      requirement: requirements.FB_album_post
    },
    {
      title: 'Reels',
      value: 'FB_reels',
      icon: 'Reels',
      requirement: requirements.FB_reels
    }
  ];

  const LMTypes: {
    title: string;
    value: string;
    icon: IconName;
    requirement: number | string;
  }[] = [
    {
      title: 'Lemon8 Article',
      value: 'LM_article',
      icon: 'Article',
      requirement: requirements.LM_article
    }
  ];

  return (
    <Container>
      <InfluencerDetailHeader
        username={displayName || name}
        img={img || PLACEHOLDER_PROFILE}
        interests={interests}
        lastOnline={lastOnline}
        avgRating={review.avgRating}
        navigate={navigate}
        dealContactId={dealContactId}
        dealId={deal.dealId}
        page={'work'}
        accountId={accountId || ''}
        bodySize={bodySize}
      />
      <InfluencerWork
        hasInstagram={hasInstagram}
        igRequirements={IGTypes}
        hasTiktok={hasTiktok}
        tkRequirements={TKTypes}
        hasLemon={hasLemon}
        lmRequirements={LMTypes}
        works={works}
        displayImage={displayImage}
        sendImages={forwardImages}
        sendVideos={forwardVideos}
        openFinishWorkModal={openFinishWorkModal}
        navigate={navigate}
        dealContactId={dealContactId}
        dealId={deal.dealId}
        status={status}
        account={brandAccount}
        reviewScore={dealContact?.review?.rate || 0}
        hasFacebook={hasFacebook}
        fbRequirements={FBTypes}
      />
      <FinishWorkModal
        key={accountId}
        finishWorkModal={finishWorkModal}
        setFinishWorkModal={closeFinishModal}
        accountId={accountId || ''}
        username={influencer?.account?.displayName || influencer?.account?.name}
        img={influencer?.account?.img || PLACEHOLDER_PROFILE}
        submissionDate={deal.submissionDate}
        review={async (star: string, comment: string, reviewTag: number[]) => {
          getDealInfluReview({
            dealContactId: dealContactId,
            influencerName:
              influencer?.account?.displayName ||
              influencer?.account?.name ||
              '',
            rate: star,
            comment,
            reviewTag
          });
          const index = influencers.check.data.findIndex(influencer => {
            return influencer.dealContactId === dealContactId;
          });
          removeToReviewInflu({ dealContactId });
          if (influencers.check.data.length !== 0) {
            let newIndex = index;
            if (influencers.check.data.length === index) newIndex = index - 1;
            const nextDealContactId =
              influencers.check.data[newIndex].dealContactId;
            navigate(
              `/influencer?dealId=${
                deal.dealId
              }&dealContactId=${nextDealContactId}&page=work&platform=${deal.platforms[0].toUpperCase()}`
            );
          } else {
            navigate(`/deal?dealId=${deal.dealId}&status=done`);
          }
        }}
        // isSaved={isSaved}
        // saveFavorite={() => {
        //   saveFavorite({
        //     accountId,
        //     isSaved: !isSaved,
        //     localIsSaved: true,
        //   });
        // }}
      />
    </Container>
  );
}

export default observer(InfluencerCheckWork);

const Container = styled.div`
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 20px 36px 36px 36px;
  border-radius: 16px;
`;
