import { RXIcon } from 'rn-rx-icons';
import styled from 'styled-components';
import { COLOR } from '../../../fonts/color';
import { TEXT } from '../../../fonts/text';
import { ActiveProps } from '../../../types';
import { DealAnalysis } from '../../../types/deal';
import {
  genderFieldToText,
  PLACEHOLDER_PROFILE,
  toDecimalNumber
} from '../../../utils';
import {
  RowContainer,
  SpaceBetweenRowContainer
} from '../../../utils/styling/general';

interface Props {
  tags: ('High Reach' | 'Popular')[] | undefined;
  analysis: DealAnalysis | undefined;
  pastInfluencers:
    | {
        id: string;
        profile_picture_url: string;
        username: string;
      }[]
    | undefined;
  brandName: string;
  brandImg: string;
  totalRegister: number;
}

interface TagsProps {
  color: string;
}

function DealCardBrandProfile(props: Props) {
  const {
    tags,
    analysis,
    pastInfluencers = [],
    brandName,
    brandImg,
    totalRegister
  } = props;

  const displayRegister = () => {
    if (totalRegister < 4) {
      return 'สมัครแคมเปญนี้';
    } else {
      return `และอีก ${
        totalRegister - pastInfluencers.length
      } คนสมัครแคมเปญนี้`;
    }
  };

  const customColor =
    tags && tags[0] === 'Popular'
      ? COLOR.PINK
      : tags && tags[0] === 'High Reach'
        ? COLOR.YELLOW
        : COLOR.Gray_M;

  const renderTag = () => {
    if (tags && (tags[0] === 'High Reach' || tags[0] === 'Popular')) {
      return (
        <BannerContainer>
          {tags && tags[0] === 'High Reach' ? (
            <TagsContainer color={customColor}>
              <TagsTextContainer>
                <IconContainer>
                  <RXIcon name="Signal" size={40} color={COLOR.WHITE} />
                </IconContainer>
                <ColumnContainer>
                  <BoldBody1Text>High Reach Campaign on Pickle</BoldBody1Text>
                  <Body2Text>
                    แคมเปญที่ Influencer โปรโมท ที่มียอด Like และ Comment
                    เป็นจำนวนมาก
                  </Body2Text>
                </ColumnContainer>
              </TagsTextContainer>
            </TagsContainer>
          ) : (
            <TagsContainer color={customColor}>
              <TagsTextContainer>
                <IconContainer>
                  <RXIcon name="Popular" size={40} color={COLOR.WHITE} />
                </IconContainer>
                <ColumnContainer>
                  <BoldBody1Text>Popular Campaign on Pickle</BoldBody1Text>
                  <Body2Text>
                    แคมเปญที่ได้รับการสมัครเข้าร่วมจาก Influencer เป็นจำนวนมาก
                  </Body2Text>
                </ColumnContainer>
              </TagsTextContainer>
            </TagsContainer>
          )}
          <TagsDetailContainer>
            <InfoContainer color={customColor}>
              <RXIcon name="HeartFill" color={customColor} />

              <ColumnInfoContainer>
                <BoldCustomBody1Text color={customColor}>
                  {toDecimalNumber({
                    number: analysis?.likes || 0,
                    toFixed: 0
                  })}
                </BoldCustomBody1Text>
                <CustomBody1Text color={customColor}>Like</CustomBody1Text>
              </ColumnInfoContainer>
            </InfoContainer>
            <InfoContainer color={customColor}>
              <RXIcon name="Comment" color={customColor} />
              <ColumnInfoContainer>
                <BoldCustomBody1Text color={customColor}>
                  {toDecimalNumber({
                    number: analysis?.comments || 0,
                    toFixed: 0
                  })}
                </BoldCustomBody1Text>
                <CustomBody1Text color={customColor}>Comment</CustomBody1Text>
              </ColumnInfoContainer>
            </InfoContainer>
            <InfoContainer color={customColor}>
              <RXIcon name="Signal" color={customColor} />
              <ColumnInfoContainer>
                <BoldCustomBody1Text color={customColor}>
                  {toDecimalNumber({
                    number: analysis?.reachs || 0,
                    toFixed: 0
                  })}
                </BoldCustomBody1Text>
                <CustomBody1Text color={customColor}>Reach</CustomBody1Text>
              </ColumnInfoContainer>
            </InfoContainer>
          </TagsDetailContainer>
        </BannerContainer>
      );
    }
  };

  return (
    <Container>
      <ColumnContainer>
        <SpaceBetweenRowContainer>
          <ProfileContainer>
            <Avartar src={brandImg} />
            <BoldH6Text>{brandName}</BoldH6Text>
          </ProfileContainer>
          <ProfileContainer>
            <InfluImageContainer>
              {pastInfluencers &&
                pastInfluencers.map(
                  (
                    image: {
                      id: string;
                      profile_picture_url: string;
                      username: string;
                    },
                    index: number
                  ) => {
                    return (
                      <InfluImage
                        style={{
                          zIndex: '9' + 9 * Math.pow(10, index),
                          marginLeft: index === 0 ? '0px' : '-20px'
                        }}
                        alt={PLACEHOLDER_PROFILE}
                        src={image.profile_picture_url || PLACEHOLDER_PROFILE}
                        onError={e => {
                          e.currentTarget.src = PLACEHOLDER_PROFILE;
                        }}
                      />
                    );
                  }
                )}
            </InfluImageContainer>
            <BoldBody2Text>{displayRegister()}</BoldBody2Text>
          </ProfileContainer>
        </SpaceBetweenRowContainer>
        {renderTag()}
      </ColumnContainer>
    </Container>
  );
}

export default DealCardBrandProfile;

const Container = styled.div`
  margin-top: 32px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 32px 0px;
  padding: 0px;
  width: 822px;
  height: 96px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
`;

const TagsContainer = styled.div<TagsProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;

  width: 411px;
  height: 96px;

  background: ${props => props.color};
  border-radius: 16px 0px 0px 16px;
`;

const TagsDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  gap: 16px;

  width: 411px;
  height: 96px;

  /* White/Primary */

  background: #ffffff;
  border-radius: 0px 16px 16px 0px;
`;

const TagsTextContainer = styled(RowContainer)`
  align-items: flex-start;
  gap: 16px;
  width: 379px;
`;

const IconContainer = styled.div``;

const ColumnInfoContainer = styled(ColumnContainer)`
  align-items: flex-start;
  margin-top: 8px;
`;

const InfoContainer = styled.div<{ color: string }>`
  display: flex;
  height: 24px;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
`;

const Avartar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
`;

const InfluImageContainer = styled.div`
  margin-right: 8px;
`;

const InfluImage = styled.img`
  width: 50px;
  height: 50px;
  border: 2.5px solid ${COLOR.WHITE};
  border-radius: 50%;
  object-fit: cover;
`;

const BoldH6Text = styled(TEXT.header6BOLD)`
  max-width: 432px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoldBody2Text = styled(TEXT.body2BOLD)``;

const BoldBody1Text = styled(TEXT.body1BOLD)`
  color: ${COLOR.WHITE};
`;

const Body2Text = styled(TEXT.body2)`
  color: ${COLOR.WHITE};
`;

const BoldCustomBody1Text = styled(TEXT.body1BOLD)<{ color: string }>`
  color: ${props => props.color};
`;

const CustomBody1Text = styled(TEXT.body1)<{ color: string }>`
  color: ${props => props.color};
`;
